import React, { useEffect, useState, useRef } from 'react';
import { Form, Modal, Spinner, ButtonGroup } from 'react-bootstrap';
import { Button, Row, Col, CardHeader, Card, CardBody } from 'reactstrap';
import Axios from 'axios';
import { useHistory } from 'react-router-dom';
import { Backspace, Save2Fill, Star } from 'react-bootstrap-icons';
import { Typeahead } from 'react-bootstrap-typeahead';
import 'react-bootstrap-typeahead/css/Typeahead.css';
import * as Yup from 'yup';
import { Formik } from 'formik';
import dateformat from 'dateformat';
import common from '../../commonData';
import { ProductList } from './card';

var count = 0;

function UpdateCommandeFrs({ ids, show, setIsSave, setMsg, setMsgravity, setLoad, handleClose, setLoading, load, loading }) {
    var ladate = new Date();
    const [codeart, setCodeart] = useState([]);
    const [article, setArticle] = useState([]);
    const [clients, setClientData] = useState([]);
    const [listeSelect, setListeSelect] = useState([]);
    const [articles, setArticleData] = useState([]);
    const [famille, setfamilleData] = useState([]);
    const [famille_id, setIdFamille] = useState('');

    const [states, setStates] = useState({
        clients_id: '',
        dateCommande: dateformat(ladate.getMonth() + 1 + '/' + ladate.getDate() + '/' + ladate.getFullYear(), 'yyyy-mm-dd'),
        datePrevLivr: '',
        observation: ''
    });
    const { clients_id, dateCommande, datePrevLivr, observation } = states;

    const history = useHistory();
    const refFourn = useRef(null);
    const typeaheadModel = useRef(null);

    useEffect(() => {
        if (!localStorage.getItem('user-info')) {
            history.push('/login');
        }
        (async function anyNameFunction() {
            setLoad(true);
            const getarticle = Axios.get(common.ipapi + '/api/article/indexall');
            const getClient = Axios.get(common.ipapi + '/api/client/indexmin');
            const getFamille = Axios.get(common.ipapi + '/api/famille/indexstep');
            const getcmd = Axios.get(common.ipapi + '/api/commandecli/' + ids);
            await Axios.all([getarticle, getClient, getFamille, getcmd])
                .then(
                    Axios.spread((...responses) => {
                        setArticle(responses[0].data);
                        setArticleData(responses[0].data);
                        setClientData(responses[1].data);
                        setfamilleData(responses[2].data);
                        setListeSelect(responses[3].data.datas.lignecommande);
                        setStates({
                            clients_id: responses[3].data.datas.clients_id,
                            dateCommande: dateformat(responses[3].data.datas.datecommande, 'yyyy-mm-dd'),
                            datePrevLivr: dateformat(responses[3].data.datas.datelivraison_prevu, 'yyyy-mm-dd'),
                            observation: responses[3].data.datas.observation
                        });
                        setLoad(false);
                    })
                )
                .catch((errors) => {
                    setLoad(false);
                });
        })();
    }, [history, ids]);

    function setFamilleId(d) {
        setIdFamille(d.length > 0 ? d[0].id : 0);
        if (d.length > 0) {
            const res = article.filter((element) => element.famille_id == d[0].id);
            setArticle(res);
        } else {
            setArticle(articles);
        }
    }

    function setCodearts(codeart) {
        setCodeart(codeart);
        if (codeart.length > 0) {
            codeart.forEach((ent, index) => {
                function search(d) {
                    return d.id === ent.article_id;
                }
                const del = listeSelect.find(search);
                if (del === undefined) {
                    let t = [];
                    let d = {
                        article_id: ent.id,
                        qrbarre: codeart[0].qrbarre,
                        simodif: true,
                        isNew: true,
                        pivot: {
                            quantite: 1,
                            prix: ent.prixvente,
                            descriptionart: ent.descriptionartfr || ent.descriptionart,
                            article_id: ent.id,
                            commande_id: 0
                        }
                    };
                    t.push(d);
                    t = t.concat(listeSelect);
                    setListeSelect(t);
                    setCodeart([]);
                } else {
                    setIsSave(true);
                    setMsgravity('primary');
                    setMsg('A été déjà ajouter à la liste');
                    setCodeart([]);
                }
            });
        }
    }

    const onChangeProductQuantity = (index, event) => {
        const cloneProducts = [...listeSelect];
        cloneProducts[index].pivot.quantite = event.target.value;
        cloneProducts[index].simodif = true;
        setListeSelect(cloneProducts);
    };

    const onChangePrestation = (index, event) => {
        const value = event.target.value;
        const cloneProducts = [...listeSelect];
        cloneProducts[index].pivot.descriptionart = value;
        cloneProducts[index].simodif = true;
        setListeSelect(cloneProducts);
    };

    const onRemoveProduct = async (i, lignedata) => {
        let text = 'Voulez-vous supprimer cette ligne';
        let res = window.confirm(text);
        if (res === false) {
            return;
        }
        if (typeof lignedata.isNew === 'undefined') {
            const response = await fetch(common.ipapi + '/api/commandecli/destroyline/' + lignedata.pivot.id, {
                method: 'DELETE'
            });
            const json = await response.json();
        }
        var filteredProduct = [];
        setLoad(true);
        filteredProduct = listeSelect.filter((product, index) => {
            return index != i;
        });
        setListeSelect(filteredProduct);
        setMsg('Ligne supprimée');
        setMsgravity('info');
        setIsSave(true);
        setLoad(false);
    };

    function saveCommande(values) {
        setLoad(true);
        if (listeSelect.length === 0) {
            setMsg('Veuillez saisir au moin une prestation');
            setMsgravity('info');
            setIsSave(true);
            setLoad(false);
            return false;
        }

        let text = 'Voulez-vous confirmer cette action';
        let res = window.confirm(text);
        if (res === false) {
            return;
        }
        const del = listeSelect.filter((d) => d.simodif === true);

        var formData = new FormData();
        formData.append('datecommande', values.datecommande);
        formData.append('datelivraisonprevu', values.dateprevlivr);
        formData.append('observation', values.observation);
        formData.append('codeart', JSON.stringify(del));
        formData.append('user_id', common.userid);
        formData.append('clients_id', values.clients_id);
        Axios({
            url: common.ipapi + '/api/commandecli/update/' + ids,
            method: 'POST',
            data: formData
        })
            .then((res) => {
                if (res.status !== 200) {
                    setMsg('Veuillez vérifier votre connexion internet');
                    setMsgravity('info');
                    setIsSave(false);
                    return;
                }
                if (res.data.status == 1) {
                    setCodeart([]);
                    setListeSelect([]);
                    setMsg(res.data.msg);
                    setMsgravity('success');
                    setIsSave(true);
                    count++;
                    setLoading(!loading);
                    setLoad(false);
                    handleClose();
                } else {
                    setMsg("Une erreur c'est produite veuillez verifier les données saisies");
                    setMsgravity('danger');
                    setIsSave(true);
                    setLoad(false);
                }
            })
            .catch((error) => {
                setIsSave(true);
                setMsgravity('danger');
                setLoad(false);
            });
    }

    return (
        <>
            <Modal show={load} size="sm" aria-labelledby="contained-modal-title-vcenter" centered>
                <Modal.Body>
                    Veuillez patienter.......
                    <Spinner animation="grow" variant="warning" />
                </Modal.Body>
            </Modal>
            <Formik
                initialValues={{
                    clients_id: clients_id,
                    datecommande: dateCommande,
                    dateprevlivr: datePrevLivr,
                    observation: observation
                }}
                enableReinitialize
                validationSchema={Yup.object().shape({
                    clients_id: Yup.number().required('Veuillez saisir cette donnée'),
                    datecommande: Yup.date().required('Veuillez saisir cette donnée')
                })}
                onSubmit={async (values, { setSubmitting }) => {
                    try {
                        saveCommande(values);
                        setSubmitting(false);
                    } catch (err) {
                        setMsg('Une erreur à eu lieu');
                        setMsgravity('danger');
                        setIsSave(true);
                        setLoad(false);
                    }
                }}
            >
                {({ errors, handleBlur, handleChange, handleSubmit, values, setFieldValue, setFieldTouched }) => (
                    <Modal show={show} fullscreen={true}>
                        <Form noValidate onSubmit={handleSubmit}>
                            <Modal.Header>
                                MODIFIER UNE COMMANDE CLIENT
                                <Backspace onClick={handleClose} color="red" size={30} />
                            </Modal.Header>
                            <Modal.Body>
                                <div id="lightbody">
                                    <Row>
                                        <Col md={4}>
                                            <Form.Group as={Row} className="mb-3" controlId="formHorizontalEmail">
                                                <Form.Label column sm={2}>
                                                    Client <Star color="red" size={10} />
                                                </Form.Label>
                                                <Col sm={10}>
                                                    <Typeahead
                                                        id="clients_id"
                                                        ref={refFourn}
                                                        multiple={false}
                                                        onChange={(selected) => {
                                                            const value = selected.length > 0 ? selected[0].id : 0;
                                                            setFieldValue('clients_id', value);
                                                        }}
                                                        onInputChange={(text, event) => setFieldValue('clients_id', text)}
                                                        onBlur={(e) => setFieldTouched('clients_id', true)}
                                                        labelKey="raisonsocial"
                                                        options={clients}
                                                        selected={clients.slice(
                                                            clients.findIndex((element) => element.id === values.clients_id),
                                                            clients.findIndex((element) => element.id === values.clients_id) + 1
                                                        )}
                                                        disabled={common.userclientid ? true : false}
                                                    />
                                                    {errors.clients_id && <div id="feedback">{errors.clients_id}</div>}
                                                </Col>
                                            </Form.Group>

                                            <Form.Group as={Row} className="mb-3" controlId="formPlaintextDateCommande">
                                                <Form.Label column sm={5}>
                                                    Date commande <Star color="red" size={10} />
                                                </Form.Label>
                                                <Col sm={7}>
                                                    <Form.Control
                                                        id="datecommande"
                                                        type="date"
                                                        placeholder="Date commande"
                                                        aria-describedby="inputGroupPrepend"
                                                        required
                                                        size="sm"
                                                        value={values.datecommande}
                                                        onBlur={handleBlur}
                                                        onChange={handleChange}
                                                    />
                                                    {errors.datecommande && <div id="feedback">{errors.datecommande}</div>}
                                                </Col>
                                            </Form.Group>

                                            <Form.Group as={Row} className="mb-3">
                                                <Form.Label column sm="5">
                                                    Date livraison voulue <Star color="red" size={10} />
                                                </Form.Label>
                                                <Col sm="7">
                                                    <Form.Control
                                                        id="dateprevlivr"
                                                        type="date"
                                                        placeholder="Date livraison prévisionnelle"
                                                        aria-describedby="dateliv"
                                                        size="sm"
                                                        value={values.dateprevlivr}
                                                        onBlur={handleBlur}
                                                        onChange={handleChange}
                                                    />
                                                </Col>
                                            </Form.Group>

                                            <Form.Group as={Row} className="mb-3">
                                                <Form.Label column sm="4">
                                                    Observation
                                                </Form.Label>
                                                <Col sm="8">
                                                    <Form.Control
                                                        id="observation"
                                                        as="textarea"
                                                        placeholder="Observation"
                                                        aria-describedby="inputGroupPrepend"
                                                        size="sm"
                                                        value={values.observation}
                                                        onBlur={handleBlur}
                                                        onChange={handleChange}
                                                        rows={6}
                                                    />
                                                </Col>
                                            </Form.Group>
                                        </Col>
                                        <Col md={8}>
                                            <Card>
                                                <CardHeader>Sélection des articles à commander</CardHeader>
                                                <CardBody>
                                                    <Row>
                                                        <Col md={4}>
                                                            <Form.Group as={Col} md="12" controlId="famille">
                                                                <Form.Label column sm="12">
                                                                    Filtre par model{' '}
                                                                </Form.Label>
                                                                <Col sm="12">
                                                                    <Typeahead
                                                                        ref={typeaheadModel}
                                                                        id="my-typeahead-id"
                                                                        labelKey={(option) => `${option.libellefam}`}
                                                                        filterBy={['libellefam']}
                                                                        value={famille_id}
                                                                        options={famille}
                                                                        onChange={(cli) => setFamilleId(cli)}
                                                                    />
                                                                </Col>
                                                            </Form.Group>
                                                        </Col>
                                                        <Col md={8}>
                                                            <Form.Group as={Col} md="12" controlId="validationCustomUsername">
                                                                <Form.Label column sm="12">
                                                                    Sélectionner vos articles{' '}
                                                                </Form.Label>
                                                                <Col sm="12">
                                                                    <Typeahead
                                                                        id="my-typeahead-id"
                                                                        labelKey={(option) =>
                                                                            `${option.qrbarre} / ${
                                                                                option.descriptionartfr || option.descriptionart
                                                                            } / qte (${option.qtestock})`
                                                                        }
                                                                        filterBy={['descriptionart', 'descriptionartfr', 'qrbarre']}
                                                                        selected={codeart}
                                                                        options={article}
                                                                        placeholder="Sélectionner vos articles ici"
                                                                        onChange={(codeart) => setCodearts(codeart)}
                                                                    />
                                                                </Col>
                                                            </Form.Group>
                                                        </Col>
                                                        <Col>
                                                            <ProductList
                                                                products={listeSelect}
                                                                onChangeProductQuantity={onChangeProductQuantity}
                                                                onRemoveProduct={onRemoveProduct}
                                                                onChangePrestation={onChangePrestation}
                                                            />
                                                        </Col>
                                                    </Row>
                                                </CardBody>
                                            </Card>
                                        </Col>
                                    </Row>
                                </div>
                            </Modal.Body>
                            <Modal.Footer>
                                <div className="ml-auto">
                                    <ButtonGroup size="sm">
                                        <Button color="secondary" onClick={handleClose}>
                                            <Backspace color="red" size={25} />
                                        </Button>
                                        <Button type="submit" color="warning">
                                            <Save2Fill color="#000" size={25} /> Enregistrer
                                        </Button>
                                    </ButtonGroup>
                                </div>
                            </Modal.Footer>
                        </Form>
                    </Modal>
                )}
            </Formik>
        </>
    );
}

export default UpdateCommandeFrs;
