import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { ButtonGroup, OverlayTrigger, Tooltip } from 'react-bootstrap';
import ReactDataGrid from '@inovua/reactdatagrid-community';
import '@inovua/reactdatagrid-community/index.css';
import { jsPDF } from 'jspdf';
import XLSX from 'sheetjs-style';
import 'jspdf-autotable';
import 'assets/css/table.css';
import dateformat from 'dateformat';
import common from '../../commonData';
import NavMenu from './navbarAvoir';
import NumberToLetter from '../../converter';
import AddFactureAvoir from './addFactureAvoir';
import { PencilFill, Printer, Trash } from 'react-bootstrap-icons';

function MyAwesomeTable({ rows, isLoading, getFactureAvoir, setLoading, loading, setIsSave, setMsg, setMsgravity, onDeleteAvoir }) {
    const [selected, setSelected] = useState({});
    const [gridRef, setGridRef] = useState(null);
    const [showAddOrEdit, setShowAddOrEdit] = useState(false);
    const [ids, setId] = useState(0);
    const filterValue = [
        { name: 'nofact', operator: 'contains', type: 'string', value: '' },
        { name: 'date_avoir', operator: 'inrange', type: 'date', value: null },
        { name: 'observation_avoir', operator: 'contains', type: 'string', value: '' },
        { name: 'montant_avoir', operator: 'gte', type: 'number', value: 0 }
    ];

    const columnss = [
        {
            name: 'id',
            header: 'Id',
            defaultVisible: false,
            defaultWidth: 80,
            type: 'number'
        },
        {
            header: 'Action',
            defaultWidth: 110,
            textAlign: 'center',
            type: 'string',
            render: ({ data }) => (
                <div className="menudrops">
                    <ButtonGroup aria-label="Basic example">
                        <OverlayTrigger overlay={<Tooltip id="tooltip-disabled">Modifier ligne</Tooltip>}>
                            <a onClick={() => setShowAddOrEditAvoir(data.id)}>
                                <PencilFill size={25} color="green" />
                            </a>
                        </OverlayTrigger>
                        <OverlayTrigger overlay={<Tooltip id="tooltip-disabled"> Imprimer</Tooltip>}>
                            <a onClick={() => printFactureAvoir(data)}>
                                <Printer color="#000" size={25} />
                            </a>
                        </OverlayTrigger>

                        <OverlayTrigger overlay={<Tooltip id="tooltip-disabled">Supprimer ligne</Tooltip>}>
                            <a onClick={() => onDeleteAvoir(data.id)}>
                                <Trash color="red" size={25} />
                            </a>
                        </OverlayTrigger>
                    </ButtonGroup>
                </div>
            )
        },
        { name: 'numero_avoir', header: 'N° avoir', defaultWidth: 90, type: 'string' },
        {
            name: 'date_avoir',
            header: 'Date Avoir',
            defaultWidth: 100,
            type: 'string',
            render: ({ value }) => (value ? dateformat(value, 'dd-mm-yyyy') : 'neant')
        },
        {
            name: 'montant_avoir',
            header: 'Montant avoir',
            defaultWidth: 90,
            textAlign: 'center',
            type: 'number',
            render: ({ value }) => numberformat(value)
        },
        {
            name: 'motif_avoir',
            header: 'Motif avoir',
            defaultWidth: 170,
            type: 'string',
            render: ({ data }) => (
                <div className="rgt-cell-inner" style={{ display: 'flex', alignItems: 'center', overflow: 'hidden' }}>
                    {data.motif_avoir === '1' ? 'Avoir commerciale' : data.motif_avoir === '2' ? 'Avoir pour annulation' : "Avoir d'erreur"}
                </div>
            )
        },
        { name: 'observation_avoir', header: 'Observation', defaultWidth: 200, type: 'string' },
        {
            name: 'client',
            header: 'Clients',
            defaultWidth: 170,
            type: 'string'
        },
        {
            name: 'nofact',
            header: 'N° facture',
            defaultWidth: 80,
            type: 'string'
        },

        {
            name: 'datefact',
            header: 'Date facture',
            defaultWidth: 100,
            type: 'string',
            render: ({ value }) => (value ? dateformat(value, 'dd-mm-yyyy') : 'neant')
        },
        { name: 'user', header: 'Crée par', defaultWidth: 100, type: 'string' },
        {
            name: 'created_at',
            header: 'Crée le',
            defaultWidth: 130,
            type: 'string',
            render: ({ value }) => dateformat(value, 'dd-mm-yyyy HH:MM')
        },
        { name: 'useru', header: 'Modif. par', defaultWidth: 100, type: 'string' },
        {
            name: 'updated_at',
            header: 'Modif. le',
            defaultWidth: 130,
            type: 'string',
            render: ({ value }) => dateformat(value, 'dd-mm-yyyy HH:MM')
        }
    ];

    const gridStyle = { minHeight: window.screen.height - 200 };

    function GetSortOrder(prop) {
        return function (a, b) {
            if (a[prop] > b[prop]) {
                return 1;
            } else if (a[prop] < b[prop]) {
                return -1;
            }
            return 0;
        };
    }

    function formatCurrency(value) {
        return new Intl.NumberFormat('de-DE').format(Math.round(value));
    }

    //------- IMPRIMER FACTURE -----------------
    async function printFactureAvoir(data) {
        console.log(data);

        let text =
            'Voulez-vous imprimer avec ou sans entête ? \n Ok si vous voulez avec entête \n Annuler si vous ne voulez pas avec entête';
        let res = window.confirm(text);
        var doc = new jsPDF();
        var dataMap = [];
        var width = doc.internal.pageSize.getWidth();
        var motif_avoir =
            data.motif_avoir === '1' ? 'Avoir commerciale' : data.motif_avoir === '2' ? 'Avoir pour annulation' : "Avoir d'erreur";
        if (res === true) {
            doc.setFillColor(255, 127, 0);
            common.ipapi == 'https://192.168.8.109:81' || common.ipapi == 'https://api.tracky-entrepot.com'
                ? doc.addImage(common.logo, 'jpg', 14, 10, 30, 30)
                : doc.addImage(common.logo1, 'jpg', 14, 10, 30, 30);
            doc.setFontSize(16);
            doc.setFontSize(20);
            doc.setTextColor(0, 0, 0);
            doc.text('AVOIR CLIENT', 113, 26);
            doc.setFontSize(10);
            doc.setTextColor(0, 0, 0);
            doc.text(113, 43, 'Abidjan le: ' + dateformat(new Date(), 'dd/mm/yyyy'));
            doc.rect(110, 45, 86, 21);
            doc.text('Client: ' + data.client, 113, 50);
            doc.text('Télephone: ' + data.telcli, 113, 55);

            doc.text('Avoir n°: ' + data.numero_avoir, 14, 50);
            doc.text('Motif: ' + motif_avoir, 14, 55);
            doc.text('N° facture: ' + data.nofact, 14, 60);
            doc.text('Date facture : ' + dateformat(data.datefact, 'dd/mm/yyyy'), 14, 65);

            doc.setFontSize(10);
        } else {
            doc.setFillColor(255, 127, 0);
            doc.setFillColor(0, 0, 0);
            doc.setTextColor(255, 255, 255);
            doc.setFontSize(10);
            doc.setTextColor(0, 0, 0);
            doc.text(119, 60, 'Abidjan le: ' + dateformat(new Date(), 'dd/mm/yyyy'));
            doc.rect(115, 61, 81, 21);
            doc.text('Client: ' + data.client, 119, 66);
            doc.text('Télephone: ' + data.telcli, 119, 71);

            doc.text('Avoir n°: ' + data.numero_avoir, 14, 66);
            doc.text('Motif: ' + motif_avoir, 14, 71);
            doc.text('N° facture: ' + data.nofact, 14, 76);
            doc.text('Date facture : ' + dateformat(data.datefact, 'dd/mm/yyyy'), 14, 81);

            doc.setFontSize(10);
        }
        var ttc = 0;
        var ht = 0;

        data.ligneavoir.forEach((row, index) => {
            ht += row.qte_avoir * row.montant_avoir;
            dataMap.push([
                { content: index + 1 },
                { content: row.libelle_avoir },
                { content: row.qte_avoir },
                {
                    content: new Intl.NumberFormat('de-DE', {
                        style: 'decimal',
                        currency: 'CFA'
                    }).format(row.montant_avoir)
                }
            ]);
        });

        if (data.sitva === '1') {
            ttc = ht;
        } else {
            ttc = ht + ht * 0.18;
        }
        doc.autoTable({
            styles: {
                fillColor: [0, 0, 0],
                textColor: [255, 255, 255],
                halign: 'center',
                fontSize: 12,
                cellPadding: 0.75,
                lineColor: [127, 143, 166]
            },
            columnStyles: {
                0: {
                    halign: 'center',
                    fillColor: [255, 255, 255],
                    textColor: [0, 0, 0],
                    fontSize: 12
                },
                1: {
                    fillColor: [255, 255, 255],
                    textColor: [0, 0, 0],
                    fontSize: 12,
                    halign: 'left'
                },
                2: {
                    halign: 'center',
                    fillColor: [255, 255, 255],
                    textColor: [0, 0, 0],
                    fontSize: 12
                },
                3: {
                    halign: 'left',
                    fillColor: [255, 255, 255],
                    textColor: [0, 0, 0],
                    fontSize: 12
                }
            },
            theme: 'grid',
            startY: 100,
            fontSize: 6,
            head: [['Ordre', 'Designation', 'Quantité', 'Montant']],
            body: dataMap
        });

        doc.autoTable({
            styles: {
                fillColor: [255, 255, 255],
                textColor: [0, 0, 0],
                cellPadding: 0.8,
                lineColor: [0, 0, 0],
                halign: 'center'
            },
            columnStyles: {
                0: {
                    halign: 'center',
                    fillColor: [255, 255, 255],
                    textColor: [0, 0, 0],
                    cellPadding: 0.75,
                    fontSize: 12
                },
                1: {
                    halign: 'center',
                    fillColor: [255, 255, 255],
                    textColor: [0, 0, 0],
                    cellPadding: 0.75,
                    fontSize: 12
                },
                2: {
                    halign: 'center',
                    fillColor: [255, 255, 255],
                    textColor: [0, 0, 0],
                    cellPadding: 0.75,
                    fontSize: 12
                },
                3: {
                    halign: 'center',
                    fillColor: [255, 255, 255],
                    textColor: [0, 0, 0],
                    cellPadding: 0.75,
                    fontSize: 12
                }
            },
            margin: { bottom: 35 },
            theme: 'grid',
            startY: doc.lastAutoTable.finalY + 6,
            head: [['TOTAL BRUT', 'TOTAL NET', data.sitva == 1 ? 'TVA EXONERE (18%)' : 'TVA (18%)', 'TOTAL TTC']],
            body: [[formatCurrency(ht), formatCurrency(ht), formatCurrency(ht * 0.18), formatCurrency(ttc)]]
        });
        doc.text('Arrêté à la somme de:', 15, 229);
        doc.setFontSize(8);
        doc.text(NumberToLetter(Math.round(ttc)).toUpperCase() + ' FCFA', 15, 236);
        doc.rect(14, 231, width - 28, 8);
        doc.setFontSize(10);
        doc.text('Visa direction:', 15, 246);
        // doc.rect(14, 247, 60, 22);
        doc.text('Visa client:', 135, 246);
        doc.setFontSize(8);
        doc.setLineWidth(1.5);
        doc.setDrawColor(0, 0, 0);
        doc.line(0, 275, width, 275);
        doc.text(
            'Siège social - Marcory Boulevard de Marseille - Zone 4 - 26 BP 695 Abidjan 26 - Tel.: 27 21 36 39 79 / Fax: 27 21 34 59 59',
            105,
            280,
            null,
            'center'
        );
        doc.text(
            'RCCM N°CI-ABJ-2012-A-5995 - Compte SIB N° CI 007 0106885056950010020 / Cel: 07 08 29 40 40 /  Email: vicaso11@hotmail.fr ',
            105,
            285,
            null,
            'center'
        );

        doc.save('facture-avoir-' + data.numero_avoir + '.pdf');
    }

    const exportCSVAvoir = () => {
        //--------------- EXPORT VERS EXCEL ------------------
        const raw_data = gridRef.current.data;
        /* flatten objects */
        const rows = [];
        raw_data.map((row, index) => {
            rows.push({
                numero_avoir: row.numero_avoir,
                date_avoir: dateformat(row.date_avoir, 'dd-mm-yyyy'),
                montant_avoir: row.montant_avoir,
                motif_avoir: row.motif_avoir == 1 ? 'Avoir commerciale' : row.motif_avoir == 2 ? 'Avoir pour annulation' : "Avoir d'erreur",
                observation_avoir: row.observation_avoir,
                client: row.client,
                nofact: row.nofact,
                datefact: dateformat(row.datefact, 'dd-mm-yyyy')
            });
        });

        /* generate worksheet and workbook */
        const worksheet = XLSX.utils.json_to_sheet(rows);
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, 'Mes avoirs');
        /* fix headers */
        XLSX.utils.sheet_add_aoa(
            worksheet,
            [['N° avoir', 'Date', 'Montant', 'Motif', 'Observation', 'Client', 'N° facture', 'Date facture']],
            { origin: 'A1' }
        );

        /* calculate column width */
        const max_width = rows.reduce((w, r) => Math.max(w, r.client.length), 10);
        worksheet['!cols'] = [{ wch: max_width }];
        XLSX.writeFile(workbook, 'facture-avoir.xlsx');
    };

    //-------------IMPRIMER LES LISTE ----------------
    function printListeAvoir() {
        if (gridRef.current.data.length <= 0) {
            setMsg('Aucune donnée à imprimer');
            setIsSave(true);
            setMsgravity('danger');
            return;
        }
        const doc = new jsPDF({
            orientation: 'l',
            unit: 'mm',
            format: 'a4',
            putOnlyUsedFonts: true
        });
        var width = doc.internal.pageSize.getWidth();
        const data = [];
        let mont = 0;
        let reglement = 0;
        let reste = 0;
        common.ipapi == 'https://192.168.8.109:81' || common.ipapi == 'https://api.tracky-entrepot.com'
            ? doc.addImage(common.logo, 'jpg', 14, 10, 30, 30)
            : doc.addImage(common.logo1, 'jpg', 14, 10, 30, 30);
        doc.setFontSize(16);

        doc.setFontSize(12);
        doc.setFillColor(225, 206, 154);
        doc.rect(65, 15, width - 80, 10, 'F');
        doc.setTextColor(0, 0, 0);
        doc.text('LISTE DES FACTURES AVOIRS CLIENTS', 90, 22);

        doc.setFontSize(12);
        doc.setTextColor(0, 0, 0);
        // doc.text("Période du: "+dateformat(datedeb || gridRef.current.data[gridRef.current.data.length-1].datesort,"dd-mm-yyyy")+" Au : "+dateformat(datefin || gridRef.current.data[0].datesort,"dd-mm-yyyy") ,65,32)
        gridRef.current.data.forEach((row) => {
            mont += row.prix_ttc;
            reglement += row.reglement;
            reste += row.reste;
            data.push([
                { content: row.numero_avoir },
                { content: dateformat(row.date_avoir, 'dd-mm-yyyy') },
                {
                    content: new Intl.NumberFormat('de-DE', {
                        style: 'decimal',
                        currency: 'CFA'
                    }).format(row.montant_avoir)
                },
                { content: row.motif_avoir == 1 ? 'Avoir commerciale' : row.motif_avoir == 2 ? 'Avoir pour annulation' : "Avoir d'erreur" },
                { content: row.observation_avoir || 'néant' },
                { content: row.client },
                { content: row.nofact },
                { content: dateformat(row.datefact, 'dd-mm-yyyy') }
            ]);
        });
        doc.autoTable({
            styles: {
                fillColor: [225, 206, 154],
                textColor: [0, 0, 0],
                halign: 'center',
                fontSize: 10,
                cellPadding: 0.75
            },
            columnStyles: {
                0: {
                    halign: 'center',
                    fillColor: [255, 255, 255],
                    textColor: [0, 0, 0],
                    fontSize: 8
                },
                1: {
                    fillColor: [255, 255, 255],
                    textColor: [0, 0, 0],
                    fontSize: 8
                },
                2: {
                    halign: 'center',
                    fillColor: [255, 255, 255],
                    textColor: [0, 0, 0],
                    fontSize: 8
                },
                3: {
                    halign: 'left',
                    fillColor: [255, 255, 255],
                    textColor: [0, 0, 0],
                    fontSize: 8
                },
                4: {
                    halign: 'left',
                    fillColor: [255, 255, 255],
                    textColor: [0, 0, 0],
                    fontSize: 8
                },
                5: {
                    halign: 'left',
                    fillColor: [255, 255, 255],
                    textColor: [0, 0, 0],
                    fontSize: 8
                },
                6: {
                    halign: 'left',
                    fillColor: [255, 255, 255],
                    textColor: [0, 0, 0],
                    fontSize: 8
                },
                7: {
                    halign: 'center',
                    fillColor: [255, 255, 255],
                    textColor: [0, 0, 0],
                    fontSize: 8
                },
                8: {
                    halign: 'left',
                    fillColor: [255, 255, 255],
                    textColor: [0, 0, 0],
                    fontSize: 8
                }
            },
            theme: 'grid',
            startY: 35,
            fontSize: 6,
            head: [['N° avoir', 'Libellé', 'Date', 'Montant', 'Motif', 'Observation', 'Client', 'N° facture', 'Date facture']],
            body: data
        });
        const pageCount = doc.internal.getNumberOfPages();
        for (var i = 1; i <= pageCount; i++) {
            doc.setPage(i);
            doc.setFontSize(10);
            doc.text('Page ' + String(i) + ' sur ' + String(pageCount), 210 - 55, 200, null, null, 'right');
        }
        doc.save('liste-avoir.pdf');
    }

    function numberformat(data) {
        return new Intl.NumberFormat().format(data);
    }

    function setShowAddOrEditAvoir(id) {
        if (id) {
            setId(id);
            setShowAddOrEdit(true);
        } else {
            setId(0);
            setShowAddOrEdit(true);
        }
    }

    return (
        <div>
            {showAddOrEdit ? (
                <AddFactureAvoir
                    setShowAddOrEdit={setShowAddOrEdit}
                    show={showAddOrEdit}
                    setIsSave={setIsSave}
                    setMsg={setMsg}
                    setMsgravity={setMsgravity}
                    setLoading={setLoading}
                    loading={loading}
                    ids={ids}
                    getFactureAvoir={getFactureAvoir}
                />
            ) : (
                <></>
            )}
            <NavMenu
                printListeAvoir={printListeAvoir}
                selected={selected}
                setShowAddOrEditAvoir={setShowAddOrEditAvoir}
                exportCSVAvoir={exportCSVAvoir}
            />
            <ReactDataGrid
                idProperty="id"
                handle={setGridRef}
                style={gridStyle}
                loading={isLoading}
                reorderColumns={false}
                pagination
                selected={selected}
                // checkboxColumn
                // onSelectionChange={onSelectionChange}
                pageSizes={[200, 400, 600, 800, 1000]}
                defaultLimit={400}
                showHoverRows={true}
                editable={true}
                showColumnMenuTool={false}
                defaultFilterValue={filterValue}
                columns={columnss}
                dataSource={rows}
            />
        </div>
    );
}

export default MyAwesomeTable;
