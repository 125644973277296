import React, { useEffect, useState } from 'react';
import { Form, InputGroup, Button, Spinner, ButtonGroup, Modal } from 'react-bootstrap';
import { Row, Col } from 'reactstrap';
import { Typeahead } from 'react-bootstrap-typeahead';
import 'react-bootstrap-typeahead/css/Typeahead.css';
import { jsPDF } from 'jspdf';
import 'jspdf-autotable';
import 'assets/css/table.css';
import Axios from 'axios';
import * as Yup from 'yup';
import { Field, Formik } from 'formik';
import dateformat from 'dateformat';
import { Backspace, Search, Star, Trash } from 'react-bootstrap-icons';
import common from '../../commonData';

function FilterModale({ showFilterModal, setMsg, setIsSave, setMsgravity, setLoad, load, handleClose, setData, paramShow }) {
    var ladate = new Date();
    const [clients, setClients] = useState([]);
    const [states, setState] = useState({
        datedebut: localStorage.getItem('datedebut'),
        datefin:
            localStorage.getItem('datefin') ||
            dateformat(ladate.getMonth() + 1 + '/' + ladate.getDate() + '/' + ladate.getFullYear(), 'yyyy-mm-dd'),
        client_id: localStorage.getItem('clientselection') || 0
    });
    const { datedebut, datefin, client_id } = states;
    useEffect(() => {
        paramShow === 1
            ? Axios.get(common.ipapi + '/api/client/indexmin')
                  .then((response) => {
                      setClients(response.data);
                      setLoad(false);
                  })
                  .catch((errors) => {
                      setLoad(false);
                  })
            : setLoad(false);
    }, []);

    function GetSortOrder(prop) {
        return function (a, b) {
            if (a[prop] > b[prop]) {
                return 1;
            } else if (a[prop] < b[prop]) {
                return -1;
            }
            return 0;
        };
    }

    //-------------IMPRIMER LES LISTE ----------------
    function printListeModel(datas) {
        if (datas.length <= 0) {
            return;
        }

        const doc = new jsPDF({
            orientation: 'l',
            unit: 'mm',
            format: 'a4',
            putOnlyUsedFonts: true
        });
        var width = doc.internal.pageSize.getWidth();
        const data = [];
        const dataretour = [];
        let mont = 0;
        let qte = 0;
        let benef = 0;
        let qteretour = 0;
        let puTotal = 0;
        let pvTotal = 0;
        common.ipapi === 'https://192.168.8.109:81' || common.ipapi === 'https://api.tracky-entrepot.com'
            ? doc.addImage(common.logo, 'jpg', 12, 10, 30, 30)
            : doc.addImage(common.logo1, 'jpg', 12, 10, 30, 30);
        doc.setFontSize(16);
        // common.ipapi === 'https://192.168.8.109:81' || common.ipapi === 'https://api.tracky-entrepot.com'
        //     ? doc.text('CLUB AUTO', 90, 14)
        //     : doc.text('HR MOTORS', 90, 14);
        doc.setFontSize(12);
        doc.setFillColor(225, 206, 154);
        doc.rect(65, 15, width - 80, 10, 'F');
        doc.setTextColor(0, 0, 0);
        doc.text("LISTE DES  SORTIES D'ARTICLES PAR MODELS", 90, 22);
        doc.setTextColor(0, 0, 0);
        var datavar = [...datas.sort(GetSortOrder('libellefam'))];

        var len = datavar.length;
        var i = 0;
        var datedeb = dateformat(datavar[0].datesort, 'dd-mm-yyyy');
        var datefin = dateformat(datavar[datavar.length - 1].datesort, 'dd-mm-yyyy');
        console.log(datavar);
        datavar.forEach((row, index) => {
            if (len > i + 1) {
                if (i === 0) {
                    data.push([
                        {
                            content: row.libellefam,
                            colSpan: 8,
                            styles: { fontStyle: 'bold', fontSize: '12' }
                        }
                    ]);
                } else if (datavar[i].libellefam !== datavar[i - 1].libellefam) {
                    data.push([
                        {
                            content: row.libellefam,
                            colSpan: 8,
                            styles: { fontStyle: 'bold', fontSize: '12' }
                        }
                    ]);
                }
            } else {
                data.push([
                    {
                        content: row.libellefam,
                        colSpan: 8,
                        styles: { fontStyle: 'bold', fontSize: '12' }
                    }
                ]);
            }
            var realqte = 0;

            // row.forEach((lsort) => {
            realqte = row.qte - row.qteretour;
            mont += row.prix ? row.prix * realqte : row.prixvente * realqte;
            benef += row.prix ? row.prix * realqte - row.prixachat * realqte : row.prixvente * realqte - row.prixachat * realqte;
            qte += Number(row.qte);
            qteretour += Number(row.qteretour);
            puTotal += row.prixvente;
            pvTotal += row.prix ? row.prix : row.prixvente;
            data.push([
                row.numbon,
                row.qrbarre,
                row.descriptionart,
                // lsort.famille ? lsort.famille.libellefam + ' / ' + row.engin : row.engin,
                new Intl.NumberFormat(['ban', 'id']).format(row.qte).replace('.', ' '),
                row.qteretour || 0,
                new Intl.NumberFormat(['ban', 'id']).format(realqte).replace('.', ' '),
                // new Intl.NumberFormat(['ban', 'id']).format(lsort.prixachat).replace('.', ' '),
                new Intl.NumberFormat(['ban', 'id']).format(row.prix ? row.prix : row.prixvente).replace('.', ' '),
                row.prix
                    ? new Intl.NumberFormat(['ban', 'id']).format(row.prix * realqte).replace('.', ' ')
                    : new Intl.NumberFormat(['ban', 'id']).format(row.prixvente * realqte).replace('.', ' '),
                row.prix
                    ? new Intl.NumberFormat(['ban', 'id']).format(row.prix * realqte - row.prixachat * realqte).replace('.', ' ')
                    : new Intl.NumberFormat(['ban', 'id']).format(row.prixvente * realqte - row.prixachat * realqte).replace('.', ' '),
                dateformat(row.datesort, 'dd-mm-yyyy')
            ]);
            // }
            // });
            i++;
        });
        doc.text(
            'Période du: ' +
                dateformat(localStorage.getItem('datedebut'), 'dd-mm-yyyy') +
                ' Au : ' +
                dateformat(localStorage.getItem('datefin'), 'dd-mm-yyyy'),
            65,
            32
        );

        data.push([
            { content: 'TOTAL', styles: { fontStyle: 'bold', fontSize: 12 } },
            '',
            '',
            { content: new Intl.NumberFormat(['ban', 'id']).format(qte).replace('.', ' '), styles: { fontStyle: 'bold', fontSize: 12 } },
            {
                content: new Intl.NumberFormat(['ban', 'id']).format(qteretour).replace('.', ' '),
                styles: { fontStyle: 'bold', fontSize: 12 }
            },
            {
                content: new Intl.NumberFormat(['ban', 'id']).format(qte - qteretour).replace('.', ' '),
                styles: { fontStyle: 'bold', fontSize: 12 }
            },
            // {
            //     content: new Intl.NumberFormat(['ban', 'id']).format(puTotal).replace('.', ' '),
            //     styles: { fontStyle: 'bold', fontSize: 12 }
            // },
            // {
            //     content: new Intl.NumberFormat(['ban', 'id']).format(pvTotal).replace('.', ' '),
            //     styles: { fontStyle: 'bold', fontSize: 12 }
            // },
            '',
            {
                content: new Intl.NumberFormat(['ban', 'id']).format(mont).replace('.', ' '),
                styles: { fontStyle: 'bold', fontSize: 12 }
            },
            {
                content: new Intl.NumberFormat(['ban', 'id']).format(benef).replace('.', ' '),
                styles: { fontStyle: 'bold', fontSize: 12 }
            }
        ]);
        // dataretour.push([
        //     { content: 'TOTAL', styles: { fontStyle: 'bold', fontSize: 12 } },
        //     '',
        //     '',
        //     '',
        //     {
        //         content: new Intl.NumberFormat(['ban', 'id']).format(qteretour).replace('.', ' '),
        //         styles: { fontStyle: 'bold', fontSize: 12 }
        //     },
        //     // {
        //     //     content: new Intl.NumberFormat(['ban', 'id']).format(puTotal).replace('.', ' '),
        //     //     styles: { fontStyle: 'bold', fontSize: 12 }
        //     // },
        //     {
        //         content: new Intl.NumberFormat(['ban', 'id']).format(pvTotal).replace('.', ' '),
        //         styles: { fontStyle: 'bold', fontSize: 12 }
        //     },
        //     {
        //         content: new Intl.NumberFormat(['ban', 'id']).format(montretour).replace('.', ' '),
        //         styles: { fontStyle: 'bold', fontSize: 12 }
        //     }
        // ]);
        doc.autoTable({
            styles: {
                fillColor: [206, 206, 206],
                textColor: [4, 4, 4],
                halign: 'center',
                fontSize: 10,
                cellPadding: 0.75
                // lineColor: [0, 0, 0]
            },
            columnStyles: {
                0: {
                    halign: 'center',
                    fillColor: [255, 255, 255],
                    textColor: [0, 0, 0],
                    cellWidth: 15,
                    fontSize: 8
                },
                1: {
                    halign: 'left',
                    fillColor: [255, 255, 255],
                    textColor: [0, 0, 0],
                    fontSize: 8
                    // cellWidth: 40
                },
                2: {
                    halign: 'left',
                    fillColor: [255, 255, 255],
                    textColor: [0, 0, 0],
                    fontSize: 8
                    // cellWidth: 45
                },
                3: {
                    halign: 'left',
                    fillColor: [255, 255, 255],
                    textColor: [0, 0, 0],
                    fontSize: 8,
                    cellWidth: 40
                },
                4: {
                    halign: 'center',
                    fillColor: [255, 255, 255],
                    textColor: [0, 0, 0],
                    fontSize: 8
                },
                5: {
                    halign: 'center',
                    fillColor: [255, 255, 255],
                    textColor: [0, 0, 0],
                    fontSize: 8
                },
                6: {
                    halign: 'center',
                    fillColor: [255, 255, 255],
                    textColor: [0, 0, 0],
                    fontSize: 8
                },
                7: {
                    halign: 'right',
                    fillColor: [255, 255, 255],
                    textColor: [0, 0, 0],
                    fontSize: 8
                },
                8: {
                    halign: 'right',
                    fillColor: [255, 255, 255],
                    textColor: [0, 0, 0],
                    fontSize: 8
                },
                9: {
                    halign: 'right',
                    fillColor: [255, 255, 255],
                    textColor: [0, 0, 0],
                    fontSize: 8
                }
                // 10: {
                //     halign: 'center',
                //     fillColor: [255, 255, 255],
                //     textColor: [0, 0, 0],
                //     fontSize: 8
                // }
            },
            theme: 'grid',
            startY: 35,
            fontSize: 6,
            head: [
                [
                    'N° bon',
                    'Code',
                    'Articles',
                    // 'Models/Engins',
                    'Qté liv',
                    'Qté retour',
                    'Reste',
                    // 'Prix achat U',
                    'Prix vente U',
                    'Total',
                    'Bénéfice',
                    'Sortie le'
                ]
            ],
            body: data
        });
        // console.log(data);
        const pageCount = doc.internal.getNumberOfPages();
        for (var u = 1; u <= pageCount; u++) {
            doc.setPage(u);
            doc.setFontSize(10);
            doc.text('Page ' + String(u) + ' sur ' + String(pageCount), 210 - 55, 200, null, null, 'right');
        }
        doc.save('Listesortie.pdf');
    }

    async function SearchData(values) {
        setLoad(true);
        await Axios({
            url:
                paramShow === 1
                    ? common.ipapi + '/api/sortie/filterplus/' + values.client_id + '/' + values.datedebut + '/' + values.datefin
                    : common.ipapi + '/api/sortie/filterbymodel/' + values.datedebut + '/' + values.datefin,
            method: 'GET'
        })
            .then((res) => {
                if (res.status !== 200) {
                    setMsg('Veuillez vérifier votre connexion internet');
                    setMsgravity('info');
                    setIsSave(false);
                    return;
                }
                // console.log(res.data.length);
                if (res.data.status === 1) {
                    localStorage.setItem('datedebut', values.datedebut);
                    localStorage.setItem('datefin', values.datefin);
                    localStorage.setItem('clientselection', values.client_id);
                    paramShow === 1 ? setData(res.data.datas) : printListeModel(res.data.datas);
                    setLoad(false);
                    handleClose();
                } else {
                    setIsSave(true);
                    setMsg(res.data.msg);
                    setMsgravity('danger');
                    setLoad(false);
                }
            })
            .catch((error) => {
                setIsSave(true);
                setMsg('Erreur');
                setMsgravity('danger');
                setLoad(false);
            });
    }

    return (
        <>
            <Modal show={load} size="sm" aria-labelledby="contained-modal-title-vcenter" centered>
                <Modal.Body>
                    Veuillez patienter.......
                    <Spinner animation="border" variant="warning" />
                </Modal.Body>
            </Modal>
            <Formik
                initialValues={{
                    datedebut: datedebut,
                    datefin: datefin,
                    client_id: client_id
                }}
                enableReinitialize
                validationSchema={Yup.object().shape({
                    datefin: Yup.date().required('Veuillez saisir cette donnée'),
                    datedebut: Yup.date().required('Veuillez saisir cette donnée')
                })}
                onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
                    SearchData(values);
                }}
            >
                {({ errors, handleBlur, handleChange, handleSubmit, isSubmitting, touched, values, setFieldValue, setFieldTouched }) => (
                    <Modal show={showFilterModal} onHide={handleClose}>
                        <Modal.Header>
                            FILTRE LIVRAISON ARTICLES <Backspace onClick={handleClose} color="red" size={30} />
                        </Modal.Header>
                        <Form noValidate onSubmit={handleSubmit}>
                            <Modal.Body>
                                {paramShow === 1 ? (
                                    <Form.Group as={Row} className="mb-3">
                                        <Form.Label column sm={5}>
                                            Nom client:
                                        </Form.Label>
                                        <Col sm={7}>
                                            <InputGroup>
                                                <Typeahead
                                                    id="client_id"
                                                    multiple={false}
                                                    onChange={(selected) => {
                                                        const value = selected.length > 0 ? selected[0].id : 0;
                                                        setFieldValue('client_id', value);
                                                    }}
                                                    onInputChange={(text, event) => setFieldValue('client_id', text)}
                                                    onBlur={(e) => setFieldTouched('client_id', true)}
                                                    labelKey="raisonsocial"
                                                    options={clients}
                                                    selected={clients.slice(
                                                        clients.findIndex((element) => element.id === values.client_id),
                                                        clients.findIndex((element) => element.id === values.client_id) + 1
                                                    )}
                                                />
                                            </InputGroup>
                                        </Col>
                                    </Form.Group>
                                ) : (
                                    <></>
                                )}

                                <Form.Group as={Row} className="mb-3">
                                    <Form.Label column sm={5}>
                                        Date début période: <Star color="red" size={10} />
                                    </Form.Label>
                                    <Col sm={7}>
                                        <Form.Control
                                            id="datedebut"
                                            type="date"
                                            placeholder="Date début période"
                                            aria-describedby="inputGroupPrepend"
                                            // required
                                            size="sm"
                                            value={values.datedebut}
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                        />
                                    </Col>
                                    {errors.datedebut && <div id="feedback">{errors.datedebut}</div>}
                                </Form.Group>
                                <Form.Group as={Row} className="mb-3">
                                    <Form.Label column sm={5}>
                                        Date fin période: <Star color="red" size={10} />
                                    </Form.Label>
                                    <Col sm={7}>
                                        <Form.Control
                                            id="datefin"
                                            type="date"
                                            placeholder="Date fin période"
                                            aria-describedby="inputGroupPrepend"
                                            // required
                                            size="sm"
                                            value={values.datefin}
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                        />
                                    </Col>
                                    {errors.datefin && <div id="feedback">{errors.datefin}</div>}
                                </Form.Group>
                            </Modal.Body>
                            <Modal.Footer>
                                <div className="ml-auto">
                                    <ButtonGroup size="sm">
                                        <Button type="submit" variant="ligth">
                                            <Search color="#000" size={20} /> Rechercher
                                        </Button>
                                    </ButtonGroup>
                                </div>
                            </Modal.Footer>
                        </Form>
                    </Modal>
                )}
            </Formik>
        </>
    );
}
export default FilterModale;
