import React from 'react';
import { jsPDF } from 'jspdf';
import 'jspdf-autotable';
import { ButtonGroup, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { Basket, Printer } from 'react-bootstrap-icons';
import common from '../../commonData';
import dateformat from 'dateformat';
function ActionBtn({ data, setShowAdds }) {
    function printFacture(data) {
        var datas = [];
        var doc = new jsPDF();
        var width = doc.internal.pageSize.getWidth();
        common.ipapi == 'https://192.168.8.109:81' || common.ipapi == 'https://api.tracky-entrepot.com'
            ? doc.addImage(common.logo, 'jpg', 14, 10, 40, 20)
            : doc.addImage(common.logo1, 'jpg', 14, 10, 40, 20);

        doc.setFontSize(16);
        // doc.text('FOOTPRINT', 90, 17);
        doc.setFontSize(12);
        doc.setFillColor(225, 206, 154);
        doc.rect(65, 13, width - 80, 10, 'F');
        doc.setTextColor(0, 0, 0);
        doc.text('COMMANDE FOURNISSEUR', 90, 20);
        doc.setFontSize(10);
        doc.setDrawColor(0);
        doc.rect(14, 30, width / 2 - 10, 20);
        doc.rect(width / 2 + 10, 30, 80, 20);
        doc.setTextColor(0, 0, 0);
        doc.text('Fournisseur : ' + data.fournisseur, 16, 36);
        doc.text('Commander par : ' + data.user, 16, 41);
        doc.text('Oboservation : ' + data.observation, 16, 46);

        doc.text('N° commande : ' + data.nocommande, width / 2 + 12, 36);
        doc.text('Date commande : ' + dateformat(data.datecommande, 'dd-mm-yyyy'), width / 2 + 12, 41);
        doc.text('Date prév. livraison. : ' + dateformat(data.datelivraison_prevu, 'dd-mm-yyyy'), width / 2 + 12, 46);

        var total = 0;
        data.lignecommande.forEach((data, index) => {
            total += data.pivot.quantite;
            datas.push([
                { content: index + 1 },
                { content: data.qrbarre },
                { content: data.descriptionart },
                { content: data.marque.libellemarq },
                { content: data.famille.libellefam },
                { content: data.pivot.quantite }
            ]);
        });

        datas.push([
            { content: 'TOTAL', colSpan: 5, styles: { fontStyle: 'bold', fontSize: 12 } },
            { content: new Intl.NumberFormat('de-DE').format(total), styles: { fontStyle: 'bold', fontSize: 12 } }
        ]);

        if (datas.length > 0) {
            doc.autoTable({
                styles: {
                    fillColor: [0, 0, 0],
                    textColor: [255, 255, 255],
                    cellPadding: 1.5,
                    halign: 'center',
                    fontSize: 10
                },
                columnStyles: {
                    0: {
                        halign: 'center',
                        fillColor: [255, 255, 255],
                        textColor: [0, 0, 0],
                        cellPadding: 0.75,
                        fontSize: 10,
                        cellWidth: 8
                    },
                    1: {
                        halign: 'left',
                        fillColor: [255, 255, 255],
                        textColor: [0, 0, 0],
                        cellPadding: 0.75,
                        fontSize: 10,
                        cellWidth: 50
                    },
                    2: {
                        halign: 'left',
                        fillColor: [255, 255, 255],
                        textColor: [0, 0, 0],
                        cellPadding: 0.75,
                        fontSize: 10,
                        cellWidth: 50
                    },
                    3: {
                        halign: 'left',
                        fillColor: [255, 255, 255],
                        textColor: [0, 0, 0],
                        cellPadding: 0.75,
                        fontSize: 10
                    },
                    4: {
                        halign: 'left',
                        fillColor: [255, 255, 255],
                        textColor: [0, 0, 0],
                        cellPadding: 0.75,
                        fontSize: 10
                    },
                    5: {
                        halign: 'center',
                        fillColor: [255, 255, 255],
                        textColor: [0, 0, 0],
                        cellPadding: 0.75,
                        fontSize: 10
                    }
                },
                theme: 'grid',
                startY: 54,
                margin: { bottom: 35 },
                // horizontalPageBreak: true,
                head: [['N°', 'Code', 'Description', 'Marque', 'Famille', 'Quantité']],
                body: datas
            });
        }
        var now = new Date();
        doc.text(
            'Edité par ' + common.user + ' le ' + now.getDay() + '/' + now.getMonth() + '/' + now.getFullYear(),
            16,
            292,
            null,
            null,
            'left'
        );
        const pageCount = doc.internal.getNumberOfPages();
        for (var u = 1; u <= pageCount; u++) {
            doc.setPage(u);
            doc.setFontSize(10);
            doc.text('Page ' + String(u) + ' sur ' + String(pageCount), 210 - 18, 292, null, null, 'right');
        }

        doc.setFontSize(8);
        doc.setLineWidth(1.5);
        doc.setDrawColor(0, 0, 0);
        doc.line(0, 275, width, 275);
        doc.text(
            'Siège social - Marcory Boulevard de Marseille - Zone 4 - 26 BP 695 Abidjan 26 - Tel.: 27 21 36 39 79 / Fax: 27 21 34 59 59',
            105,
            280,
            null,
            'center'
        );
        doc.text(
            'RCCM N°CI-ABJ-2012-A-5995 - Compte SIB N° CI 007 0106885056950010020 / Cel: 07 08 29 40 40 /  Email: vicaso11@hotmail.fr ',
            105,
            285,
            null,
            'center'
        );
        doc.setLineWidth(0.5);
        // setLoading(false);
        doc.save(`commandefrs.pdf`);
    }

    return (
        <div className="menudrops">
            <ButtonGroup aria-label="Basic example">
                <OverlayTrigger overlay={<Tooltip id="tooltip-disabled"> Imprimer</Tooltip>}>
                    <Printer color="#000" size={25} id="actionbtn" onClick={() => printFacture(data)} />
                </OverlayTrigger>

                {!data.silivre ? (
                    <OverlayTrigger overlay={<Tooltip id="tooltip-disabled">Livraison</Tooltip>}>
                        <Basket color="orange" size={25} id="actionbtn" onClick={(e) => setShowAdds(data)} />
                    </OverlayTrigger>
                ) : (
                    ''
                )}
            </ButtonGroup>
        </div>
    );
}
export default ActionBtn;
