import React from 'react';
import { Container, Nav, Navbar, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { Printer, FileExcel, PlusCircleDotted, List } from 'react-bootstrap-icons';

import 'jspdf-autotable';
import 'assets/css/table.css';

function NavMenu({ printListe, setAdds, exportCSV }) {
    return (
        <Navbar expand="lg" bg="light" variant="light">
            <Container>
                {/* <Navbar.Brand href="#/admin/entrees">LISTE DES ENTREES D'ARTICLES </Navbar.Brand> */}
                <Nav className="me-auto">
                    <OverlayTrigger placement="bottom" overlay={<Tooltip id="tooltip-disabled">Faire une entrée de stock</Tooltip>}>
                        <Nav.Link href="#/admin/entrees" onClick={() => setAdds()} title="Faire une entrée de stock">
                            <PlusCircleDotted color="orange" size={25} /> Créer
                        </Nav.Link>
                    </OverlayTrigger>
                    <OverlayTrigger placement="bottom" overlay={<Tooltip id="tooltip-disabled">Exporter vers Excel</Tooltip>}>
                        <Nav.Link href="#/admin/entrees" onClick={() => exportCSV()} title="Exporter vers excel">
                            <FileExcel color="green" size={25} /> Excel
                        </Nav.Link>
                    </OverlayTrigger>
                    <OverlayTrigger placement="bottom" overlay={<Tooltip id="tooltip-disabled">Exporter vers pdf</Tooltip>}>
                        <Nav.Link href="#/admin/entrees" onClick={() => printListe()} title="Exporter vers pdf">
                            <Printer color="#0080ff" size={25} /> Imprimer
                        </Nav.Link>
                    </OverlayTrigger>
                </Nav>
            </Container>
        </Navbar>
    );
}
export default NavMenu;
