import { useHistory, Link } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import { Spinner, Form, Toast, Modal } from 'react-bootstrap';
import { Button, Card, CardBody, Row, Col, CardText, CardTitle } from 'reactstrap';
import { Eye, Trash, BackspaceFill, TriangleHalf } from 'react-bootstrap-icons';
import { ButtonGroup } from 'react-bootstrap';
import Axios from 'axios';
import dateformat from 'dateformat';
import Tables from './gridtables';
import common from '../../commonData';
import AddEntre from './addEntree';
var dta = [];
function Entrees() {
    var ladate = new Date();
    const history = useHistory();
    const [datas, setDatas] = useState([]);
    const [datal, setDatal] = useState([]);
    const [load, setLoad] = useState(false);
    const [isSave, setIsSave] = useState(false);
    const [identr, setId] = useState(0);
    const [arrivage, setArrivage] = useState('');
    const [dateentr, setDateentr] = useState('');
    const [fournisseur_id, setIdFourn] = useState('');
    const [msgGravity, setMsgravity] = useState('success');
    const [msg, setMsg] = useState('');
    const [show, setShow] = useState(false);
    const [showAdd, setShowAdd] = useState(false);
    const [actualise, setActualise] = useState(false);
    const [states, setState] = useState({
        dateentr: dateformat(ladate.getMonth() + 1 + '/' + ladate.getDate() + '/' + ladate.getFullYear(), 'yyyy-mm-dd'),
        datearriv: dateformat(ladate.getMonth() + 1 + '/' + ladate.getDate() + '/' + ladate.getFullYear(), 'yyyy-mm-dd'),
        arrivage: '',
        remarque: '',
        fournisseur_id: '',
        ligneentree: []
    });

    useEffect(() => {
        if (!localStorage.getItem('user-info')) {
            history.push('/login');
        }
        const log = common.search(common.userdroit, 'SAD');
        const log1 = common.search(common.userdroit, 'ENT');
        if (log === undefined) {
            if (log1 === undefined) {
                setMsg("Vous n'avez pas le droit d'effectuer cette action, merci de contacter l'administrateur");
                setMsgravity('danger');
                setIsSave(true);
                return;
            }
        }
        setLoad(true);
        (async function anyNameFunction() {
            await Axios.get(common.ipapi + '/api/entrees/index')
                .then((response) => {
                    setDatas(response.data.datas);
                    dta = response.data.datas;
                    setLoad(false);
                })
                .catch((errors) => {
                    setLoad(false);
                });
        })();
    }, [history, actualise]);

    function onDeletel(id) {
        if (window.confirm('Voulez-vous supprimer cette ligne?')) {
            setLoad(true);
            Axios.delete(common.ipapi + '/api/entrees/destroyligne/' + id).then((response) => {
                if (response.data.status === 1) {
                    const del = datal.filter((d) => d.pivot.id !== id);
                    setDatal(del);
                    setMsg(response.data.msg);
                    setMsgravity('info');
                    setIsSave(true);
                    setLoad(false);
                } else {
                    setMsg(response.data.msg);
                    setIsSave(true);
                    setMsgravity('danger');
                    setLoad(false);
                }
            });
        }
    }
    function onDelete(id) {
        if (window.confirm('Voulez-vous supprimer cette ligne ? En car de suppression les quantités des articles sont impactées')) {
            setLoad(true);
            Axios.delete(common.ipapi + '/api/entrees/destroy/' + id).then((response) => {
                if (response.data.status === 1) {
                    const del = datas.filter((d) => d.id !== id);
                    dta = del;
                    setDatas(del);
                    setMsg(response.data.msg);
                    setIsSave(true);
                    setLoad(false);
                } else {
                    setMsg(response.data.msg);
                    setIsSave(true);
                    setLoad(false);
                }
            });
        }
    }
    const handleClose = () => {
        setShow(false);
    };

    function groupBy(tableauObjets) {
        let t = [];
        setLoad(true);
        return tableauObjets.reduce(function (res, value, index) {
            t.push({
                id: value.pivot.id,
                qrbarre: value.qrbarre,
                article_id: value.pivot.article_id,
                descriptionart: value.descriptionart,
                quantite: value.pivot.quantite,
                prixachat: value.pivot.prixachat,
                remise: value.pivot.remise
            });
            return t;
        }, {});
    }
    function viewEntre(ids) {
        setLoad(true);
        Axios.get(common.ipapi + '/api/entrees/edit/' + ids).then((response) => {
            setDatal(response.data.datas.ligneentree);
            setArrivage(response.data.datas.arrivage);
            setDateentr(dateformat(response.data.datas.dateentr, 'yyyy-mm-dd'));
            setIdFourn(response.data.datas.fournisseur ? response.data.datas.fournisseur.nomfourn : '');
            setShow(true);
            setLoad(false);
        });
    }

    function setAdds() {
        setId(0);
        setState({
            dateentr: dateformat(ladate.getMonth() + 1 + '/' + ladate.getDate() + '/' + ladate.getFullYear(), 'yyyy-mm-dd'),
            datearriv: dateformat(ladate.getMonth() + 1 + '/' + ladate.getDate() + '/' + ladate.getFullYear(), 'yyyy-mm-dd'),
            arrivage: '',
            remarque: '',
            fournisseur_id: '',
            ligneentree: []
        });
        setShowAdd(true);
    }
    function setIds(id) {
        Axios.get(common.ipapi + '/api/entrees/edit/' + id)
            .then((response) => {
                if (response.data.status) {
                    setState({
                        dateentr: response.data.datas.dateentr ? dateformat(response.data.datas.dateentr, 'yyyy-mm-dd') : '',
                        datearriv: response.data.datas.datearriv ? dateformat(response.data.datas.datearriv, 'yyyy-mm-dd') : '',
                        arrivage: response.data.datas.arrivage,
                        remarque: response.data.datas.remarque,
                        fournisseur_id: response.data.datas.fournisseur_id !== null ? response.data.datas.fournisseur_id : 0,
                        ligneentree: response.data.datas.ligneentree.length > 0 ? groupBy(response.data.datas.ligneentree) : []
                    });
                    setId(id);
                    setShowAdd(true);
                    setLoad(false);
                }
                setLoad(false);
            })
            .catch((errors) => {
                setLoad(false);
            });
    }
    return (
        <>
            <Toast id="toast" onClose={() => setIsSave(false)} show={isSave} delay={3000} className={'bg-' + msgGravity} autohide>
                <Toast.Header>
                    <img src="holder.js/20x20?text=%20" className="rounded me-2" alt="" />
                    <strong className="me-auto">Info</strong>
                </Toast.Header>
                <Toast.Body className="dark">{msg}</Toast.Body>
            </Toast>
            <Modal show={load} size="sm" aria-labelledby="contained-modal-title-vcenter" centered>
                <Modal.Body>
                    Veuillez patienter.......
                    <Spinner animation="border" variant="success" />
                </Modal.Body>
            </Modal>
            {showAdd ? (
                <AddEntre
                    id={identr}
                    setShowAdd={setShowAdd}
                    show={showAdd}
                    setIsSave={setIsSave}
                    setDatas={setDatas}
                    setMsg={setMsg}
                    setMsgravity={setMsgravity}
                    stateParent={states}
                    setActualise={setActualise}
                    actualise={actualise}
                />
            ) : (
                <></>
            )}

            <div className="content contents">
                {common.search(common.userdroit, 'SAD') !== undefined || common.search(common.userdroit, 'FAC') !== undefined ? (
                    <div>
                        <Row>
                            <Col md="12">
                                <Modal show={show} onHide={handleClose} size="lg">
                                    <Modal.Header closeButton>CONSULTATION ENTREE EN STOCK</Modal.Header>
                                    <Modal.Body>
                                        <div id="lightbody">
                                            <Card>
                                                <CardBody>
                                                    <Form.Group as={Row} className="mb-3" controlId="formHorizontalEmail">
                                                        <Form.Label column sm={2}>
                                                            Arrivage:
                                                        </Form.Label>
                                                        <Col sm={10}>
                                                            <Form.Control
                                                                type="text"
                                                                placeholder="Arrivage"
                                                                aria-describedby="inputGroupPrepend"
                                                                disabled
                                                                size="sm"
                                                                value={arrivage}
                                                                onChange={(e) => setArrivage(e.target.value)}
                                                            />
                                                        </Col>
                                                    </Form.Group>
                                                    <Form.Group as={Row} className="mb-3" controlId="formHorizontalEmail">
                                                        <Form.Label column sm={2}>
                                                            Date entrées:
                                                        </Form.Label>
                                                        <Col sm={10}>
                                                            <Form.Control
                                                                type="date"
                                                                placeholder="Date entrées"
                                                                aria-describedby="inputGroupPrepend"
                                                                disabled
                                                                size="sm"
                                                                value={dateentr}
                                                                onChange={(e) => setDateentr(e.target.value)}
                                                            />
                                                        </Col>
                                                    </Form.Group>
                                                    <Form.Group as={Row} className="mb-3" controlId="formHorizontalEmail">
                                                        <Form.Label column sm={2}>
                                                            Fournisseur:
                                                        </Form.Label>
                                                        <Col sm={10}>
                                                            <Form.Control
                                                                type="text"
                                                                placeholder="Fournisseur"
                                                                aria-describedby="inputGroupPrepend"
                                                                disabled
                                                                size="sm"
                                                                value={fournisseur_id}
                                                                onChange={(e) => setIdFourn(e.target.value)}
                                                            />
                                                        </Col>
                                                    </Form.Group>

                                                    <table className="table table-striped-hover table-sm">
                                                        <thead>
                                                            <tr>
                                                                {/* <th scope="col">#</th> */}
                                                                <th scope="col">Code</th>
                                                                <th scope="col">Descr. art.</th>
                                                                <th scope="col">Qté</th>
                                                                {/* <th scope="col">Crée le</th> */}
                                                                <th scope="col">#</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {datal.map((d, index) => {
                                                                return (
                                                                    <tr key={index} hover="true">
                                                                        {/* <td>{d.id}</td> */}
                                                                        <td>{d.qrbarre}</td>
                                                                        <td>{d.descriptionart}</td>
                                                                        <td>{d.pivot.quantite}</td>
                                                                        {/* <td>{dateformat(d.created_at,"yyyy-MM-dd")}</td> */}
                                                                        <td>
                                                                            <ButtonGroup aria-label="Basic example">
                                                                                <Link to={`#/admin/article/fiche/${d.id}`}>
                                                                                    <Eye size={22} color="royalblue" />
                                                                                </Link>
                                                                                <a
                                                                                    href="#/admin/entrees"
                                                                                    onClick={() => onDeletel(d.pivot.id)}
                                                                                >
                                                                                    <Trash color="red" size={20} />
                                                                                </a>
                                                                            </ButtonGroup>
                                                                        </td>
                                                                    </tr>
                                                                );
                                                            })}
                                                        </tbody>
                                                    </table>
                                                </CardBody>
                                            </Card>
                                        </div>
                                    </Modal.Body>
                                    <Modal.Footer>
                                        <Button type="reset" variant="ligth" onClick={handleClose}>
                                            <BackspaceFill color="orange" size={20} /> Annuler
                                        </Button>
                                    </Modal.Footer>
                                </Modal>
                                <div id="tablecontent">
                                    {/* <Tables columns={columns} rows={datas}/>> */}
                                    <Tables
                                        rows={datas}
                                        isLoading={load}
                                        onDelete={onDelete}
                                        setIds={setIds}
                                        viewEntre={viewEntre}
                                        setAdds={setAdds}
                                    />
                                </div>
                            </Col>
                        </Row>
                    </div>
                ) : (
                    <Card style={{ width: '25rem' }} className="centerCard">
                        <CardBody>
                            <CardTitle tag="h5">
                                <TriangleHalf size={60} color="red"></TriangleHalf> Avertissement
                            </CardTitle>
                            <CardText>Vous n'êtes pas autorisé à utiliser cette section. Merci de contacter votre administrateur</CardText>
                        </CardBody>
                    </Card>
                )}
            </div>
        </>
    );
}

export default Entrees;
