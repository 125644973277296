import React, { useEffect, useState } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { Toast, Spinner, Modal } from 'react-bootstrap';

import Axios from 'axios';

import 'jspdf-autotable';
import Tables from './gridtables';
import common from '../../commonData';
import FicheArticle from './ficheArticle';
import AddArticle from './addArticle';

var dta = [];
function MarqueArticle() {
    const [msgGravity, setMsgravity] = useState('success');
    const [msg, setMsg] = useState('');
    const [isSave, setIsSave] = useState(false);
    const [load, setLoading] = useState(true);
    const [ids, setId] = useState(0);
    const [datas, setDatas] = useState([]);
    const [libellemarq, setMarque] = useState('');
    let { id } = useParams();
    const history = useHistory();
    const [showAdd, setShowAdd] = useState(false);
    const [showView, setShowView] = useState(false);
    const [idview, setIdView] = useState(0);
    const [min, setMin] = useState(0);
    const [zero, setZero] = useState(0);
    const [editData, setEditData] = useState();
    const [title, setTitle] = useState('MES ARTICLES EN STOCK');

    useEffect(() => {
        setLoading(true);
        if (!localStorage.getItem('user-info')) {
            history.push('/login');
        } else {
            (async function anyNameFunction() {
                getArticle();
            })();
        }
    }, []);
    function getArticle() {
        Axios.get(common.ipapi + '/api/article/marque/' + id).then((response) => {
            setMarque(response.data[0].length > 0 ? response.data[0][0].marque : '');
            setDatas(response.data[0]);
            dta = response.data[0];
            setMin(response.data[4]);
            setZero(response.data[5]);
            setLoading(false);
        });
    }

    function setIds(id) {
        setId(id);
        setShowAdd(true);
    }

    function onDelete(id) {
        if (window.confirm('Voulez-vous supprimer cet article ?')) {
            setLoading(true);
            Axios.delete(common.ipapi + '/api/article/destroy/' + id).then((response) => {
                if (response.data.status == 1) {
                    const del = dta.filter((d) => d.id !== id);
                    dta = del;
                    setDatas(del);
                    setMsgravity('success');
                    setMsg(response.data.msg);
                    setIsSave(true);
                    setLoading(false);
                } else {
                    setMsgravity('danger');
                    setMsg('Une erreur a eu lieu');
                    setIsSave(true);
                    setLoading(false);
                }
            });
        }
    }

    function setAdds() {
        const log = common.search(common.userdroit, 'SAD');
        const log1 = common.search(common.userdroit, 'ADA');
        if (log === undefined) {
            if (log1 === undefined) {
                setMsg("Vous n'avez pas le droit d'effectuer cette action, merci de contacter l'administrateur");
                setMsgravity('danger');
                setIsSave(true);
                return;
            }
        }
        setId(0);
        setEditData([]);
        setShowAdd(true);
    }

    function setIds(id) {
        const log = common.search(common.userdroit, 'SAD');
        const log1 = common.search(common.userdroit, 'UDA');
        if (log === undefined) {
            if (log1 === undefined) {
                setMsg("Vous n'avez pas le droit d'effectuer cette action, merci de contacter l'administrateur");
                setMsgravity('danger');
                setIsSave(true);
                return;
            }
        }
        setLoading(true);
        Axios.get(common.ipapi + '/api/article/edit/' + id)
            .then((response) => {
                if (response.data) {
                    setEditData(response.data);
                    setId(id);
                    setShowAdd(true);
                }
                setLoading(false);
            })
            .catch((errors) => {
                setLoading(false);
            });
    }
    function setIdViews(id) {
        setIdView(id);
        setShowView(true);
    }

    function getMin(t) {
        setLoading(true);
        Axios.get(common.ipapi + '/api/article/min')
            .then((response) => {
                setTitle(t);
                setDatas(response.data);
                dta = response.data;
                setLoading(false);
            })
            .catch((errors) => {
                setLoading(false);
            });
    }
    function getEmpty(t) {
        setLoading(true);
        Axios.get(common.ipapi + '/api/article/empty')
            .then((response) => {
                setTitle(t);
                setDatas(response.data);
                dta = response.data;
                setLoading(false);
            })
            .catch((errors) => {
                setLoading(false);
            });
    }

    return (
        <>
            <Toast id="toast" onClose={() => setIsSave(false)} show={isSave} delay={3000} className={'bg-' + msgGravity} autohide>
                <Toast.Header>
                    <img src="holder.js/20x20?text=%20" className="rounded me-2" alt="" />
                    <strong className="me-auto">Info</strong>
                </Toast.Header>
                <Toast.Body className="dark">{msg}</Toast.Body>
            </Toast>
            <Modal show={load} size="sm" aria-labelledby="contained-modal-title-vcenter" centered>
                <Modal.Body>
                    Veuillez patienter.......
                    <Spinner animation="border" variant="warning" />
                </Modal.Body>
            </Modal>

            {showAdd ? (
                <AddArticle
                    ids={ids}
                    editData={editData}
                    setShowAdd={setShowAdd}
                    show={showAdd}
                    setIsSave={setIsSave}
                    isSave={false}
                    setDatas={setDatas}
                    setMsg={setMsg}
                    setMsgravity={setMsgravity}
                    getArticle={getArticle}
                />
            ) : (
                <></>
            )}
            {idview != 0 ? (
                <FicheArticle
                    id={idview}
                    setShowView={setShowView}
                    show={showView}
                    setIdViews={setIdViews}
                    setDatas={setDatas}
                    getArticle={getArticle}
                    data={datas}
                />
            ) : (
                <></>
            )}

            <div className="content contents">
                <div id="tablecontent">
                    <Tables
                        rows={datas}
                        isLoading={load}
                        onDelete={onDelete}
                        setIds={setIds}
                        setIdViews={setIdViews}
                        min={min}
                        zero={zero}
                        title={libellemarq}
                        setAdds={setAdds}
                        getMin={getMin}
                        getEmpty={getEmpty}
                    />
                </div>
            </div>
        </>
    );
}

export default MarqueArticle;
