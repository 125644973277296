import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Spinner, Form, InputGroup, Modal, ButtonGroup, Toast } from 'react-bootstrap';
import { Button, Card, CardBody, CardTitle, Row, Col, CardSubtitle, CardLink, CardFooter } from 'reactstrap';
import Axios from 'axios';
import { ArrowLeft, Backspace, PencilFill, PencilSquare, Save2Fill, Trash } from 'react-bootstrap-icons';
import dateformat from 'dateformat';
import Table from '../../table';
import common from '../../commonData';

function Enrepots() {
    const [validated, setValidated] = useState(false);
    const [codeentrepot, setCodeentr] = useState('');
    const [libelleentrepot, setLibentr] = useState('');
    const [tailleentrepot, setTailleentr] = useState('');
    const [isSave, setIsSave] = useState(false);
    const [data, setData] = useState([]);
    const history = useHistory();
    const [load, setLoad] = useState(true);
    const [modif, setModif] = useState([false, '']);
    const [view, setView] = useState(true);
    const [ident, setId] = useState(0);
    const [msg, setMsg] = useState('');
    const [msgGravity, setMsgravity] = useState('success');
    const [loading, setLoading] = React.useState(false);
    const [pageCount, setPageCount] = React.useState(0);
    const fetchIdRef = React.useRef(0);

    useEffect(() => {
        if (localStorage.getItem('user-info')) {
            (async function anyNameFunction() {
                getEntrepots();
            })();
        } else {
            history.push('/login');
        }
    }, [history]);

    function getEntrepots() {
        Axios.get(common.ipapi + '/api/entrepot/index/1').then((response) => {
            setData(response.data);
            setLoad(false);
        });
    }
    function saveEntrepot(e) {
        setLoad(true);
        var formData = new FormData();
        formData.append('app', 1);
        formData.append('libelleentrepot', libelleentrepot);
        formData.append('tailleentrepot', tailleentrepot);
        formData.append('user_id', common.userid);
        Axios({
            url: common.ipapi + '/api/entrepot/create',
            method: 'POST',
            headers: {
                'Content-Type': 'form-data'
            },
            data: formData
        })
            .then((res) => {
                setCodeentr('');
                setLibentr('');
                setTailleentr('');
                setMsg(res.data.msg);
                setMsgravity('success');
                setIsSave(true);
                getEntrepots();
                setLoad(false);
            })
            .catch((error) => {
                setMsg(error);
                setIsSave(true);
                setLoad(false);
                setMsgravity('danger');
            });
    }
    function updateEntrepot(ide) {
        setLoad(true);
        var formData = new FormData();
        formData.append('libelleentrepot', libelleentrepot);
        formData.append('tailleentrepot', tailleentrepot);
        formData.append('user_id', common.userid);
        Axios({
            url: common.ipapi + '/api/entrepot/update/' + ide,
            method: 'POST',
            headers: {
                'Content-Type': 'form-data'
            },
            data: formData
        })
            .then((res) => {
                setIsSave(true);
                setMsgravity('success');
                setMsg(res.data.msg);
                getEntrepots();
                initialise();
                setLoad(false);
            })
            .catch((error) => {
                setMsg(error);
                setMsgravity('danger');
            });
    }
    const handleSubmit = (event) => {
        const form = event.currentTarget;
        if (form.checkValidity() === false) {
            event.preventDefault();
            event.stopPropagation();
            setValidated(true);
        } else {
            if (ident != 0) {
                updateEntrepot(ident);
            } else {
                saveEntrepot();
            }
        }
    };

    function IsModif(ids) {
        setLoad(true);
        Axios.get(common.ipapi + '/api/entrepot/edit/' + ids).then((response) => {
            setCodeentr(response.data.codeentrepot);
            setLibentr(response.data.libelleentrepot);
            setTailleentr(response.data.tailleentrepot);
            setId(response.data.id);
            setModif(true);
            setLoad(false);
        });
    }

    function onDelete(id) {
        if (window.confirm('Voulez-vous supprimer ce entrepot ?')) {
            Axios.delete(common.ipapi + '/api/entrepot/destroy/' + id).then((response) => {
                if (response.data.status == 1) {
                    const del = data.filter((d) => d.id !== id);
                    setData(del);
                    setMsgravity('success');
                    setMsg(response.data.msg);
                    setIsSave(true);
                } else {
                    setMsgravity('danger');
                    setMsg(response.data.msg);
                    setIsSave(true);
                }
            });
        }
    }

    function cancel(e) {
        e.preventDefault();
        e.stopPropagation();
        initialise();
    }
    function initialise() {
        setId(0);
        setLibentr('');
        setTailleentr('');
        setCodeentr('');
        setModif(false);
    }

    const fetchData = React.useCallback(({ pageSize, pageIndex }) => {
        const fetchId = ++fetchIdRef.current;
        setLoading(true);
        Axios.get(common.ipapi + '/api/entrepot/index').then((response) => {
            if (fetchId === fetchIdRef.current) {
                const startRow = pageSize * pageIndex;
                const endRow = startRow + pageSize;
                setData(response.data.slice(startRow, endRow));
                setPageCount(Math.ceil(response.data.length / 15));
                setLoading(false);
            }
            setLoad(false);
        });
    }, []);

    const columns = [
        {
            Header: '#',
            accessor: 'id',
            Footer: data.length
        },
        {
            Header: 'Code',
            accessor: 'codeentrepot',
            Footer: ''
        },
        {
            Header: 'Libellé',
            accessor: 'libelleentrepot',
            Footer: ''
        },
        {
            Header: 'Taille',
            accessor: 'tailleentrepot',
            Footer: ''
        },
        {
            Header: 'Créer le.',
            accessor: (originalRow) => dateformat(originalRow.created_at, 'dd-mm-yyyy HH:MM'),
            Footer: ''
        },
        {
            Header: '#',
            accessor: '[editButton]',
            Cell: (row) => (
                <ButtonGroup aria-label="Basic example">
                    <a href="/admin/entrepots" id="actionbtn" onClick={() => IsModif(row.row.original.id)}>
                        <PencilFill size={15} color="green" />
                    </a>
                    <a href="/admin/entrepots" id="actionbtn" onClick={() => onDelete(row.row.original.id)}>
                        <Trash color="red" size={15} />
                    </a>
                </ButtonGroup>
            )
        }
    ];

    return (
        <>
            <Toast id="toast" onClose={() => setIsSave(false)} show={isSave} delay={3000} className={'bg' + msgGravity} autohide>
                <Toast.Header>
                    <img src="holder.js/20x20?text=%20" className="rounded me-2" alt="" />
                    <strong className="me-auto">Info</strong>
                </Toast.Header>
                <Toast.Body className="dark">{msg}</Toast.Body>
            </Toast>
            <Modal show={load} size="sm" aria-labelledby="contained-modal-title-vcenter" centered>
                <Modal.Body>
                    Veuillez patienter.......
                    <Spinner animation="border" variant="success" />
                </Modal.Body>
            </Modal>
            <div className="content contents">
                <Row>
                    <Col md="12">
                        <Form noValidate validated={validated} onSubmit={handleSubmit}>
                            <Row>
                                <Col sm={12}>
                                    <Card>
                                        <CardBody>
                                            <CardTitle>Ajouter un nouvel entrepot</CardTitle>
                                            <Row>
                                                <Col md={4}>
                                                    <Form.Group as={Row} className="mb-3" controlId="formHorizontalEmail">
                                                        <Form.Label column sm={4}>
                                                            Libellé:
                                                        </Form.Label>
                                                        <Col sm={8}>
                                                            <Form.Control
                                                                type="text"
                                                                placeholder="Désignation française"
                                                                aria-describedby="inputGroupPrepend"
                                                                required
                                                                size="sm"
                                                                value={libelleentrepot}
                                                                onChange={(e) => setLibentr(e.target.value)}
                                                            />
                                                            <Form.Control.Feedback type="invalid">
                                                                Veuillez saisir le libellé
                                                            </Form.Control.Feedback>
                                                        </Col>
                                                    </Form.Group>
                                                </Col>
                                                <Col md={4}>
                                                    <Form.Group as={Row} className="mb-3" controlId="formHorizontalEmail">
                                                        <Form.Label column sm={4}>
                                                            Dimension:
                                                        </Form.Label>
                                                        <Col sm={8}>
                                                            <Form.Control
                                                                type="text"
                                                                placeholder="Taille"
                                                                aria-describedby="inputGroupPrepend"
                                                                size="sm"
                                                                value={tailleentrepot}
                                                                onChange={(e) => setTailleentr(e.target.value)}
                                                            />
                                                        </Col>
                                                    </Form.Group>
                                                </Col>
                                                <Col md={3}>
                                                    <Button type="submit" variant="ligth">
                                                        <Save2Fill color="#807f7e" size={20} /> Enregister
                                                    </Button>
                                                </Col>
                                            </Row>
                                        </CardBody>
                                    </Card>
                                </Col>
                                <Col sm={12}>
                                    {/* <Card>
                                        <CardBody> */}
                                    {
                                        !view ? (
                                            <Table
                                                columns={columns}
                                                data={data}
                                                pageCount={pageCount}
                                                loading={loading}
                                                fetchData={fetchData}
                                                tablename="entrepot"
                                            />
                                        ) : (
                                            <Row>
                                                {data.map((row, i) => {
                                                    return (
                                                        <Col md="4" key={i}>
                                                            <Card>
                                                                <CardBody>
                                                                    <CardTitle>{row.libelleentrepot}</CardTitle>
                                                                    <CardSubtitle className="mb-2 text-muted">
                                                                        {row.codeentrepot} /{' '}
                                                                        {dateformat(row.created_at, 'dd-mm-yyyy HH:MM')}
                                                                    </CardSubtitle>
                                                                    {/* <CardText>Some quick example text to build on the card title and make up the bulk of the card's content.</CardText> */}
                                                                    <CardLink href={`/admin/article/entrepot/${row.id}`}>
                                                                        Coût{' '}
                                                                        {new Intl.NumberFormat('fr-FR', {
                                                                            style: 'currency',
                                                                            currency: 'CFA'
                                                                        }).format(row.nb)}
                                                                    </CardLink>
                                                                    <CardLink>Quantité {row.qte}</CardLink>
                                                                    <CardFooter>
                                                                        <ButtonGroup aria-label="Basic example">
                                                                            <PencilFill
                                                                                id="actionbtn"
                                                                                onClick={() => IsModif(row.id)}
                                                                                size={20}
                                                                                color="green"
                                                                            />
                                                                            <Trash
                                                                                id="actionbtn"
                                                                                onClick={() => onDelete(row.id)}
                                                                                color="red"
                                                                                size={20}
                                                                            />
                                                                        </ButtonGroup>
                                                                    </CardFooter>
                                                                </CardBody>
                                                            </Card>
                                                        </Col>
                                                    );
                                                })}
                                            </Row>
                                        )
                                        // <ListeCard columns={columns} datalist={data}></ListeCard>
                                    }
                                    {/* </CardBody>
                                    </Card> */}
                                </Col>
                            </Row>
                        </Form>
                    </Col>
                </Row>
            </div>
        </>
    );
}

export default Enrepots;
