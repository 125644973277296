import React, { useEffect, useState } from 'react';
import { useHistory, Link } from 'react-router-dom';
import { Spinner, ButtonGroup, Toast, Modal, Navbar, Container, Nav, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { CardSubtitle, Card, CardBody, CardTitle, Row, Col, CardLink, CardFooter, CardImg } from 'reactstrap';
import Axios from 'axios';
import { FileExcel, PencilSquare, PlusCircleDotted, Printer, Trash } from 'react-bootstrap-icons';
import dateformat from 'dateformat';
import common from '../../commonData';
import AddMarque from './addMarque';

function Marques() {
    const [isSave, setIsSave] = useState(false);
    const history = useHistory();
    const [load, setLoad] = useState(true);
    const [idMarq, setId] = useState(0);
    const [msg, setMsg] = useState('');
    const [msgGravity, setMsgravity] = useState('danger');
    const [showAddMarque, setShowAddMarque] = useState(false);
    const [data, setDataMarque] = useState([]);
    const [libMarq, setLibelleMarq] = useState('');
    const [actualise, setActualise] = useState(false);

    useEffect(() => {
        if (localStorage.getItem('user-info')) {
            (async function anyNameFunction() {
                getMarque();
            })();
        } else {
            history.push('/login');
        }
    }, [history, actualise]);

    function getMarque() {
        setLoad(true);
        Axios.get(common.ipapi + '/api/marque/index')
            .then((response) => {
                setDataMarque(response.data);
                setLoad(false);
            })
            .catch((errors) => {
                setLoad(false);
            });
    }
    async function getMarqueId(id) {
        setLoad(true);
        await Axios.get(common.ipapi + '/api/marque/edit/' + id)
            .then((responses) => {
                setLibelleMarq(responses.data.libellemarq);
                setLoad(false);
                setId(id);
                setShowAddMarque(true);
            })
            .catch((errors) => {
                setLoad(false);
            });
    }
    function onDelete(id) {
        if (window.confirm('Voulez-vous supprimer cette marque ?')) {
            Axios.delete(common.ipapi + '/api/marque/destroy/' + id).then((response) => {
                if (response.data.status === 1) {
                    const del = data.filter((d) => d.id !== id);
                    setDataMarque(del);
                    //getMarque()
                    setMsg(response.data.msg);
                    setIsSave(true);
                } else {
                    setMsg(response.data.msg);
                    setIsSave(true);
                }
            });
        }
    }

    function setAdds(val) {
        setId(0);
        setShowAddMarque(true);
    }
    function setIds(id) {
        getMarqueId(id);
    }
    function saveAsExcel() {}
    function printListe() {}
    return (
        <>
            <Toast id="toast" onClose={() => setIsSave(false)} show={isSave} delay={3000} className={'bg-' + msgGravity} autohide>
                <Toast.Header>
                    <img src="holder.js/20x20?text=%20" className="rounded me-2" alt="" />
                    <strong className="me-auto">Info</strong>
                </Toast.Header>
                <Toast.Body className="dark">{msg}</Toast.Body>
            </Toast>
            <Modal show={load} size="sm" aria-labelledby="contained-modal-title-vcenter" centered>
                <Modal.Body>
                    Veuillez patienter.......
                    <Spinner animation="border" variant="success" />
                </Modal.Body>
            </Modal>

            {showAddMarque ? (
                <AddMarque
                    id={idMarq}
                    setShowAddFamille={setShowAddMarque}
                    show={showAddMarque}
                    setIsSave={setIsSave}
                    setMsg={setMsg}
                    setMsgravity={setMsgravity}
                    setActualise={setActualise}
                    libMarq={libMarq}
                    actualise={actualise}
                />
            ) : (
                <></>
            )}
            {/* {idMarq !== 0 ? (
                <EditMarque
                    id={idMarq}
                    setShowEdit={setShowEdit}
                    show={showEdit}
                    setIsSave={setIsSave}
                    isSave={false}
                    setDataMarque={setDataMarque}
                    setMsg={setMsg}
                    setMsgravity={setMsgravity}
                />
            ) : (
                <></>
            )} */}

            <div className="content contents">
                <Navbar expand="lg" bg="light" variant="light">
                    <Container>
                        <Navbar.Brand href="#/admin/marques">LISTE DES MARQUES </Navbar.Brand>
                        <Nav className="me-auto">
                            <OverlayTrigger overlay={<Tooltip id="tooltip-disabled">Créer une nouvel marque</Tooltip>}>
                                <Nav.Link href="#/admin/marques" onClick={() => setAdds(true)} title="Créer une nouvel marque">
                                    <PlusCircleDotted color="orange" size={25} /> Créer
                                </Nav.Link>
                            </OverlayTrigger>
                            <OverlayTrigger overlay={<Tooltip id="tooltip-disabled">Exporter vers Excel</Tooltip>}>
                                <Nav.Link href="#/admin/marques" onClick={() => saveAsExcel(data)} title="Exporter vers excel">
                                    <FileExcel color="#0080ff" size={25} /> Excel
                                </Nav.Link>
                            </OverlayTrigger>
                            <OverlayTrigger overlay={<Tooltip id="tooltip-disabled">Exporter vers pdf</Tooltip>}>
                                <Nav.Link href="#/admin/marques" onClick={() => printListe(data)} title="Exporter vers pdf">
                                    <Printer color="#0080ff" size={25} /> Imprimer
                                </Nav.Link>
                            </OverlayTrigger>
                        </Nav>
                    </Container>
                </Navbar>
                <br></br>
                <Row>
                    <Col md={12}>
                        <Row>
                            {data.map((row, i) => {
                                return (
                                    <Col md="3" key={i}>
                                        <Card className="hovers">
                                            <CardBody>
                                                <CardTitle>
                                                    {row.libellemarq}{' '}
                                                    <CardSubtitle className="mb-2 text-muted">
                                                        {dateformat(row.created_at, 'dd-mm-yyyy HH:MM')}
                                                    </CardSubtitle>
                                                </CardTitle>
                                                <CardLink href={`#/admin/marque/${row.id}`}>
                                                    {row.logo ? (
                                                        <CardImg
                                                            alt="Card image cap"
                                                            src={common.ipapi + '/public' + row.logo}
                                                            top
                                                            width="265px"
                                                            height="160px"
                                                        />
                                                    ) : (
                                                        <CardImg src={common.defaultimg} alt="img" top width="265px" height="160px" />
                                                    )}
                                                </CardLink>
                                                {/* <CardLink href={`#/admin/article/marque/${row.id}`}>{row.qte} / {new Intl.NumberFormat('fr-FR', { style: 'currency', currency: 'CFA' }).format(row.nb)}</CardLink> */}
                                                <CardFooter>
                                                    <Row>
                                                        <Col md={9}>
                                                            <Link to={`/admin/marque/${row.id}`} id="actionbtn">
                                                                {row.qte ? row.qte : 0} /{' '}
                                                                {new Intl.NumberFormat('fr-FR', {
                                                                    style: 'currency',
                                                                    currency: 'CFA'
                                                                }).format(row.nb)}
                                                            </Link>
                                                        </Col>
                                                        <Col md={3}>
                                                            <ButtonGroup aria-label="Basic example">
                                                                <PencilSquare
                                                                    id="actionbtn"
                                                                    onClick={() => setIds(row.id)}
                                                                    size={20}
                                                                    color="dark"
                                                                />
                                                                <Trash
                                                                    id="actionbtn"
                                                                    onClick={() => onDelete(row.id)}
                                                                    color="red"
                                                                    size={20}
                                                                />
                                                            </ButtonGroup>
                                                        </Col>
                                                    </Row>
                                                </CardFooter>
                                            </CardBody>
                                        </Card>
                                    </Col>
                                );
                            })}
                        </Row>
                    </Col>
                </Row>
            </div>
        </>
    );
}

export default Marques;
