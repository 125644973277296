import React, { useState, useCallback } from 'react';
import { Link } from 'react-router-dom';
import { ButtonGroup, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { Trash, PencilFill, FileEarmarkExcel, Printer } from 'react-bootstrap-icons';
import moment from 'moment';
import ReactDataGrid from '@inovua/reactdatagrid-community';
import '@inovua/reactdatagrid-community/index.css';
import DateFilter from '@inovua/reactdatagrid-community/DateFilter';
import { jsPDF } from 'jspdf';
import XLSX from 'sheetjs-style';
import 'jspdf-autotable';
import 'assets/css/table.css';
import dateformat from 'dateformat';
import NavMenu from './navbar';

function MyAwesomeTable({ rows, isLoading, onDelete, setIds, setAdds }) {
    window.moment = moment;
    const [selected, setSelected] = useState({});
    const [gridRef, setGridRef] = useState(null);
    const [addfact, setAddFact] = useState(false);
    const [showFact, setShowFact] = useState(false);
    const [filterDate, setFilterDate] = useState({});

    const filterValue = [
        { name: 'codeinventaire', operator: 'contains', type: 'string', value: '' },
        { name: 'dateinventaire', operator: 'inrange', type: 'date', value: '' },
        { name: 'commentaire', operator: 'contains', type: 'string', value: '' },
        { name: 'titre', operator: 'contains', type: 'string', value: '' },
        { name: 'user', operator: 'contains', type: 'string', value: '' }
    ];

    const columnss = [
        {
            name: 'id',
            header: 'Id',
            defaultVisible: false,
            defaultWidth: 80,
            type: 'number'
        },

        {
            header: 'Action',
            defaultWidth: 90,
            type: 'string',
            render: ({ data }) => {
                return (
                    <div className="menudrops">
                        <ButtonGroup aria-label="Basic example">
                            <OverlayTrigger overlay={<Tooltip id="tooltip-disabled">Modifier ligne</Tooltip>}>
                                <a href="#/admin/inventaire" onClick={() => setIds(data.id)}>
                                    <PencilFill size={25} color="green" />
                                </a>
                            </OverlayTrigger>
                            <OverlayTrigger overlay={<Tooltip id="tooltip-disabled">Supprimer ligne</Tooltip>}>
                                <a href="#/admin/inventaire" onClick={() => onDelete(data.id)}>
                                    <Trash color="red" size={25} />{' '}
                                </a>
                            </OverlayTrigger>
                        </ButtonGroup>
                    </div>
                );
            }
        },
        {
            name: 'codeinventaire',
            header: 'N° inventaire',
            defaultWidth: 100,
            type: 'string'
        },
        {
            name: 'dateinventaire',
            header: 'Date inventaire',
            defaultWidth: 200,
            type: 'date',
            textAlign: 'center',
            dateFormat: 'DD-MM-YYYY',
            filterEditor: DateFilter,
            filterEditorProps: (props, { index }) => {
                // for range and notinrange operators, the index is 1 for the after field
                return {
                    dateFormat: 'DD-MM-YYYY',
                    placeholder: index === 1 ? 'Date fin' : 'Date debut'
                };
            },
            render: ({ value, cellProps: { dateFormat } }) => moment(value).format(dateFormat)
        },
        {
            name: 'titre',
            header: 'Titre',
            defaultWidth: 200,
            type: 'string'
        },
        {
            name: 'commentaire',
            header: 'Commentaire',
            defaultWidth: 200,
            type: 'string'
        },
        { name: 'username', header: 'Crée par', defaultWidth: 100, type: 'string' },
        {
            name: 'created_at',
            header: 'Crée le',
            defaultWidth: 130,
            type: 'string',
            render: ({ value }) => dateformat(value, 'dd-mm-yyyy HH:MM')
        },
        {
            name: 'updated_at',
            header: 'Modif. le',
            defaultWidth: 130,
            type: 'string',
            render: ({ value }) => dateformat(value, 'dd-mm-yyyy HH:MM')
        }
    ];

    const setFacts = () => {
        setAddFact(true);
        setShowFact(true);
    };

    const gridStyle = { minHeight: window.screen.height - 200 };

    const onSelectionChange = useCallback(({ selected }) => {
        setSelected(selected);
    }, []);

    const toArray = (selected) => Object.keys(selected).map((id) => id * 1);

    const onFilterValueChange = useCallback((filterValue) => {
        setFilterDate(filterValue[2].value);
    }, []);

    return (
        <div>
            <NavMenu selected={JSON.stringify(toArray(selected))} setAdds={setAdds} setFacts={setFacts} />
            <ReactDataGrid
                idProperty="id"
                handle={setGridRef}
                style={gridStyle}
                loading={isLoading}
                reorderColumns={false}
                pagination
                selected={selected}
                // checkboxColumn
                onSelectionChange={onSelectionChange}
                pageSizes={[200, 400, 600, 800, 1000]}
                defaultLimit={400}
                showHoverRows={true}
                editable={true}
                showColumnMenuTool={false}
                defaultFilterValue={filterValue}
                onFilterValueChange={onFilterValueChange}
                columns={columnss}
                dataSource={rows}
            />
            {/* <p>Selected rows: {JSON.stringify(toArray(selected, dataMap))}.</p> */}
        </div>
    );
}

export default MyAwesomeTable;
