import { useHistory } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import { Spinner, Modal, Toast } from 'react-bootstrap';
import { Card, CardText, CardTitle, CardBody } from 'reactstrap';
import { TriangleHalf } from 'react-bootstrap-icons';

import Axios from 'axios';
import Tables from './gridtables';
import common from '../../commonData';
import EditArticle from './editLight';
import FilterModal from './filterModal';
import AddArticle from './addArticle';
import FicheArticle from './ficheArticle';
import ImportArticle from './importArticle';

var dta = [];
function Articles() {
    const history = useHistory();
    const [datas, setDatas] = useState([]);
    const [csv, setCsv] = useState([]);
    const [load, setLoad] = useState(false);
    const [isSave, setIsSave] = useState(false);
    const [msgGravity, setMsgravity] = useState('success');
    const [msg, setMsg] = useState('');
    const [show, setShow] = useState(false);
    const [showprogress, setShowProgress] = useState(false);
    const [showView, setShowView] = useState(false);
    const [id, setId] = useState(0);
    const [idview, setIdView] = useState(0);
    const [showEdit, setShowEdit] = useState(false);
    const [showAdd, setShowAdd] = useState(false);
    const [showFilterModal, setShowFilterModal] = useState(false);
    const [title, setTitle] = useState('MES ARTICLES EN STOCK');
    const [min, setMin] = useState(0);
    const [zero, setZero] = useState(0);
    const [editData, setEditData] = useState();

    useEffect(() => {
        if (!localStorage.getItem('user-info')) {
            history.push('/login');
        }
        const log = common.search(common.userdroit, 'SAD');
        const log1 = common.search(common.userdroit, 'ART');
        if (log === undefined) {
            if (log1 === undefined) {
                setMsg("Vous n'avez pas le droit d'effectuer cette action, merci de contacter l'administrateur");
                setMsgravity('danger');
                setIsSave(true);
                return;
            }
        }

        (async function anyNameFunction() {
            await getArticle(1);
        })();
    }, [history]);

    async function getArticle(p) {
        setLoad(true);
        await Axios.get(p ? common.ipapi + '/api/article/index' : common.ipapi + '/api/article/indexarticledesactive')
            .then((response) => {
                setDatas(response.data[0]);
                dta = response.data[0];
                setMin(response.data[4]);
                setZero(response.data[5]);
                setLoad(false);
            })
            .catch((errors) => {
                setLoad(false);
            });
    }
    function getMin(t) {
        setLoad(true);
        Axios.get(common.ipapi + '/api/article/min')
            .then((response) => {
                setTitle(t);
                setDatas(response.data);
                dta = response.data;
                setLoad(false);
            })
            .catch((errors) => {
                setLoad(false);
            });
    }
    function getEmpty(t) {
        setLoad(true);
        Axios.get(common.ipapi + '/api/article/empty')
            .then((response) => {
                setTitle(t);
                setDatas(response.data);
                dta = response.data;
                setLoad(false);
            })
            .catch((errors) => {
                setLoad(false);
            });
    }

    const handleClose = () => {
        setCsv([]);
        setShow(false);
        setShowFilterModal(false);
    };

    function onDelete(id) {
        const log = common.search(common.userdroit, 'SAD');
        const log1 = common.search(common.userdroit, 'SART');
        if (log === undefined) {
            if (log1 === undefined) {
                setMsg("Vous n'avez pas le droit d'effectuer cette action, merci de contacter l'administrateur");
                setMsgravity('danger');
                setIsSave(true);
                return;
            }
        }
        if (window.confirm('Voulez-vous supprimer cet article ?')) {
            setLoad(true);
            Axios.delete(common.ipapi + '/api/article/destroy/' + id).then((response) => {
                if (response.data.status === 1) {
                    const del = dta.filter((d) => d.id !== id);
                    dta = del;
                    setDatas(del);
                    setMsgravity('info');
                    setMsg(response.data.msg);
                    setIsSave(true);
                    setLoad(false);
                } else if (response.data.status === 0) {
                    setMsgravity('warning');
                    setMsg(response.data.msg);
                    setIsSave(true);
                    setLoad(false);
                } else {
                    setMsgravity('danger');
                    setMsg('Une erreur a eu lieu');
                    setIsSave(true);
                    setLoad(false);
                }
            });
        }
    }

    function setAdds(val) {
        const log = common.search(common.userdroit, 'SAD');
        const log1 = common.search(common.userdroit, 'ADA');
        if (log === undefined) {
            if (log1 === undefined) {
                setMsg("Vous n'avez pas le droit d'effectuer cette action, merci de contacter l'administrateur");
                setMsgravity('danger');
                setIsSave(true);
                return;
            }
        }
        setEditData([]);
        setId(0);
        setShowAdd(true);
    }

    function setIds(id) {
        const log = common.search(common.userdroit, 'SAD');
        const log1 = common.search(common.userdroit, 'UDA');
        if (log === undefined) {
            if (log1 === undefined) {
                setMsg("Vous n'avez pas le droit d'effectuer cette action, merci de contacter l'administrateur");
                setMsgravity('danger');
                setIsSave(true);
                return;
            }
        }
        Axios.get(common.ipapi + '/api/article/edit/' + id)
            .then((response) => {
                if (response.data) {
                    setEditData(response.data);
                    setId(id);
                    setShowAdd(true);
                }
                setLoad(false);
            })
            .catch((errors) => {
                setLoad(false);
            });
    }
    function setIdViews(id) {
        setIdView(id);
        setShowView(true);
    }
    function setShowFilterModals(bool) {
        setShowFilterModal(true);
    }

    return (
        <>
            <Toast id="toast" onClose={() => setIsSave(false)} show={isSave} delay={3000} className={'bg-' + msgGravity} autohide>
                <Toast.Header>
                    <img src="holder.js/20x20?text=%20" className="rounded me-2" alt="" />
                    <strong className="me-auto">Info</strong>
                </Toast.Header>
                <Toast.Body className="dark">{msg}</Toast.Body>
            </Toast>
            <Modal show={load} size="sm" aria-labelledby="contained-modal-title-vcenter" centered>
                <Modal.Body>
                    Veuillez patienter.......
                    <Spinner animation="border" variant="success" />
                </Modal.Body>
            </Modal>

            {showAdd ? (
                <AddArticle
                    ids={id}
                    editData={editData}
                    setShowAdd={setShowAdd}
                    show={showAdd}
                    setIsSave={setIsSave}
                    isSave={false}
                    setDatas={setDatas}
                    setMsg={setMsg}
                    setMsgravity={setMsgravity}
                    getArticle={getArticle}
                />
            ) : (
                <></>
            )}
            {showFilterModal ? (
                <FilterModal
                    setShowFilterModals={setShowFilterModals}
                    showFilterModal={showFilterModal}
                    setMsg={setMsg}
                    setIsSave={setIsSave}
                    setMsgravity={setMsgravity}
                    setLoad={setLoad}
                    load={load}
                    handleClose={handleClose}
                />
            ) : (
                <></>
            )}
            {showEdit ? (
                <EditArticle
                    id={id}
                    setShowEdit={setShowEdit}
                    show={showEdit}
                    setIsSave={setIsSave}
                    isSave={false}
                    setDatas={setDatas}
                    setMsg={setMsg}
                    setMsgravity={setMsgravity}
                    getArticle={getArticle}
                />
            ) : (
                <></>
            )}
            {showView ? (
                <FicheArticle
                    id={idview}
                    setShowView={setShowView}
                    show={showView}
                    setIdViews={setIdViews}
                    setDatas={setDatas}
                    getArticle={getArticle}
                    data={datas}
                />
            ) : (
                <></>
            )}
            {show ? (
                <ImportArticle
                    show={show}
                    setMsg={setMsg}
                    setIsSave={setIsSave}
                    setMsgravity={setMsgravity}
                    setLoad={setLoad}
                    load={load}
                    showprogress={showprogress}
                    setShowProgress={setShowProgress}
                    handleClose={handleClose}
                />
            ) : (
                <></>
            )}
            {common.search(common.userdroit, 'SAD') !== undefined || common.search(common.userdroit, 'ART') !== undefined ? (
                <div className="content contents">
                    <div id="tablecontent">
                        <Tables
                            rows={datas}
                            isLoading={load}
                            onDelete={onDelete}
                            setIds={setIds}
                            setIdViews={setIdViews}
                            min={min}
                            zero={zero}
                            title=""
                            setAdds={setAdds}
                            getMin={getMin}
                            getEmpty={getEmpty}
                            setShow={setShow}
                            setShowFilterModals={setShowFilterModals}
                            getArticle={getArticle}
                        />
                    </div>
                </div>
            ) : (
                <Card style={{ width: '25rem' }} className="centerCard">
                    <CardBody>
                        <CardTitle tag="h5">
                            <TriangleHalf size={60} color="red"></TriangleHalf> Avertissement
                        </CardTitle>
                        <CardText>Vous n'êtes pas autorisé à utiliser cette section. Merci de contacter votre administrateur</CardText>
                    </CardBody>
                </Card>
            )}
        </>
    );
}

export default Articles;
