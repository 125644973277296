import React, { useEffect, useState } from 'react';
import { Button, ButtonGroup, Col, Form, Modal, OverlayTrigger, Row, Spinner, Tooltip } from 'react-bootstrap';
import { Backspace, BackspaceFill, Save2Fill } from 'react-bootstrap-icons';
import * as Yup from 'yup';
import { Field, Formik } from 'formik';
import Axios from 'axios';
import { useHistory } from 'react-router-dom';
import Table from 'views/tableaddedit';
import common from './commonData';

function AddUserRight({ data, setShowAddRight, showAddRight, setIsSave, setMsg, setMsgravity, setLoad }) {
    const [dataDroit, setUserDroit] = useState([]);
    const history = useHistory();
    useEffect(() => {
        if (localStorage.getItem('user-info')) {
            fetchData();
        } else {
            history.push('/login');
        }
    }, [data]);

    const fetchData = async () => {
        try {
            await Axios.get(common.ipapi + '/api/user/droitbyuser/' + data.id).then((response) => {
                setUserDroit(response.data);
            });
        } catch (error) {}
    };

    const InterventionState = ({ value: initialValue, row: { index } }) => {
        const [value, setValue] = React.useState(initialValue);
        const onChange = (e) => {
            setValue(e.target.checked);
        };
        const onBlur = () => {
            dataDroit[index].listdroituser = value;
        };
        React.useEffect(() => {
            setValue(initialValue);
        }, [initialValue]);
        return <Form.Control type="checkbox" value={value} checked={value} onChange={onChange} onBlur={onBlur} />;
    };
    const columns = [
        {
            Header: 'Code droit',
            accessor: 'code_Droit'
        },
        {
            Header: 'Libellé droit',
            accessor: 'lib_droit'
        },
        {
            Header: '#',
            accessor: 'listdroituser',
            Cell: InterventionState
        }
    ];

    function saveDroit(setSubmitting) {
        if (window.confirm('Voulez-vous confirmer cette action') == false) {
            return;
        }

        setLoad(true);
        setSubmitting(false);
        var formData = new FormData();
        formData.append('droits', JSON.stringify(dataDroit));
        Axios({
            url: common.ipapi + '/api/user/ajoutdroituser/' + data.id,
            method: 'POST',
            headers: {
                'Content-Type': 'form-data'
            },
            data: formData
        })
            .then((res) => {
                if (res.status !== 200) {
                    setMsg('Veuillez vérifier votre connexion internet');
                    setMsgravity('info');
                    setIsSave(false);
                    setLoad(false);
                    setSubmitting(true);
                    return;
                }
                if (res.data.status === 1) {
                    setMsg(res.data.msg);
                    setMsgravity('success');
                    setIsSave(true);
                    setLoad(false);
                    setShowAddRight(true);
                    setSubmitting(true);
                    // setTimeout(handleClose, 600);
                } else {
                    // setMsg(res.data.msg);
                    // setMsgravity('danger');
                    // setIsSave(true);
                    // setLoad(false);
                    setSubmitting(true);
                }
            })
            .catch((error) => {
                // setMsg(error);
                // setIsSave(true);
                // setMsgravity('danger');
                //setLoad(false);
            });
    }

    return (
        <Formik
            initialValues={{}}
            enableReinitialize
            onSubmit={async (values, { resetForm }) => {
                saveDroit(resetForm);
            }}
        >
            {({ handleSubmit, isSubmitting }) => (
                <Modal show={showAddRight} size="md">
                    <Form noValidate onSubmit={handleSubmit}>
                        <Modal.Header>
                            AJOUTER UN DROIT D'UTILISATEUR
                            <BackspaceFill onClick={() => setShowAddRight(false)} color="red" size={30} />
                        </Modal.Header>
                        <Modal.Body>
                            <div className="lightbody">
                                <Table columns={columns} data={dataDroit} />
                            </div>
                        </Modal.Body>
                        <Modal.Footer>
                            <div className="ml-auto">
                                <ButtonGroup size="sm">
                                    <Button variant="secondary" onClick={() => setShowAddRight(true)}>
                                        <Backspace color="red" size={20} />
                                    </Button>
                                    <Button type="submit" variant="warning" disabled={isSubmitting}>
                                        {isSubmitting ? (
                                            <Spinner animation="border" variant="success" />
                                        ) : (
                                            <Save2Fill color="#807f7e" size={20} />
                                        )}
                                        Enregister
                                    </Button>
                                </ButtonGroup>
                            </div>
                        </Modal.Footer>
                    </Form>
                </Modal>
            )}
        </Formik>
    );
}
export default AddUserRight;
