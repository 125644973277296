import * as React from 'react';

import { jsPDF } from 'jspdf';
import 'jspdf-autotable';
import config from '../../../commonData';
import dateformat from 'dateformat';
import { Container, Nav, Navbar, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { Pencil, PencilSquare, PlusCircleDotted, Printer, X } from 'react-bootstrap-icons';

export default function AccessibilityTooltips({
    selected,
    handleClickOpen,
    setLoading,
    setLoad,
    load,
    setOpenSnak,
    setMsg,
    setSeverity,
    gridRef
}) {
    const toArray = (selected) => Object.keys(selected).map((id) => id * 1);
    const id = JSON.stringify(toArray(selected)).replace('[', '').replace(']', '');

    async function handleClickDeleteLigne(id) {
        const log = config.search(config.userdroit, 'SAD');
        const log1 = config.search(config.userdroit, 'DSP');
        if (log == undefined) {
            if (log1 == undefined) {
                setMsg("Vous n'avez pas le droit d'effectuer cette action, merci de contacter l'administrateur");
                setSeverity('danger');
                setOpenSnak(true);
                return;
            }
        }
        if (id === '') {
            setOpenSnak(true);
            setMsg('Veuillez sélectionner une ligne');
            setSeverity('error');
            return;
        }
        let text = 'Voulez-vous supprimer cette ligne';
        let res = window.confirm(text);
        if (res === false) {
            return;
        }
        setLoading(true);
        const response = await fetch(config.ipapi + '/api/del-depense/' + id, {
            method: 'DELETE'
        });
        const json = await response.json();
        if (json.status) {
            setOpenSnak(true);
            setMsg(json.msg);
            setSeverity('success');
            setLoad(!load);
        } else {
            setOpenSnak(true);
            setMsg(json.msg);
            setSeverity('error');
        }
        setLoading(false);
    }

    async function handlePrintListe() {
        var datas = [];
        var total = 0;
        setLoading(true);
        var doc = new jsPDF();
        var width = doc.internal.pageSize.getWidth();

        gridRef.current.data.forEach((data, index) => {
            total += data.montant_depense;
            datas.push([
                { content: index + 1 },
                { content: data.type },
                { content: dateformat(data.date_dep, 'dd-mm-yyyy') },
                { content: new Intl.NumberFormat('de-DE').format(data.montant_depense) },
                { content: data.motif_dep }
            ]);
        });
        datas.push([
            { content: 'TOTAL', colSpan: 3, fstyles: { fontStyle: 'bold', fontSize: 15 } },
            { content: new Intl.NumberFormat('de-DE').format(total), styles: { fontStyle: 'bold', fontSize: 15 } },
            { content: '' }
        ]);

        // doc.addImage(config.logo, 'jpg', 14, 10, 40, 13);
        config.ipapi == 'https://192.168.8.109:81' || config.ipapi == 'https://api.tracky-entrepot.com'
            ? doc.addImage(config.logo, 'jpg', 14, 5, 30, 30)
            : doc.addImage(config.logo1, 'jpg', 14, 5, 30, 30);
        doc.setFontSize(16);
        // doc.text('FOOTPRINT', 90, 17);
        doc.setFontSize(12);
        doc.setFillColor(225, 206, 154);
        doc.rect(65, 13, width - 80, 10, 'F');
        doc.setTextColor(0, 0, 0);
        doc.text('LISTE DES DEPENSES', 90, 20);

        if (datas.length > 0) {
            doc.autoTable({
                styles: {
                    fillColor: [0, 0, 0],
                    textColor: [255, 255, 255],
                    cellPadding: 1.5,
                    halign: 'center'
                },
                columnStyles: {
                    0: {
                        halign: 'center',
                        fillColor: [255, 255, 255],
                        textColor: [0, 0, 0],
                        cellPadding: 0.75,
                        fontSize: 10
                    },
                    1: {
                        halign: 'left',
                        fillColor: [255, 255, 255],
                        textColor: [0, 0, 0],
                        cellPadding: 0.75,
                        fontSize: 10
                    },
                    2: {
                        halign: 'center',
                        fillColor: [255, 255, 255],
                        textColor: [0, 0, 0],
                        cellPadding: 0.75,
                        fontSize: 10
                    },
                    3: {
                        halign: 'center',
                        fillColor: [255, 255, 255],
                        textColor: [0, 0, 0],
                        cellPadding: 0.75,
                        fontSize: 10
                    },
                    4: {
                        halign: 'left',
                        fillColor: [255, 255, 255],
                        textColor: [0, 0, 0],
                        cellPadding: 0.75,
                        fontSize: 10
                    }
                },
                theme: 'grid',
                startY: 38,
                margin: { bottom: 15 },
                horizontalPageBreak: true,
                head: [['Ordre', 'Type', 'Date dépense', 'Montant dépense', 'Motifs']],
                body: datas
            });
        }
        var now = new Date();
        var mois = now.getMonth() + 1;
        doc.text('Edité par ' + config.user + ' le ' + now.getDate() + '/' + mois + '/' + now.getFullYear(), 14, 292, null, null, 'left');
        const pageCount = doc.internal.getNumberOfPages();
        for (var u = 1; u <= pageCount; u++) {
            doc.setPage(u);
            doc.setFontSize(10);
            doc.text('Page ' + String(u) + ' sur ' + String(pageCount), 210 - 18, 292, null, null, 'right');
        }
        setLoading(false);
        doc.save('listedepense-' + now.getDate() + '/' + mois + '/' + now.getFullYear() + '.pdf');
    }

    return (
        <Navbar expand="lg" bg="light" variant="light">
            <Container>
                {/* <Navbar.Brand href="#/admin/sorties">LISTE DES FACTURES CLIENTS </Navbar.Brand> */}
                <Nav className="me-auto">
                    <OverlayTrigger placement="top" overlay={<Tooltip id="tooltip-disabled">Ajouter une nouvelle dépense</Tooltip>}>
                        <Nav.Link href="#/admin/depenses" onClick={() => handleClickOpen(1, 0)}>
                            <PlusCircleDotted color="orange" size={25} /> Créer
                        </Nav.Link>
                    </OverlayTrigger>
                    {id != 0 ? (
                        <>
                            <OverlayTrigger
                                placement="top"
                                overlay={<Tooltip id="tooltip-disabled">Modifier la ligne sélectionnée</Tooltip>}
                            >
                                <Nav.Link href="#/admin/depenses" onClick={() => handleClickOpen(1, id)}>
                                    <PencilSquare color="blue" size={25} /> Modifier
                                </Nav.Link>
                            </OverlayTrigger>
                            <OverlayTrigger
                                placement="top"
                                overlay={<Tooltip id="tooltip-disabled">Supprimer la ligne sélectionnée</Tooltip>}
                            >
                                <Nav.Link href="#/admin/depenses" onClick={() => handleClickDeleteLigne(id)}>
                                    <X color="red" size={25} /> Supprimer
                                </Nav.Link>
                            </OverlayTrigger>
                        </>
                    ) : (
                        <></>
                    )}

                    <OverlayTrigger placement="top" overlay={<Tooltip id="tooltip-disabled">Exporter vers PDF</Tooltip>}>
                        <Nav.Link href="#/admin/depenses" onClick={() => handlePrintListe()}>
                            <Printer color="#0080ff" size={25} /> Pdf
                        </Nav.Link>
                    </OverlayTrigger>
                    <OverlayTrigger placement="top" overlay={<Tooltip id="tooltip-disabled">Exporter vers Excel</Tooltip>}>
                        <Nav.Link href="#/admin/depenses">
                            <X color="green" size={25} /> Excel
                        </Nav.Link>
                    </OverlayTrigger>
                </Nav>
            </Container>
        </Navbar>
    );
}
