import { useHistory } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import { Spinner, Toast, Modal, Dropdown, OverlayTrigger, Tooltip, Navbar, Container, Nav } from 'react-bootstrap';
import { Card, CardBody, CardTitle, CardText } from 'reactstrap';
import { TriangleHalf } from 'react-bootstrap-icons';
import Tables from './gridtables';
import 'jspdf-autotable';
import Axios from 'axios';
import common from '../../commonData';
import AddReglement from './addReglement';
import UpdateReglement from './updateReglement';

var dta = [];
function ListeFacture() {
    const history = useHistory();
    const [datas, setDatas] = useState([]);
    const [load, setLoad] = useState(false);
    const [actualise, setActualise] = useState(false);
    const [msg, setMsg] = useState('');
    const [isSave, setIsSave] = useState(false);
    const [msgGravity, setMsgravity] = useState('success');
    const [add, setAdd] = useState(false);
    const [showAdd, setShowAdd] = useState(false);
    const [showEdit, setShowEdit] = useState(true);
    const [ids, setId] = useState(0);

    const log = common.search(common.userdroit, 'SAD');
    const log1 = common.search(common.userdroit, 'REG');

    useEffect(() => {
        setLoad(true);
        if (localStorage.getItem('user-info')) {
            if (log == undefined) {
                if (log1 == undefined) {
                    setMsg("Vous n'avez pas le droit d'effectuer cette action, merci de contacter l'administrateur");
                    setMsgravity('danger');
                    setIsSave(true);
                    setLoad(false);
                    return;
                }
            }
            (async function anyNameFunction() {
                await Axios.get(common.ipapi + '/api/reglement/index')
                    .then((response) => {
                        setDatas(response.data.datas);
                        dta = response.data.datas;
                        setLoad(false);
                        if (localStorage.getItem('showl')) {
                            setId(localStorage.getItem('showl'));
                            setShowEdit(true);
                        }
                    })
                    .catch((errors) => {
                        setLoad(false);
                    });
            })();
        } else {
            history.push('/login');
        }
    }, [history, actualise]);

    function setAdds(val) {
        setLoad(true);
        Axios.get(common.ipapi + '/api/check-ouverture-caisse/1')
            .then((response) => {
                if (response.data == 0) {
                    setMsg('Veillez ouvrir la caisse');
                    setMsgravity('danger');
                    setIsSave(true);
                    setLoad(false);
                    return;
                } else {
                    setLoad(false);
                    setAdd(val);
                    setShowAdd(true);
                }
            })
            .catch((errors) => {
                setLoad(false);
            });
    }
    function setIds(id) {
        localStorage.setItem('showl', id);
        setId(id);
        setShowEdit(true);
    }

    function droitSup(obj) {
        if (obj.code_Droit === 'SAD' || obj.code_Droit === 'SREG') {
            return true;
        } else {
            return false;
        }
    }
    function onDelete(id) {
        var arrByID = common.userdroit.filter(droitSup);
        if (arrByID.length === 0) {
            setIsSave(true);
            setMsg("Vous n'avez pas le droit de mener cette action, merci de contacter l'administrateur");
            setMsgravity('danger');
            return false;
        }
        if (window.confirm('Voulez-vous supprimer cette ligne ?')) {
            setLoad(true);
            Axios.delete(common.ipapi + '/api/reglement/destroy/' + id).then((response) => {
                if (response.data.status == 1) {
                    const del = dta.filter((d) => d.id !== id);
                    dta = del;
                    setDatas(del);
                    setMsg(response.data.msg);
                    setMsgravity('success');
                    setLoad(false);
                } else {
                    setMsg(response.data.msg);
                    //setIsSave(true)
                    setLoad(false);
                }
            });
        }
    }

    return (
        <>
            <Modal show={load} size="sm" aria-labelledby="contained-modal-title-vcenter" centered>
                <Modal.Body>
                    Veuillez patienter.......
                    <Spinner animation="border" variant="success" />
                </Modal.Body>
            </Modal>
            {showAdd !== false ? (
                <AddReglement
                    setShowAdd={setShowAdd}
                    show={showAdd}
                    setIsSave={setIsSave}
                    isSave={false}
                    setDatas={setDatas}
                    setMsg={setMsg}
                    setMsgravity={setMsgravity}
                    setActualise={setActualise}
                    actualise={actualise}
                />
            ) : (
                <></>
            )}

            {ids !== 0 ? (
                <UpdateReglement
                    ids={ids}
                    setShowEdit={setShowEdit}
                    show={showEdit}
                    setIsSave={setIsSave}
                    isSave={false}
                    setDatas={setDatas}
                    setMsg={setMsg}
                    setMsgravity={setMsgravity}
                    setId={setId}
                    setActualise={setActualise}
                    actualise={actualise}
                />
            ) : (
                <></>
            )}
            <Toast id="toast" onClose={() => setIsSave(false)} show={isSave} delay={3000} className={'bg-' + msgGravity} autohide>
                <Toast.Header>
                    <img src="holder.js/20x20?text=%20" className="rounded me-2" alt="" />
                    <strong className="me-auto">Info</strong>
                </Toast.Header>
                <Toast.Body className="dark">{msg}</Toast.Body>
            </Toast>
            {log != undefined || log1 != undefined ? (
                <div className="content contents">
                    <div id="tablecontent">
                        <Tables
                            rows={datas}
                            isLoading={load}
                            onDelete={onDelete}
                            setIds={setIds}
                            setIsSave={setIsSave}
                            setMsg={setMsg}
                            setAdds={setAdds}
                        />
                        {/* <Tables columns={columns} rows={datas} isLoading={load}/> */}
                    </div>
                </div>
            ) : (
                <Card style={{ width: '25rem' }} className="centerCard">
                    <CardBody>
                        <CardTitle tag="h5">
                            <TriangleHalf size={60} color="red"></TriangleHalf> Avertissement
                        </CardTitle>
                        <CardText>Vous n'êtes pas autorisé à utiliser cette section. Merci de contacter votre administrateur</CardText>
                    </CardBody>
                </Card>
            )}
        </>
    );
}

export default ListeFacture;
