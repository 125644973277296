import React, { useState, useCallback } from 'react';
import ReactDataGrid from '@inovua/reactdatagrid-community';
import '@inovua/reactdatagrid-community/index.css';
import DateFilter from '@inovua/reactdatagrid-community/DateFilter';
import moment from 'moment';

import axios from 'axios';

import config from '../../../commonData';
import dateformat from 'dateformat';
import { ButtonGroup, OverlayTrigger, Tooltip } from 'react-bootstrap';
import {
    Arrow90degLeft,
    ArrowBarLeft,
    Backspace,
    BoxArrowInDown,
    Check,
    CheckSquare,
    PenFill,
    Pencil,
    PencilFill
} from 'react-bootstrap-icons';

function DataGridCaisse({
    selected,
    setSelected,
    operation,
    isLoading,
    setLoad,
    load,
    setMsg,
    setSeverity,
    setOpenSnak,
    setGridRef,
    handleClickOpen
}) {
    window.moment = moment;
    const filterValue = [
        { name: 'date_caisse', operator: 'inrange', type: 'date', value: null },
        { name: 'solde_init', operator: 'gte', type: 'number', value: '' },
        { name: 'montant_caisse_jour', operator: 'gte', type: 'number', value: '' },
        { name: 'created_at', operator: 'inrange', type: 'date', value: null }
    ];

    const handleClickChangeEtat = (id, etat) => {
        const log = config.search(config.userdroit, 'SAD');
        const log1 = config.search(config.userdroit, 'DVA');
        if (log == undefined) {
            if (log1 == undefined) {
                setMsg("Vous n'avez pas le droit d'effectuer cette action, merci de contacter l'administrateur");
                setSeverity('danger');
                setOpenSnak(true);
                return;
            }
        }
        if (window.confirm('Voulez-vous confirmer cette action ?')) {
            let url = config.ipapi + '/api/update-depense-etat/' + id;
            var formData = new FormData();
            formData.append('etat', etat);
            axios({
                url: url,
                method: 'POST',
                data: formData
            })
                .then((res) => {
                    if (res.data.status) {
                        setMsg(res.data.msg);
                        setSeverity('success');
                        setOpenSnak(true);
                        setLoad(!load);
                    } else {
                        setOpenSnak(true);
                        setMsg(res.data.msg);
                        setSeverity('error');
                    }
                })
                .catch(() => {
                    setOpenSnak(true);
                    setMsg('error');
                    setSeverity('error');
                });
        }
    };

    async function handleCloture(id, etat) {
        if (window.confirm('Voulez-vous confirmer cette action ?')) {
            let url = config.ipapi + '/api/cloture/' + etat + '/' + id;
            await axios
                .post(url)
                .then((res) => {
                    if (res.data.status) {
                        setOpenSnak(true);
                        setMsg('Opération terminée avec succes');
                        setSeverity('success');
                        setLoad(!load);
                    } else {
                        setOpenSnak(true);
                        setOpenSnak({ success: false });
                        setMsg(res.data.msg);
                        setSeverity('error');
                    }
                })
                .catch(() => {
                    setOpenSnak(true);
                });
        }
    }
    const User = ({ data }) => {
        return data.user ? data.user.name : '';
    };
    const Link = ({ id, children, title }) => (
        <OverlayTrigger overlay={<Tooltip id={id}>{title}</Tooltip>}>
            <a href="#/admin/caisses">{children}</a>
        </OverlayTrigger>
    );
    const Etat = ({ data }) => {
        var icons = (
            <div>
                <Link title="Validé cette demande" id="etat-1">
                    <PencilFill onClick={() => handleClickOpen(data.id)} color="blue" size={25} />
                </Link>
                {data.date_fermeture_caisse == null ? (
                    <Link title="Clôturer cette caisse " id="etat-1">
                        <BoxArrowInDown onClick={() => handleCloture(data.id, 1)} color="red" size={25} />
                    </Link>
                ) : (
                    <Link title="Annuler clôturer cette caisse " id="etat-1">
                        <ArrowBarLeft onClick={() => handleCloture(data.id, 0)} color="red" size={25} />
                    </Link>
                )}
            </div>
        );

        return icons;
    };
    const columnss = [
        {
            name: 'id',
            header: 'Id',
            defaultVisible: false,
            defaultWidth: 80,
            type: 'number'
        },
        {
            header: '#',
            defaultWidth: 100,
            type: 'string',
            render: ({ data }) => <Etat data={data} />
        },

        {
            name: 'solde_init',
            header: 'Solde init.',
            defaultWidth: 90,
            textAlign: 'center',
            type: 'number',
            render: ({ value }) => numberformat(value)
        },
        {
            name: 'montant_caisse_jour',
            header: 'Montant encaissé',
            defaultWidth: 90,
            textAlign: 'center',
            type: 'number',
            render: ({ value }) => numberformat(value)
        },
        {
            name: 'date_caisse',
            header: 'Date ',
            textAlign: 'center',
            defaultWidth: 150,
            dateFormat: 'DD-MM-YYYY',
            filterEditor: DateFilter,
            filterEditorProps: (props, { index }) => {
                // for range and notinrange operators, the index is 1 for the after field
                return {
                    dateFormat: 'DD-MM-YYYY',
                    placeholder: index === 1 ? 'Date fin' : 'Date debut'
                };
            },
            render: ({ value, cellProps: { dateFormat } }) => moment(value).format(dateFormat)
        },

        { name: 'user', header: 'Crée par', defaultWidth: 100, type: 'string', render: ({ data }) => <User data={data} /> },
        {
            name: 'created_at',
            header: 'Crée le',
            defaultWidth: 130,
            type: 'string',
            render: ({ value }) => dateformat(value, 'dd-mm-yyyy HH:MM')
        }
    ];

    function numberformat(data) {
        return new Intl.NumberFormat().format(data);
    }
    const gridStyle = { minHeight: window.screen.height - 400 };
    const onSelectionChange = useCallback(({ selected }) => {
        setSelected(selected);
    }, []);

    return (
        <div>
            <ReactDataGrid
                idProperty="id"
                handle={setGridRef}
                style={gridStyle}
                loading={isLoading}
                reorderColumns={false}
                pagination
                selected={selected}
                onSelectionChange={onSelectionChange}
                pageSizes={[200, 400, 600, 800, 1000]}
                defaultLimit={400}
                showHoverRows={true}
                editable={true}
                showColumnMenuTool={false}
                defaultFilterValue={filterValue}
                columns={columnss}
                dataSource={operation}
            />
        </div>
    );
}

export default DataGridCaisse;
