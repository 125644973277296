import React, { useState, useRef } from 'react';
import { Link, useParams, useHistory } from 'react-router-dom';
import { Form, ButtonGroup, Toast, Spinner } from 'react-bootstrap';
import { Card, Navbar, Container, NavbarBrand, Collapse, Nav, Button, FormGroup, Row, Col, CardBody } from 'reactstrap';
import Axios from 'axios';
import { Arrow90degLeft, CartCheck, Eye, Trash, PencilSquare } from 'react-bootstrap-icons';
import Table from '../../table';
import common from '../../commonData';

function FournisseurArticle() {
    const [msgGravity, setMsgravity] = useState('success');
    const [msg, setMsg] = useState('');
    const [isSave, setIsSave] = useState(false);
    const [load, setLoading] = useState(true);
    const [pageCount, setPageCount] = useState(0);
    const fetchIdRef = useRef(0);
    const sidebarToggle = useRef();
    const [datas, setDatas] = useState([]);
    let { id } = useParams();
    const history = useHistory();

    const fetchData = React.useCallback(
        ({ pageSize, pageIndex }) => {
            if (!localStorage.getItem('user-info')) {
                history.push('/login');
            }
            const fetchId = ++fetchIdRef.current;
            setLoading(true);
            Axios.get(common.ipapi + '/api/article/fournisseur/' + id).then((response) => {
                if (fetchId === fetchIdRef.current) {
                    const startRow = pageSize * pageIndex;
                    const endRow = startRow + pageSize;
                    setDatas(response.data.slice(startRow, endRow));
                    setPageCount(Math.ceil(response.data.length / 1005));
                    setLoading(false);
                }
                setLoading(false);
            });
        },
        [history, id]
    );

    const columns = [
        {
            Header: '#',
            accessor: 'id',
            Footer: datas.length
        },
        {
            Header: 'Image',
            accessor: (originalRow) => (
                <Link to={`/admin/article/fiche/${originalRow.id}`}>
                    {originalRow.urlart ? (
                        originalRow.urlart.indexOf('sortly') == -1 ? (
                            <img className="imgart rounded" src={common.ipapi + originalRow.urlart}></img>
                        ) : (
                            <img className="imgart rounded" src={common.ipapi + originalRow.urlart}></img>
                        )
                    ) : (
                        ''
                    )}
                </Link>
            ),
            Footer: ''
        },
        {
            Header: 'Code article',
            accessor: 'codeart',
            Footer: ''
        },
        {
            Header: 'Description',
            accessor: 'descriptionart',
            Footer: ''
        },
        {
            Header: 'Famille',
            accessor: 'libellefam',
            Footer: ''
        },
        {
            Header: 'Marque',
            accessor: 'libellemarq',
            Footer: ''
        },
        {
            Header: 'Entrepot',
            accessor: 'libelleentrepot',
            Footer: ''
        },
        {
            Header: 'Fournisseur',
            accessor: 'nomfourn',
            Footer: ''
        },
        {
            Header: 'Quantite',
            accessor: 'qtestock',
            Footer: (info) => {
                const total = React.useMemo(() => info.rows.reduce((sum, row) => row.values.qtestock + sum, 0), [info.rows]);

                return <>{total}</>;
            }
        },
        {
            Header: 'Seuil',
            accessor: 'qteseuil',
            Footer: ''
        },
        {
            Header: 'Prix achat',
            accessor: 'prixachat',
            Footer: (info) => {
                // Only calculate total visits if rows change
                const total = React.useMemo(() => info.rows.reduce((sum, row) => row.values.prixachat + sum, 0), [info.rows]);

                return <>{new Intl.NumberFormat('fr-FR', { style: 'currency', currency: 'CFA' }).format(total)}</>;
            }
        },
        {
            Header: 'Coeficient',
            accessor: 'coeficient',
            Footer: ''
        },
        {
            Header: 'Prix vente',
            accessor: (originalRow) => originalRow.prixachat * originalRow.coeficient,
            Footer: (info) => {
                const total = React.useMemo(
                    () => info.rows.reduce((sum, row) => row.values.prixachat * row.values.coeficient + sum, 0),
                    [info.rows]
                );
                return <>{new Intl.NumberFormat('fr-FR', { style: 'currency', currency: 'CFA' }).format(total)}</>;
            }
        },
        {
            Header: '#',
            accessor: '[editButton]',
            Cell: (row) => (
                <ButtonGroup aria-label="Basic example">
                    <Link to={`/admin/article/fiche/${row.row.original.id}`}>
                        <Eye size={20} color="royalblue" />
                    </Link>
                    <Link to={`/admin/article/edit/${row.row.original.id}`}>
                        <PencilSquare size={15} color="green" />
                    </Link>
                    <a href="#" onClick={() => onDelete(row.row.original.id)}>
                        <Trash color="red" size={15} />
                    </a>
                </ButtonGroup>
            )
        }
    ];
    function onDelete(id) {
        if (window.confirm('Voulez-vous supprimer cet article ?')) {
            setLoading(true);
            Axios.delete(common.ipapi + '/api/article/destroy/' + id).then((response) => {
                if (response.data.status == 1) {
                    const del = datas.filter((d) => d.id !== id);
                    console.log(del);
                    setDatas(del);
                    setMsgravity('success');
                    setMsg(response.data.msg);
                    setIsSave(true);
                    setLoading(false);
                } else {
                    setMsgravity('danger');
                    setMsg('Une erreur a eu lieu');
                    setIsSave(true);
                    setLoading(false);
                }
            });
        }
    }
    function cancel(e) {
        e.preventDefault();
        window.history.back();
    }
    const openSidebar = () => {
        document.documentElement.classList.toggle('nav-open');
        sidebarToggle.current.classList.toggle('toggled');
    };
    return (
        <>
            {load ? <Spinner animation="grow" variant="warning" /> : ''}
            <Navbar expand="lg" color="white">
                <Container>
                    <div className="navbar-translate">
                        <button type="button" ref={sidebarToggle} className="navbar-toggler" onClick={() => openSidebar()}>
                            <span className="navbar-toggler-bar bar1" />
                            <span className="navbar-toggler-bar bar2" />
                            <span className="navbar-toggler-bar bar3" />
                        </button>
                        <NavbarBrand>Liste de article du fournisseur {}</NavbarBrand>
                    </div>
                    <Collapse isOpen={true} navbar>
                        <Form className="ml-auto">
                            <FormGroup className="no-border">
                                <Link to="#/admin/addentrees">
                                    <Button variant="light">
                                        <CartCheck color="#ff7300" size={20} /> Ajouter
                                    </Button>
                                </Link>
                                <Button type="submit" variant="ligth" onClick={cancel}>
                                    <Arrow90degLeft color="red" size={20} /> Retour
                                </Button>
                            </FormGroup>
                        </Form>
                    </Collapse>
                </Container>
            </Navbar>
            <br />
            <div className="content contents">
                <Row>
                    <Col md="12">
                        <Toast onClose={() => setIsSave(false)} show={isSave} delay={3000} bg="info" autohide>
                            <Toast.Header>
                                <img src="holder.js/20x20?text=%20" className="rounded me-2" alt="" />
                                <strong className="me-auto">Info</strong>
                            </Toast.Header>
                            <Toast.Body>Opération réussie!</Toast.Body>
                        </Toast>
                        <Row>
                            <Col md={12}>
                                <Card>
                                    <CardBody>
                                        <Table
                                            columns={columns}
                                            data={datas}
                                            fetchData={fetchData}
                                            loading={load}
                                            pageCount={pageCount}
                                            tablename="article"
                                        />
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </div>
        </>
    );
}

export default FournisseurArticle;
