import React, { useState, useCallback } from 'react';
import ReactDataGrid from '@inovua/reactdatagrid-community';
import '@inovua/reactdatagrid-community/index.css';
import DateFilter from '@inovua/reactdatagrid-community/DateFilter';
import moment from 'moment';

import axios from 'axios';

import config from '../../../commonData';
import dateformat from 'dateformat';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { Arrow90degLeft, Backspace, Check, CheckSquare, Pencil } from 'react-bootstrap-icons';

function DataGrid({ selected, setSelected, depenses, isLoading, setLoad, load, setMsg, setSeverity, setOpenSnak, setGridRef }) {
    window.moment = moment;
    const filterValue = [
        { name: 'motif_dep', operator: 'contains', type: 'string', value: '' },
        { name: 'date_dep', operator: 'inrange', type: 'date', value: null },
        { name: 'type', operator: 'contains', type: 'string', value: '' },
        { name: 'montant_depense', operator: 'gte', type: 'number', value: '' }
    ];

    const User = ({ data }) => {
        return data.user;
    };

    const Type = ({ data }) => {
        return data.type;
    };
    const handleClickChangeEtat = (id, etat) => {
        const log = config.search(config.userdroit, 'SAD');
        const log1 = config.search(config.userdroit, 'DVA');
        if (log == undefined) {
            if (log1 == undefined) {
                setMsg("Vous n'avez pas le droit d'effectuer cette action, merci de contacter l'administrateur");
                setSeverity('danger');
                setOpenSnak(true);
                return;
            }
        }
        if (window.confirm('Voulez-vous confirmer cette action ?')) {
            let url = config.ipapi + '/api/update-depense-etat/' + id;
            var formData = new FormData();
            formData.append('etat', etat);
            axios({
                url: url,
                method: 'POST',
                data: formData
            })
                .then((res) => {
                    if (res.data.status) {
                        setMsg(res.data.msg);
                        setSeverity('success');
                        setOpenSnak(true);
                        setLoad(!load);
                    } else {
                        setOpenSnak(true);
                        setMsg(res.data.msg);
                        setSeverity('error');
                    }
                })
                .catch(() => {
                    setOpenSnak(true);
                    setMsg('error');
                    setSeverity('error');
                });
        }
    };
    const Link = ({ id, children, title }) => (
        <OverlayTrigger overlay={<Tooltip id={id}>{title}</Tooltip>}>
            <a href="#/admin/depenses">{children}</a>
        </OverlayTrigger>
    );
    const Etat = ({ data }) => {
        var icons;
        switch (data.etat) {
            case '0':
                icons = (
                    <>
                        <Link title="Validé cette demande" id="etat-1">
                            <CheckSquare onClick={() => handleClickChangeEtat(data.id, 1)} color="red" size={25} />
                        </Link>
                    </>
                );
                break;
            case '1':
                icons = (
                    <>
                        <Link title="Annuler la validation " id="etat-1">
                            <Arrow90degLeft onClick={() => handleClickChangeEtat(data.id, 0)} color="yellow" size={25} />
                        </Link>
                    </>
                );
                break;
            case '2':
                icons = (
                    <>
                        <Link title="Retour à la  demande" id="etat-1">
                            <Backspace onClick={() => handleClickChangeEtat(data.id, 0)} color="yellow" size={20} />
                        </Link>
                    </>
                );
                break;
        }
        return icons;
    };
    const columnss = [
        {
            name: 'id',
            header: 'Id',
            defaultVisible: false,
            defaultWidth: 80,
            type: 'number'
        },
        {
            name: 'etat',
            header: 'Etat',
            defaultWidth: 50,
            type: 'string',
            textAlign: 'center',
            render: ({ data }) => <Etat data={data} />
        },
        {
            name: 'type',
            header: 'Type de dépense',
            defaultWidth: 150,
            type: 'string',
            textAlign: 'start',
            render: ({ data }) => <Type data={data} />
        },
        {
            name: 'montant_depense',
            header: 'Montant dépense',
            defaultWidth: 90,
            textAlign: 'center',
            type: 'number',
            render: ({ value }) => numberformat(value)
        },
        {
            name: 'date_dep',
            header: 'Date dépense',
            textAlign: 'center',
            defaultWidth: 150,
            dateFormat: 'DD-MM-YYYY',
            filterEditor: DateFilter,
            filterEditorProps: (props, { index }) => {
                // for range and notinrange operators, the index is 1 for the after field
                return {
                    dateFormat: 'DD-MM-YYYY',
                    placeholder: index === 1 ? 'Date fin' : 'Date debut'
                };
            },
            render: ({ value, cellProps: { dateFormat } }) => moment(value).format(dateFormat)
        },
        {
            name: 'motif_dep',
            header: 'Motifs',
            defaultWidth: 300,
            type: 'string'
        },

        { name: 'user', header: 'Crée par', defaultWidth: 100, type: 'string', render: ({ data }) => <User data={data} /> },
        {
            name: 'created_at',
            header: 'Crée le',
            defaultWidth: 130,
            type: 'string',
            render: ({ value }) => dateformat(value, 'dd-mm-yyyy HH:MM')
        },
        {
            name: 'updated_at',
            header: 'Modif. le',
            defaultWidth: 130,
            type: 'string',
            render: ({ value }) => dateformat(value, 'dd-mm-yyyy HH:MM')
        }
    ];

    function numberformat(data) {
        return new Intl.NumberFormat().format(data);
    }
    const gridStyle = { minHeight: window.screen.height - 200 };
    const onSelectionChange = useCallback(({ selected }) => {
        setSelected(selected);
    }, []);

    return (
        <div>
            <ReactDataGrid
                idProperty="id"
                handle={setGridRef}
                style={gridStyle}
                loading={isLoading}
                reorderColumns={false}
                pagination
                selected={selected}
                onSelectionChange={onSelectionChange}
                pageSizes={[200, 400, 600, 800, 1000]}
                defaultLimit={400}
                showHoverRows={true}
                editable={true}
                showColumnMenuTool={false}
                defaultFilterValue={filterValue}
                columns={columnss}
                dataSource={depenses}
            />
        </div>
    );
}

export default DataGrid;
