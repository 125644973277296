import React, { useEffect, useState, useRef } from 'react';
import { Form, Spinner, Modal, Card, Alert } from 'react-bootstrap';
import { Button, ButtonGroup, Row, Col } from 'reactstrap';
import Axios from 'axios';
import { Backspace, Save2Fill, Trash, TriangleHalf } from 'react-bootstrap-icons';
import 'react-bootstrap-typeahead/css/Typeahead.css';
import * as Yup from 'yup';
import { Formik } from 'formik';
import dateformat from 'dateformat';
import common from '../../commonData';
import Table from '../../tableaddedit';

var count = 0;

function AddSortie({ setShowAdd, show, setIsSave, setMsg, setMsgravity, data, setLoading, loading }) {
    var ladate = new Date();
    const [article, setArticle] = useState([]);
    const [load, setLoad] = useState(false);
    const [qtesup, setQtesup] = useState(false);
    const [qtesupmsg, setQtesupmsg] = useState(false);
    const [listeSelect, setListeSelect] = useState([]);
    const [states, setStates] = useState({
        datearriv: '',
        dateentr: dateformat(ladate.getMonth() + 1 + '/' + ladate.getDate() + '/' + ladate.getFullYear(), 'yyyy-mm-dd'),
        remarque: '',
        arrivage: ''
    });
    const { datearriv, dateentr, remarque, arrivage } = states;
    useEffect(() => {
        let t = [];

        (async function anyNameFunction() {
            await Axios.get(common.ipapi + '/api/article/indexall')
                .then((response) => {
                    setArticle(response.data);
                    setLoad(false);
                })
                .catch((errors) => {
                    setLoad(false);
                });
        })();
        data.lignecommande.forEach((ent, index) => {
            t.push({
                idligne: ent.pivot.id,
                id: ent.id,
                article_id: ent.id,
                descriptionart: ent.descriptionart,
                quantite_cmd: ent.pivot.quantite,
                quantite_liv: ent.pivot.quantite_livre,
                quantite: '',
                qrbarre: ent.qrbarre,
                prixachat: '',
                remise: 0
            });
            setListeSelect(t);
        });
    }, []);

    const Editableqte = ({ value: initialValue, row: { index }, column: { id } }) => {
        const [value, setValue] = React.useState(initialValue);
        let val = 0;
        const onChange = (e) => {
            e.target.value > listeSelect[index].quantite_cmd - listeSelect[index].quantite_liv ? (val = '') : (val = e.target.value);
            setValue(val);
            listeSelect[index].quantite = val;
        };
        // const onBlur = () => {
        //     listeSelect[index].quantite = parseInt(value);
        // };
        React.useEffect(() => {
            setValue(initialValue);
        }, [initialValue]);
        return (
            <Form.Control
                type="number"
                className="quantite"
                aria-describedby="inputGroupPrepend"
                required
                size="sm"
                min={1}
                value={value}
                onChange={onChange}
                // onBlur={onBlur}
            />
        );
    };

    const EditableCellart = ({ value: initialValue, row: { index } }) => {
        const [value, setValue] = React.useState(initialValue);
        const onChange = (e) => {
            setValue(e.target.value);
        };
        const onBlur = () => {
            listeSelect[index].descriptionart = value;
        };
        React.useEffect(() => {
            setValue(initialValue);
        }, [initialValue]);
        return (
            <Form.Control
                type="text"
                className="prestation"
                aria-describedby="inputGroupPrepend"
                required
                size="sm"
                step="any"
                min="1"
                value={value}
                onChange={onChange}
                onBlur={onBlur}
            />
        );
    };
    const Editprixachat = ({ value: initialValue, row: { index } }) => {
        const [value, setValue] = React.useState(initialValue);
        const onChange = (e) => {
            setValue(e.target.value);
            listeSelect[index].prixachat = e.target.value;
        };

        React.useEffect(() => {
            setValue(initialValue);
        }, [initialValue]);
        return (
            <Form.Control
                type="number"
                className="prix"
                aria-describedby="inputGroupPrepend"
                size="sm"
                min={0}
                value={value}
                onChange={onChange}
            />
        );
    };

    const columns = [
        {
            Header: 'Code art.',
            accessor: 'qrbarre'
        },
        {
            Header: 'Descr.art.',
            accessor: 'descriptionart',
            Cell: EditableCellart
        },
        {
            Header: 'Qté commande',
            accessor: 'quantite_cmd'
            // Cell: Editableqte
        },
        {
            Header: 'Qté reçu',
            accessor: 'quantite_liv'
            // Cell: Editableqte
        },
        {
            Header: 'Qté réception',
            accessor: 'quantite',
            Cell: Editableqte
        },
        {
            Header: 'Prix achat',
            accessor: 'prixachat',
            Cell: Editprixachat
        },
        {
            Header: '#',
            accessor: '[editButton]',
            Cell: (row) => (
                <ButtonGroup aria-label="Basic example">
                    <Trash onClick={() => onDelete(row)} color="red" size={15} />
                </ButtonGroup>
            )
        }
    ];

    function onDelete(row) {
        setLoad(true);
        const del = listeSelect.filter((d) => d.article_id != row.row.original.article_id);
        setListeSelect(del);
        setMsg('Ligne supprimée');
        setMsgravity('success');
        setIsSave(true);
        setLoad(false);
    }

    function saveCommande(values) {
        try {
            if (listeSelect.length === 0) {
                setMsg('Veuillez sélectionner les produits');
                setMsgravity('info');
                setIsSave(true);
                setLoad(false);
                return;
            }
            setLoad(true);
            var formData = new FormData();
            formData.append('dateentr', values.dateentr);
            formData.append('datearriv', values.datearriv);
            formData.append('fournisseur_id', data.fournisseurid);
            formData.append('arrivage', values.arrivage);
            formData.append('remarque', values.remarque);
            formData.append('commandeid', data.id);
            formData.append('codeart', JSON.stringify(listeSelect));
            formData.append('user_id', common.userid);
            Axios({
                url: common.ipapi + '/api/entrees/create',
                method: 'POST',
                headers: {
                    'Content-Type': 'form-data'
                },
                data: formData
            })
                .then((res) => {
                    if (res.status !== 200) {
                        setMsg('Veuillez vérifier votre connexion internet');
                        setMsgravity('info');
                        setIsSave(false);
                        return;
                    }
                    if (res.data.status === 1) {
                        setMsg(res.data.msg);
                        setMsgravity('info');
                        setIsSave(true);
                        count++;
                        setListeSelect([]);
                        setLoading(!loading);
                        setLoad(false);
                        setTimeout(handleCloses(), 600);
                    } else if (res.data.status === 2) {
                        setMsg(res.data.msg);
                        setMsgravity('danger');
                        setIsSave(true);
                        setLoad(false);
                        setQtesup(true);
                        setQtesupmsg(res.data.msg);
                    } else {
                        if (res.data.code === 23000) {
                            setMsg('Ce numéro de bon existe déja');
                            setMsgravity('danger');
                            setIsSave(true);
                            setLoad(false);
                        } else {
                            setMsg(res.data.msg);
                            setMsgravity('danger');
                            setIsSave(true);
                            setLoad(false);
                        }
                    }
                })
                .catch((error) => {
                    setMsg('Veuillez vérifier votre connexion internet');
                    setIsSave(true);
                    setMsgravity('danger');
                    setLoad(false);
                });
        } catch (error) {
            setMsg('Veuillez vérifier votre connexion internet');
            setIsSave(true);
            setMsgravity('danger');
            setLoad(false);
        }
    }

    const handleCloses = () => {
        setShowAdd(false);
        setIsSave(false);
    };

    return (
        <>
            <Modal show={load} size="sm" aria-labelledby="contained-modal-title-vcenter" centered>
                <Modal.Body>
                    Veuillez patienter.......
                    <Spinner animation="border" variant="success" />
                </Modal.Body>
            </Modal>

            <Formik
                initialValues={{
                    datearriv: datearriv,
                    dateentr: dateentr,
                    remarque: remarque,
                    arrivage: arrivage
                }}
                enableReinitialize
                validationSchema={Yup.object().shape({
                    dateentr: Yup.date().required(common.msg)
                })}
                onSubmit={async (values, { setSubmitting }) => {
                    try {
                        saveCommande(values);
                        setSubmitting(false);
                    } catch (err) {
                        setMsg('Une erreur à eu lieu');
                        setMsgravity('danger');
                        setIsSave(true);
                        setLoad(false);
                    }
                }}
            >
                {({ errors, handleBlur, handleChange, handleSubmit, values, setFieldValue, setFieldTouched }) => (
                    <Modal show={show} fullscreen={true}>
                        <div className="content">
                            <Form noValidate onSubmit={handleSubmit}>
                                {/*noValidate */}
                                <Modal.Header>
                                    RECEPTION COMMANDE FOURNISSEUR
                                    <Backspace onClick={handleCloses} color="red" size={30} />
                                </Modal.Header>
                                <Modal.Body>
                                    {qtesup ? (
                                        <Alert key="danger" transition="Fade" variant="danger">
                                            <TriangleHalf color="red" size={30} />
                                            {qtesupmsg}
                                        </Alert>
                                    ) : (
                                        <></>
                                    )}
                                    <div id="lightbody">
                                        <Row>
                                            <Col md={4}>
                                                <Card bg="light" border="dark">
                                                    <Card.Body>
                                                        <Card.Title>Entête de la commande </Card.Title>
                                                        <Form.Group as={Row} className="mb-3">
                                                            <Form.Label column sm={3}>
                                                                Date entrées:
                                                            </Form.Label>
                                                            <Col sm={4}>
                                                                <Form.Control
                                                                    id="dateentr"
                                                                    type="date"
                                                                    placeholder="Date entrées"
                                                                    aria-describedby="inputGroupPrepend"
                                                                    size="sm"
                                                                    value={values.dateentr}
                                                                    onBlur={handleBlur}
                                                                    onChange={handleChange}
                                                                />
                                                            </Col>
                                                        </Form.Group>
                                                        <Form.Group as={Row} className="mb-3">
                                                            <Form.Label column sm={3}>
                                                                N° conteneur:
                                                            </Form.Label>
                                                            <Col sm={9}>
                                                                <Form.Control
                                                                    id="arrivage"
                                                                    type="text"
                                                                    placeholder="N° conteneur"
                                                                    aria-describedby="inputGroupPrepend"
                                                                    size="sm"
                                                                    value={values.arrivage}
                                                                    onBlur={handleBlur}
                                                                    onChange={handleChange}
                                                                />
                                                            </Col>
                                                        </Form.Group>
                                                        <Form.Group as={Row} className="mb-3">
                                                            <Form.Label column sm={3}>
                                                                Date arrivage:
                                                            </Form.Label>
                                                            <Col sm={4}>
                                                                <Form.Control
                                                                    id="datearriv"
                                                                    type="date"
                                                                    placeholder="Date arrivage"
                                                                    aria-describedby="inputGroupPrepend"
                                                                    size="sm"
                                                                    value={values.datearriv}
                                                                    onBlur={handleBlur}
                                                                    onChange={handleChange}
                                                                />
                                                            </Col>
                                                        </Form.Group>
                                                        <Form.Group as={Row} className="mb-3">
                                                            <Form.Label column sm={3}>
                                                                Fournisseur:
                                                            </Form.Label>
                                                            <Col sm={9}>{data.fournisseur}</Col>
                                                        </Form.Group>
                                                        <Form.Group as={Row} className="mb-3">
                                                            <Form.Label column sm={3}>
                                                                Remarques:
                                                            </Form.Label>
                                                            <Col sm={9}>
                                                                <Form.Control
                                                                    id="remarque"
                                                                    as="textarea"
                                                                    placeholder="Remarques"
                                                                    aria-describedby="inputGroupPrepend"
                                                                    cols={10}
                                                                    value={values.remarque}
                                                                    onBlur={handleBlur}
                                                                    onChange={handleChange}
                                                                />
                                                            </Col>
                                                        </Form.Group>
                                                    </Card.Body>
                                                </Card>
                                            </Col>
                                            <Col md={8}>
                                                <Card bg="light" border="dark">
                                                    <Card.Body>
                                                        <Card.Title>Liste des articles à réceptionner </Card.Title>
                                                        <Table columns={columns} data={listeSelect} />
                                                    </Card.Body>
                                                </Card>
                                            </Col>
                                        </Row>
                                    </div>
                                </Modal.Body>
                                <Modal.Footer>
                                    <div className="ml-auto">
                                        <ButtonGroup size="sm">
                                            <Button color="secondary" onClick={handleCloses}>
                                                <Backspace color="red" size={20} />
                                            </Button>
                                            <Button type="submit" color="warning">
                                                <Save2Fill color="#807f7e" size={20} /> Enregister
                                            </Button>
                                        </ButtonGroup>
                                    </div>
                                </Modal.Footer>
                            </Form>
                        </div>
                    </Modal>
                )}
            </Formik>
        </>
    );
}

export default AddSortie;
