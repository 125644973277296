import { useHistory } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import Axios from 'axios';
import { Spinner, Modal, Toast, Tooltip, OverlayTrigger, Navbar, Container, Nav } from 'react-bootstrap';
import { Printer, FileExcel, PlusCircleDotted, TriangleHalf } from 'react-bootstrap-icons';
import { jsPDF } from 'jspdf';
import 'jspdf-autotable';
import commondata from '../../commonData';
import AddClient from './addClient';
import MyAwesomeTable from './gridtables';
import { Card, CardBody, CardText, CardTitle } from 'reactstrap';

var dta = [];
function Clients() {
    const history = useHistory();
    const [datas, setClient] = useState([]);
    const [load, setLoad] = useState(true);
    const [showAddclient, setShowAddclient] = useState(false);
    const [ids, setId] = useState(0);
    const [msgGravity, setMsgravity] = useState('success');
    const [msg, setMsg] = useState('');

    const [isSave, setIsSave] = useState(false);

    const log = commondata.search(commondata.userdroit, 'SAD');
    const log1 = commondata.search(commondata.userdroit, 'GCL');

    useEffect(() => {
        if (localStorage.getItem('user-info')) {
            (async function anyNameFunction() {
                await Axios.get(commondata.ipapi + '/api/client/index')
                    .then((response) => {
                        setClient(response.data.datas);
                        dta = response.data.datas;
                        setLoad(false);
                    })
                    .catch((errors) => {
                        setLoad(false);
                    });
            })();
        } else {
            history.push('/login');
        }
    }, [history]);

    function setAdds(val) {
        const log = commondata.search(commondata.userdroit, 'SAD');
        const log1 = commondata.search(commondata.userdroit, 'ADC');
        if (log === undefined) {
            if (log1 === undefined) {
                setMsg("Vous n'avez pas le droit d'effectuer cette action, merci de contacter l'administrateur");
                setMsgravity('danger');
                setIsSave(true);
                return;
            }
        }
        setId(val);
        setShowAddclient(true);
    }
    function setIds(id) {
        const log = commondata.search(commondata.userdroit, 'SAD');
        const log1 = commondata.search(commondata.userdroit, 'UPC');
        if (log === undefined) {
            if (log1 === undefined) {
                setMsg("Vous n'avez pas le droit d'effectuer cette action, merci de contacter l'administrateur");
                setMsgravity('danger');
                setIsSave(true);
                return;
            }
        }
        setId(id);
        setShowAddclient(true);
    }

    function onDelete(id) {
        const log = commondata.search(commondata.userdroit, 'SAD');
        const log1 = commondata.search(commondata.userdroit, 'SCLI');
        if (log === undefined) {
            if (log1 === undefined) {
                setMsg("Vous n'avez pas le droit d'effectuer cette action, merci de contacter l'administrateur");
                setMsgravity('danger');
                setIsSave(true);
                return;
            }
        }
        setLoad(true);
        if (window.confirm('Voulez-vous supprimer ce client ?')) {
            Axios.delete(commondata.ipapi + '/api/client/destroy/' + id).then((response) => {
                if (response.data.status === 1) {
                    const del = dta.filter((d) => d.id !== id);
                    dta = del;
                    setClient(del);
                    setLoad(false);
                } else {
                    setLoad(false);
                    setMsg(response.data.msg);
                    setMsgravity('danger');
                    setIsSave(true);
                }
            });
        }
    }

    async function saveAsExcel() {
        // if(datas.length<=0){
        //   setMsg("Aucune donnée à imprimer")
        //   setIsSave(true)
        //   setMsgravity("danger")
        //   setLoad(false)
        //   return
        // }
        // const data = [];
        // datas.forEach((row) => {
        //     data.push(
        //       { code: row.codecli, client: row.raisonsocial, telcli: row.telcli,sisociete: row.sisociete, bp: row.bp, sitva: row.sitva,remise: row.remise ? row.remise.pourcentage : 0, typereglement: row.typereglement }
        //     )
        // })
        // let header = ["Code", "Noms", "Contacts", "Type", "Boite postal", "Si exoneré","Remise %","Type règlement"];
        // XlsxPopulate.fromBlankAsync().then(async (workbook) => {
        //   const sheet1 = workbook.sheet(0);
        //   const sheetData = getSheetData(data, header);
        //   const totalColumns = sheetData[0].length;
        //   sheet1.cell("A1").value(sheetData);
        //   const range = sheet1.usedRange();
        //   const endColumn = String.fromCharCode(64 + totalColumns);
        //   sheet1.row(1).style("bold", true);
        //   sheet1.range("A1:" + endColumn + "1").style("fill", "BFBFBF");
        //   range.style("border", true);
        //   return workbook.outputAsync().then((res) => {
        //     saveAs(res, "Listeclient.xlsx");
        //   });
        // });
    }

    // ====================== EXPORT PDF =============================

    async function printListe() {
        if (datas.length <= 0) {
            setMsg('Aucune donnée à imprimer');
            setIsSave(true);
            setMsgravity('danger');
            setLoad(false);
            return;
        }
        const doc = new jsPDF({
            orientation: 'l',
            unit: 'mm',
            format: 'a4',
            putOnlyUsedFonts: true
        });
        var width = doc.internal.pageSize.getWidth();
        const data = [];
        doc.addImage(commondata.logo, 'jpg', 14, 10, 30, 30);
        doc.setFontSize(16);
        doc.text('CLUB AUTO ENTREPOT', 90, 14);
        doc.setFontSize(12);
        doc.setFillColor(0, 0, 0);
        doc.rect(65, 15, width - 80, 10, 'F');
        doc.setTextColor(255, 255, 255);
        doc.text('LISTE DES CLIENTS', 90, 22);
        doc.setTextColor(0, 0, 0);

        datas.forEach((row) => {
            data.push([
                row.codecli,
                row.raisonsocial,
                row.telcli,
                row.sisociete,
                row.bp,
                row.sitva,
                row.remise ? row.remise.pourcentage : 0,
                row.typereglement
            ]);
        });
        doc.autoTable({
            styles: {
                fillColor: [0, 0, 0],
                textColor: [255, 255, 255],
                halign: 'center',
                fontSize: 10
            },
            columnStyles: {
                0: {
                    halign: 'center',
                    fillColor: [255, 255, 255],
                    textColor: [0, 0, 0],
                    fontSize: 8
                },
                1: {
                    halign: 'center',
                    fillColor: [255, 255, 255],
                    textColor: [0, 0, 0],
                    fontSize: 8
                },
                2: {
                    halign: 'center',
                    fillColor: [255, 255, 255],
                    textColor: [0, 0, 0],
                    fontSize: 8
                },
                3: {
                    halign: 'center',
                    fillColor: [255, 255, 255],
                    textColor: [0, 0, 0],
                    fontSize: 8
                },
                4: {
                    halign: 'center',
                    fillColor: [255, 255, 255],
                    textColor: [0, 0, 0],
                    fontSize: 8
                },
                5: {
                    halign: 'center',
                    fillColor: [255, 255, 255],
                    textColor: [0, 0, 0],
                    fontSize: 8
                },
                6: {
                    halign: 'center',
                    fillColor: [255, 255, 255],
                    textColor: [0, 0, 0],
                    fontSize: 8
                },
                7: {
                    halign: 'center',
                    fillColor: [255, 255, 255],
                    textColor: [0, 0, 0],
                    fontSize: 8
                }
            },
            theme: 'grid',
            startY: 35,
            fontSize: 6,
            head: [['Code', 'Noms', 'Contacts', 'Type', 'Boite postal', 'Si exoneré', 'Remise %', 'Type règlement']],
            body: data
        });
        const pageCount = doc.internal.getNumberOfPages();
        for (var i = 1; i <= pageCount; i++) {
            doc.setPage(i);
            doc.setFontSize(10);
            doc.text('Page ' + String(i) + ' sur ' + String(pageCount), 210 - 55, 200, null, null, 'right');
        }
        doc.save('Listeclient.pdf');
    }
    return (
        <>
            <Modal show={load} size="sm" aria-labelledby="contained-modal-title-vcenter" centered>
                <Modal.Body>
                    Veuillez patienter.......
                    <Spinner animation="border" variant="success" />
                </Modal.Body>
            </Modal>
            <Toast id="toast" onClose={() => setIsSave(false)} show={isSave} delay={3000} className={'bg-' + msgGravity} autohide>
                <Toast.Header>
                    <img src="holder.js/20x20?text=%20" className="rounded me-2" alt="" />
                    <strong className="me-auto">Info</strong>
                </Toast.Header>
                <Toast.Body className="dark">{msg}</Toast.Body>
            </Toast>
            {showAddclient ? (
                <AddClient
                    id={ids}
                    setShowAddclient={setShowAddclient}
                    show={showAddclient}
                    setIsSave={setIsSave}
                    setClient={setClient}
                    setMsg={setMsg}
                    setMsgravity={setMsgravity}
                />
            ) : (
                <></>
            )}
            {log != undefined || log1 != undefined ? (
                <div className="content contents">
                    <Navbar expand="lg" bg="light" variant="light">
                        <Container>
                            <Navbar.Brand>LISTE DES CLIENTS </Navbar.Brand>
                            <Nav className="me-auto">
                                <OverlayTrigger overlay={<Tooltip id="tooltip-disabled">Ajouter un nouveau client</Tooltip>}>
                                    <Nav.Link onClick={() => setAdds(0)}>
                                        <PlusCircleDotted color="orange" size={20} /> Créer
                                    </Nav.Link>
                                </OverlayTrigger>
                                <OverlayTrigger overlay={<Tooltip id="tooltip-disabled">Exporter vers Excel</Tooltip>}>
                                    <Nav.Link onClick={() => saveAsExcel(datas)} title="Exporter vers excel">
                                        <FileExcel color="#0080ff" size={20} /> Excel
                                    </Nav.Link>
                                </OverlayTrigger>
                                <OverlayTrigger overlay={<Tooltip id="tooltip-disabled">Exporter vers pdf</Tooltip>}>
                                    <Nav.Link onClick={() => printListe(datas)} title="Exporter vers pdf">
                                        <Printer color="#0080ff" size={20} /> Imprimer
                                    </Nav.Link>
                                </OverlayTrigger>
                            </Nav>
                        </Container>
                    </Navbar>
                    <div id="tablecontent">
                        <MyAwesomeTable rows={datas} isLoading={load} setLoad={setLoad} onDelete={onDelete} setIds={setIds} />
                    </div>
                </div>
            ) : (
                <Card style={{ width: '25rem' }} className="centerCard">
                    <CardBody>
                        <CardTitle tag="h5">
                            <TriangleHalf size={60} color="red"></TriangleHalf> Avertissement
                        </CardTitle>
                        <CardText>Vous n'êtes pas autorisé à utiliser cette section. Merci de contacter votre administrateur</CardText>
                    </CardBody>
                </Card>
            )}
        </>
    );
}

export default Clients;
