import React from 'react';
import { useTable, usePagination } from 'react-table';
import Table from 'react-bootstrap/Table';

function Tables({ columns, data, skipPageReset }) {
    const { getTableProps, getTableBodyProps, headerGroups, prepareRow, page } = useTable(
        {
            columns,
            data,
            initialState: { pageSize: 5000 }
        },
        usePagination
    );

    // Render the UI for your table
    return (
        <div className="flex flex-col w-full">
            <div className="">
                <div className="">
                    <Table className="table-striped-hover table-sm" size="sm" {...getTableProps()}>
                        <thead>
                            {headerGroups.map((headerGroup) => (
                                <tr {...headerGroup.getHeaderGroupProps()}>
                                    {headerGroup.headers.map((column) => (
                                        <th {...column.getHeaderProps()}>{column.render('Header')}</th>
                                    ))}
                                </tr>
                            ))}
                        </thead>
                        <tbody {...getTableBodyProps()}>
                            {page.map((row, i) => {
                                prepareRow(row);
                                return (
                                    <tr {...row.getRowProps()}>
                                        {row.cells.map((cell) => {
                                            return <td {...cell.getCellProps()}>{cell.render('Cell')}</td>;
                                        })}
                                    </tr>
                                );
                            })}
                        </tbody>
                    </Table>
                </div>
            </div>
        </div>
    );
}
export default Tables;
