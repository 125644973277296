import React, { useEffect, useState, useRef } from 'react';
import { X } from 'react-bootstrap-icons';
import { Card, CardBody } from 'reactstrap';

export function ProductList({ products, onChangeProductQuantity, onRemoveProduct, onChangePrestation, onChangeRemise, onChangePrix }) {
    return (
        // <Card>
        //   <CardBody>
        <table className="table table-striped-hover table-sm">
            <thead>
                <tr>
                    <th scope="col">CODE</th>
                    <th scope="col">DESIGNATION</th>
                    <th scope="col">QUANTITÉ</th>
                    <th scope="col">MODELS</th>
                    <th scope="col">MARQUES</th>
                    <th scope="col">#</th>
                </tr>
            </thead>
            <tbody>
                {products.map((product, index) => {
                    return (
                        <tr key={index} hover="true">
                            <td>{product.qrbarre}</td>
                            <td>
                                <input
                                    type="text"
                                    className="prestation"
                                    value={product.descriptionart}
                                    onChange={(event) => onChangePrestation(index, event)}
                                />
                            </td>
                            <td>
                                <input
                                    type="number"
                                    className="prix"
                                    step="any"
                                    value={product.pivot.quantite}
                                    onChange={(event) => onChangeProductQuantity(index, event)}
                                />
                            </td>
                            <td>{product.famille ? product.famille.libellefam : ''}</td>
                            <td>{product.marque ? product.marque.libellemarq : ''}</td>
                            <td>
                                <X color="red" size={20} onClick={() => onRemoveProduct(index, product)}></X>
                            </td>
                        </tr>
                    );
                })}
            </tbody>
        </table>

        //     </CardBody>

        // </Card>
    );
}

export function Summary({ subTotal, sitva }) {
    //return  mttc;
    var total = subTotal;
    const ht = total / (1 + 0.18);
    const tva = total - ht;
    total = sitva == 1 ? total - tva : total;
    return (
        <p id="lightr">
            Total HT: <b>{formatCurrency(ht)} </b>|| Total TVA {sitva == 1 ? 'exonéré' : ''}: <b>{formatCurrency(tva)} </b>|| Total TTC:{' '}
            <b>{formatCurrency(total)}</b>
        </p>
    );
}
function formatCurrency(value) {
    return new Intl.NumberFormat().format(Math.round(value));
}
