import Familles from './views/stocks/familles/listeFamilles';
import Marques from './views/stocks/marques/listeMarque';
import Clients from './views/stocks/clients/listeClients';
import Entrees from './views/stocks/entrees/entrees';
import Dashboard from './views/Dashboard';
import Articles from './views/stocks/articles/listeArticle';
import Fournisseurs from './views/stocks/fournisseurs/listeFournisseurs';
import Entrepots from './views/stocks/entrepots/listeEntrepot';
import ListeSortie from './views/stocks/sorties/listeSortie';
import ListeCommandeCli from 'views/stocks/commandecli/listeCommande';
import ListeCommandeFrs from 'views/stocks/commandefourn/listeCommande';
import ListeFacture from './views/stocks/factures/listeFacture';
import Reglements from './views/stocks/reglements/listeReglement';
import ListeInventaire from 'views/stocks/inventaires/listeInventaire';
import TypeDepense from 'views/stocks/depenses/typedepense/typeDepense';
import Depenses from 'views/stocks/depenses/depense/depense';
import Caisses from 'views/stocks/depenses/caisse/caisse';

var routes = [
    {
        path: '/dashboard',
        name: 'Tableau de bord',
        title: 'Tableau de bord',
        icon: 'nc-icon nc-bank',
        component: Dashboard,
        layout: '/admin',
        separe: true
    },
    {
        path: '/marques',
        name: 'Marques',
        title: 'Gestion des marques',
        icon: 'nc-icon nc-bookmark-2',
        component: Marques,
        layout: '/admin'
    },
    {
        path: '/familles',
        name: 'Models',
        title: 'Gestion des Models',
        icon: 'nc-icon nc-vector',
        component: Familles,
        layout: '/admin'
    },
    {
        path: '/entrepots',
        name: 'Entrepots',
        title: 'Gestion des entrepots',
        icon: 'nc-icon nc-app',
        component: Entrepots,
        layout: '/admin',
        separe: true
    },
    {
        path: '/clients',
        name: 'Clients',
        title: 'Gestion des clients',
        icon: 'nc-icon nc-single-02',
        component: Clients,
        layout: '/admin'
    },
    {
        path: '/fournisseurs',
        name: 'Fournisseurs',
        title: 'Gestion des fournisseurs',
        icon: 'nc-icon nc-circle-10',
        component: Fournisseurs,
        layout: '/admin',
        separe: true
    },

    {
        path: '/articles',
        name: 'Stocks',
        title: "Gestion du stock d'articles",
        icon: 'nc-icon nc-cart-simple',
        component: Articles,
        layout: '/admin'
    },
    {
        path: '/entrees',
        name: 'Entrées articles',
        title: "Gestion des entrées d'articles",
        icon: 'nc-icon nc-delivery-fast',
        component: Entrees,
        layout: '/admin'
    },
    {
        path: '/sorties',
        name: 'Sorties articles',
        title: "Gestion des sorties d'articles",
        icon: 'nc-icon nc-minimal-left',
        component: ListeSortie,
        layout: '/admin',
        separe: false
    },
    {
        path: '/inventaire',
        name: 'Inventaire stock',
        title: 'Gestion des inventaires',
        icon: 'nc-icon nc-single-copy-04',
        component: ListeInventaire,
        layout: '/admin',
        separe: true
    },
    {
        path: '/factures',
        name: 'Facturation',
        title: 'Gestion des factures clients',
        icon: 'nc-icon nc-paper',
        component: ListeFacture,
        layout: '/admin'
    },
    {
        path: '/reglement',
        name: 'Reglements',
        title: 'Gestion des reglements clients',
        icon: 'nc-icon nc-money-coins',
        component: Reglements,
        layout: '/admin',
        separe: true
    },
    {
        path: '/commandecli',
        name: 'Commande client',
        title: 'Gestion des commandes clients',
        icon: 'nc-icon nc-satisfied',
        component: ListeCommandeCli,
        layout: '/admin'
    },
    {
        path: '/commandefrs',
        name: 'Commande fournisseur',
        title: 'Gestion des commandes foursnisseurs',
        icon: 'nc-icon nc-badge',
        component: ListeCommandeFrs,
        layout: '/admin',
        separe: true
    },
    {
        path: '/typedepense',
        name: 'Types dépenses',
        title: 'Gestion des types de dépenses',
        icon: 'nc-icon nc-paper',
        component: TypeDepense,
        layout: '/admin'
    },
    {
        path: '/depenses',
        name: 'Dépenses',
        title: 'Gestion des dépenses',
        icon: 'nc-icon nc-money-coins',
        component: Depenses,
        layout: '/admin'
    },
    {
        path: '/caisses',
        name: 'Trésorerie',
        title: 'Gestion de la trésorerie',
        icon: 'nc-icon nc-money-coins',
        component: Caisses,
        layout: '/admin'
    }
];
export default routes;
