import React, { useState, useCallback } from 'react';
import { Link } from 'react-router-dom';
import { ButtonGroup, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { Trash, Printer, CardChecklist, ListCheck, PencilFill } from 'react-bootstrap-icons';
import moment from 'moment';
import ReactDataGrid from '@inovua/reactdatagrid-community';
import '@inovua/reactdatagrid-community/index.css';
import SelectFilter from '@inovua/reactdatagrid-community/SelectFilter';
import DateFilter from '@inovua/reactdatagrid-community/DateFilter';
import { jsPDF } from 'jspdf';
import XLSX from 'sheetjs-style';
import 'jspdf-autotable';
import 'assets/css/table.css';
import dateformat from 'dateformat';

import common from '../../commonData';
import NavMenu from './navbar';
import FactureMultiple from './factureMultiple';

function MyAwesomeTable({
    rows,
    isLoading,
    onDelete,
    setIds,
    viewSortie,
    clients,
    setAdds,
    printBl,
    setIsSave,
    setMsg,
    setShowFilterModals,
    setDatas,
    setMsgravity,
    actualise,
    setActualise
}) {
    window.moment = moment;
    const [selected, setSelected] = useState({});
    const [gridRef, setGridRef] = useState(null);
    const [showFact, setShowFact] = useState(false);
    const [filterDate, setFilterDate] = useState({});

    const filterValue = [
        { name: 'numbon', operator: 'contains', type: 'string', value: '' },
        { name: 'client', operator: 'inlist', type: 'select', value: [] },
        { name: 'datesort', operator: 'inrange', type: 'date', value: '' },
        { name: 'demand_par', operator: 'contains', type: 'string', value: '' },
        { name: 'engin', operator: 'contains', type: 'string', value: '' },
        { name: 'user', operator: 'contains', type: 'string', value: '' },
        { name: 'useru', operator: 'contains', type: 'string', value: '' },
        { name: 'nocommande', operator: 'contains', type: 'string', value: '' },
        { name: 'nofact', operator: 'contains', type: 'string', value: '' }
    ];

    const columnss = [
        {
            name: 'id',
            header: 'Id',
            defaultVisible: false,
            defaultWidth: 80,
            type: 'number'
        },
        {
            header: 'Action',
            defaultWidth: 130,
            type: 'string',
            render: ({ data }) => {
                return (
                    <div className="menudrops">
                        <ButtonGroup aria-label="Basic example">
                            {data.inventaireid === null ? (
                                <OverlayTrigger overlay={<Tooltip id="tooltip-disabled"> Facturer ligne</Tooltip>}>
                                    {data.nofact === 'neant' && data.nofact != null ? (
                                        <a href="#/admin/sorties" onClick={(e) => setFacts(data.id)}>
                                            <CardChecklist color="#0dd1fd" size={25} />
                                        </a>
                                    ) : (
                                        <></>
                                    )}
                                </OverlayTrigger>
                            ) : (
                                <OverlayTrigger overlay={<Tooltip id="tooltip-disabled">Inventaire</Tooltip>}>
                                    <ListCheck color="blue" size={25} />
                                </OverlayTrigger>
                            )}
                            <OverlayTrigger overlay={<Tooltip id="tooltip-disabled"> Imprimer BL</Tooltip>}>
                                <a href="#/admin/sorties" onClick={() => printBl(data.id)}>
                                    <Printer color="#000" size={25} />
                                </a>
                            </OverlayTrigger>
                            <OverlayTrigger overlay={<Tooltip id="tooltip-disabled">Modifier</Tooltip>}>
                                {data.facture !== 'neant' ? (
                                    data.nofact === 'neant' ? (
                                        <a href="#/admin/sorties" onClick={() => setIds(data.id)}>
                                            <PencilFill size={25} color="green" />
                                        </a>
                                    ) : (
                                        <></>
                                    )
                                ) : data.inventaireid === null ? (
                                    <a href="#/admin/sorties" onClick={() => setIds(data.id)}>
                                        <PencilFill size={25} color="green" />
                                    </a>
                                ) : (
                                    <></>
                                )}
                            </OverlayTrigger>
                            <OverlayTrigger overlay={<Tooltip id="tooltip-disabled">Supprimer</Tooltip>}>
                                {data.inventaireid === null ? (
                                    <a href="#/admin/sorties" onClick={() => onDelete(data.id)}>
                                        <Trash color="red" size={25} />
                                    </a>
                                ) : (
                                    <></>
                                )}
                            </OverlayTrigger>
                        </ButtonGroup>
                    </div>
                );
            }
        },
        {
            name: 'numbon',
            header: 'N° bon',
            defaultWidth: 100,
            type: 'string',
            textAlign: 'center',
            render: ({ data }) => (
                <OverlayTrigger placement="right" overlay={<Tooltip id="tooltip-disabled">Cliquer pour plus de détails</Tooltip>}>
                    <Link to="/admin/sorties" onClick={() => viewSortie(data.id, 1)}>
                        {data.nofact !== 'neant' && data.nofact !== null ? (
                            <p id="successs">{data.numbon}</p>
                        ) : data.noprof !== 'neant' && data.noprof !== null ? (
                            <p id="warning">{data.numbon}</p>
                        ) : (
                            <p id="light">{data.numbon}</p>
                        )}
                    </Link>
                </OverlayTrigger>
            )
        },
        {
            name: 'client',
            header: 'Clients',
            defaultWidth: 170,
            type: 'string',
            filterEditor: SelectFilter,
            filterEditorProps: {
                multiple: true,
                wrapMultiple: false,
                dataSource: clients.map((c) => {
                    return { id: c.raisonsocial, label: c.raisonsocial };
                })
            }
        },
        {
            name: 'datesort',
            header: 'Date sortie',
            defaultWidth: 200,
            type: 'date',
            dateFormat: 'DD-MM-YYYY',
            textAlign: 'center',
            filterEditor: DateFilter,
            filterEditorProps: (props, { index }) => {
                // for range and notinrange operators, the index is 1 for the after field
                return {
                    dateFormat: 'DD-MM-YYYY',
                    placeholder: index === 1 ? 'Date fin' : 'Date debut'
                };
            },
            render: ({ value, cellProps: { dateFormat } }) => moment(value).format(dateFormat)
        },
        { name: 'noprof', header: 'N° proforma', defaultWidth: 130, type: 'string' },
        { name: 'nofact', header: 'N° facture', defaultWidth: 130, type: 'string' },
        { name: 'nocommande', header: 'N° commande', defaultWidth: 130, type: 'string' },
        {
            name: 'demand_par',
            header: 'Demandeur',
            defaultWidth: 120,
            type: 'string'
        },
        { name: 'engin', header: 'Engin', defaultWidth: 130, type: 'string' },
        { name: 'user', header: 'Crée par', defaultWidth: 100, type: 'string' },
        {
            name: 'created_at',
            header: 'Crée le',
            defaultWidth: 130,
            type: 'string',
            render: ({ value }) => dateformat(value, 'dd-mm-yyyy HH:MM')
        },
        { name: 'useru', header: 'Modif. par', defaultWidth: 100, type: 'string' },
        {
            name: 'updated_at',
            header: 'Modif. le',
            defaultWidth: 130,
            type: 'string',
            render: ({ value }) => dateformat(value, 'dd-mm-yyyy HH:MM')
        }
    ];

    const setFacts = (id) => {
        if (id !== 0) {
            setSelected(id);
        }
        setShowFact(true);
    };

    const gridStyle = { minHeight: window.screen.height - 200 };

    const onSelectionChange = useCallback(({ selected }) => {
        setSelected(selected);
    }, []);
    const toArray = (selected, dataMap) => {
        const keysObj = selected === true ? dataMap : selected;
        return Object.keys(keysObj).map((key) => Number(key));
    };
    const dataMap = gridRef && gridRef.current && gridRef.current.dataMap ? gridRef.current.dataMap : null;

    function GetSortOrder(prop) {
        return function (a, b) {
            if (a[prop] > b[prop]) {
                return 1;
            } else if (a[prop] < b[prop]) {
                return -1;
            }
            return 0;
        };
    }

    const exportCSV = async () => {
        //--------------- EXPORT VERS EXCEL ------------------
        const raw_data = gridRef.current.data.sort(GetSortOrder('datesort'));
        /* flatten objects */

        const rows = [];
        var realqte = 0;
        raw_data.map((row, index) => {
            row.lignesort.map((lsort) => {
                realqte = lsort.pivot.quantite - lsort.pivot.quantiteretour;
                rows.push({
                    numbon: row.numbon,
                    client: row.client,
                    qrbarre: lsort.qrbarre,
                    description: lsort.descriptionart,
                    engin: lsort.famille ? lsort.famille.libellefam + ' / ' + row.engin : row.engin,
                    retour: realqte,
                    // prixachat: lsort.prixachat,
                    prixvente: lsort.pivot.prix ? lsort.pivot.prix : lsort.prixachat * lsort.coeficient,
                    total: lsort.pivot.prix ? lsort.pivot.prix * realqte : lsort.prixachat * lsort.coeficient * realqte,
                    date: dateformat(row.datesort, 'dd-mm-yyyy')
                });
            });
        });

        /* generate worksheet and workbook */
        const worksheet = XLSX.utils.json_to_sheet(rows);
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, 'Mes sorties');
        /* fix headers */
        XLSX.utils.sheet_add_aoa(
            worksheet,
            [['N° bon', 'Clients', 'Code', 'Articles', 'Engin', 'Qté', 'Prix vente U', 'Total', 'Date sortie']],
            {
                origin: 'A1'
            }
        );
        /* calculate column width */
        const max_width = rows.reduce((w, r) => Math.max(w, r.numbon.length), 10);
        worksheet['!cols'] = [{ wch: max_width }];
        XLSX.writeFile(workbook, 'sortie.xlsx');
    };

    const exportCSVModel = async () => {
        //--------------- EXPORT VERS EXCEL ------------------
        const raw_data = gridRef.current.data.sort(GetSortOrder('datesort'));
        /* flatten objects */

        const rows = [];
        var realqte = 0;
        raw_data.map((row, index) => {
            row.lignesort.map((lsort) => {
                realqte = lsort.pivot.quantite - lsort.pivot.quantiteretour;
                rows.push({
                    numbon: row.numbon,
                    client: row.client,
                    qrbarre: lsort.qrbarre,
                    description: lsort.descriptionart,
                    engin: lsort.famille ? lsort.famille.libellefam + ' / ' + row.engin : row.engin,
                    retour: realqte,
                    // prixachat: lsort.prixachat,
                    prixvente: lsort.pivot.prix ? lsort.pivot.prix : lsort.prixachat * lsort.coeficient,
                    total: lsort.pivot.prix ? lsort.pivot.prix * realqte : lsort.prixachat * lsort.coeficient * realqte,
                    date: dateformat(row.datesort, 'dd-mm-yyyy')
                });
            });
        });

        /* generate worksheet and workbook */
        const worksheet = XLSX.utils.json_to_sheet(rows);
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, 'Mes sorties');
        /* fix headers */
        XLSX.utils.sheet_add_aoa(
            worksheet,
            [['N° bon', 'Clients', 'Code', 'Articles', 'Engin', 'Qté', 'Prix vente U', 'Total', 'Date sortie']],
            {
                origin: 'A1'
            }
        );
        /* calculate column width */
        const max_width = rows.reduce((w, r) => Math.max(w, r.numbon.length), 10);
        worksheet['!cols'] = [{ wch: max_width }];
        XLSX.writeFile(workbook, 'sortie.xlsx');
    };

    //-------------IMPRIMER LES LISTE ----------------
    function printListe() {
        if (gridRef.current.data.length <= 0) {
            return;
        }
        // console.log(gridRef.current.data);
        const doc = new jsPDF({
            orientation: 'l',
            unit: 'mm',
            format: 'a4',
            putOnlyUsedFonts: true
        });
        var width = doc.internal.pageSize.getWidth();
        const data = [];
        const dataretour = [];
        let mont = 0;
        let qte = 0;
        let montretour = 0;
        let qteretour = 0;
        let puTotal = 0;
        let pvTotal = 0;
        common.ipapi === 'https://192.168.8.109:81' || common.ipapi === 'https://api.tracky-entrepot.com'
            ? doc.addImage(common.logo, 'jpg', 12, 10, 30, 30)
            : doc.addImage(common.logo1, 'jpg', 12, 10, 30, 30);
        doc.setFontSize(16);
        // common.ipapi === 'https://192.168.8.109:81' || common.ipapi === 'https://api.tracky-entrepot.com'
        //     ? doc.text('CLUB AUTO', 90, 14)
        //     : doc.text('HR MOTORS', 90, 14);
        doc.setFontSize(12);
        doc.setFillColor(225, 206, 154);
        doc.rect(65, 15, width - 80, 10, 'F');
        doc.setTextColor(0, 0, 0);
        doc.text("LISTE DES  SORTIES D'ARTICLES", 90, 22);
        doc.setTextColor(0, 0, 0);
        var datavar = [...gridRef.current.data.sort(GetSortOrder('datesort'))];
        var len = datavar.length;
        var i = 0;
        var datedeb = dateformat(datavar[0].datesort, 'dd-mm-yyyy');
        var datefin = dateformat(datavar[datavar.length - 1].datesort, 'dd-mm-yyyy');
        datavar.forEach((row, index) => {
            if (len > i + 1) {
                if (i === 0) {
                    data.push([
                        {
                            content: row.inventaireid ? row.client + ' / (Inventaire)' : row.client,
                            colSpan: 8,
                            styles: { fontStyle: 'bold', fontSize: '12' }
                        }
                    ]);
                } else if (gridRef.current.data[i].client !== gridRef.current.data[i - 1].client) {
                    data.push([
                        {
                            content: row.inventaireid ? row.client + ' / (Inventaire)' : row.client,
                            colSpan: 8,
                            styles: { fontStyle: 'bold', fontSize: '12' }
                        }
                    ]);
                }
            } else {
                data.push([
                    {
                        content: row.inventaireid ? row.client + ' / (Inventaire)' : row.client,
                        colSpan: 8,
                        styles: { fontStyle: 'bold', fontSize: '12' }
                    }
                ]);
            }
            var realqte = 0;

            row.lignesort.forEach((lsort) => {
                realqte = lsort.pivot.quantite - lsort.pivot.quantiteretour;
                mont += lsort.pivot.prix ? lsort.pivot.prix * realqte : lsort.prixachat * lsort.coeficient * realqte;
                qte += lsort.pivot.quantite;
                qteretour += lsort.pivot.quantiteretour;
                puTotal += lsort.prixachat;
                pvTotal += lsort.pivot.prix ? lsort.pivot.prix : lsort.prixachat;
                data.push([
                    row.numbon,
                    lsort.qrbarre,
                    lsort.descriptionart,
                    lsort.famille ? lsort.famille.libellefam + ' / ' + row.engin : row.engin,
                    new Intl.NumberFormat(['ban', 'id']).format(lsort.pivot.quantite).replace('.', ' '),
                    lsort.pivot.quantiteretour || 0,
                    new Intl.NumberFormat(['ban', 'id']).format(realqte).replace('.', ' '),
                    // new Intl.NumberFormat(['ban', 'id']).format(lsort.prixachat).replace('.', ' '),
                    new Intl.NumberFormat(['ban', 'id'])
                        .format(lsort.pivot.prix ? lsort.pivot.prix : lsort.prixachat * lsort.coeficient)
                        .replace('.', ' '),
                    lsort.pivot.prix
                        ? new Intl.NumberFormat(['ban', 'id']).format(lsort.pivot.prix * realqte).replace('.', ' ')
                        : new Intl.NumberFormat(['ban', 'id']).format(lsort.prixachat * lsort.coeficient * realqte).replace('.', ' '),
                    dateformat(row.datesort, 'dd-mm-yyyy')
                ]);
                // }
            });
            i++;
        });
        if (Object.keys(filterDate).length === 1) {
            if ((filterDate.start === undefined || filterDate.start === '') && filterDate.end !== '') {
                doc.text('Période du: ' + datedeb + ' Au : ' + filterDate.end, 65, 32);
            } else if ((filterDate.end === undefined || filterDate.end === '') && filterDate.start !== '') {
                doc.text('Période du: ' + filterDate.start + ' Au : ' + datefin, 65, 32);
            } else {
                doc.text('Période du: ' + datedeb + ' Au : ' + datefin, 65, 32);
            }
        } else if (Object.keys(filterDate).length === 2) {
            if (filterDate.start !== '' && filterDate.end !== '') {
                doc.text('Période du: ' + filterDate.start + ' Au : ' + filterDate.end, 65, 32);
            } else if (filterDate.start === '' && filterDate.end === '') {
                doc.text('Période du: ' + datedeb + ' Au : ' + datefin, 65, 32);
            } else if (filterDate.start !== '' && filterDate.end === '') {
                doc.text('Période du: ' + filterDate.start + ' Au : ' + datefin, 65, 32);
            } else if (filterDate.start === '' && filterDate.end !== '') {
                doc.text('Période du: ' + datedeb + ' Au : ' + filterDate.end, 65, 32);
            } else {
                doc.text('Période du: ' + datedeb + ' Au : ' + datefin, 65, 32);
            }
        } else {
            doc.text('Période du: ' + datedeb + ' Au : ' + datefin, 65, 32);
        }

        data.push([
            { content: 'TOTAL', styles: { fontStyle: 'bold', fontSize: 12 } },
            '',
            '',
            '',
            { content: new Intl.NumberFormat(['ban', 'id']).format(qte).replace('.', ' '), styles: { fontStyle: 'bold', fontSize: 12 } },
            {
                content: new Intl.NumberFormat(['ban', 'id']).format(qteretour).replace('.', ' '),
                styles: { fontStyle: 'bold', fontSize: 12 }
            },
            {
                content: new Intl.NumberFormat(['ban', 'id']).format(qte - qteretour).replace('.', ' '),
                styles: { fontStyle: 'bold', fontSize: 12 }
            },
            // {
            //     content: new Intl.NumberFormat(['ban', 'id']).format(puTotal).replace('.', ' '),
            //     styles: { fontStyle: 'bold', fontSize: 12 }
            // },
            // {
            //     content: new Intl.NumberFormat(['ban', 'id']).format(pvTotal).replace('.', ' '),
            //     styles: { fontStyle: 'bold', fontSize: 12 }
            // },
            '',
            {
                content: new Intl.NumberFormat(['ban', 'id']).format(mont).replace('.', ' '),
                styles: { fontStyle: 'bold', fontSize: 12 }
            }
        ]);
        // dataretour.push([
        //     { content: 'TOTAL', styles: { fontStyle: 'bold', fontSize: 12 } },
        //     '',
        //     '',
        //     '',
        //     {
        //         content: new Intl.NumberFormat(['ban', 'id']).format(qteretour).replace('.', ' '),
        //         styles: { fontStyle: 'bold', fontSize: 12 }
        //     },
        //     // {
        //     //     content: new Intl.NumberFormat(['ban', 'id']).format(puTotal).replace('.', ' '),
        //     //     styles: { fontStyle: 'bold', fontSize: 12 }
        //     // },
        //     {
        //         content: 't',
        //         styles: { fontStyle: 'bold', fontSize: 12 }
        //     },
        //     {
        //         content: new Intl.NumberFormat(['ban', 'id']).format(montretour).replace('.', ' '),
        //         styles: { fontStyle: 'bold', fontSize: 12 }
        //     }
        // ]);
        doc.autoTable({
            styles: {
                fillColor: [206, 206, 206],
                textColor: [4, 4, 4],
                halign: 'center',
                fontSize: 10,
                cellPadding: 0.75
                // lineColor: [0, 0, 0]
            },
            columnStyles: {
                0: {
                    halign: 'center',
                    fillColor: [255, 255, 255],
                    textColor: [0, 0, 0],
                    cellWidth: 15,
                    fontSize: 8
                },
                1: {
                    halign: 'left',
                    fillColor: [255, 255, 255],
                    textColor: [0, 0, 0],
                    fontSize: 8,
                    cellWidth: 40
                },
                2: {
                    halign: 'left',
                    fillColor: [255, 255, 255],
                    textColor: [0, 0, 0],
                    fontSize: 8,
                    cellWidth: 45
                },
                3: {
                    halign: 'left',
                    fillColor: [255, 255, 255],
                    textColor: [0, 0, 0],
                    fontSize: 8,
                    cellWidth: 40
                },
                4: {
                    halign: 'center',
                    fillColor: [255, 255, 255],
                    textColor: [0, 0, 0],
                    fontSize: 8
                },
                5: {
                    halign: 'center',
                    fillColor: [255, 255, 255],
                    textColor: [0, 0, 0],
                    fontSize: 8
                },
                6: {
                    halign: 'center',
                    fillColor: [255, 255, 255],
                    textColor: [0, 0, 0],
                    fontSize: 8
                },
                7: {
                    halign: 'right',
                    fillColor: [255, 255, 255],
                    textColor: [0, 0, 0],
                    fontSize: 8
                },
                8: {
                    halign: 'right',
                    fillColor: [255, 255, 255],
                    textColor: [0, 0, 0],
                    fontSize: 8
                },
                9: {
                    halign: 'right',
                    fillColor: [255, 255, 255],
                    textColor: [0, 0, 0],
                    fontSize: 8
                }
                // 10: {
                //     halign: 'center',
                //     fillColor: [255, 255, 255],
                //     textColor: [0, 0, 0],
                //     fontSize: 8
                // }
            },
            theme: 'grid',
            startY: 35,
            fontSize: 6,
            head: [
                [
                    'N° bon',
                    'Code',
                    'Articles',
                    'Models/Engins',
                    'Qté liv',
                    'Qté retour',
                    'Reste',
                    // 'Prix achat U',
                    'Prix vente U',
                    'Total',
                    'Sortie le'
                ]
            ],
            body: data
        });

        const pageCount = doc.internal.getNumberOfPages();
        for (var u = 1; u <= pageCount; u++) {
            doc.setPage(u);
            doc.setFontSize(10);
            doc.text('Page ' + String(u) + ' sur ' + String(pageCount), 210 - 55, 200, null, null, 'right');
        }
        doc.save('Listesortie.pdf');
    }

    const onFilterValueChange = useCallback((filterValue) => {
        setFilterDate(filterValue[2].value);
    }, []);

    return (
        <div>
            {showFact !== false ? (
                <FactureMultiple
                    setShowFact={setShowFact}
                    show={showFact}
                    setIsSave={setIsSave}
                    setMsg={setMsg}
                    setMsgravity={setMsgravity}
                    selectedRows={JSON.stringify(toArray(selected, dataMap))}
                    actualise={actualise}
                    setActualise={setActualise}
                />
            ) : (
                <></>
            )}

            <NavMenu
                printListe={printListe}
                selected={JSON.stringify(toArray(selected, dataMap))}
                setAdds={setAdds}
                exportCSV={exportCSV}
                setFacts={setFacts}
                setShowFilterModals={setShowFilterModals}
                // printListeModel={printListeModel}
                exportCSVModel={exportCSVModel}
            />
            <ReactDataGrid
                idProperty="id"
                handle={setGridRef}
                style={gridStyle}
                loading={isLoading}
                reorderColumns={false}
                pagination
                selected={selected}
                checkboxColumn
                onSelectionChange={onSelectionChange}
                pageSizes={[200, 400, 600, 800, 1000, rows.length]}
                defaultLimit={400}
                showHoverRows={true}
                editable={true}
                showColumnMenuTool={false}
                defaultFilterValue={filterValue}
                onFilterValueChange={onFilterValueChange}
                columns={columnss}
                dataSource={rows}
            />
            {/* <p>Selected rows: {JSON.stringify(toArray(selected, dataMap))}.</p> */}
        </div>
    );
}

export default MyAwesomeTable;
