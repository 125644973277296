import React, { useState, useCallback } from 'react';
import { Link } from 'react-router-dom';
import { ButtonGroup, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { Eye, Trash, PencilFill, FileEarmarkExcel, Printer } from 'react-bootstrap-icons';
import moment from 'moment';
import ReactDataGrid from '@inovua/reactdatagrid-community';
import '@inovua/reactdatagrid-community/index.css';
import SelectFilter from '@inovua/reactdatagrid-community/SelectFilter';
import DateFilter from '@inovua/reactdatagrid-community/DateFilter';
import Axios from 'axios';
import { jsPDF } from 'jspdf';
import 'jspdf-autotable';
import XLSX from 'sheetjs-style';
import dateformat from 'dateformat';
import common from '../../commonData';
import 'assets/css/table.css';
import NumberToLetter from '../../converter';
import NavMenu from './navbar';

function MyAwesomeTable({ rows, isLoading, onDelete, setIds, viewSortie, client, setAdds, printBl, setIsSave, setMsg }) {
    window.moment = moment;
    const [gridRef, setGridRef] = useState(null);

    const filterValue = [
        { name: 'norecu', operator: 'contains', type: 'string', value: '' },
        { name: 'client', operator: 'contains', type: 'string', value: '' },
        { name: 'date_regl', operator: 'inrange', type: 'date', value: '' },
        { name: 'nofact', operator: 'contains', type: 'string', value: '' },
        { name: 'nocheq', operator: 'contains', type: 'string', value: '' },
        { name: 'user', operator: 'contains', type: 'string', value: '' },
        { name: 'useru', operator: 'contains', type: 'string', value: '' }
    ];

    const columnss = [
        {
            name: 'id',
            header: 'Id',
            defaultVisible: false,
            defaultWidth: 80,
            type: 'number'
        },
        {
            header: 'Action',
            defaultWidth: 110,
            type: 'string',
            render: ({ data }) => {
                return (
                    <div className="menudrops">
                        <ButtonGroup aria-label="Basic example">
                            <OverlayTrigger overlay={<Tooltip id="tooltip-disabled"> Imprimer</Tooltip>}>
                                <a href="#/admin/reglement" onClick={() => printFacture(data.id)}>
                                    <Printer color="#000" size={25} />{' '}
                                </a>
                            </OverlayTrigger>
                            <OverlayTrigger overlay={<Tooltip id="tooltip-disabled">Modifier</Tooltip>}>
                                <a href="#/admin/reglement" onClick={() => setIds(data.id)}>
                                    <PencilFill size={25} color="green" />
                                </a>
                            </OverlayTrigger>
                            <OverlayTrigger overlay={<Tooltip id="tooltip-disabled">Supprimer</Tooltip>}>
                                <a href="#/admin/reglement" onClick={() => onDelete(data.id)}>
                                    <Trash color="red" size={25} />{' '}
                                </a>
                            </OverlayTrigger>
                        </ButtonGroup>
                    </div>
                );
            }
        },
        { name: 'norecu', header: 'N° reçu', defaultWidth: 120, type: 'string' },
        { name: 'client', header: 'Clients', defaultWidth: 170, type: 'string' },
        {
            name: 'date_regl',
            header: 'Date Regle.',
            defaultWidth: 200,
            type: 'date',
            textAlign: 'center',
            dateFormat: 'DD-MM-YYYY',
            filterEditor: DateFilter,
            filterEditorProps: (props, { index }) => {
                // for range and notinrange operators, the index is 1 for the after field
                return {
                    dateFormat: 'DD-MM-YYYY',
                    placeholder: index === 1 ? 'Date fin' : 'Date debut'
                };
            },
            render: ({ value, cellProps: { dateFormat } }) => moment(value).format(dateFormat)
        },
        {
            name: 'montant',
            header: 'Montant',
            defaultWidth: 120,
            type: 'number',
            render: ({ value }) => new Intl.NumberFormat().format(value)
        },
        { name: 'type', header: 'Type règl', defaultWidth: 120, type: 'string' },
        { name: 'nocheq', header: 'N° chèque', defaultWidth: 120, type: 'string' },
        { name: 'nofact', header: 'N° facture', defaultWidth: 130, type: 'string' },
        {
            name: 'datefact',
            header: 'Date facture',
            defaultWidth: 130,
            type: 'string',
            render: ({ value }) => dateformat(value, 'dd-mm-yyyy HH:MM')
        },
        { name: 'user', header: 'Crée par', defaultWidth: 100, type: 'string' },
        {
            name: 'created_at',
            header: 'Crée le',
            defaultWidth: 130,
            type: 'string',
            render: ({ value }) => dateformat(value, 'dd-mm-yyyy HH:MM')
        },
        { name: 'useru', header: 'Modif. par', defaultWidth: 100, type: 'string' },
        {
            name: 'updated_at',
            header: 'Modif. le',
            defaultWidth: 130,
            type: 'string',
            render: ({ value }) => dateformat(value, 'dd-mm-yyyy HH:MM')
        }
    ];

    //------ IMPRIMER FACTURE -----------------
    function printFacture(id) {
        let text =
            'Voulez-vous imprimer avec ou sans entête ? \n Ok si vous voulez avec entête \n Annuler si vous ne voulez pas avec entête';
        let res = window.confirm(text);
        var doc = new jsPDF();
        var width = doc.internal.pageSize.getWidth();

        if (res === true) {
            Axios.get(common.ipapi + '/api/reglement/edit/' + id)
                .then((response) => {
                    doc.setFillColor(255, 127, 0);
                    common.ipapi === 'https://192.168.8.109:81' || common.ipapi === 'https://api.tracky-entrepot.com'
                        ? doc.addImage(common.logo, 'jpg', 14, 10, 30, 30)
                        : doc.addImage(common.logo1, 'jpg', 14, 10, 30, 30);

                    doc.setFillColor(225, 206, 154);
                    doc.rect(65, 19, width - 79, 10, 'F');
                    doc.setFontSize(16);
                    // common.ipapi === 'https://192.168.8.109:81' || common.ipapi === 'https://api.tracky-entrepot.com'
                    //     ? doc.text('CLUB AUTO', 90, 17)
                    //     : doc.text('HR MOTORS', 90, 17);
                    doc.setFontSize(12);
                    doc.setTextColor(0, 0, 0);
                    doc.text('RECU DE REGLEMENT CLIENT', 90, 26);
                    doc.setFontSize(10);
                    doc.setTextColor(0, 0, 0);
                    doc.setDrawColor(0);
                    doc.rect(110, 45, 86, 21);
                    doc.setTextColor(0, 0, 0);
                    //doc.text(146, 50,"Imprimer le: "+dateformat(new Date(),"dd-mm-yyyy"))
                    doc.text(response.data.proforma.client ? 'Non: ' + response.data.proforma.client.raisonsocial : 'Nom: Néant', 112, 50);
                    doc.text(
                        response.data.proforma.client ? 'Télephone: ' + response.data.proforma.client.telcli : 'Télephone: Néant',
                        112,
                        55
                    );
                    response.data.proforma.client
                        ? doc.text('Type client: ' + response.data.proforma.client.sisociete, 112, 60)
                        : doc.text('Type client: Néant', 112, 65);

                    doc.text('N° Reçu: ' + response.data.norecu, 15, 45);
                    doc.text('Date règlement: ' + dateformat(response.data.date_regl, 'dd/mm/yyyy'), 15, 50);
                    doc.text('N° facture: ' + response.data.proforma.nofact, 15, 55);
                    doc.text('Date facture: ' + dateformat(response.data.proforma.datefact, 'dd/mm/yyyy'), 15, 60);
                    doc.text('Type règlement: ' + response.data.type_reglement, 15, 65);
                    doc.text('Engin: ' + response.data.proforma.engin, 15, 70);

                    doc.text('Arrêté à la somme de: ' + new Intl.NumberFormat('de-DE').format(response.data.montant), 15, 110);
                    doc.setFontSize(8);
                    doc.text(NumberToLetter(response.data.montant).toUpperCase() + ' FCFA', 15, 118);
                    doc.rect(14, 113, width - 28, 8);
                    doc.setFontSize(10);
                    doc.text('Visa direction', 15, 150);
                    // doc.rect(14, 97, 60, 22);
                    // doc.text('Visa client:',135,96)
                    // doc.rect(135, 97, 60, 22);
                    doc.setFontSize(8);

                    doc.setLineWidth(1.5);
                    doc.setFontSize(8);
                    doc.setLineWidth(1.5);
                    doc.setDrawColor(0, 0, 0);
                    doc.line(0, 270, width, 270);
                    doc.text(
                        'Siège social - Marcory Boulevard de Marseille - Zone 4 - 26 BP 695 Abidjan 26 - Tel.: 27 21 36 39 79 / Fax: 27 21 34 59 59',
                        105,
                        275,
                        null,
                        'center'
                    );
                    doc.text(
                        'RCCM N°CI-ABJ-2012-A-5995 - Compte SIB N° CI 007 0106885056950010020 / Cel: 07 08 29 40 40 /  Email: vicaso11@hotmail.fr ',
                        105,
                        280,
                        null,
                        'center'
                    );

                    doc.save('reglement-' + response.data.norecu + '.pdf');
                })
                .catch((errors) => {});
        } else {
            Axios.get(common.ipapi + '/api/reglement/edit/' + id)
                .then((response) => {
                    doc.setFontSize(10);
                    doc.setTextColor(0, 0, 0);
                    doc.setDrawColor(0);
                    doc.text(119, 53, 'Abidjan le: ' + dateformat(new Date(), 'dd/mm/yyyy'));
                    doc.rect(115, 58, 81, 24);
                    doc.text(response.data.proforma.client ? 'Non: ' + response.data.proforma.client.raisonsocial : 'Nom: Néant', 119, 63);
                    doc.text(
                        response.data.proforma.client ? 'Télephone: ' + response.data.proforma.client.telcli : 'Télephone: Néant',
                        119,
                        68
                    );
                    response.data.proforma.client
                        ? doc.text('Type client: ' + response.data.proforma.client.sisociete, 119, 73)
                        : doc.text('Type client: Néant', 119, 73);

                    doc.text('N° Reçu: ' + response.data.norecu, 15, 63);
                    doc.text('Date règlement: ' + dateformat(response.data.date_regl, 'dd/mm/yyyy'), 15, 68);
                    doc.text('N° facture: ' + response.data.proforma.nofact, 15, 73);
                    doc.text('Date facture: ' + dateformat(response.data.proforma.datefact, 'dd/mm/yyyy'), 15, 78);
                    doc.text('Engin: ' + response.data.proforma.engin, 15, 83);

                    doc.text('Arrêté à la somme de', 15, 90).setFont(undefined, 'bold');
                    doc.setFontSize(8);
                    doc.text(NumberToLetter(response.data.montant).toUpperCase() + ' FCFA', 15, 98);
                    doc.rect(14, 93, width - 28, 8);
                    doc.setFontSize(10);
                    doc.text('Visa direction', 15, 120);
                    doc.setFontSize(8);
                    doc.save('reglement-' + response.data.norecu + '.pdf');
                })
                .catch((errors) => {});
        }
    }

    const gridStyle = { minHeight: window.screen.height - 200 };

    function GetSortOrder(prop) {
        return function (a, b) {
            if (a[prop] > b[prop]) {
                return 1;
            } else if (a[prop] < b[prop]) {
                return -1;
            }
            return 0;
        };
    }

    const exportCSV = async () => {
        //--------------- EXPORT VERS EXCEL ------------------
        const raw_data = gridRef.current.data.sort(GetSortOrder('norecu'));
        /* flatten objects */
        const rows = [];
        raw_data.map((row, index) => {
            rows.push({
                norecu: row.norecu,
                montant: new Intl.NumberFormat('de-DE').format(row.montant),
                client: row.client,
                typer: row.type,
                type: row.nocheq,
                dateregl: dateformat(row.date_regl, 'dd-mm-yyyy'),
                nofact: row.nofact,
                datfact: dateformat(row.datefact, 'dd-mm-yyyy')
            });
        });

        /* generate worksheet and workbook */
        const worksheet = XLSX.utils.json_to_sheet(rows);
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, 'Mes sorties');
        /* fix headers */
        XLSX.utils.sheet_add_aoa(
            worksheet,
            [['N° Recu', 'Montant', 'Clients', 'Type règlement', 'N° chèque', 'Date règlement', 'N° facture', 'Date facture']],
            { origin: 'A1' }
        );
        /* calculate column width */
        const max_width = rows.reduce((w, r) => Math.max(w, r.client.length), 10);
        worksheet['!cols'] = [{ wch: max_width }];
        XLSX.writeFile(workbook, 'reglement.xlsx');
    };

    async function printListe() {
        if (gridRef.current.data.length <= 0) {
            //   setMsg("Aucune donnée à imprimer")
            //   setIsSave(true)
            //   setMsgravity("danger")
            //   setLoad(false)
            return;
        }
        const doc = new jsPDF({});
        var width = doc.internal.pageSize.getWidth();
        const data = [];
        let mont = 0;
        common.ipapi === 'https://192.168.8.109:81' || common.ipapi === 'https://api.tracky-entrepot.com'
            ? doc.addImage(common.logo, 'jpg', 12, 5, 30, 30)
            : doc.addImage(common.logo1, 'jpg', 12, 5, 30, 30);
        doc.setFontSize(16);
        // common.ipapi === 'https://192.168.8.109:81' || common.ipapi === 'https://api.tracky-entrepot.com'
        //     ? doc.text('CLUB AUTO', 90, 14)
        //     : doc.text('HR MOTORS', 90, 14);
        doc.setFontSize(12);
        doc.setFillColor(225, 206, 154);
        doc.rect(65, 15, width - 80, 10, 'F');
        doc.setTextColor(0, 0, 0);
        doc.text('LISTE DES  REGLEMENTS', 90, 22);
        doc.setTextColor(0, 0, 0);
        gridRef.current.data.forEach((row, index) => {
            mont += row.montant;
            data.push([
                index + 1,
                row.norecu,
                row.client,
                row.type,
                row.nocheq,
                new Intl.NumberFormat('de-DE').format(row.montant),
                dateformat(row.date_regl, 'dd-mm-yyyy'),
                row.nofact,
                dateformat(row.datefact, 'dd-mm-yyyy')
            ]);
        });
        data.push([
            { content: 'TOTAL', styles: { fontStyle: 'bold', fontSize: 12 } },
            { content: gridRef.current.data.length, styles: { fontStyle: 'bold', fontSize: 12 } },
            { content: '' },
            { content: '', colSpan: 2 },
            { content: new Intl.NumberFormat('de-DE').format(mont), styles: { fontStyle: 'bold', fontSize: 12 } },
            { content: '', colSpan: 2 }
        ]);
        doc.autoTable({
            styles: {
                fillColor: [255, 255, 255],
                textColor: [0, 0, 0],
                cellPadding: 1.5,
                halign: 'center',
                fontSize: 9
            },
            columnStyles: {
                0: {
                    halign: 'center',
                    fillColor: [255, 255, 255],
                    textColor: [0, 0, 0],
                    cellPadding: 0.75,
                    cellWidth: 15,
                    fontSize: 8
                },
                1: {
                    halign: 'center',
                    fillColor: [255, 255, 255],
                    textColor: [0, 0, 0],
                    cellPadding: 0.75,
                    fontSize: 8
                },
                2: {
                    halign: 'center',
                    fillColor: [255, 255, 255],
                    textColor: [0, 0, 0],
                    cellPadding: 0.75,
                    fontSize: 8
                },
                3: {
                    halign: 'center',
                    fillColor: [255, 255, 255],
                    textColor: [0, 0, 0],
                    cellPadding: 0.75,
                    fontSize: 8
                },
                4: {
                    halign: 'center',
                    fillColor: [255, 255, 255],
                    textColor: [0, 0, 0],
                    cellPadding: 0.75,
                    fontSize: 8
                },
                5: {
                    halign: 'center',
                    fillColor: [255, 255, 255],
                    textColor: [0, 0, 0],
                    cellPadding: 0.75,
                    fontSize: 8
                },
                6: {
                    halign: 'center',
                    fillColor: [255, 255, 255],
                    textColor: [0, 0, 0],
                    cellPadding: 0.75,
                    fontSize: 8
                },
                7: {
                    halign: 'center',
                    fillColor: [255, 255, 255],
                    textColor: [0, 0, 0],
                    cellPadding: 0.75,
                    fontSize: 8
                },
                8: {
                    halign: 'center',
                    fillColor: [255, 255, 255],
                    textColor: [0, 0, 0],
                    cellPadding: 0.75,
                    fontSize: 8
                }
            },
            theme: 'grid',
            startY: 30,
            fontSize: 6,
            head: [
                ['Ordre', 'N° Recu', 'Clients', 'Type règlement', 'N° chèque', 'Montant', 'Date règlement', 'N° facture', 'Date facture']
            ],
            body: data
        });
        const pageCount = doc.internal.getNumberOfPages();
        for (var u = 1; u <= pageCount; u++) {
            doc.setPage(u);
            doc.setFontSize(10);
            doc.text('Page ' + String(u) + ' sur ' + String(pageCount), 210 - 18, 292, null, null, 'right');
        }
        doc.save('reglement.pdf');
    }

    return (
        <div>
            <NavMenu printListe={printListe} exportCSV={exportCSV} setAdds={setAdds} />
            <ReactDataGrid
                idProperty="id"
                handle={setGridRef}
                style={gridStyle}
                loading={isLoading}
                reorderColumns={false}
                pagination
                pageSizes={[200, 400, 600, 800, 1000]}
                defaultLimit={400}
                showHoverRows={true}
                editable={true}
                showColumnMenuTool={false}
                defaultFilterValue={filterValue}
                columns={columnss}
                dataSource={rows}
            />
        </div>
    );
}

export default MyAwesomeTable;
