import React, { useEffect, useState, useRef } from 'react';
import { Link, useParams, useHistory } from 'react-router-dom';
import { Form, ButtonGroup, Toast, Spinner, Popover, OverlayTrigger } from 'react-bootstrap';
import { Card, NavbarBrand, Button, Row, Col, CardBody, CardTitle, CardSubtitle, CardImg, CardLink, CardFooter } from 'reactstrap';
import Axios from 'axios';
import { jsPDF } from 'jspdf';
import 'jspdf-autotable';
import { Eye, Trash, PencilSquare, GearFill, BackspaceFill } from 'react-bootstrap-icons';

import common from '../../commonData';
import dateformat from 'dateformat';

function FamilleArticleMarque() {
    const [msgGravity, setMsgravity] = useState('success');
    const [msg, setMsg] = useState('');
    const [isSave, setIsSave] = useState(false);
    const [load, setLoading] = useState(true);
    const sidebarToggle = useRef();
    const [libellefam, setFamille] = useState('');
    const [datas, setDatas] = useState([]);
    const history = useHistory();

    let { id } = useParams();

    useEffect(() => {
        setLoading(true);
        if (!localStorage.getItem('user-info')) {
            history.push('/login');
        } else {
            (async function anyNameFunction() {
                await Axios.get(common.ipapi + '/api/article/famille/marque/' + id).then((response) => {
                    setFamille(response.data.length > 0 ? response.data[0].libellefam : '');
                    setDatas(response.data);
                    setLoading(false);
                });
            })();
        }
    }, [history, id]);

    const Images = ({ data, rowIndex }) => {
        return (
            <div>
                <Link to={`/admin/article/fiche/${data.id}`}>
                    {data.urlart ? (
                        data.urlart.indexOf('sortly') === -1 ? (
                            <img alt={rowIndex} className="imgart rounded" src={common.ipapi + data.urlart}></img>
                        ) : (
                            <img className="imgart rounded" alt={rowIndex} src={data.urlart}></img>
                        )
                    ) : (
                        ''
                    )}
                </Link>
            </div>
        );
    };
    const Operation = ({ data }) => {
        return (
            <OverlayTrigger
                trigger="click"
                placement="left"
                overlay={
                    <Popover id="popover-positioned">
                        <Popover.Header as="h3">Options</Popover.Header>
                        <Popover.Body>
                            <ButtonGroup aria-label="Basic example">
                                <Link to={`/admin/article/fiche/${data.id}`} id="actionbtn">
                                    <Eye size={25} color="royalblue" />
                                </Link>
                                <Link to={`/admin/article/edit/${data.id}`} id="actionbtn">
                                    <PencilSquare size={20} color="green" />
                                </Link>
                                <a href="#/admin/articles" onClick={() => onDelete(data.id)} id="actionbtn">
                                    <Trash color="red" size={20} />
                                </a>
                            </ButtonGroup>
                        </Popover.Body>
                    </Popover>
                }
            >
                <GearFill title="Options" size={20} color="orange" />
            </OverlayTrigger>
        );
    };
    const CPrivente = ({ data }) => {
        return new Intl.NumberFormat('fr-FR', { style: 'currency', currency: 'CFA' }).format(data.prixachat * data.coeficient);
    };

    const columns = [
        {
            id: 1,
            label: 'Images',
            cellRenderer: Images,
            pinned: true,
            sortable: false,
            width: '40px',
            minResizeWidth: 40
        },
        {
            id: 2,
            field: 'codeart',
            label: 'Code article',
            width: '130px',
            minResizeWidth: 70
        },
        {
            id: 3,
            field: 'qrbarreshort',
            label: 'Code',
            width: '130px',
            minResizeWidth: 70
        },
        {
            id: 4,
            field: 'descriptionart',
            label: 'Descriptions'
        },
        {
            id: 5,
            field: 'libellefam',
            label: 'Famille'
        },
        {
            id: 6,
            field: 'libellemarq',
            label: 'Models',
            width: '150px'
        },
        {
            id: 7,
            field: 'libelleentrepot',
            label: 'Entrepots'
        },
        {
            id: 8,
            field: 'qtestock',
            label: 'Quantité'
        },
        {
            id: 9,
            field: 'qteseuil',
            label: 'Qte seuil.'
        },
        {
            id: 10,
            field: 'prixachat',
            label: 'Prix'
        },
        {
            id: 11,
            field: 'coeficient',
            label: 'Coef'
        },
        {
            id: 12,
            label: 'P. vente',
            cellRenderer: CPrivente
        },
        {
            id: 13,
            label: 'Actions',
            cellRenderer: Operation,
            pinned: true,
            width: '20px',
            sortable: false,
            minResizeWidth: 20
        }
    ];

    function onDelete(id) {
        if (window.confirm('Voulez-vous supprimer cet article ?')) {
            setLoading(true);
            Axios.delete(common.ipapi + '/api/article/destroy/' + id).then((response) => {
                if (response.data.status === 1) {
                    const del = datas.filter((d) => d.id !== id);
                    console.log(del);
                    setDatas(del);
                    setMsgravity('success');
                    setMsg(response.data.msg);
                    setIsSave(true);
                    setLoading(false);
                } else {
                    setMsgravity('danger');
                    setMsg('Une erreur a eu lieu');
                    setIsSave(true);
                    setLoading(false);
                }
            });
        }
    }
    function cancel(e) {
        e.preventDefault();
        window.history.back();
    }

    return (
        <>
            <Toast onClose={() => setIsSave(false)} show={isSave} delay={3000} bg={msgGravity} autohide>
                <Toast.Header>
                    <img src="holder.js/20x20?text=%20" className="rounded me-2" alt="" />
                    <strong className="me-auto">Info</strong>
                </Toast.Header>
                <Toast.Body>{msg}</Toast.Body>
            </Toast>
            {load ? <Spinner animation="grow" variant="warning" /> : ''}
            <div className="content contents">
                <Card>
                    <NavbarBrand>Liste de article de famille {libellefam}</NavbarBrand>
                    <Form className="ml-auto">
                        <ButtonGroup size="sm">
                            <Button type="submit" variant="ligth" onClick={cancel}>
                                <BackspaceFill color="#000" size={20} /> Retour
                            </Button>
                            <Button>
                                <a href={`#/admin/article/famille/${id}`}>
                                    <Eye color="#ff7300" size={20} /> Voir toutes les pièces
                                </a>
                            </Button>
                            {/* <Button variant="light" onClick={()=>printListeFacture()}><Printer color="#ff7300" size={20}/> Imprimer</Button> */}
                        </ButtonGroup>
                    </Form>
                </Card>
                <Row>
                    <Col md="12">
                        <Row>
                            {datas.map((row, i) => {
                                return (
                                    <Col md="3" key={i}>
                                        <Card>
                                            <CardBody>
                                                <CardTitle>
                                                    {row.libellemarq}{' '}
                                                    <CardSubtitle className="mb-2 text-muted">
                                                        {dateformat(row.created_at, 'dd-mm-yyyy HH:MM')}
                                                    </CardSubtitle>
                                                </CardTitle>
                                                <CardLink href={`#/admin/article/marque/${row.id}`}>
                                                    <CardImg
                                                        alt="Card image cap"
                                                        src={common.ipapi + '/' + row.logo}
                                                        top
                                                        width="265px"
                                                        height="160px"
                                                    />
                                                </CardLink>
                                                <CardLink href={`#/admin/article/marque/${row.id}`}>
                                                    {row.qte} /{' '}
                                                    {new Intl.NumberFormat('fr-FR', { style: 'currency', currency: 'CFA' }).format(row.nb)}
                                                </CardLink>
                                                <CardFooter></CardFooter>
                                            </CardBody>
                                        </Card>
                                    </Col>
                                );
                            })}
                        </Row>
                    </Col>
                </Row>
            </div>
        </>
    );
}

export default FamilleArticleMarque;
