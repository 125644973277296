import React, { useEffect, useState } from 'react';
import { Modal, Spinner, ListGroup } from 'react-bootstrap';
import { Row, Col } from 'reactstrap';
import Axios from 'axios';
import { useHistory } from 'react-router-dom';
import { ArrowLeft, Backspace, Folder } from 'react-bootstrap-icons';
import 'react-bootstrap-typeahead/css/Typeahead.css';
import common from '../../commonData';

function SelectFolder({
  setShowSelectFolder,
  show,
  idParent,
  setIdFolder,
  setsIdFolder,
  setFolderSelect,
}) {
  const [btnretour, setBtnRetour] = useState(false);
  const [idsParent, setParentIds] = useState([]);
  const [folder, setFolder] = useState([]);
  const [load, setLoad] = useState(false);
  const history = useHistory();
  useEffect(() => {
    if (!localStorage.getItem('user-info')) {
      history.push('/login');
    }
    (async function anyNameFunction() {
      setLoad(true);
      await Axios.get(common.ipapi + '/api/famille/sid')
        .then((response) => {
          setFolder(response.data);
          setLoad(false);
        })
        .catch((errors) => {
          setLoad(false);
        });
    })();
  }, [history]);

  function getfolder(id) {
    setBtnRetour(true);
    Axios.get(common.ipapi + '/api/famille/sid/' + id)
      .then((response) => {
        idsParent.push(id);
        setFolder(response.data);
        setIdFolder(null);
        setLoad(false);
      })
      .catch((errors) => {
        setLoad(false);
      });
  }

  function retour() {
    var url = 0;
    if (idsParent.length - 1 === 0) {
      url = common.ipapi + '/api/famille/sid';
      setBtnRetour(false);
    } else {
      var tab = idsParent.slice(0, idsParent.length - 1);
      url = common.ipapi + '/api/famille/sid/' + tab[tab.length - 1];
      setParentIds(tab);
    }

    Axios.get(url)
      .then((response) => {
        setFolder(response.data);
        setIdFolder(null);
        setLoad(false);
      })
      .catch((errors) => {
        setLoad(false);
      });
  }

  const handleCloseFolder = () => {
    setShowSelectFolder(false);
  };

  function setIdFolders(d) {
    setsIdFolder(d.sid);
    setIdFolder(d.id);
    setFolderSelect(d.libellefam);
    setShowSelectFolder(false);
  }
  return (
    <>
      <Modal
        show={load}
        size="sm"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Body>
          Veuillez patienter.......
          <Spinner animation="grow" variant="warning" />
        </Modal.Body>
      </Modal>

      <Modal
        show={show}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header>
          {btnretour ? <ArrowLeft size={20} onClick={() => retour()} /> : <></>}{' '}
          SELECTIONNER UN DOSSIER{' '}
          <Backspace color="red" size={20} onClick={handleCloseFolder} />
        </Modal.Header>
        <Modal.Body>
          <div id="lightbody">
            <ListGroup as="ul">
              {folder.map((d, index) => (
                <ListGroup.Item
                  onDoubleClick={() => setIdFolders(d)}
                  as="li"
                  key={index}
                  className={d.sid === idParent ? 'active' : ''}
                >
                  <Row>
                    <Col md={11}>{d.libellefam.toUpperCase()}</Col>
                    {
                      d.nb ? (
                        <Col md={1}>
                          <Folder
                            color="orange"
                            size={17}
                            onClick={() => getfolder(d.sid)}
                          />
                        </Col>
                      ) : (
                        <></>
                      )
                      // d.nb ? <Col md={1}><ArrowRight color="red" size={20}  onClick={()=>getfolder(d.sid)}/></Col> : <></>
                    }
                  </Row>
                </ListGroup.Item>
              ))}
            </ListGroup>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default SelectFolder;
