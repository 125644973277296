import React, { useEffect, useState, useRef } from 'react';
import { Form, InputGroup, Modal, Spinner, ButtonGroup, OverlayTrigger, Tooltip, Card } from 'react-bootstrap';
import { Button, Row, Col } from 'reactstrap';
import Axios from 'axios';
import { useHistory } from 'react-router-dom';
import { Backspace, PlusCircle, Save2Fill } from 'react-bootstrap-icons';
import { Typeahead } from 'react-bootstrap-typeahead';
import 'react-bootstrap-typeahead/css/Typeahead.css';
import * as Yup from 'yup';
import { Field, Formik } from 'formik';

import dateformat from 'dateformat';
import common from '../../commonData';
import AddClient from '../clients/addClient';
import { ProductList, Summary } from './cardadd';

function AddFacture({ setShowAdd, show, setIsSave, setMsg, setMsgravity, setActualise, actualise, ids, statesParent }) {
    var ladate = new Date();
    const [codeart, setCodeart] = useState([]);
    const [article, setArticle] = useState([]);
    const [client, setClient] = useState([]);
    const [listeSelect, setListeSelect] = useState(statesParent.listeSelect);
    const [load, setLoad] = useState(false);
    const [showAddclient, setShowAddclient] = useState(false);
    const [addclient, setAddclient] = useState(false);
    const [articles, setArticleData] = useState([]);
    const [famille, setfamilleData] = useState([]);
    const [famille_id, setIdFamille] = useState('');
    const [states, setState] = useState({
        nobon: statesParent.nobon,
        datet: statesParent.datet,
        sitva: statesParent.sitva,
        type: statesParent.type,
        clientid: statesParent.clientid,
        engin: statesParent.engin,
        sortie: statesParent.sortie
    });
    const { nobon, datet, sitva, type, clientid, engin, sortie } = states;
    const history = useHistory();
    const idclient = useRef(null);
    const typeaheadModel = useRef(null);
    useEffect(() => {
        if (!localStorage.getItem('user-info')) {
            history.push('/login');
        }
        (async function anyNameFunction() {
            setLoad(true);
            const getarticle = Axios.get(common.ipapi + '/api/article/indexall');
            const getClient = Axios.get(common.ipapi + '/api/client/indexmin');
            const getFamille = Axios.get(common.ipapi + '/api/famille/indexstep');
            await Axios.all([getarticle, getClient, getFamille])
                .then(
                    Axios.spread((...responses) => {
                        setArticle(responses[0].data);
                        setArticleData(responses[0].data);
                        setClient(responses[1].data);
                        setfamilleData(responses[2].data);
                        setLoad(false);
                    })
                )
                .catch((errors) => {
                    setLoad(false);
                });
        })();
    }, [history]);

    function setFamilleId(d) {
        setIdFamille(d.length > 0 ? d[0].id : 0);
        if (d.length > 0) {
            const res = article.filter((element) => element.famille_id == d[0].id);
            setArticle(res);
        } else {
            setArticle(articles);
        }
    }

    const subTotal = listeSelect.reduce((total, product) => {
        return (
            total +
            (product.quantite * product.prix - product.quantite * product.prix * (product.remise / 100)) +
            (product.quantite * product.prix - product.quantite * product.prix * (product.remise / 100)) * 0.18
        );
    }, 0);
    const onChangeProductQuantity = (index, event) => {
        const cloneProducts = [...listeSelect];
        cloneProducts[index].quantite = event.target.value;
        setListeSelect(cloneProducts);
    };

    const onChangePrestation = (index, event) => {
        const cloneProducts = [...listeSelect];
        cloneProducts[index].descriptionart = event.target.value;
        setListeSelect(cloneProducts);
    };

    const onChangeRemise = (index, event) => {
        const cloneProducts = [...listeSelect];
        cloneProducts[index].remise = event.target.value;
        setListeSelect(cloneProducts);
    };

    const onChangePrix = (index, event) => {
        const valueInt = parseInt(event.target.value);
        const cloneProducts = [...listeSelect];
        cloneProducts[index].prix = valueInt;

        setListeSelect(cloneProducts);
    };

    const onRemoveProduct = (i, id) => {
        if (ids) {
            if (id != undefined) {
                if (window.confirm('Voulez-vous supprimer cette ligne ? ')) {
                    setLoad(true);
                    Axios.delete(common.ipapi + '/api/facture/destroyligne/' + id).then((response) => {
                        if (response.data.status == 1) {
                            //sidelete = 1
                            const filteredProduct = listeSelect.filter((product, index) => {
                                return index != i;
                            });
                            setListeSelect(filteredProduct);
                            setMsg(response.data.msg);
                            setIsSave(true);
                            setLoad(false);
                        } else {
                            setMsg(response.data.msg);
                            setIsSave(true);
                            setLoad(false);
                        }
                    });
                }
            } else {
                const filteredProduct = listeSelect.filter((product, index) => {
                    return index != i;
                });
                setListeSelect(filteredProduct);
                setMsg('Ligne supprimée');
                setMsgravity('success');
                setIsSave(true);
                setLoad(false);
            }
        } else {
            var filteredProduct = [];
            setLoad(true);
            filteredProduct = listeSelect.filter((product, index) => {
                return index != i;
            });
            setListeSelect(filteredProduct);
            setMsg('Ligne supprimée');
            setMsgravity('info');
            setIsSave(true);
            setLoad(false);
        }
    };

    function saveFacture(values, setSubmitting) {
        if (window.confirm('Voulez-vous confirmer cette action') == false) {
            return;
        }
        if (!navigator.onLine) {
            setMsg('Veuillez vérifier votre connexion internet');
            setMsgravity('info');
            setIsSave(true);
            return;
        }

        if (listeSelect.length === 0) {
            setMsg('Veuillez saisir au moin un article');
            setMsgravity('info');
            setIsSave(true);
            return false;
        }
        setLoad(true);
        setSubmitting(true);

        const filteredProduct = listeSelect.filter((product, index) => {
            return product.quantite !== '0';
        });
        var formData = new FormData();
        formData.append('date', values.date);
        formData.append('type', values.type);
        formData.append('numbon', values.numbon);
        formData.append('codeart', sortie ? JSON.stringify([]) : JSON.stringify(filteredProduct));
        formData.append('user_id', common.userid);
        formData.append('engin', values.engin);
        formData.append('sitva', values.sitva);
        formData.append('client_id', values.client_id);
        Axios({
            url: ids ? common.ipapi + '/api/facture/update/' + ids : common.ipapi + '/api/facture/create',
            method: 'POST',
            data: formData
        })
            .then((res) => {
                if (res.status !== 200) {
                    setMsg('Veuillez vérifier votre connexion internet');
                    setMsgravity('warning');
                    setIsSave(false);
                    setLoad(false);
                    setSubmitting(false);
                    return;
                }
                if (res.data.status === 1) {
                    setCodeart([]);
                    setListeSelect([]);
                    idclient.current.clear();
                    setMsg(res.data.msg);
                    setMsgravity('info');
                    setIsSave(true);
                    setActualise(!actualise);
                    setLoad(false);
                    setSubmitting(false);
                    if (ids) {
                        handleClose();
                    }
                } else {
                    setMsg(res.data.msg);
                    setMsgravity('danger');
                    setIsSave(true);
                    setLoad(false);
                    setSubmitting(false);
                }
            })
            .catch((error) => {
                setIsSave(true);
                setMsgravity('danger');
                setLoad(false);
                setSubmitting(false);
            });
    }

    const handleClose = () => {
        setCodeart([]);
        setListeSelect([]);
        idclient.current.clear();
        setShowAdd(false);
        setIsSave(false);
    };

    function setAddClient(val) {
        setAddclient(val);
        setShowAddclient(true);
    }

    return (
        <>
            <Modal show={load} size="sm" aria-labelledby="contained-modal-title-vcenter" centered>
                <Modal.Body>
                    Veuillez patienter.......
                    <Spinner animation="grow" variant="warning" />
                </Modal.Body>
            </Modal>
            {addclient != false ? (
                <AddClient
                    setShowAddclient={setShowAddclient}
                    show={showAddclient}
                    setIsSave={setIsSave}
                    isSave={false}
                    setClient={setClient}
                    setMsg={setMsg}
                    setMsgravity={setMsgravity}
                />
            ) : (
                <></>
            )}
            <Formik
                initialValues={{
                    numbon: nobon,
                    date: datet || dateformat(ladate.getMonth() + 1 + '/' + ladate.getDate() + '/' + ladate.getFullYear(), 'yyyy-mm-dd'),
                    client_id: clientid,
                    user_id: common.userid,
                    sitva: sitva || '0',
                    type: type || 'Proforma',
                    engin: engin,
                    codeart: listeSelect,
                    submit: null
                }}
                // enableReinitialize
                validationSchema={Yup.object().shape({
                    client_id: Yup.number().typeError('Aucune correspondance').required(common.msg),
                    date: Yup.date().required(common.msg)
                })}
                onSubmit={async (values, { setSubmitting }) => {
                    try {
                        saveFacture(values, setSubmitting);
                    } catch (err) {
                        setMsg('Une erreur à eu lieu');
                        setMsgravity('danger');
                        setIsSave(true);
                        setLoad(false);
                    }
                }}
            >
                {({ errors, handleBlur, handleChange, handleSubmit, isSubmitting, touched, values, setFieldValue, setFieldTouched }) => (
                    <Modal show={show} fullscreen={true}>
                        <div className="content">
                            <Form noValidate onSubmit={handleSubmit}>
                                <Modal.Header>
                                    {ids ? 'MOFIFIER UNE FACTURE / PROFORMA' : 'FAIRE UNE FACTURE / PROFORMA'}
                                    <Backspace onClick={handleClose} color="red" size={30} />
                                </Modal.Header>
                                <Modal.Body>
                                    <div id="lightbody">
                                        <Row>
                                            <Col md={4}>
                                                <Card bg="light" border="dark">
                                                    <Card.Body>
                                                        <Card.Title>Entête de la facture </Card.Title>
                                                        <Form.Group as={Row} className="mb-3">
                                                            <Form.Label column sm={3}>
                                                                Type:
                                                            </Form.Label>
                                                            <Col sm={9}>
                                                                <div role="group" aria-labelledby="my-radio-group">
                                                                    <label className="radio">
                                                                        <Field type="radio" name="type" value="Proforma" />
                                                                        Proforma
                                                                    </label>
                                                                    <label className="radio">
                                                                        <Field type="radio" name="type" value="Facture" />
                                                                        Facture
                                                                    </label>
                                                                </div>
                                                            </Col>
                                                        </Form.Group>
                                                        <Form.Group as={Row} className="mb-3">
                                                            <Form.Label column sm={3}>
                                                                N° de pièce:
                                                            </Form.Label>
                                                            <Col sm={9}>
                                                                <Form.Control
                                                                    id="numbon"
                                                                    type="text"
                                                                    placeholder="N° de facture n'est pas obligatoire"
                                                                    aria-describedby="inputGroupPrepend"
                                                                    size="sm"
                                                                    value={values.numbon}
                                                                    onBlur={handleBlur}
                                                                    onChange={handleChange}
                                                                />
                                                            </Col>
                                                        </Form.Group>
                                                        <Form.Group as={Row} className="mb-3">
                                                            <Form.Label column sm={3}>
                                                                Client:
                                                            </Form.Label>
                                                            <Col sm={9}>
                                                                <InputGroup hasValidation>
                                                                    <Typeahead
                                                                        id="client_id"
                                                                        ref={idclient}
                                                                        multiple={false}
                                                                        onChange={(selected) => {
                                                                            const value = selected.length > 0 ? selected[0].id : 0;
                                                                            setFieldValue('client_id', value);
                                                                        }}
                                                                        onInputChange={(text, event) => setFieldValue('client_id', text)}
                                                                        onBlur={(e) => setFieldTouched('client_id', true)}
                                                                        labelKey="raisonsocial"
                                                                        options={client}
                                                                        selected={client.slice(
                                                                            client.findIndex((element) => element.id === values.client_id),
                                                                            client.findIndex((element) => element.id === values.client_id) +
                                                                                1
                                                                        )}
                                                                    />
                                                                    <OverlayTrigger
                                                                        overlay={
                                                                            <Tooltip id="tooltip-disabled">
                                                                                Ajouter un nouveau client
                                                                            </Tooltip>
                                                                        }
                                                                    >
                                                                        <PlusCircle
                                                                            color="#51cbce"
                                                                            onClick={() => setAddClient()}
                                                                            size={35}
                                                                        />
                                                                    </OverlayTrigger>
                                                                </InputGroup>
                                                                {errors.client_id && <div id="feedback">{errors.client_id}</div>}
                                                            </Col>
                                                        </Form.Group>
                                                        <Form.Group as={Row} className="mb-3">
                                                            <Form.Label column sm={3}>
                                                                Date édition:
                                                            </Form.Label>
                                                            <Col sm={9}>
                                                                <Form.Control
                                                                    id="date"
                                                                    type="date"
                                                                    placeholder="Date édition"
                                                                    aria-describedby="inputGroupPrepend"
                                                                    required
                                                                    size="sm"
                                                                    value={values.date}
                                                                    onBlur={handleBlur}
                                                                    onChange={handleChange}
                                                                />
                                                                {errors.date && <div id="feedback">{errors.date}</div>}
                                                            </Col>
                                                        </Form.Group>
                                                        <Form.Group as={Row} className="mb-3">
                                                            <Form.Label column sm={3}>
                                                                Application TVA:
                                                            </Form.Label>
                                                            <Col sm={9}>
                                                                <div role="group" aria-labelledby="my-radio-group">
                                                                    <label className="radio">
                                                                        <Field type="radio" name="sitva" value="0" />
                                                                        TVA applicable
                                                                    </label>
                                                                    <label className="radio">
                                                                        <Field type="radio" name="sitva" value="1" />
                                                                        TVA non applicable
                                                                    </label>
                                                                </div>
                                                            </Col>
                                                        </Form.Group>

                                                        <Form.Group as={Row} className="mb-3">
                                                            <Form.Label column sm={3}>
                                                                N° engin:
                                                            </Form.Label>
                                                            <Col sm={9}>
                                                                <Form.Control
                                                                    id="engin"
                                                                    type="text"
                                                                    placeholder="Ex: chassis, immatriculation"
                                                                    aria-describedby="inputGroupPrepend"
                                                                    size="sm"
                                                                    value={values.engin}
                                                                    onBlur={handleBlur}
                                                                    onChange={handleChange}
                                                                />
                                                            </Col>
                                                        </Form.Group>
                                                    </Card.Body>
                                                </Card>
                                            </Col>
                                            <Col md={8}>
                                                <Card bg="light" border="dark">
                                                    <Card.Body>
                                                        <Card.Title>Sélection des articles à facturer </Card.Title>
                                                        <Form.Group as={Row} className="mb-3">
                                                            <Form.Label column sm={2}>
                                                                Filtre (Model pièces):
                                                            </Form.Label>
                                                            <Col sm={10}>
                                                                <Typeahead
                                                                    ref={typeaheadModel}
                                                                    id="my-typeahead-id"
                                                                    labelKey={(option) => `${option.libellefam}`}
                                                                    filterBy={['libellefam']}
                                                                    value={famille_id}
                                                                    options={famille}
                                                                    onChange={(cli) => setFamilleId(cli)}
                                                                />
                                                                <Form.Control.Feedback type="invalid">
                                                                    Veuillez renseigner le model.
                                                                </Form.Control.Feedback>
                                                            </Col>
                                                        </Form.Group>
                                                        <Form.Group as={Row} className="mb-3">
                                                            <Form.Label column sm={2}>
                                                                Sélection articles:
                                                            </Form.Label>
                                                            <Col sm={10}>
                                                                <Typeahead
                                                                    id="my-typeahead-id"
                                                                    labelKey={(option) =>
                                                                        `${option.famille} > ${option.qrbarre} > ${
                                                                            option.descriptionartfr || option.descriptionart
                                                                        } > qte (${option.qtestock})`
                                                                    }
                                                                    filterBy={['descriptionartfr']}
                                                                    selected={codeart}
                                                                    options={article}
                                                                    disabled={load}
                                                                    onChange={(selected) => {
                                                                        var t = [];
                                                                        setCodeart(selected);
                                                                        if (selected.length > 0) {
                                                                            selected[selected.length - 1].quantite = 1;
                                                                        }

                                                                        if (selected.length > 0) {
                                                                            selected.forEach((ent) => {
                                                                                function search(d) {
                                                                                    return d.id === ent.id;
                                                                                }
                                                                                const del = listeSelect.find(search);
                                                                                if (del === undefined) {
                                                                                    let d = {
                                                                                        id: selected[0].id,
                                                                                        article_id: selected[0].id,
                                                                                        qrbarre: selected[0].qrbarre,
                                                                                        remise: 0,
                                                                                        quantite: 1,
                                                                                        prix:
                                                                                            selected[0].prixachat * selected[0].coeficient,
                                                                                        descriptionart:
                                                                                            selected[0].descriptionartfr ||
                                                                                            selected[0].descriptionart
                                                                                    };
                                                                                    t.push(d);
                                                                                    t = t.concat(listeSelect);
                                                                                    setListeSelect(t);
                                                                                    setCodeart([]);
                                                                                    setFieldValue('codeart', t);
                                                                                } else {
                                                                                    setIsSave(true);
                                                                                    setMsgravity('warning');
                                                                                    setMsg('Déjà ajouter à la liste');
                                                                                    setCodeart([]);
                                                                                }
                                                                            });
                                                                        }
                                                                    }}
                                                                    onInputChange={(text, event) => setFieldValue('codeart', text)}
                                                                    onBlur={(e) => setFieldTouched('codeart', true)}
                                                                />
                                                            </Col>
                                                        </Form.Group>
                                                        <div>
                                                            <ProductList
                                                                products={listeSelect}
                                                                onChangeProductQuantity={onChangeProductQuantity}
                                                                onRemoveProduct={onRemoveProduct}
                                                                onChangePrestation={onChangePrestation}
                                                                onChangeRemise={onChangeRemise}
                                                                onChangePrix={onChangePrix}
                                                            />
                                                        </div>
                                                    </Card.Body>
                                                </Card>
                                            </Col>
                                        </Row>
                                    </div>
                                </Modal.Body>
                                <Modal.Footer>
                                    <Summary subTotal={subTotal} sitva={values.sitva} />
                                    <div className="ml-auto">
                                        <ButtonGroup size="sm">
                                            <Button color="secondary" onClick={handleClose}>
                                                <Backspace color="red" size={25} />
                                            </Button>
                                            <Button type="submit" color="warning">
                                                <Save2Fill color="#000" size={25} /> Enregistrer
                                            </Button>
                                        </ButtonGroup>
                                    </div>
                                </Modal.Footer>
                            </Form>
                        </div>
                    </Modal>
                )}
            </Formik>
        </>
    );
}

export default AddFacture;
