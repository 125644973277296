import React, { useEffect, useState } from 'react';
import { Form, ToggleButton, Button, Spinner, ButtonGroup, Modal, Carousel } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import { Card, Input, Row, Col, CardBody, Label, CardHeader } from 'reactstrap';
// import { isMobile } from 'react-device-detect';
// import BarcodeScannerComponent from "react-qr-barcode-scanner";
import Axios from 'axios';
import { Backspace, Folder, Images, Save2Fill, Star, Trash } from 'react-bootstrap-icons';
import common from '../../commonData';
import SelectFolder from '../familles/folderselect';

var count = 0;

function EditArticle({ id, setShowEdit, show, setIsSave, setMsg, setMsgravity, getArticle }) {
    const [validated, setValidated] = useState(false);
    const [codeart, setCodeart] = useState('');
    const [sortlyid, setSortlyid] = useState('');
    const [descriptionart, setDescriptionart] = useState('');
    const [descriptionartfr, setDescriptionartFr] = useState('');
    const [qtestock, setQtestock] = useState(0);
    const [qteseuil, setQteseuil] = useState(0);
    const [coeficient, setCoeficient] = useState(25);
    const [coeficientx, setCoeficientx] = useState(25);
    const [prixachat, setPrixachat] = useState(0);
    const [prixvente, setPrixvente] = useState(0);
    const [img, setImg] = useState('');
    const [file, setFile] = useState('');
    const [emplacement, setEmplacement] = useState('');
    const [famille_id, setFamille] = useState(0);
    const [marque_id, setMarque] = useState(0);
    const [entrepot_id, setEntrepot] = useState(0);
    const [marque, setMarqueData] = useState([]);
    const [famille, setfamilleData] = useState([]);
    const [entrepot, setEntrData] = useState([]);
    const [load, setLoad] = useState(false);
    const [barQrCode, setBarCode] = useState('');
    const [barQrCodecourt, setLongBar] = useState('');
    const [folderSelect, setFolderSelect] = useState('');
    const [showselectFolder, setShowSelectFolder] = useState(false);
    const [idParent, setsIdFolder] = useState(null);
    const [idFolder, setIdFolder] = useState(null);
    const history = useHistory();
    const [imgload, setImageLoad] = useState([]);
    const [imgs, setImage] = useState([]);
    const [imgsTf, setImageTf] = useState([]);
    const [actif, setActif] = useState('1');

    useEffect(() => {
        if (!localStorage.getItem('user-info')) {
            history.push('/dashboard');
        }
        (async function anyNameFunction() {
            setLoad(true);
            const getArticle = Axios.get(common.ipapi + '/api/article/edit/' + id);
            const getFamille = Axios.get(common.ipapi + '/api/famille/index');
            const getMarque = Axios.get(common.ipapi + '/api/marque/index');
            const getEntrepot = Axios.get(common.ipapi + '/api/entrepot/index/1');
            await Axios.all([getFamille, getMarque, getArticle, getEntrepot])
                .then(
                    Axios.spread((...responses) => {
                        setfamilleData(responses[0].data || []);
                        setMarqueData(responses[1].data || []);
                        setEntrData(responses[3].data || []);
                        setCodeart(responses[2].data.codeart || '');
                        setDescriptionart(responses[2].data.descriptionart || '');
                        setQtestock(responses[2].data.qtestock ? responses[2].data.qtestock : 0);
                        setQteseuil(responses[2].data.qteseuil ? responses[2].data.qteseuil : 0);
                        setCoeficient(responses[2].data.coeficient);
                        var x = Number(responses[2].data.prixvente) / Number(responses[2].data.prixachat);
                        setCoeficientx(x);
                        setActif('' + responses[2].data.up + '');
                        setBarCode(responses[2].data.qrbarre || '');
                        setLongBar(responses[2].data.qrbarreshort || '');
                        setSortlyid(responses[2].data.sortlyid || '');
                        setEmplacement(responses[2].data.emplacement || '');
                        setDescriptionartFr(responses[2].data.descriptionartfr || '');
                        setImg(responses[2].data.urlart || '');
                        setImageLoad(responses[2].data.image);
                        if (responses[2].data.famille) {
                            setFamille(responses[2].data.famille.id);
                            setIdFolder(responses[2].data.famille.id);
                            setFolderSelect(responses[2].data.famille.libellefam);
                        } else {
                            setFamille([]);
                            setIdFolder(0);
                            setFolderSelect('');
                        }
                        if (responses[2].data.marque) {
                            setMarque(responses[2].data.marque.id);
                        } else {
                            setMarque(0);
                        }
                        if (responses[2].data.entrepot) {
                            setEntrepot(responses[2].data.entrepot.id);
                        } else {
                            setEntrepot(0);
                        }
                        setPrixachat(responses[2].data.prixachat || 0);
                        setPrixvente(responses[2].data.prixvente || 0);
                        setLoad(false);
                    })
                )
                .catch((errors) => {
                    // react on errors.
                });
        })();
    }, [history, id]);

    function onChangeImage(e) {
        let files = e.target.files || e.dataTransfer.files;
        if (!files.length) {
            return;
        }
        setFile(e.target.value);
        imgs.push(URL.createObjectURL(e.target.files[0]));
        createImage(files[0]);
    }
    function createImage(file) {
        let reader = new FileReader();
        reader.onload = (e) => {
            imgsTf.push(e.target.result);
        };
        reader.readAsDataURL(file);
    }

    function onDeleteImg(index, file) {
        const del = imgs.filter((d) => d !== file);
        setImage(del);
        imgsTf.splice(index, 1);
        setImageTf(imgsTf);
    }
    function onDeleteImgSlide(ind, file) {
        if (window.confirm('Voulez-vous supprimer cette images ? ')) {
            setLoad(true);
            Axios.delete(common.ipapi + '/api/article/destroyimg/' + file.id).then((response) => {
                if (response.data.status == 1) {
                    const del = imgload.filter((product, index) => {
                        return index != ind;
                    });
                    setImageLoad(del);
                    setMsgravity('success');
                    setMsg(response.data.msg);
                    setIsSave(true);
                    setLoad(false);
                } else {
                    setMsgravity('error');
                    setMsg(response.data.msg);
                    setIsSave(true);
                    setLoad(false);
                }
            });
        }
    }

    async function saveArticle(e) {
        setLoad(true);
        if (qtestock < 0) {
            setMsg('Veuillez une quantité superieur ou égale à 0');
            setMsgravity('danger');
            setIsSave(true);
            setLoad(false);
        }
        var formData = new FormData();
        for (var a = 0; a < imgsTf.length; a++) {
            formData.append('file[]', imgsTf[a]);
        }
        formData.append('codeart', codeart);
        formData.append('qrbarreshort', barQrCodecourt);
        formData.append('qrbarre', barQrCode);
        formData.append('descriptionart', descriptionart);
        formData.append('descriptionartfr', descriptionartfr);
        formData.append('qtestock', qtestock);
        formData.append('qteseuil', qteseuil);
        formData.append('coeficient', coeficient);
        formData.append('famille_id', idFolder);
        formData.append('marque_id', marque_id);
        formData.append('entrepot_id', entrepot_id);
        formData.append('user_id', common.userid);
        formData.append('prixachat', prixachat);
        formData.append('prixvente', prixvente);
        formData.append('emplacement', emplacement);
        formData.append('up', actif);
        await Axios({
            url: common.ipapi + '/api/article/update/' + id,
            method: 'POST',
            headers: {
                'Content-Type': 'form-data'
            },
            data: formData
        })
            .then((res) => {
                if (res.status !== 200) {
                    setMsg('Veuillez vérifier votre connexion internet');
                    setMsgravity('info');
                    setIsSave(false);
                    return;
                }
                if (res.data.status == 1) {
                    count++;
                    //if (!isMobile) {
                    var images = imgsTf.length;
                    const formDat = new FormData();
                    for (var a = 0; a < images; a++) {
                        formDat.append('myFile[]', imgsTf[a]);
                    }
                    formDat.append('artid', res.data.datas.id);
                    if (images > 0) {
                        Axios({
                            url: common.ipapi + '/api/article/storeimage',
                            method: 'POST',
                            headers: {
                                'Content-Type': 'form-data'
                            },
                            data: formDat
                        }).then((res) => {
                            if (res.status == 1) {
                                count++;
                            }
                        });
                    }
                    //}
                }
                setIsSave(true);
                setMsg(res.data.msg);
                setImage([]);
                count++;
                handleClose();
                setShowEdit(false);
                setMsgravity('success');
                setLoad(false);
            })
            .catch((error) => {
                setIsSave(true);
                setMsg('Erreur, le code semble existant');
                setMsgravity('danger');
                setLoad(false);
            });
    }

    const handleSubmit = (event) => {
        const form = event.currentTarget;
        if (form.checkValidity() === false) {
            event.preventDefault();
            event.stopPropagation();
            setValidated(true);
        } else {
            saveArticle();
        }
    };
    const setCoeficients = (tag) => {
        setCoeficient(tag);
        setPrixvente(tag * prixachat);
    };
    const setPrixachats = (tag) => {
        setPrixachat(tag);
        setPrixvente(tag * coeficient);
    };
    const setPrixventes = (tag) => {
        setPrixvente(tag);
        setCoeficient(tag / prixachat);
    };

    const handleClose = () => {
        if (count != 0) {
            getArticle(1);
        }
        setImageTf([]);
        setShowEdit(false);
        setIsSave(false);
    };
    return (
        <>
            <Modal show={load} size="sm" aria-labelledby="contained-modal-title-vcenter" centered>
                <Modal.Body>
                    Veuillez patienter.......
                    <Spinner animation="border" variant="warning" />
                </Modal.Body>
            </Modal>
            {showselectFolder ? (
                <SelectFolder
                    setShowSelectFolder={setShowSelectFolder}
                    show={showselectFolder}
                    idParent={idParent}
                    setIdFolder={setIdFolder}
                    setsIdFolder={setsIdFolder}
                    setFolderSelect={setFolderSelect}
                ></SelectFolder>
            ) : (
                <></>
            )}

            <Modal show={show} onHide={handleClose} size="xl">
                <div className="content">
                    <Form noValidate validated={validated} onSubmit={handleSubmit}>
                        <Modal.Header>
                            {descriptionart.toUpperCase()}
                            <Backspace onClick={handleClose} color="red" size={30} />
                        </Modal.Header>
                        <div id="lightbody">
                            <Row>
                                <Col md={7}>
                                    <Card>
                                        <CardBody>
                                            {imgload ? (
                                                <Carousel
                                                    slide={false}
                                                    pause="hover"
                                                    fade={true}
                                                    variant="dark"
                                                    nextLabel="Suivant"
                                                    prevLabel="Précédent"
                                                >
                                                    {imgload.map((d, index) => {
                                                        return (
                                                            <Carousel.Item interval={5000} key={index}>
                                                                <div id="carrouDel">
                                                                    <Button
                                                                        variant="info"
                                                                        size="sm"
                                                                        onClick={() => onDeleteImgSlide(index, d)}
                                                                    >
                                                                        <Trash size={30} color="red"></Trash>
                                                                    </Button>
                                                                </div>
                                                                <img
                                                                    className="d-block w-100"
                                                                    src={
                                                                        d.link.indexOf('sortly') == -1
                                                                            ? common.ipapi + '/public/' + d.link
                                                                            : d.link
                                                                    }
                                                                    alt="First slide"
                                                                />
                                                            </Carousel.Item>
                                                        );
                                                    })}
                                                </Carousel>
                                            ) : (
                                                <Carousel slide={false} fade={true} variant="dark" nextLabel="" prevLabel="">
                                                    <Carousel.Item interval={5000}>
                                                        {img ? (
                                                            img.indexOf('sortly') == -1 ? (
                                                                <img className="d-block w-100" src={common.ipapi + '/public/' + img}></img>
                                                            ) : (
                                                                <img className="d-block w-100" src={img}></img>
                                                            )
                                                        ) : (
                                                            ''
                                                        )}
                                                    </Carousel.Item>
                                                </Carousel>
                                            )}
                                        </CardBody>
                                    </Card>
                                </Col>
                                <Col md={5}>
                                    <Card>
                                        <CardBody>
                                            <Form.Group as={Row} className="mb-3" controlId="formHorizontalEmail">
                                                <Form.Label column sm={4}>
                                                    Code 1:
                                                </Form.Label>
                                                <Col sm={8}>
                                                    <Input
                                                        type="text"
                                                        placeholder="Code barre / QR"
                                                        aria-describedby="inputGroupPrepend"
                                                        bsSize="sm"
                                                        value={barQrCode}
                                                        onChange={(e) => setBarCode(e.target.value, setLongBar(e.target.value))}
                                                    />
                                                </Col>
                                            </Form.Group>
                                            <Form.Group as={Row} className="mb-3" controlId="formHorizontalEmail">
                                                <Form.Label column sm={4}>
                                                    Code 2:
                                                </Form.Label>
                                                <Col sm={8}>
                                                    <Form.Control
                                                        type="text"
                                                        placeholder="Autre code article:"
                                                        aria-describedby="inputGroupPrepend"
                                                        size="sm"
                                                        value={barQrCodecourt}
                                                        onChange={(e) => setLongBar(e.target.value)}
                                                    />
                                                    <Form.Control.Feedback type="invalid">
                                                        Veuillez saisir le code article
                                                    </Form.Control.Feedback>
                                                </Col>
                                            </Form.Group>
                                            <Form.Group as={Row} className="mb-3" controlId="formHorizontalEmail">
                                                <Form.Label column sm={4}>
                                                    Libellé EN.:
                                                </Form.Label>
                                                <Col sm={8}>
                                                    <Form.Control
                                                        type="text"
                                                        placeholder="Description article"
                                                        aria-describedby="inputGroupPrepend"
                                                        required
                                                        size="sm"
                                                        value={descriptionart}
                                                        onChange={(e) => setDescriptionart(e.target.value)}
                                                    />
                                                    <Form.Control.Feedback type="invalid">
                                                        Veuillez saisir la description article
                                                    </Form.Control.Feedback>
                                                </Col>
                                            </Form.Group>
                                            <Form.Group as={Row} className="mb-3" controlId="formHorizontalEmail">
                                                <Form.Label column sm={4}>
                                                    Libellé FR.:
                                                </Form.Label>
                                                <Col sm={8}>
                                                    <Form.Control
                                                        type="text"
                                                        placeholder="Désignation française"
                                                        aria-describedby="inputGroupPrepend"
                                                        required
                                                        size="sm"
                                                        value={descriptionartfr}
                                                        onChange={(e) => setDescriptionartFr(e.target.value)}
                                                    />
                                                    <Form.Control.Feedback type="invalid">
                                                        Veuillez saisir la description article
                                                    </Form.Control.Feedback>
                                                </Col>
                                            </Form.Group>
                                            <Form.Group as={Row} className="mb-3" controlId="formHorizontalEmail">
                                                <Form.Label column sm={4}>
                                                    Entrepot:
                                                </Form.Label>
                                                <Col sm={8}>
                                                    <Input type="select" value={entrepot_id} onChange={(e) => setEntrepot(e.target.value)}>
                                                        <option key={0} value="0"></option>
                                                        {entrepot.map((d, index) => {
                                                            return (
                                                                <option key={index} value={d.id}>
                                                                    {d.codeentrepot + '/' + d.libelleentrepot}
                                                                </option>
                                                            );
                                                        })}
                                                    </Input>

                                                    <Form.Control.Feedback type="invalid">
                                                        Veuillez selectionnez la famille.
                                                    </Form.Control.Feedback>
                                                </Col>
                                            </Form.Group>
                                            <Form.Group as={Row} className="mb-3" controlId="formHorizontalEmail">
                                                <Form.Label column sm={4}>
                                                    Famille:
                                                </Form.Label>
                                                <Col sm={8}>
                                                    <Form.Control
                                                        type="text"
                                                        placeholder="Cliquer ici pour sélectionner un dossier"
                                                        aria-describedby="inputGroupPrepend"
                                                        size="sm"
                                                        value={folderSelect}
                                                        onChange={(e) => setFolderSelect(e.target.value)}
                                                        onClick={() => setShowSelectFolder(true)}
                                                    />
                                                    <Form.Control.Feedback type="invalid">
                                                        Veuillez renseigner le Libellé famille
                                                    </Form.Control.Feedback>
                                                </Col>
                                            </Form.Group>
                                            <Form.Group as={Row} className="mb-3" controlId="formHorizontalEmail">
                                                <Form.Label column sm={4}>
                                                    Marque:
                                                </Form.Label>
                                                <Col sm={8}>
                                                    <Input
                                                        type="select"
                                                        aria-label="Sélectionner"
                                                        value={marque_id}
                                                        onChange={(e) => setMarque(e.target.value)}
                                                    >
                                                        <option value="0"></option>
                                                        {marque.map((d, index) => {
                                                            return (
                                                                <option key={d.id} value={d.id}>
                                                                    {d.libellemarq}
                                                                </option>
                                                            );
                                                        })}
                                                    </Input>
                                                    <Form.Control.Feedback type="invalid">
                                                        Veuillez Sélectionner la marque.
                                                    </Form.Control.Feedback>
                                                </Col>
                                            </Form.Group>
                                            <Form.Group as={Row} className="mb-3" controlId="formHorizontalEmail">
                                                <Form.Label column sm={4}>
                                                    Emplacement:
                                                </Form.Label>
                                                <Col sm={8}>
                                                    <Form.Control
                                                        type="text"
                                                        placeholder="Emplacement dans l'entrepot"
                                                        aria-describedby="inputGroupPrepend"
                                                        size="sm"
                                                        value={emplacement}
                                                        onChange={(e) => setEmplacement(e.target.value)}
                                                    />
                                                </Col>
                                            </Form.Group>
                                            <Form.Group as={Row} className="mb-3" controlId="formHorizontalEmail">
                                                <Form.Label column sm={4}>
                                                    Quantité initiale:
                                                </Form.Label>
                                                <Col sm={8}>
                                                    <Form.Control
                                                        type="number"
                                                        placeholder="Quantité initiale"
                                                        aria-describedby="inputGroupPrepend"
                                                        size="sm"
                                                        min={0}
                                                        value={qtestock}
                                                        onChange={(e) => setQtestock(e.target.value)}
                                                        // disabled
                                                    />
                                                    <Form.Control.Feedback type="invalid">
                                                        Veuillez saisir la quantité article.
                                                    </Form.Control.Feedback>
                                                </Col>
                                            </Form.Group>
                                            <Form.Group as={Row} className="mb-3" controlId="formHorizontalEmail">
                                                <Form.Label column sm={4}>
                                                    Quantité seuil:
                                                </Form.Label>
                                                <Col sm={8}>
                                                    <Form.Control
                                                        type="number"
                                                        placeholder="Quantité seuil"
                                                        aria-describedby="inputGroupPrepend"
                                                        size="sm"
                                                        min={0}
                                                        value={qteseuil}
                                                        onChange={(e) => setQteseuil(e.target.value)}
                                                        // disabled
                                                    />
                                                    <Form.Control.Feedback type="invalid">
                                                        Veuillez saisir la quantité seuil article.
                                                    </Form.Control.Feedback>
                                                </Col>
                                            </Form.Group>
                                            <Form.Group as={Row} className="mb-3" controlId="formHorizontalEmail">
                                                <Form.Label column sm={4}>
                                                    Prix d'achat:
                                                </Form.Label>
                                                <Col sm={8}>
                                                    <Form.Control
                                                        type="number"
                                                        placeholder="Prix d'achat"
                                                        aria-describedby="inputGroupPrepend"
                                                        size="sm"
                                                        min={0}
                                                        value={prixachat}
                                                        onChange={(e) => setPrixachats(e.target.value)}
                                                    />
                                                    <Form.Control.Feedback type="invalid">
                                                        Veuillez saisir le prix d'achat.
                                                    </Form.Control.Feedback>
                                                </Col>
                                            </Form.Group>
                                            <Form.Group as={Row} className="mb-3" controlId="formHorizontalEmail">
                                                <Form.Label column sm={4}>
                                                    Coéficient:
                                                </Form.Label>
                                                <Col sm={8}>
                                                    <Form.Control
                                                        type="number"
                                                        placeholder="Coeficient"
                                                        aria-describedby="inputGroupPrepend"
                                                        required
                                                        min={1}
                                                        size="sm"
                                                        step="any"
                                                        value={coeficient}
                                                        onChange={(e) => setCoeficients(e.target.value)}
                                                    />
                                                    <Form.Control.Feedback type="invalid">
                                                        Veuillez saisir le coeficient.
                                                    </Form.Control.Feedback>
                                                </Col>
                                            </Form.Group>

                                            <Form.Group as={Row} className="mb-3" controlId="formHorizontalEmail">
                                                <Form.Label column sm={4}>
                                                    Prix de vente:
                                                </Form.Label>
                                                <Col sm={8}>
                                                    <Form.Control
                                                        type="number"
                                                        placeholder="Prix de vente"
                                                        aria-describedby="inputGroupPrepend"
                                                        size="sm"
                                                        value={prixvente}
                                                        onChange={(e) => setPrixventes(e.target.value)}
                                                    />
                                                    <Form.Control.Feedback type="invalid">Please choose a username.</Form.Control.Feedback>
                                                </Col>
                                            </Form.Group>
                                            <Form.Group as={Row} className="mb-3" controlId="formHorizontalEmail">
                                                <Form.Label column sm={4}>
                                                    Etat stock:
                                                </Form.Label>
                                                <Col sm={8}>
                                                    <ButtonGroup>
                                                        {[
                                                            { name: 'Actif', value: '1' },
                                                            { name: 'Inactif', value: '0' }
                                                        ].map((radio, idx) => (
                                                            <ToggleButton
                                                                key={idx}
                                                                id={`radiorevision-${idx}`}
                                                                type="radio"
                                                                size="sm"
                                                                variant={idx % 2 ? 'outline-warning' : 'outline-warning'}
                                                                value={radio.value}
                                                                checked={actif === radio.value}
                                                                onChange={(e) => setActif(e.currentTarget.value)}
                                                            >
                                                                {radio.name}
                                                            </ToggleButton>
                                                        ))}
                                                    </ButtonGroup>
                                                </Col>
                                            </Form.Group>
                                            <Label className="custom-file-upload" title="Ajouter des images.">
                                                Cliquez ici pour ajouter des images
                                                <input type="file" accept=".jpg, .png, .jpeg" onChange={(e) => onChangeImage(e)} />
                                                <Images color="orange" size={50} />
                                            </Label>

                                            <Row>
                                                {imgs.map((d, index) => {
                                                    return (
                                                        <Col md={4}>
                                                            <Card>
                                                                <CardBody>
                                                                    <CardHeader>
                                                                        <ButtonGroup aria-label="Basic example">
                                                                            <Trash
                                                                                onClick={() => onDeleteImg(index, d)}
                                                                                color="red"
                                                                                size={15}
                                                                            />
                                                                        </ButtonGroup>
                                                                    </CardHeader>
                                                                    <img
                                                                        alt="index"
                                                                        key={index}
                                                                        className="imgartUpload rounded"
                                                                        src={d}
                                                                    ></img>
                                                                </CardBody>
                                                            </Card>
                                                        </Col>
                                                    );
                                                })}
                                            </Row>
                                        </CardBody>
                                    </Card>
                                </Col>
                            </Row>
                        </div>
                        <Modal.Footer>
                            <div className="ml-auto">
                                <ButtonGroup size="sm">
                                    <Button color="danger" onClick={handleClose}>
                                        <Backspace color="#000" size={20} />
                                    </Button>
                                    <Button type="submit" variant="ligth">
                                        <Save2Fill color="#807f7e" size={20} /> Enregister
                                    </Button>
                                </ButtonGroup>
                            </div>
                        </Modal.Footer>
                    </Form>
                </div>
            </Modal>
        </>
    );
}

export default EditArticle;
