import React, { useEffect, useState } from 'react';
import { useHistory, Link } from 'react-router-dom';
import { Spinner, ButtonGroup, Toast, OverlayTrigger, Modal, Tooltip, Navbar, Container, Nav } from 'react-bootstrap';
import { Card, CardBody } from 'reactstrap';
import { Trash, Printer, PlusCircleDotted, Folder, FileExcel, PencilFill } from 'react-bootstrap-icons';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
// import XlsxPopulate from "xlsx-populate";
import Tables from '../../tableData';
import common from '../../commonData';
import Axios from 'axios';
import EditFolder from './editFolder';
import AddFolder from './addFolder';

var dta = [];
function Familles() {
    const [data, setData] = useState([]);
    const history = useHistory();
    const [load, setLoad] = useState(true);
    const [isSave, setIsSave] = useState(false);
    const [msgGravity, setMsgravity] = useState('success');
    const [msg, setMsg] = useState('');
    const [datas, setDatas] = useState([]);
    const [showEditFolder, setShowEditFolder] = useState(false);
    const [idFolder, setIdFolder] = useState(0);
    const [showAddFolder, setShowAddFolder] = useState(false);
    const [addfolder, setAddFolder] = useState(false);

    useEffect(() => {
        if (localStorage.getItem('user-info')) {
            getFamille();
        } else {
            history.push('/login');
        }
    }, [history]);

    function getFamille() {
        localStorage.removeItem('bread');
        setLoad(true);
        Axios.get(common.ipapi + '/api/famille/indexstep')
            .then((response) => {
                setData(response.data);
                dta = response.data;
                setLoad(false);
            })
            .catch((errors) => {
                setLoad(false);
            });
    }

    function GetSortOrder(prop) {
        return function (a, b) {
            if (a[prop] > b[prop]) {
                return 1;
            } else if (a[prop] < b[prop]) {
                return -1;
            }
            return 0;
        };
    }

    function setAdds() {
        setAddFolder(true);
        setShowAddFolder(true);
    }

    function IsModif(ids) {
        setShowEditFolder(true);
        setIdFolder(ids);
    }

    function onDelete(id) {
        if (window.confirm("Voulez-vous supprimer cette famille d'article ?")) {
            Axios.delete(common.ipapi + '/api/famille/destroy/' + id).then((response) => {
                if (response.data.status === 1) {
                    const del = dta.filter((d) => d.id !== id);
                    dta = del;
                    setDatas(del);
                    setMsgravity('info');
                    setMsg(response.data.msg);
                    setIsSave(true);
                    setData(del);
                } else {
                    setMsgravity('danger');
                    setMsg(response.data.msg);
                    setIsSave(true);
                }
            });
        }
    }

    //-------------IMPRIMER LES LISTE ----------------
    function printListe() {
        setLoad(true);
        const doc = new jsPDF();
        var width = doc.internal.pageSize.getWidth();
        const data = [];
        let qte = 0;
        doc.setFontSize(16);
        common.ipapi === 'https://192.168.8.109:81'
            ? doc.addImage(common.logo, 'jpg', 14, 10, 30, 30)
            : doc.addImage(common.logo1, 'jpg', 14, 10, 30, 30);
        doc.text(datas.entrepot || 'TRACKYENTREPOT', 90, 14);
        doc.setFontSize(12);
        doc.setFillColor(0, 0, 0);
        doc.rect(65, 15, width - 80, 10, 'F');
        doc.setTextColor(255, 255, 255);
        doc.text('LISTE DES ARTICLES', 90, 22);
        doc.setTextColor(0, 0, 0);
        datas.sort(GetSortOrder('famille'));
        datas.forEach((row) => {
            qte += row.qtestock;
            data.push([
                row.qrbarre,
                row.descriptionart,
                row.famille || 'Néant',
                row.marque || 'Néant',
                new Intl.NumberFormat('de-DE', { style: 'decimal', currency: 'CFA' }).format(row.prixachat),
                new Intl.NumberFormat('de-DE', { style: 'decimal', currency: 'CFA' }).format(row.prixvente),
                row.qtestock
            ]);
        });
        data.push(['Total quantité', '', '', '', qte, '']);
        doc.autoTable({
            styles: { fillColor: [225, 206, 154], textColor: [255, 0, 0], halign: 'center', fontSize: 10 },
            columnStyles: {
                0: { halign: 'center', fillColor: [255, 255, 255], textColor: [0, 0, 0], fontSize: 8 },
                1: { halign: 'left', fillColor: [255, 255, 255], textColor: [0, 0, 0], fontSize: 8 },
                2: { halign: 'center', fillColor: [255, 255, 255], textColor: [0, 0, 0], fontSize: 8 },
                3: { halign: 'center', fillColor: [255, 255, 255], textColor: [0, 0, 0], fontSize: 8 },
                4: { halign: 'center', fillColor: [255, 255, 255], textColor: [0, 0, 0], fontSize: 8 },
                5: { halign: 'center', fillColor: [255, 255, 255], textColor: [0, 0, 0], fontSize: 8 },
                6: { halign: 'center', fillColor: [255, 255, 255], textColor: [0, 0, 0], fontSize: 8 }
            },
            theme: 'grid',
            startY: 35,
            fontSize: 9,
            // margin: { bottom: 35 },
            head: [['Code', 'Libellé', 'Familles', 'Marques', 'Prix achat', 'Prix vente', 'Qté']],
            body: data
        });
        const pageCount = doc.internal.getNumberOfPages();

        for (var i = 1; i <= pageCount; i++) {
            doc.setPage(i);
            doc.setFontSize(10);
            doc.text('Page ' + String(i) + ' sur ' + String(pageCount), 210 - 85, 285, null, null, 'right');
        }
        doc.save('article.pdf');
        setLoad(false);
    }

    //--------------- EXPORT VERS EXCEL ------------------
    function getSheetData(data, header) {
        var fields = Object.keys(data[0]);
        var sheetData = data.map(function (row) {
            return fields.map(function (fieldName) {
                return row[fieldName] ? row[fieldName] : '';
            });
        });
        sheetData.unshift(header);
        return sheetData;
    }

    async function saveAsExcel() {
        // const data = [];
        // datas.sort(GetSortOrder("famille"))
        // datas.forEach((row) => {
        //   data.push(
        //     { qrbarreshort: row.qrbarre, descriptionart: row.descriptionart, famille: row.famille || 'null', marque: row.marque || 'null',prvente: new Intl.NumberFormat('de-DE', { style: 'decimal', currency: 'CFA' }).format(row.prixachat),prix: new Intl.NumberFormat('de-DE', { style: 'decimal', currency: 'CFA' }).format(row.prixvente), codeart: row.qtestock }
        //   )
        // })
        // let header = ["Code", "Articles", "Famille","Marques","Prix achat","Prix vente", "Quantité"];
        // XlsxPopulate.fromBlankAsync().then(async (workbook) => {
        //   const sheet1 = workbook.sheet(0);
        //   const sheetData = getSheetData(data, header);
        //   const totalColumns = sheetData[0].length;
        //   sheet1.cell("A1").value(sheetData);
        //   const range = sheet1.usedRange();
        //   const endColumn = String.fromCharCode(64 + totalColumns);
        //   sheet1.row(1).style("bold", true);
        //   sheet1.range("A1:" + endColumn + "1").style("fill", "BFBFBF");
        //   range.style("border", true);
        //   return workbook.outputAsync().then((res) => {
        //     saveAs(res, "article.xlsx");
        //   });
        // });
    }

    const Operations = ({ tableManager, value, field, data, column, colIndex, rowIndex }) => {
        return (
            <div className="menudrop">
                <ButtonGroup aria-label="Basic example">
                    <PencilFill id="actionbtn" onClick={() => IsModif(data)} size={25} color="dark" />
                    <Trash id="actionbtn" onClick={() => onDelete(data.id)} color="red" size={25} />
                </ButtonGroup>
            </div>
        );
    };

    const ImagesFolder = ({ tableManager, value, field, data, column, colIndex, rowIndex }) => {
        return (
            <div>
                <OverlayTrigger
                    placement="right"
                    overlay={<Tooltip id="tooltip-disabled">Cliquer pour voir les détails du dossier</Tooltip>}
                >
                    {
                        <Link to={`/admin/famille/${data.sortlyid}/content`}>
                            <Folder color={data.etat === 1 ? '#ff7300' : 'black'} size={35} />
                        </Link>
                    }
                </OverlayTrigger>
            </div>
        );
    };

    const folderColumns = [
        {
            id: 1,
            label: '#',
            cellRenderer: ImagesFolder,
            pinned: true,
            width: '40px',
            minResizeWidth: 40,
            sortable: false
        },
        {
            id: 2,
            label: 'Libellé famille',
            field: 'libellefam'
        },
        {
            id: 3,
            label: 'Marques',
            field: 'libellemarq'
        },
        {
            id: 4,
            label: 'Nb Articles',
            field: 'nbartfam',
            width: '100px'
        },
        {
            id: 5,
            label: 'Quantité',
            field: 'qte',
            width: '100px'
        },
        {
            id: 6,
            label: 'Montant',
            field: 'nb',
            width: '100px'
        },
        {
            id: 7,
            label: 'Créer le',
            field: 'created_at',
            width: '190px'
        },
        {
            id: 8,
            label: 'créer par',
            field: 'name',
            width: '100px'
        },
        {
            id: 9,
            label: 'Actions',
            cellRenderer: Operations,
            pinned: true,
            width: '70px',
            minResizeWidth: 20,
            sortable: false
        }
    ];

    return (
        <>
            <Toast id="toast" onClose={() => setIsSave(false)} show={isSave} delay={3000} className={'bg-' + msgGravity} autohide>
                <Toast.Header>
                    <img src="holder.js/20x20?text=%20" className="rounded me-2" alt="" />
                    <strong className="me-auto">Info</strong>
                </Toast.Header>
                <Toast.Body className="dark">{msg}</Toast.Body>
            </Toast>
            <Modal show={load} size="sm" aria-labelledby="contained-modal-title-vcenter" centered>
                <Modal.Body>
                    Veuillez patienter.......
                    <Spinner animation="border" variant="success" />
                </Modal.Body>
            </Modal>
            {addfolder !== false ? (
                <AddFolder
                    setShowAddFolder={setShowAddFolder}
                    show={showAddFolder}
                    setIsSave={setIsSave}
                    isSave={false}
                    setDatas={setDatas}
                    setMsg={setMsg}
                    setMsgravity={setMsgravity}
                />
            ) : (
                <></>
            )}
            {idFolder !== 0 ? (
                <EditFolder
                    parentdata={idFolder}
                    setShowEditFolder={setShowEditFolder}
                    show={showEditFolder}
                    setIsSave={setIsSave}
                    isSave={false}
                    setDatas={setDatas}
                    setMsg={setMsg}
                    setMsgravity={setMsgravity}
                />
            ) : (
                <></>
            )}

            <div className="content contents">
                <Navbar expand="lg" bg="light" variant="light">
                    <Container>
                        <Navbar.Brand>LISTE DES FAMILLES </Navbar.Brand>
                        <Nav className="me-auto">
                            <OverlayTrigger overlay={<Tooltip id="tooltip-disabled">Créer une famille</Tooltip>}>
                                <Nav.Link onClick={() => setAdds()} title="Créer une famille">
                                    <PlusCircleDotted color="orange" size={25} /> Créer
                                </Nav.Link>
                            </OverlayTrigger>
                            <OverlayTrigger overlay={<Tooltip id="tooltip-disabled">Exporter vers Excel</Tooltip>}>
                                <Nav.Link title="Exporter vers excel" onClick={() => saveAsExcel()}>
                                    <FileExcel color="#0080ff" size={25} /> Excel
                                </Nav.Link>
                            </OverlayTrigger>
                            <OverlayTrigger overlay={<Tooltip id="tooltip-disabled">Exporter vers pdf</Tooltip>}>
                                <Nav.Link title="Exporter vers pdf" onClick={() => printListe()}>
                                    <Printer color="#0080ff" size={25} /> Imprimer
                                </Nav.Link>
                            </OverlayTrigger>
                        </Nav>
                    </Container>
                </Navbar>
                <Card>
                    <CardBody>
                        {data.length > 0 ? <Tables columns={folderColumns} rows={data} /> : <> </>}

                        {/* {
                  datas.length > 0 ? <Tables columns={columns} rows={datas}/> : <> </>
                } */}
                    </CardBody>
                </Card>
            </div>
        </>
    );
}

export default Familles;
