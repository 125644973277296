import React,{useEffect,useState} from 'react'
import {useHistory,Link, useParams} from 'react-router-dom'
import {Spinner,ButtonGroup,Toast,OverlayTrigger,Popover, Dropdown, Modal, Breadcrumb, Tooltip, Navbar, Container, Nav} from "react-bootstrap";
import { Row, Col} from "reactstrap";
import { PencilSquare,Eye, Trash,  Printer, PlusCircleDotted,FileText, Folder, FileExcel } from 'react-bootstrap-icons';
import 'jspdf-autotable'
import Tables from '../../tableData'
import common from '../../commonData'
import Axios from 'axios';
import EditArticle from '../articles/editLight';
import AddFolder from './addFolder';
import EditFolder from './editFolder';
import AddArticle from '../articles/addArticle';

var dta = []
function FamilleArticle() {
    const [previdar,setPrevIdar]=useState([])
    const [curentid,setCurentId]=useState(0)
    const [data,setData]=useState([])
    const history = useHistory();
    const [load,setLoad]=useState(true)
    const [isSave,setIsSave]=useState(false)
    const [showAdd,setShowAdd]=useState(false)
    const [add,setAdd] = useState(false)
    const [showAddFolder,setShowAddFolder]=useState(false)
    const [addfolder,setAddFolder] = useState(false)
    const [msgGravity,setMsgravity]=useState("success")
    const [msg,setMsg]=useState("")
    const [datas,setDatas]=useState([])
    const [ids,setIdd]=useState(0)
    const [showEdit, setShowEdit] = useState(false);
    const [showEditFolder, setShowEditFolder] = useState(false);
    const [idFolder,setIdFolder]=useState(0)
    let { id } = useParams();
    
    useEffect( ()=>{
      setCurentId(id)
      if (localStorage.getItem("user-info")) {
        (async function anyNameFunction() {
          await getFamille()
        })();
      }else{
        history.push("/login")
      }
    },[history,id])

    function getFamille(){
      var obj = JSON.parse(localStorage.getItem('bread'))
      setLoad(true)
      Axios.get(common.ipapi+'/api/famille/show/'+id)
      .then(response=>{
          setData(response.data[0])
          var tab = []
          if(obj !== null){
              for (let index = 0; index < obj.length; index++) {
                  obj[index].status = 0
              }
              const isLargeNumber = (element) => element.id == id;
              var r = obj.findIndex(isLargeNumber)
              if(r == -1){
                  obj.push({
                      id: id,
                      lib:response.data[1][0].libellefam,
                      status: 1
                  })
                  tab = obj.concat(tab)
              }else{
                  tab = obj.slice(0,r+1)
                  tab[tab.length-1].status = 1
              }
          }else{
              tab.push({
                  id: id,
                  lib: response.data[1][0].libellefam,
                  status: 1
              })
          }
          localStorage.setItem('bread',JSON.stringify(tab))
          setPrevIdar(tab)
          Axios.get(common.ipapi+'/api/famille/'+id+'/content')
          .then(responses=>{
            setDatas(response.data[0].concat(responses.data))
            dta = response.data[0].concat(responses.data)
            setLoad(false)
          })
          setLoad(false)
      }).catch(error=>{
        setMsg(error)
        setMsgravity("danger")
        setIsSave(true)
        setLoad(false)
      })
    }

    
  

    function IsModif(ids){
        setShowEditFolder(true)
        setIdFolder(ids)
    }
 
    function  onDelete(id) {
      if(window.confirm("Voulez-vous supprimer cette famille d'article ?")){

        Axios.delete(common.ipapi+'/api/famille/destroy/'+id)
        .then(response=>{
          if(response.data.status==1){
            const del = dta.filter(d=>d.id !== id)
            dta=del
            setDatas(del)
            setMsgravity('info')
            setMsg(response.data.msg)
            setIsSave(true)
            setData(del)
          }else{
            setMsgravity('danger')
            setMsg(response.data.msg)
            setIsSave(true)
          }
        })
      }
    }

    function setIds(id){
      setIdd(id)
      setShowEdit(true)
    }
    function setAdds(){
      setAddFolder(true)
      setShowAddFolder(true)
    }
    
    const ImagesFolder = ({ data }) => {
      return (
        <div>
          <OverlayTrigger placement='right' overlay={<Tooltip id="tooltip-disabled">Cliquer pour plus de détails</Tooltip>}>
          { data.codeart ? <Link to={`#/admin/article/fiche/${data.id}`}><FileText color='#ff7300' size={35}/></Link> : <Link to={`/admin/famille/${data.sortlyid}/content`} ><Folder color={data.etat==1 ? '#ff7300' : 'black'} size={35}/></Link> }
          </OverlayTrigger>
              
        </div>
      )
    }

    const Operations = ({ data }) => {
     return (
        <ButtonGroup aria-label="Basic example" >
            { data.codeart ? <PencilSquare onClick={()=>setIds(data.id)} size={20}  color='dark'/> : <PencilSquare id="actionbtn" onClick={()=>IsModif(data)}  size={20}  color='dark'/> }
            <Trash id="actionbtn" onClick={()=>onDelete(data.id)} color="red" size={20} />
        </ButtonGroup>
      )
    }


  const folderColumns = [
    {
      id: 1,  
      label: '#',
      cellRenderer: ImagesFolder,
      pinned:true,
      width: '40px',
      minResizeWidth: 40,
      sortable: false,
    },
    {
      id: 2,
      label: 'Code',
      field: 'qrbarre',
    },
    {
      id: 3,
      label: 'Libellé',
      field: 'libellefam',
      width: '250px',
    },

    {
      id: 4,
      label: 'Libellé',
      field: 'libellemarq',
      width: '250px',
    },
    {
      id: 5,
      label: 'Nb Articles',
      field: 'nbartfam',
      width: '100px',
    },
    {
      id: 6,
      label: 'Montant',
      field: 'nb',
      width: '100px',
    },
    {
      id: 7,
      label: 'Créer le',
      field: 'created_at',
      width: '190px',
    },
    {
      id: 8,
      label: 'créer par',
      field: 'name',
      width: '100px',
    },
    {
      id: 9,
      label: 'Code ',
      field: 'codefam',
    },
    {
      id: 10,  
      label: 'Actions',
      cellRenderer: Operations,
      pinned:true,
      width: '70px',
      minResizeWidth: 20,
      sortable: false,
    },
  ]  
   
    return (
      <>
        <Modal show={load} size="sm" aria-labelledby="contained-modal-title-vcenter" centered>
          <Modal.Body>Veuillez patienter.......<Spinner animation="border" variant="success"/></Modal.Body>
        </Modal>
      <Toast id="toast" onClose={() => setIsSave(false)} show={isSave} delay={3000} className={"bg-"+msgGravity} autohide>
                <Toast.Header>
                    <img
                            src="holder.js/20x20?text=%20"
                            className="rounded me-2"
                            alt=""
                    />
                    <strong className="me-auto">Info</strong>
                </Toast.Header>
                <Toast.Body className='dark'>{msg}</Toast.Body>
            </Toast>

      { ids != 0 ? <EditArticle id={ids} setShowEdit={setShowEdit}  show={showEdit} setIsSave={setIsSave} isSave={false} setDatas={setDatas} setMsg={setMsg} setMsgravity={setMsgravity}/> : <></> }
      { addfolder != false ? <AddFolder setShowAddFolder={setShowAddFolder}  show={showAddFolder} setIsSave={setIsSave} isSave={false} setDatas={setDatas} setMsg={setMsg} setMsgravity={setMsgravity}/> : <></> }
      { idFolder != 0 ? <EditFolder parentdata={idFolder} setShowEditFolder={setShowEditFolder}  show={showEditFolder} setIsSave={setIsSave} isSave={false} setDatas={setDatas} setMsg={setMsg} setMsgravity={setMsgravity}/> : <></> }
      { add != false ? <AddArticle setShowAdd={setShowAdd}  show={showAdd} setIsSave={setIsSave} isSave={false} setDatas={setDatas} setMsg={setMsg} setMsgravity={setMsgravity}/> : <></> }
     
      <div className="content">
        <Row>
          <Col md="12">
          <Navbar expand="lg" bg="light" variant="light">
          <Container>
            <Navbar.Brand><Breadcrumb>
                      {
                        
                        previdar.map((d, index) => (
                          d.status ? 
                            <Breadcrumb.Item key={index} active href={`#/admin/famille/${d.id}/content`}>{d.lib}</Breadcrumb.Item>
                            :
                            <Breadcrumb.Item key={index} href={`#/admin/famille/${d.id}/content`}>{d.lib}</Breadcrumb.Item>
                        ))
                      }
                    </Breadcrumb> </Navbar.Brand>
            <Nav className="me-auto">
              <OverlayTrigger overlay={<Tooltip id="tooltip-disabled">Créer une famille</Tooltip>}>
                <Nav.Link onClick={()=>setAdds()} title='Créer une famille'><PlusCircleDotted color="green" size={20}/> Créer</Nav.Link>
              </OverlayTrigger>
              <OverlayTrigger overlay={<Tooltip id="tooltip-disabled">Exporter vers Excel</Tooltip>}>
                <Nav.Link title='Exporter vers excel'><FileExcel color="#0080ff" size={20}/> Excel</Nav.Link>
              </OverlayTrigger>
              <OverlayTrigger overlay={<Tooltip id="tooltip-disabled">Exporter vers pdf</Tooltip>}>
                <Nav.Link  title='Exporter vers pdf'><Printer color="#0080ff" size={20}/> Imprimer</Nav.Link>
              </OverlayTrigger>
            </Nav>
          </Container>
        </Navbar>
            </Col>
            <Col md="12"><Tables columns={folderColumns} rows={datas}/></Col>
      </Row>
      </div>

      </>
    );
  }

export default FamilleArticle;