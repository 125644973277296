import React from 'react';
import { ButtonGroup, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { Trash, PencilFill, Basket, Printer, CardChecklist } from 'react-bootstrap-icons';
function ActionBtn({ data, handleShow, printFacture, setShowAddOrEdit, onDelete, printBl, setShowAdds }) {
    return (
        <div className="menudrops">
            <ButtonGroup aria-label="Basic example">
                <OverlayTrigger overlay={<Tooltip id="tooltip-disabled"> Facturer ligne</Tooltip>}>
                    {data.nofact == null ? (
                        <a onClick={(e) => handleShow([data.id, data.noprof, data.dateprof])}>
                            <CardChecklist color="blue" size={25} />
                        </a>
                    ) : (
                        <></>
                    )}
                </OverlayTrigger>
                <OverlayTrigger overlay={<Tooltip id="tooltip-disabled">{data.sortie == null ? 'Livrer' : ''}</Tooltip>}>
                    {data.sortie == null ? (
                        <a href="#/admin/factures" onClick={(e) => setShowAdds(data)}>
                            <Basket color="orange" size={25} />
                        </a>
                    ) : (
                        <></>
                    )}
                </OverlayTrigger>
                <OverlayTrigger overlay={<Tooltip id="tooltip-disabled"> Imprimer</Tooltip>}>
                    <a onClick={() => printFacture(data.id)}>
                        <Printer color="#000" size={25} />
                    </a>
                </OverlayTrigger>
                <OverlayTrigger overlay={<Tooltip id="tooltip-disabled">Modifier ligne</Tooltip>}>
                    {data.reglement == 0 ? (
                        <a onClick={() => setShowAddOrEdit(data.id)}>
                            <PencilFill size={25} color="green" />
                        </a>
                    ) : (
                        <></>
                    )}
                </OverlayTrigger>
                <OverlayTrigger overlay={<Tooltip id="tooltip-disabled">Supprimer ligne</Tooltip>}>
                    {data.reglement == 0 ? (
                        <a onClick={() => onDelete(data.id, data.nofact)}>
                            <Trash color="red" size={25} />
                        </a>
                    ) : (
                        <></>
                    )}
                </OverlayTrigger>
            </ButtonGroup>
        </div>
    );
}
export default ActionBtn;
