import { useHistory, Link } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import { Spinner, Form, Toast, Alert, Modal, ButtonGroup, Tabs, Tab } from 'react-bootstrap';
import { Button, Card, CardBody, Row, Col, CardTitle, CardText } from 'reactstrap';
import { Eye, Save2Fill, Backspace, TriangleHalf } from 'react-bootstrap-icons';
import Tables from './gridtables';
import { jsPDF } from 'jspdf';
import 'jspdf-autotable';
import NumberToLetter from '../../converter';
import Axios from 'axios';
import * as Yup from 'yup';
import { Formik } from 'formik';
import dateformat from 'dateformat';
import common from '../../commonData';
import AddFacture from './addFacture';
import MyAwesomeTable from './gridtablesAvoir';
var dta = [];

function ListeFacture() {
    const history = useHistory();
    const [noprof, setNoprof] = useState('');
    const [datep, setDateprof] = useState('');
    const [datas, setDatas] = useState([]);
    const [avoirData, setAvoirDatas] = useState([]);
    const [datasFact, setDataFact] = useState([]);
    const [datal, setDatal] = useState([]);
    const [load, setLoad] = useState(false);
    const [msg, setMsg] = useState('');
    const [isSave, setIsSave] = useState(false);
    const [datet, setDate] = useState('');
    const [factid, setFactid] = useState(0);
    const [msgGravity, setMsgravity] = useState('success');
    const [show, setShow] = useState(false);
    const [shows, setShows] = useState(false);
    const [actualise, setActualise] = useState(false);
    const [loading, setLoading] = useState(false);
    const [showAdd, setShowAdd] = useState(false);
    const [ids, setId] = useState(0);
    const [client, setClient] = useState('');
    const [datesort, setDatesort] = useState('');
    const [nofact, setFact] = useState(0);
    const [siqteinf, setQteInsufi] = useState(false);
    const [siqteinfQr, setQteInsufiQr] = useState([]);
    const [key, setKey] = useState('proforma');
    var ladate = new Date();
    const [statesParent, setState] = useState({
        nobon: null,
        datet: dateformat(ladate.getMonth() + 1 + '/' + ladate.getDate() + '/' + ladate.getFullYear(), 'yyyy-mm-dd'),
        sitva: '0',
        type: 'Proforma',
        clientid: '',
        engin: '',
        listeSelect: [],
        sortie: null
    });

    useEffect(() => {
        if (!localStorage.getItem('user-info')) {
            history.push('/login');
        }
        const log = common.search(common.userdroit, 'SAD');
        const log1 = common.search(common.userdroit, 'FAC');
        if (log === undefined) {
            if (log1 === undefined) {
                setMsg("Vous n'avez pas le droit d'effectuer cette action, merci de contacter l'administrateur");
                setMsgravity('danger');
                setIsSave(true);
                return;
            }
        }
        setLoad(true);
        (async function anyNameFunction() {
            getFacture();
            getFactureAvoir();
        })();
    }, [history, actualise]);

    function getFacture() {
        setLoad(true);
        Axios.get(common.ipapi + '/api/facture/index')
            .then((response) => {
                const res = response.data.datas[0].filter((element) => !element.nofact);
                setDatas(res);
                setDataFact(response.data.datas[0]);
                dta = response.data.datas;
                setLoad(false);
            })
            .catch((errors) => {
                setLoad(false);
            });
    }
    function getFactureAvoir() {
        setLoad(true);
        Axios.get(common.ipapi + '/api/avoir/index')
            .then((response) => {
                setAvoirDatas(response.data.datas);
                setLoad(false);
            })
            .catch((errors) => {
                setLoad(false);
            });
    }

    function GetSortOrder(prop) {
        return function (a, b) {
            if (a[prop] < b[prop]) {
                return 1;
            } else if (a[prop] > b[prop]) {
                return -1;
            }
            return 0;
        };
    }
    function setKeys(k) {
        setLoad(true);
        setKey(k);
        if (k === 'proforma') {
            const cloneProducts = [...datasFact];
            const res = cloneProducts.filter((element) => !element.nofact);
            setDatas(res);
        } else {
            const cloneProducts = [...datasFact];
            const res = cloneProducts.filter((element) => element.nofact);
            setDatas(res.sort(GetSortOrder('datefact')));
        }
        setLoad(false);
    }
    const handleClose = () => {
        setShow(false);
        setShows(false);
    };
    function handleShow(d) {
        setNoprof(d[1]);
        setFactid(d[0]);
        setDateprof(dateformat(d[2], 'yyyy-mm-dd'));
        setShow(true);
    }

    //------- IMPRIMER FACTURE -----------------
    function printBl(id) {
        let text =
            'Voulez-vous imprimer avec ou sans le prix ? \n Ok si vous voulez afficher le prix \n Annuler si vous ne voulez pas afficher le prix';
        let res = window.confirm(text);
        setLoad(true);
        var doc = new jsPDF();
        var width = doc.internal.pageSize.getWidth();
        const getEdit = Axios.get(common.ipapi + '/api/sortie/edit/' + id);
        Axios.all([getEdit])
            .then(
                Axios.spread((...responses) => {
                    const dataFactures = [];
                    var i = 0;
                    let h = 0;
                    let mont = 0,
                        t = 0;
                    let remise;
                    responses[0].data.datas.lignesort.forEach((row) => {
                        i++;
                        t = Math.round(row.prixvente * (row.pivot.quantite - row.pivot.quantiteretour));
                        mont += Math.round(row.prixvente * (row.pivot.quantite - row.pivot.quantiteretour));
                        res
                            ? dataFactures.push([
                                  { content: i },
                                  { content: row.pivot.descriptionart || row.descriptionart },
                                  { content: row.pivot.quantite - row.pivot.quantiteretour },
                                  {
                                      content: row.pivot.index_transaction ? row.pivot.index_transaction : 0
                                  },
                                  { content: formatCurrency(t) }
                              ])
                            : dataFactures.push([
                                  { content: i },
                                  { content: row.pivot.descriptionart || row.descriptionart },
                                  { content: row.pivot.quantite },
                                  {
                                      content: row.pivot.index_transaction ? row.pivot.index_transaction : 0
                                  }
                              ]);
                    });
                    if (res !== false) {
                        remise = responses[0].data.datas.client.remise
                            ? (responses[0].data.datas.client.remise.pourcentage / 100) * mont
                            : 0;
                        dataFactures.push([{ content: 'Montant HT', colSpan: 4 }, { content: formatCurrency(mont) }]);
                        if (responses[0].data.datas.client.remise) {
                            dataFactures.push([{ content: 'Remise', colSpan: 4 }, { content: formatCurrency(remise) }]);
                        }
                        dataFactures.push([
                            { content: 'Montant TVA', colSpan: 4 },
                            {
                                content: responses[0].data.datas.client.remise
                                    ? formatCurrency((mont - remise) * 0.18)
                                    : formatCurrency(mont * 0.18)
                            }
                        ]);
                        dataFactures.push([
                            { content: 'TOTAL', colSpan: 4, styles: { fontStyle: 'bold', fontSize: 12 } },
                            {
                                content: responses[0].data.datas.client.remise
                                    ? formatCurrency(mont - remise + (mont - remise) * 0.18)
                                    : formatCurrency(mont + mont * 0.18),
                                styles: { fontStyle: 'bold', fontSize: 12 }
                            }
                        ]);
                    }
                    common.ipapi == 'https://192.168.8.109:81' || common.ipapi == 'https://api.tracky-entrepot.com'
                        ? doc.addImage(common.logo, 'jpg', 14, 10, 30, 30)
                        : doc.addImage(common.logo1, 'jpg', 14, 10, 30, 30);
                    doc.setFontSize(16);
                    doc.text(
                        common.ipapi == 'https://192.168.8.109:81' || common.ipapi == 'https://api.tracky-entrepot.com'
                            ? 'CLUB AUTO'
                            : 'HR MOTORS',
                        90,
                        17
                    );
                    doc.setFontSize(12);
                    doc.setFillColor(225, 206, 154);
                    doc.rect(65, 19, width - 80, 10, 'F');
                    doc.setTextColor(0, 0, 0);
                    doc.text('BON DE LIVRAISON CLIENT', 90, 26);
                    doc.setFontSize(12);
                    doc.setDrawColor(0);
                    doc.rect(110, 40, 86, 28);
                    doc.setTextColor(0, 0, 0);
                    doc.setFontSize(10);
                    //doc.text(146, 50,"Imprimer le: "+dateformat(new Date(),"dd-mm-yyyy"))
                    doc.text(responses[0].data.datas.client ? 'Non: ' + responses[0].data.datas.client.raisonsocial : 'Nom', 112, 45);
                    doc.text(
                        responses[0].data.datas.client ? 'Télephone: ' + responses[0].data.datas.client.telcli : 'Télephone:',
                        112,
                        50
                    );
                    doc.text(
                        responses[0].data.datas.client ? 'Type client: ' + responses[0].data.datas.client.sisociete : 'Type client: ',
                        112,
                        55
                    );

                    doc.text('N° BL: ' + responses[0].data.datas.numbon, 15, 45);
                    doc.text('Date livraison: ' + dateformat(responses[0].data.datas.datesort, 'dd/mm/yyyy'), 15, 50);
                    doc.text('Engin: ' + responses[0].data.datas.engin, 15, 55);

                    doc.autoTable({
                        styles: {
                            fillColor: [0, 0, 0],
                            textColor: [255, 255, 255],
                            cellPadding: 1.5,
                            halign: 'center',
                            lineColor: [127, 143, 166]
                        },
                        columnStyles: {
                            0: {
                                halign: 'center',
                                fillColor: [255, 255, 255],
                                textColor: [0, 0, 0],
                                cellPadding: 0.75,
                                fontSize: 10
                            },
                            1: {
                                halign: 'left',
                                fillColor: [255, 255, 255],
                                textColor: [0, 0, 0],
                                cellPadding: 0.75,
                                fontSize: 10
                            },
                            2: {
                                halign: 'center',
                                fillColor: [255, 255, 255],
                                textColor: [0, 0, 0],
                                cellPadding: 0.75,
                                fontSize: 10
                            },
                            3: {
                                halign: 'center',
                                fillColor: [255, 255, 255],
                                textColor: [0, 0, 0],
                                cellPadding: 0.75,
                                fontSize: 10
                            },
                            4: {
                                halign: 'center',
                                fillColor: [255, 255, 255],
                                textColor: [0, 0, 0],
                                cellPadding: 0.75,
                                fontSize: 10
                            }
                        },
                        theme: 'grid',
                        startY: 75,
                        margin: { bottom: 35 },
                        horizontalPageBreak: true,
                        didDrawCell: function (data) {
                            h = data.cell.y + 20;
                        },
                        head: res ? [['Ordre', 'Article', 'Quantité', 'Index', 'Montant']] : [['Ordre', 'Article', 'Quantité', 'Index']],
                        body: dataFactures
                    });
                    var finalY = doc.lastAutoTable.finalY;
                    if (finalY > 217) {
                        doc.addPage();
                    }
                    const pageCount = doc.internal.getNumberOfPages();
                    for (var u = 1; u <= pageCount; u++) {
                        doc.setPage(u);
                        doc.setFontSize(10);
                        doc.text('Page ' + String(u) + ' sur ' + String(pageCount), 210 - 18, 292, null, null, 'right');
                    }
                    doc.text('Arrêté à la somme de:', 15, 228);
                    doc.setFontSize(8);
                    doc.text(NumberToLetter(Math.round(mont + mont * 0.18)).toUpperCase() + ' FCFA', 15, 234); //235tSize(10)
                    doc.setDrawColor(0);
                    doc.rect(14, 230, width - 28, 6);
                    doc.text('Visa direction:', 15, 245);
                    doc.setDrawColor(0);
                    doc.rect(14, 246, 60, 22);
                    doc.text('Visa client:', 135, 245);
                    doc.setDrawColor(0);
                    doc.rect(135, 246, 60, 22);
                    doc.setFontSize(8);

                    doc.setLineWidth(1.5);
                    doc.setFontSize(8);
                    doc.setLineWidth(1.5);
                    doc.setDrawColor(0, 0, 0);
                    doc.line(0, 275, width, 275);
                    doc.text(
                        'Siège social - Marcory Boulevard de Marseille - Zone 4 - 26 BP 695 Abidjan 26 - Tel.: 27 21 36 39 79 / Fax: 27 21 34 59 59',
                        105,
                        280,
                        null,
                        'center'
                    );
                    doc.text(
                        'RCCM N°CI-ABJ-2012-A-5995 - Compte SIB N° CI 007 0106885056950010020 / Cel: 07 08 29 40 40 /  Email: vicaso11@hotmail.fr ',
                        105,
                        285,
                        null,
                        'center'
                    );
                    doc.setLineWidth(0.5);
                    setLoad(false);
                    doc.setFontSize(8);
                    doc.setTextColor(254, 0, 0);
                    doc.text('* Aucun produit ne peut être retourner 15 jours après livraison', 14, doc.lastAutoTable.finalY + 3);
                    //doc.autoPrint({variant: 'non-conform'});
                    doc.save('bl.pdf');
                })
            )
            .catch((errors) => {
                setLoad(false);
            });
    }

    function saveFact(data) {
        if (window.confirm('Voulez-vous facturer cette ligne?')) {
            setLoad(true);
            var formData = new FormData();
            formData.append('date', data.date);
            formData.append('numbon', data.numbon);
            Axios({
                url: common.ipapi + '/api/facture/updatefact/' + factid,
                method: 'POST',
                headers: {
                    'Content-Type': 'form-data'
                },
                data: formData
            }).then((response) => {
                if (response.data.status === 1) {
                    setFactid(0);
                    setDate('');
                    setShow(false);
                    setMsg(response.data.msg);
                    setMsgravity('info');
                    setIsSave(true);
                    setLoad(false);
                    getFacture();
                } else {
                    setMsg(response.data.msg);
                    setIsSave(true);
                    setMsgravity('danger');
                    setLoad(false);
                }
            });
        }
    }

    async function onDelete(id, nofac) {
        const log = common.search(common.userdroit, 'SAD');
        const log1 = common.search(common.userdroit, 'SFAC');
        if (log == undefined) {
            if (log1 == undefined) {
                setMsg("Vous n'avez pas le droit d'effectuer cette action, merci de contacter l'administrateur");
                setMsgravity('danger');
                setIsSave(true);
                return;
            }
        }
        if (window.confirm('Voulez-vous supprimer cette ligne ?')) {
            setLoad(true);
            await Axios.delete(common.ipapi + '/api/facture/destroy/' + id).then((response) => {
                if (response.data.status === 1) {
                    if (nofac !== 0 && nofac !== null) {
                        const del = datas.filter((d) => d.id != id);
                        setDatas(del);
                    } else {
                        const del = datas.filter((d) => d.id != id);
                        setDatas(del);
                    }

                    setMsg(response.data.msg);
                    setMsgravity('info');
                    setIsSave(true);
                    setLoad(false);
                } else {
                    setMsgravity('danger');
                    setMsg(response.data.msg);
                    setIsSave(true);
                    setLoad(false);
                }
            });
        }
    }

    async function onDeleteAvoir(id) {
        const log = common.search(common.userdroit, 'SAD');
        const log1 = common.search(common.userdroit, 'SFAC');
        if (log == undefined) {
            if (log1 == undefined) {
                setMsg("Vous n'avez pas le droit d'effectuer cette action, merci de contacter l'administrateur");
                setMsgravity('danger');
                setIsSave(true);
                return;
            }
        }
        if (window.confirm('Voulez-vous supprimer cette ligne ?')) {
            setLoad(true);
            await Axios.delete(common.ipapi + '/api/avoir/destroy/' + id).then((response) => {
                if (response.data.status === 1) {
                    setMsg(response.data.msg);
                    setMsgravity('info');
                    setIsSave(true);
                    const del = avoirData.filter((d) => d.id != id);
                    setAvoirDatas(del);
                    setLoad(false);
                } else {
                    setMsgravity('danger');
                    setMsg(response.data.msg);
                    setIsSave(true);
                    setLoad(false);
                }
            });
        }
    }
    function formatCurrency(value) {
        return new Intl.NumberFormat('de-DE').format(Math.round(value));
    }
    //------- IMPRIMER FACTURE -----------------
    async function printFacture(id) {
        let text =
            'Voulez-vous imprimer avec ou sans entête ? \n Ok si vous voulez avec entête \n Annuler si vous ne voulez pas avec entête';
        let res = window.confirm(text);
        var doc = new jsPDF();
        var width = doc.internal.pageSize.getWidth();
        var dataFactures = [];
        var data = [];
        var resp = [];
        var i = 0;
        var h = 0;
        let mont = 0;
        let t = 0;
        let montht = 0;
        let montbrut = 0;
        let remis = 0;
        let remisChange = false;
        var siremise = false;
        let valremise = 0;
        setLoad(true);

        await Axios.get(common.ipapi + '/api/facture/edit/' + id)
            .then((responses) => {
                resp = resp.concat(responses.data);
                responses.data.lignefacture.forEach((row) => {
                    if (row.pivot.remise != 0) {
                        siremise = true;
                    }
                    valremise = responses.data.lignefacture[0].pivot.remise;
                    if (responses.data.lignefacture[0].pivot.remise != row.pivot.remise) {
                        remisChange = true;
                    }
                    data.push({
                        descriptionart: row.pivot.descriptionart || row.descriptionartfr,
                        quantite: row.pivot.quantite,
                        prix: formatCurrency(row.pivot.prix),
                        siremise: row.pivot.remise,
                        pourcentremise:
                            responses.data.client && responses.data.client.remise
                                ? responses.data.client.remise.pourcentage
                                : row.pivot.remise,
                        remis:
                            responses.data.client && responses.data.client.remise
                                ? row.pivot.prix * row.pivot.quantite * (responses.data.client.remise.pourcentage / 100)
                                : row.pivot.prix * row.pivot.quantite * (row.pivot.remise / 100),
                        t:
                            responses.data.client && responses.data.client.remise
                                ? row.pivot.prix * row.pivot.quantite -
                                  row.pivot.prix * row.pivot.quantite * (responses.data.client.remise.pourcentage / 100)
                                : row.pivot.prix * row.pivot.quantite - row.pivot.prix * row.pivot.quantite * (row.pivot.remise / 100)
                    });
                });
                data.forEach((row) => {
                    montbrut += row.t + row.remis;
                    montht += row.t;
                    remis += row.remis;
                    siremise
                        ? remisChange
                            ? dataFactures.push([
                                  { content: row.descriptionart },
                                  { content: row.quantite },
                                  { content: row.prix },
                                  { content: row.pourcentremise },
                                  { content: row.remis },
                                  { content: formatCurrency(row.t) }
                              ])
                            : dataFactures.push([
                                  { content: row.descriptionart },
                                  { content: row.quantite },
                                  { content: row.prix },
                                  { content: row.remis },
                                  { content: formatCurrency(row.t) }
                              ])
                        : dataFactures.push([
                              { content: row.descriptionart },
                              { content: row.quantite },
                              { content: row.prix },
                              { content: formatCurrency(row.t) }
                          ]);
                });

                responses.data.sitva == 1 ? (mont = montht) : (mont = montht + montht * 0.18);
            })
            .catch((errors) => {
                setLoad(false);
            });
        if (res === true) {
            doc.setFillColor(255, 127, 0);
            common.ipapi == 'https://192.168.8.109:81' || common.ipapi == 'https://api.tracky-entrepot.com'
                ? doc.addImage(common.logo, 'jpg', 14, 10, 30, 30)
                : doc.addImage(common.logo1, 'jpg', 14, 10, 30, 30);
            doc.setFontSize(16);
            doc.setFontSize(20);
            doc.setTextColor(0, 0, 0);
            doc.text(resp[0].nofact != null ? 'FACTURE CLIENT' : 'PROFORMA CLIENT', 113, 26);
            doc.setFontSize(10);
            doc.setTextColor(0, 0, 0);
            doc.text(113, 43, 'Abidjan le: ' + dateformat(new Date(), 'dd/mm/yyyy'));
            doc.rect(110, 45, 86, 27);
            doc.text(resp[0].client ? 'Nom: ' + resp[0].client.raisonsocial : 'Nom: NEANT', 113, 50);
            doc.text(resp[0].client ? 'Télephone: ' + resp[0].client.telcli : 'Télephone: NEANT', 113, 55);
            doc.text('Compte contribuable: ' + resp[0].client.nocomptec, 113, 60);
            resp[0].nofact ? doc.text('N° pièce: ' + resp[0].nofact, 113, 65) : doc.text('N° proforma: ' + resp[0].noprof, 113, 65);
            doc.text('Date pièce: ' + dateformat(resp[0].nofact != null ? resp[0].datefact : resp[0].dateprof, 'dd/mm/yyyy'), 113, 70);
            doc.text('Engin: ' + resp[0].engin, 15, 70);
            doc.setFontSize(10);

            doc.autoTable({
                styles: {
                    fillColor: [0, 0, 0],
                    textColor: [255, 255, 255],
                    lineColor: [0, 0, 0],
                    cellPadding: 1.25,
                    halign: 'center',
                    lineColor: [127, 143, 166]
                },
                columnStyles: siremise
                    ? remisChange
                        ? {
                              0: {
                                  halign: 'left',
                                  fillColor: [255, 255, 255],
                                  textColor: [0, 0, 0],
                                  cellPadding: 0.75,
                                  fontSize: 10
                              },
                              1: {
                                  halign: 'center',
                                  fillColor: [255, 255, 255],
                                  textColor: [0, 0, 0],
                                  cellPadding: 0.75,
                                  fontSize: 10
                              },
                              2: {
                                  halign: 'center',
                                  fillColor: [255, 255, 255],
                                  textColor: [0, 0, 0],
                                  cellPadding: 0.75,
                                  fontSize: 10
                              },
                              3: {
                                  halign: 'center',
                                  fillColor: [255, 255, 255],
                                  textColor: [0, 0, 0],
                                  cellPadding: 0.75,
                                  fontSize: 10
                              },
                              4: {
                                  halign: 'center',
                                  fillColor: [255, 255, 255],
                                  textColor: [0, 0, 0],
                                  cellPadding: 0.75,
                                  fontSize: 10
                              },
                              5: {
                                  halign: 'center',
                                  fillColor: [255, 255, 255],
                                  textColor: [0, 0, 0],
                                  cellPadding: 0.75,
                                  fontSize: 10
                              }
                          }
                        : {
                              0: {
                                  halign: 'left',
                                  fillColor: [255, 255, 255],
                                  textColor: [0, 0, 0],
                                  cellPadding: 0.75,
                                  fontSize: 10
                              },
                              1: {
                                  halign: 'center',
                                  fillColor: [255, 255, 255],
                                  textColor: [0, 0, 0],
                                  cellPadding: 0.75,
                                  fontSize: 10
                              },
                              2: {
                                  halign: 'center',
                                  fillColor: [255, 255, 255],
                                  textColor: [0, 0, 0],
                                  cellPadding: 0.75,
                                  fontSize: 10
                              },
                              3: {
                                  halign: 'center',
                                  fillColor: [255, 255, 255],
                                  textColor: [0, 0, 0],
                                  cellPadding: 0.75,
                                  fontSize: 10
                              },
                              4: {
                                  halign: 'center',
                                  fillColor: [255, 255, 255],
                                  textColor: [0, 0, 0],
                                  cellPadding: 0.75,
                                  fontSize: 10
                              }
                          }
                    : {
                          0: {
                              halign: 'left',
                              fillColor: [255, 255, 255],
                              textColor: [0, 0, 0],
                              cellPadding: 0.75,
                              fontSize: 10
                          },
                          1: {
                              halign: 'center',
                              fillColor: [255, 255, 255],
                              textColor: [0, 0, 0],
                              cellPadding: 0.75,
                              fontSize: 10
                          },
                          2: {
                              halign: 'center',
                              fillColor: [255, 255, 255],
                              textColor: [0, 0, 0],
                              cellPadding: 0.75,
                              fontSize: 10
                          },
                          3: {
                              halign: 'center',
                              fillColor: [255, 255, 255],
                              textColor: [0, 0, 0],
                              cellPadding: 0.75,
                              fontSize: 10
                          }
                      },
                didDrawCell: function (data) {
                    h = data.cell.y + 20;
                },
                theme: 'grid',
                startY: 75,
                fontSize: 9,
                margin: { bottom: 40 },
                head: siremise
                    ? remisChange
                        ? [['Article', 'Quantité', 'Prix unit.', 'Remise(%)', 'Mont. remise', 'Montant']]
                        : [['Article', 'Quantité', 'Prix unit.', 'Remise(' + valremise + '%)', 'Montant']]
                    : [['Article', 'Quantité', 'Prix unit.', 'Montant']],
                body: dataFactures
                //foot: [['Montant TTC','','','','','',formatCurrency(mont+mont*0.18)]],
            });
            doc.autoTable({
                styles: {
                    fillColor: [255, 255, 255],
                    textColor: [0, 0, 0],
                    cellPadding: 0.8,
                    lineColor: [0, 0, 0],
                    halign: 'center'
                },
                columnStyles: siremise
                    ? {
                          0: {
                              halign: 'center',
                              fillColor: [255, 255, 255],
                              textColor: [0, 0, 0],
                              cellPadding: 0.75,
                              fontSize: 10
                          },
                          1: {
                              halign: 'center',
                              fillColor: [255, 255, 255],
                              textColor: [0, 0, 0],
                              cellPadding: 0.75,
                              fontSize: 10
                          },
                          2: {
                              halign: 'center',
                              fillColor: [255, 255, 255],
                              textColor: [0, 0, 0],
                              cellPadding: 0.75,
                              fontSize: 10
                          },
                          3: {
                              halign: 'center',
                              fillColor: [255, 255, 255],
                              textColor: [0, 0, 0],
                              cellPadding: 0.75,
                              fontSize: 10
                          },
                          4: {
                              halign: 'center',
                              fillColor: [255, 255, 255],
                              textColor: [0, 0, 0],
                              cellPadding: 0.75,
                              fontSize: 10
                          }
                      }
                    : {
                          0: {
                              halign: 'center',
                              fillColor: [255, 255, 255],
                              textColor: [0, 0, 0],
                              cellPadding: 0.75,
                              fontSize: 10
                          },
                          1: {
                              halign: 'center',
                              fillColor: [255, 255, 255],
                              textColor: [0, 0, 0],
                              cellPadding: 0.75,
                              fontSize: 10
                          },
                          2: {
                              halign: 'center',
                              fillColor: [255, 255, 255],
                              textColor: [0, 0, 0],
                              cellPadding: 0.75,
                              fontSize: 10
                          }
                      },
                margin: { bottom: 35 },
                theme: 'grid',
                startY: doc.lastAutoTable.finalY + 3,
                head: siremise
                    ? [['TOTAL BRUT', 'REMISE', 'TOTAL NET', resp[0].sitva == 1 ? 'TVA EXONERE (18%)' : 'TVA (18%)', 'TOTAL TTC']]
                    : [['TOTAL NET', resp[0].sitva == 1 ? 'TVA  EXONERE (18%)' : 'TVA (18%)', 'TOTAL TTC']],
                body: siremise
                    ? [
                          [
                              formatCurrency(montbrut),
                              formatCurrency(remis),
                              formatCurrency(montht),
                              formatCurrency(montht * 0.18),
                              formatCurrency(mont)
                          ]
                      ]
                    : [[formatCurrency(montht), formatCurrency(montht * 0.18), formatCurrency(mont)]]
            });
            var finalY = doc.lastAutoTable.finalY;
            if (finalY > 217) {
                doc.addPage();
            }
            const pageCount = doc.internal.getNumberOfPages();
            for (var u = 1; u <= pageCount; u++) {
                doc.setPage(u);
                doc.setFontSize(10);
                doc.text('Page ' + String(u) + ' sur ' + String(pageCount), 210 - 18, 292, null, null, 'right');
            }
            doc.text('Arrêté à la somme de:', 15, 229);
            doc.setFontSize(8);
            doc.text(NumberToLetter(Math.round(mont)).toUpperCase() + ' FCFA', 15, 236);
            doc.rect(14, 231, width - 28, 8);
            doc.setFontSize(10);
            doc.text('Visa direction:', 15, 246);
            // doc.rect(14, 247, 60, 22);
            doc.text('Visa client:', 135, 246);
            // doc.rect(135, 247, 60, 22);
            doc.setFontSize(8);

            doc.setLineWidth(1.5);
            doc.setFontSize(8);
            doc.setLineWidth(1.5);
            doc.setDrawColor(0, 0, 0);
            doc.line(0, 275, width, 275);
            doc.text(
                'Siège social - Marcory Boulevard de Marseille - Zone 4 - 26 BP 695 Abidjan 26 - Tel.: 27 21 36 39 79 / Fax: 27 21 34 59 59',
                105,
                280,
                null,
                'center'
            );
            doc.text(
                'RCCM N°CI-ABJ-2012-A-5995 - Compte SIB N° CI 007 0106885056950010020 / Cel: 07 08 29 40 40 /  Email: vicaso11@hotmail.fr ',
                105,
                285,
                null,
                'center'
            );
            doc.setFontSize(8);
            doc.setTextColor(254, 0, 0);
            // doc.text('* Aucun produit ne peut être retourner 15 jours après livraison', 14, doc.lastAutoTable.finalY + 3);
            doc.text('* Aucun produit ne peut être retourné', 14, doc.lastAutoTable.finalY + 3);

            setLoad(false);
            //doc.autoPrint({variant: 'non-conform'});
            doc.save('facture.pdf');
        } else {
            doc.setFillColor(255, 127, 0);
            doc.setFillColor(0, 0, 0);
            doc.setTextColor(255, 255, 255);
            doc.setFontSize(10);
            doc.setTextColor(0, 0, 0);
            doc.text(119, 60, 'Abidjan le: ' + dateformat(new Date(), 'dd/mm/yyyy'));
            doc.rect(115, 61, 81, 27);
            doc.text(resp[0].client ? 'Nom: ' + resp[0].client.raisonsocial : 'Nom: NEANT', 119, 66);
            doc.text(resp[0].client ? 'Télephone: ' + resp[0].client.telcli : 'Télephone: NEANT', 119, 71);
            doc.text('Compte contribuable: ' + resp[0].client.nocomptec, 119, 76);
            resp[0].nofact ? doc.text('N° facture: ' + resp[0].nofact, 119, 81) : doc.text('N° proforma: ' + resp[0].noprof, 119, 81);
            doc.text('Date facture: ' + dateformat(resp[0].datefact, 'dd/mm/yyyy'), 119, 86);
            doc.text('Engin: ' + resp[0].engin, 15, 86);
            doc.setFontSize(10);

            doc.autoTable({
                styles: {
                    fillColor: [0, 0, 0],
                    textColor: [255, 255, 255],
                    lineColor: [0, 0, 0],
                    cellPadding: 1.25,
                    halign: 'center'
                },
                columnStyles: siremise
                    ? remisChange
                        ? {
                              0: {
                                  halign: 'left',
                                  fillColor: [255, 255, 255],
                                  textColor: [0, 0, 0],
                                  cellPadding: 0.75,
                                  fontSize: 10
                              },
                              1: {
                                  halign: 'center',
                                  fillColor: [255, 255, 255],
                                  textColor: [0, 0, 0],
                                  cellPadding: 0.75,
                                  fontSize: 10
                              },
                              2: {
                                  halign: 'center',
                                  fillColor: [255, 255, 255],
                                  textColor: [0, 0, 0],
                                  cellPadding: 0.75,
                                  fontSize: 10
                              },
                              3: {
                                  halign: 'center',
                                  fillColor: [255, 255, 255],
                                  textColor: [0, 0, 0],
                                  cellPadding: 0.75,
                                  fontSize: 10
                              },
                              4: {
                                  halign: 'center',
                                  fillColor: [255, 255, 255],
                                  textColor: [0, 0, 0],
                                  cellPadding: 0.75,
                                  fontSize: 10
                              },
                              5: {
                                  halign: 'center',
                                  fillColor: [255, 255, 255],
                                  textColor: [0, 0, 0],
                                  cellPadding: 0.75,
                                  fontSize: 10
                              }
                          }
                        : {
                              0: {
                                  halign: 'left',
                                  fillColor: [255, 255, 255],
                                  textColor: [0, 0, 0],
                                  cellPadding: 0.75,
                                  fontSize: 10
                              },
                              1: {
                                  halign: 'center',
                                  fillColor: [255, 255, 255],
                                  textColor: [0, 0, 0],
                                  cellPadding: 0.75,
                                  fontSize: 10
                              },
                              2: {
                                  halign: 'center',
                                  fillColor: [255, 255, 255],
                                  textColor: [0, 0, 0],
                                  cellPadding: 0.75,
                                  fontSize: 10
                              },
                              3: {
                                  halign: 'center',
                                  fillColor: [255, 255, 255],
                                  textColor: [0, 0, 0],
                                  cellPadding: 0.75,
                                  fontSize: 10
                              },
                              4: {
                                  halign: 'center',
                                  fillColor: [255, 255, 255],
                                  textColor: [0, 0, 0],
                                  cellPadding: 0.75,
                                  fontSize: 10
                              }
                          }
                    : {
                          0: {
                              halign: 'left',
                              fillColor: [255, 255, 255],
                              textColor: [0, 0, 0],
                              cellPadding: 0.75,
                              fontSize: 10
                          },
                          1: {
                              halign: 'center',
                              fillColor: [255, 255, 255],
                              textColor: [0, 0, 0],
                              cellPadding: 0.75,
                              fontSize: 10
                          },
                          2: {
                              halign: 'center',
                              fillColor: [255, 255, 255],
                              textColor: [0, 0, 0],
                              cellPadding: 0.75,
                              fontSize: 10
                          },
                          3: {
                              halign: 'center',
                              fillColor: [255, 255, 255],
                              textColor: [0, 0, 0],
                              cellPadding: 0.75,
                              fontSize: 10
                          }
                      },
                didDrawCell: function (data) {
                    h = data.cell.y + 20;
                },
                theme: 'grid',
                startY: 90,
                fontSize: 9,
                margin: { bottom: 40 },
                head: siremise
                    ? remisChange
                        ? [['Article', 'Quantité', 'Prix unit.', 'Remise(%)', 'Mont. remise', 'Montant']]
                        : [['Article', 'Quantité', 'Prix unit.', 'Remise(' + valremise + '%)', 'Montant']]
                    : [['Article', 'Quantité', 'Prix unit.', 'Montant']],
                body: dataFactures
            });
            doc.autoTable({
                styles: {
                    fillColor: [255, 255, 255],
                    textColor: [0, 0, 0],
                    cellPadding: 0.8,
                    lineColor: [0, 0, 0],
                    halign: 'center'
                },
                columnStyles: siremise
                    ? {
                          0: {
                              halign: 'center',
                              fillColor: [255, 255, 255],
                              textColor: [0, 0, 0],
                              cellPadding: 0.75,
                              fontSize: 10
                          },
                          1: {
                              halign: 'center',
                              fillColor: [255, 255, 255],
                              textColor: [0, 0, 0],
                              cellPadding: 0.75,
                              fontSize: 10
                          },
                          2: {
                              halign: 'center',
                              fillColor: [255, 255, 255],
                              textColor: [0, 0, 0],
                              cellPadding: 0.75,
                              fontSize: 10
                          },
                          3: {
                              halign: 'center',
                              fillColor: [255, 255, 255],
                              textColor: [0, 0, 0],
                              cellPadding: 0.75,
                              fontSize: 10
                          },
                          4: {
                              halign: 'center',
                              fillColor: [255, 255, 255],
                              textColor: [0, 0, 0],
                              cellPadding: 0.75,
                              fontSize: 10
                          }
                      }
                    : {
                          0: {
                              halign: 'center',
                              fillColor: [255, 255, 255],
                              textColor: [0, 0, 0],
                              cellPadding: 0.75,
                              fontSize: 10
                          },
                          1: {
                              halign: 'center',
                              fillColor: [255, 255, 255],
                              textColor: [0, 0, 0],
                              cellPadding: 0.75,
                              fontSize: 10
                          },
                          2: {
                              halign: 'center',
                              fillColor: [255, 255, 255],
                              textColor: [0, 0, 0],
                              cellPadding: 0.75,
                              fontSize: 10
                          }
                      },
                margin: { bottom: 35 },
                theme: 'grid',
                startY: doc.lastAutoTable.finalY + 4,
                head: siremise
                    ? [['TOTAL BRUT', 'REMISE', 'TOTAL NET', resp[0].sitva == 1 ? 'TVA EXONERE (18%)' : 'TVA (18%)', 'TOTAL TTC']]
                    : [['TOTAL NET', resp[0].sitva == 1 ? 'TVA  EXONERE (18%)' : 'TVA (18%)', 'TOTAL TTC']],
                body: siremise
                    ? [
                          [
                              formatCurrency(montbrut),
                              formatCurrency(remis),
                              formatCurrency(montht),
                              formatCurrency(montht * 0.18),
                              formatCurrency(mont)
                          ]
                      ]
                    : [[formatCurrency(montht), formatCurrency(montht * 0.18), formatCurrency(mont)]]
            });

            var finalY = doc.lastAutoTable.finalY;
            if (finalY > 217) {
                doc.addPage();
            }
            const pageCount = doc.internal.getNumberOfPages();
            for (var u = 1; u <= pageCount; u++) {
                doc.setPage(u);
                doc.setFontSize(10);
                doc.text('Page ' + String(u) + ' sur ' + String(pageCount), 210 - 18, 292, null, null, 'right');
            }
            doc.text('Arrêté à la somme de:', 15, 229);
            doc.setFontSize(8);
            doc.text(NumberToLetter(Math.round(mont)).toUpperCase() + ' FCFA', 15, 236);
            doc.rect(14, 231, width - 28, 8);
            doc.setFontSize(10);
            doc.text('Visa direction:', 15, 246);
            doc.text('Visa client:', 135, 246);
            doc.setFontSize(8);
            doc.setLineWidth(1.5);
            setLoad(false);
            doc.setFontSize(8);
            doc.setTextColor(254, 0, 0);
            // doc.text('* Aucun produit ne peut être retourner 15 jours après livraison', 14, doc.lastAutoTable.finalY + 3);
            doc.text('* Aucun produit ne peut être retourné', 14, doc.lastAutoTable.finalY + 3);

            //doc.autoPrint({variant: 'non-conform'});
            doc.save('facture.pdf');
        }
    }
    function viewSortie(ids, typm) {
        setLoad(true);
        Axios.get(common.ipapi + '/api/facture/edit/' + ids).then((response) => {
            setDatal(response.data.lignefacture);
            setFact(response.data.nofact == 0 || response.data.nofact == null ? response.data.noprof : response.data.nofact);
            setClient(response.data.client ? response.data.client.raisonsocial : '');
            setDatesort(
                response.data.datefact == null
                    ? dateformat(response.data.dateprof, 'yyyy-mm-dd')
                    : dateformat(response.data.datefact, 'yyyy-mm-dd')
            );
            setShows(true);
            // setTypeModal(typm);
            setLoad(false);
        });
    }

    function groupBy(tableauObjets) {
        let t = [];
        setLoad(true);
        return tableauObjets.reduce(function (res, value, index) {
            t.push({
                id: value.pivot.id,
                article_id: value.id,
                qrbarre: value.qrbarre,
                quantite: value.pivot.quantite,
                descriptionart: value.pivot.descriptionart,
                prix: value.pivot.prix,
                remise: value.pivot.remise
            });
            return t;
        }, {});
    }
    function setShowAddOrEdit(id) {
        if (id) {
            Axios.get(common.ipapi + '/api/facture/edit/' + id)
                .then((response) => {
                    if (response.data) {
                        setState({
                            nobon: response.data.nofact == 0 || response.data.nofact == null ? response.data.noprof : response.data.nofact,
                            datet:
                                response.data.nofact == 0 || response.data.nofact == null
                                    ? dateformat(response.data.dateprof, 'yyyy-mm-dd')
                                    : dateformat(response.data.datefact, 'yyyy-mm-dd'),
                            sitva: response.data.sitva,
                            type: response.data.nofact == null || response.data.nofact == 0 ? 'Proforma' : 'Facture',
                            clientid: response.data.client_id,
                            engin: response.data.engin,
                            listeSelect: response.data.lignefacture.length > 0 ? groupBy(response.data.lignefacture) : [],
                            sortie: response.data.sortie
                        });
                        setId(id);
                        setShowAdd(true);
                    }
                    setLoad(false);
                })
                .catch((errors) => {
                    setLoad(false);
                });
        } else {
            setState({
                nobon: null,
                datet: dateformat(ladate.getMonth() + 1 + '/' + ladate.getDate() + '/' + ladate.getFullYear(), 'yyyy-mm-dd'),
                sitva: '0',
                type: 'Proforma',
                clientid: '',
                engin: '',
                listeSelect: [],
                sortie: null
            });
            setId(0);
            setShowAdd(true);
        }
    }

    return (
        <>
            <Toast id="toast" onClose={() => setIsSave(false)} show={isSave} delay={3000} className={'bg-' + msgGravity} autohide>
                <Toast.Header>
                    <img src="holder.js/20x20?text=%20" className="rounded me-2" alt="" />
                    <strong className="me-auto">{'bg-' + msgGravity}</strong>
                </Toast.Header>
                <Toast.Body className="dark">{msg}</Toast.Body>
            </Toast>
            <Modal show={load} size="sm" aria-labelledby="contained-modal-title-vcenter" centered>
                <Modal.Body>
                    Veuillez patienter.......
                    <Spinner animation="border" variant="success" />
                </Modal.Body>
            </Modal>

            {showAdd ? (
                <AddFacture
                    setShowAdd={setShowAdd}
                    show={showAdd}
                    setIsSave={setIsSave}
                    isSave={isSave}
                    setMsg={setMsg}
                    setMsgravity={setMsgravity}
                    setActualise={setActualise}
                    actualise={actualise}
                    ids={ids}
                    statesParent={statesParent}
                />
            ) : (
                <></>
            )}
            {common.search(common.userdroit, 'SAD') !== undefined || common.search(common.userdroit, 'FAC') !== undefined ? (
                <div className="content contents">
                    <div id="tablecontent">
                        <Tabs id="uncontrolled-tab-exemple" onSelect={(k) => setKeys(k)}>
                            <Tab eventKey="proforma" title="Mes proformas">
                                <Tables
                                    rows={datas}
                                    isLoading={load}
                                    onDelete={onDelete}
                                    viewSortie={viewSortie}
                                    setShowAddOrEdit={setShowAddOrEdit}
                                    printFacture={printFacture}
                                    printBl={printBl}
                                    handleShow={handleShow}
                                    key={key}
                                    setLoading={setLoading}
                                    loading={loading}
                                    setIsSave={setIsSave}
                                    setMsg={setMsg}
                                    setMsgravity={setMsgravity}
                                />
                            </Tab>
                            <Tab eventKey="facture" title="Mes factures">
                                <Tables
                                    rows={datas}
                                    isLoading={load}
                                    onDelete={onDelete}
                                    viewSortie={viewSortie}
                                    setShowAddOrEdit={setShowAddOrEdit}
                                    printFacture={printFacture}
                                    printBl={printBl}
                                    handleShow={handleShow}
                                    key={key}
                                    setLoading={setLoading}
                                    loading={loading}
                                    setIsSave={setIsSave}
                                    setMsg={setMsg}
                                    setMsgravity={setMsgravity}
                                />
                            </Tab>
                            <Tab eventKey="avoirs" title="Mes avoirs">
                                <MyAwesomeTable
                                    rows={avoirData}
                                    isLoading={load}
                                    onDelete={onDelete}
                                    getFactureAvoir={getFactureAvoir}
                                    key={key}
                                    setLoading={setLoading}
                                    loading={loading}
                                    setIsSave={setIsSave}
                                    setMsg={setMsg}
                                    setMsgravity={setMsgravity}
                                    onDeleteAvoir={onDeleteAvoir}
                                />
                            </Tab>
                        </Tabs>
                    </div>
                    <Row>
                        <Col md="12">
                            <Formik
                                initialValues={{
                                    numbon: null,
                                    date: datet,
                                    submit: null
                                }}
                                // enableReinitialize
                                validationSchema={Yup.object().shape({
                                    date: Yup.date().required(common.msg)
                                })}
                                onSubmit={async (values) => {
                                    try {
                                        saveFact(values);
                                        // setSubmitting(false);
                                    } catch (err) {
                                        setMsg('Une erreur à eu lieu');
                                        setMsgravity('danger');
                                        setIsSave(true);
                                        setLoad(false);
                                    }
                                }}
                            >
                                {({ handleBlur, handleChange, handleSubmit, values, errors }) => (
                                    <Modal show={show} onHide={handleClose}>
                                        <Modal.Header>
                                            FACTURATION <Backspace onClick={handleClose} color="red" size={25} />
                                        </Modal.Header>
                                        <Form noValidate onSubmit={handleSubmit}>
                                            <Modal.Body>
                                                <Form.Group as={Row} className="mb-3" controlId="formHorizontalEmail">
                                                    <Form.Label column sm={4}>
                                                        N° proforma:
                                                    </Form.Label>
                                                    <Col sm={8}>
                                                        <Form.Control
                                                            type="text"
                                                            placeholder="N° proforma"
                                                            aria-describedby="inputGroupPrepend"
                                                            size="sm"
                                                            value={noprof}
                                                            disabled
                                                        />
                                                    </Col>
                                                </Form.Group>
                                                <Form.Group as={Row} className="mb-3" controlId="formHorizontalEmail">
                                                    <Form.Label column sm={4}>
                                                        Date proforma:
                                                    </Form.Label>
                                                    <Col sm={8}>
                                                        <Form.Control
                                                            type="date"
                                                            placeholder="Date proforma"
                                                            aria-describedby="inputGroupPrepend"
                                                            required
                                                            disabled
                                                            size="sm"
                                                            value={datep}
                                                        />
                                                    </Col>
                                                </Form.Group>
                                                <Form.Group as={Row} className="mb-3" controlId="formHorizontalEmail">
                                                    <Form.Label column sm={4}>
                                                        N° facture:
                                                    </Form.Label>
                                                    <Col sm={8}>
                                                        <Form.Control
                                                            id="numbon"
                                                            type="text"
                                                            placeholder="Numero facture"
                                                            aria-describedby="inputGroupPrepend"
                                                            size="sm"
                                                            value={values.numbon}
                                                            onBlur={handleBlur}
                                                            onChange={handleChange}
                                                        />
                                                    </Col>
                                                </Form.Group>
                                                <Form.Group as={Row} className="mb-3" controlId="formHorizontalEmail">
                                                    <Form.Label column sm={4}>
                                                        Date édition:
                                                    </Form.Label>
                                                    <Col sm={8}>
                                                        <Form.Control
                                                            id="date"
                                                            type="date"
                                                            placeholder="Date édition"
                                                            aria-describedby="inputGroupPrepend"
                                                            required
                                                            size="sm"
                                                            value={values.date}
                                                            onBlur={handleBlur}
                                                            onChange={handleChange}
                                                        />
                                                        {errors.date && <div id="feedback">{errors.date}</div>}
                                                    </Col>
                                                </Form.Group>
                                            </Modal.Body>
                                            <Modal.Footer>
                                                <div className="ml-auto">
                                                    <ButtonGroup size="sm">
                                                        <Button color="secondary" onClick={handleClose}>
                                                            <Backspace color="red" size={20} />
                                                        </Button>
                                                        <Button type="submit" color="warning">
                                                            <Save2Fill color="#807f7e" size={20} /> Enregistrer
                                                        </Button>
                                                    </ButtonGroup>
                                                </div>
                                                {/* <Button type="submit" variant="success" ><Save color="white" size={20}/> Valider</Button> */}
                                            </Modal.Footer>
                                        </Form>
                                    </Modal>
                                )}
                            </Formik>
                        </Col>
                    </Row>
                </div>
            ) : (
                <Card style={{ width: '25rem' }} className="centerCard">
                    <CardBody>
                        <CardTitle tag="h5">
                            <TriangleHalf size={60} color="red"></TriangleHalf> Avertissement
                        </CardTitle>
                        <CardText>Vous n'êtes pas autorisé à utiliser cette section. Merci de contacter votre administrateur</CardText>
                    </CardBody>
                </Card>
            )}
            <Modal show={shows} onHide={handleClose} size="lg">
                <Form>
                    <Modal.Header>
                        INFO FACTURE CLIENT
                        <Backspace onClick={handleClose} color="red" size={20} />
                    </Modal.Header>
                    <Modal.Body>
                        {siqteinf ? (
                            <Alert key="danger" transition="Fade" variant="danger">
                                <TriangleHalf color="red" size={30} />{' '}
                                <b>
                                    {siqteinfQr.map((elt, index) => {
                                        return elt + '<>';
                                    })}
                                </b>
                            </Alert>
                        ) : (
                            <></>
                        )}

                        <div id="lightbody">
                            <Card>
                                <CardBody>
                                    <Form.Group as={Row} className="mb-3" controlId="formHorizontalEmail">
                                        <Form.Label column sm={2}>
                                            N° facture:
                                        </Form.Label>
                                        <Col sm={10}>
                                            <Form.Control
                                                type="text"
                                                placeholder="N° facture"
                                                aria-describedby="inputGroupPrepend"
                                                disabled
                                                size="sm"
                                                value={nofact}
                                            />
                                        </Col>
                                    </Form.Group>
                                    <Form.Group as={Row} className="mb-3" controlId="formHorizontalEmail">
                                        <Form.Label column sm={2}>
                                            Client:
                                        </Form.Label>
                                        <Col sm={10}>
                                            <Form.Control
                                                type="text"
                                                aria-describedby="inputGroupPrepend"
                                                disabled
                                                size="sm"
                                                value={client}
                                            />
                                        </Col>
                                    </Form.Group>
                                    <Form.Group as={Row} className="mb-3" controlId="formHorizontalEmail">
                                        <Form.Label column sm={2}>
                                            Date sortie:
                                        </Form.Label>
                                        <Col sm={10}>
                                            <Form.Control
                                                type="date"
                                                placeholder="Date sortie"
                                                aria-describedby="inputGroupPrepend"
                                                disabled
                                                size="sm"
                                                value={datesort}
                                            />
                                        </Col>
                                    </Form.Group>
                                </CardBody>
                            </Card>

                            <table className="table table-striped-hover table-sm">
                                <thead>
                                    <tr>
                                        {/* <th scope="col">#</th> */}
                                        <th scope="col">Code art.</th>
                                        <th scope="col">Descr. art.</th>
                                        <th scope="col">Qté</th>
                                        <th scope="col">Prix unit.</th>
                                        <th scope="col">Actions</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {datal.map((d, index) => {
                                        return (
                                            <tr key={index} hover="true">
                                                {/* <td>{d.id}</td> */}
                                                <td>{d.qrbarre}</td>
                                                <td>{d.descriptionart}</td>
                                                <td>{d.pivot.quantite}</td>
                                                <td>{d.pivot.prix || 'Néant'}</td>
                                                <td>
                                                    <ButtonGroup aria-label="Basic example">
                                                        <Link
                                                            to={`#/admin/article/fiche/${d.id}`}
                                                            title="Voir tous les détails de l'article"
                                                        >
                                                            <Eye size={20} color="royalblue" />
                                                        </Link>
                                                    </ButtonGroup>
                                                </td>
                                            </tr>
                                        );
                                    })}
                                </tbody>
                            </table>
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <Backspace onClick={handleClose} color="red" size={20} />
                    </Modal.Footer>
                </Form>
            </Modal>
        </>
    );
}

export default ListeFacture;
