import { useHistory } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import { Spinner, Modal, Toast } from 'react-bootstrap';
import { Card, CardText, CardTitle, CardBody } from 'reactstrap';
import { TriangleHalf } from 'react-bootstrap-icons';

import Axios from 'axios';
import Tables from './gridtablesValeur';
import common from '../../commonData';
import EditArticle from './editLight';
import FilterModal from './filterModal';
import AddArticle from './addArticle';
import FicheArticle from './ficheArticle';
import ImportArticle from './importArticle';

var dta = [];
function Articles() {
    const history = useHistory();
    const [datas, setDatas] = useState([]);
    const [csv, setCsv] = useState([]);
    const [load, setLoad] = useState(false);
    const [isSave, setIsSave] = useState(false);
    const [msgGravity, setMsgravity] = useState('success');
    const [msg, setMsg] = useState('');
    const [show, setShow] = useState(false);
    const [showprogress, setShowProgress] = useState(false);
    const [showView, setShowView] = useState(false);
    const [id, setId] = useState(0);
    const [idview, setIdView] = useState(0);
    const [showEdit, setShowEdit] = useState(false);
    const [showAdd, setShowAdd] = useState(false);
    const [showFilterModal, setShowFilterModal] = useState(false);
    const [title, setTitle] = useState('MES ARTICLES EN STOCK');
    const [min, setMin] = useState(0);
    const [zero, setZero] = useState(0);
    const [editData, setEditData] = useState();

    useEffect(() => {
        if (!localStorage.getItem('user-info')) {
            history.push('/login');
        }
        const log = common.search(common.userdroit, 'SAD');
        const log1 = common.search(common.userdroit, 'ART');
        if (log === undefined) {
            if (log1 === undefined) {
                setMsg("Vous n'avez pas le droit d'effectuer cette action, merci de contacter l'administrateur");
                setMsgravity('danger');
                setIsSave(true);
                return;
            }
        }

        (async function anyNameFunction() {
            await getArticle(1);
        })();
    }, [history]);

    async function getArticle(p) {
        setLoad(true);
        await Axios.get(common.ipapi + '/api/article/indexvaleur')
            .then((response) => {
                setDatas(response.data);
                setLoad(false);
            })
            .catch((errors) => {
                setLoad(false);
            });
    }

    return (
        <>
            <Toast id="toast" onClose={() => setIsSave(false)} show={isSave} delay={3000} className={'bg-' + msgGravity} autohide>
                <Toast.Header>
                    <img src="holder.js/20x20?text=%20" className="rounded me-2" alt="" />
                    <strong className="me-auto">Info</strong>
                </Toast.Header>
                <Toast.Body className="dark">{msg}</Toast.Body>
            </Toast>
            <Modal show={load} size="sm" aria-labelledby="contained-modal-title-vcenter" centered>
                <Modal.Body>
                    Veuillez patienter.......
                    <Spinner animation="border" variant="success" />
                </Modal.Body>
            </Modal>
            {common.search(common.userdroit, 'SAD') !== undefined || common.search(common.userdroit, 'ART') !== undefined ? (
                <div className="content contents">
                    <div id="tablecontent">
                        <Tables rows={datas} isLoading={load} />
                    </div>
                </div>
            ) : (
                <Card style={{ width: '25rem' }} className="centerCard">
                    <CardBody>
                        <CardTitle tag="h5">
                            <TriangleHalf size={60} color="red"></TriangleHalf> Avertissement
                        </CardTitle>
                        <CardText>Vous n'êtes pas autorisé à utiliser cette section. Merci de contacter votre administrateur</CardText>
                    </CardBody>
                </Card>
            )}
        </>
    );
}

export default Articles;
