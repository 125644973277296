import CryptoJS from 'crypto-js';
const secretPass = 'XkhZG4fW2t2Ws';
let USERNAME;
let USERID;
let ACCES_TOKEN;
let USER_PHOTO;
let USER_DROIT;
let CLIENTID;
const decryptData = (text) => {
    const bytes = CryptoJS.AES.decrypt(text, secretPass);
    const data = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
    return data;
};
if (localStorage.getItem('user-info')) {
    var descrypt = decryptData(localStorage.getItem('user-info'));
    USERNAME = descrypt.name;
    USERID = descrypt.id;
    ACCES_TOKEN = descrypt.access_token;
    USER_PHOTO = descrypt.photo;
    USER_DROIT = descrypt.list_droit_user ? descrypt.list_droit_user : [];
    CLIENTID = descrypt.clients_id;
}

var image = new Image();
image.src = process.env.PUBLIC_URL + '/logo.png';
var image1 = new Image();
image1.src = process.env.PUBLIC_URL + '/logohr.jpg';
var data = {
    //ipapi: 'https://192.168.8.109:81',
    //ipapi: 'https://192.168.1.100:81',
    ipapi: 'https://api.tracky-entrepot.com',
    //ipapi: 'http://localhost:8000',
    logo: image,
    logo1: image1,
    defaultimg: process.env.PUBLIC_URL + '/icon.png',
    userid: USERID,
    user: USERNAME,
    usertof: USER_PHOTO,
    userdroit: USER_DROIT,
    userclientid: CLIENTID,
    sortly_link: 'https://api.sortly.co/api/v1/',
    sortly_secret_key: 'sk_sortly_L_yMNqAa_jLrdRzxTEAB',
    msg: 'Saisir obligatoirement cette donnée',
    search: function search(obj, key) {
        if (typeof obj !== 'object' || obj === null) {
            return obj === key ? obj : undefined;
        }
        for (const [k, v] of Object.entries(obj)) {
            const result = search(v, key);
            if (result !== undefined) {
                return result;
            }
        }
        return undefined;
    }
};
export default data;
