import React, { useEffect, useState, useRef } from 'react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { PlusCircleDotted, X } from 'react-bootstrap-icons';
import { Card, CardBody } from 'reactstrap';

export function AvoirListAdd({ products, onChangeProductQuantity, onRemoveProduct, onChangePrestation, onChangePrix, AddLigneAvoir }) {
    return (
        <Card>
            <CardBody>
                <table className="table table-striped-hover table-sm">
                    <thead>
                        <tr>
                            <th scope="col">Ordre</th>
                            <th scope="col">DESIGNATION</th>
                            <th scope="col">QUANTITÉ</th>
                            <th scope="col">MONTANT</th>
                            <th scope="col">
                                <OverlayTrigger overlay={<Tooltip id="tooltip-disabled">Ajouter une nouvelle ligne</Tooltip>}>
                                    <PlusCircleDotted size={25} color="#51cbce" onClick={AddLigneAvoir} />
                                </OverlayTrigger>
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {products.map((product, index) => {
                            return (
                                <tr key={index} hover="true">
                                    <td>{index + 1}</td>
                                    <td>
                                        <input
                                            type="textarea"
                                            rows={4}
                                            className="prestation"
                                            value={product.libelle_avoir}
                                            onChange={(event) => onChangePrestation(index, event)}
                                        />
                                    </td>

                                    <td>
                                        <input
                                            type="number"
                                            className="prix"
                                            step="any"
                                            value={product.qte_avoir}
                                            onChange={(event) => onChangeProductQuantity(index, event)}
                                        />
                                    </td>
                                    <td>
                                        <input
                                            type="number"
                                            className="prix"
                                            step="any"
                                            value={product.montant_avoir || 0}
                                            onChange={(event) => onChangePrix(index, event)}
                                        />
                                    </td>
                                    <td>
                                        <X color="red" size={20} onClick={() => onRemoveProduct(index, product.id)}></X>
                                    </td>
                                </tr>
                            );
                        })}
                    </tbody>
                </table>
            </CardBody>
        </Card>
    );
}

export function Summary({ subTotal, sitva }) {
    //return  mttc;
    var total = subTotal;
    const ht = total;
    const tva = total * 0.18;
    total = sitva == 1 ? total : total + tva;
    return (
        <p id="lightr">
            Total HT: <b>{formatCurrency(ht)} </b>|| Total TVA {sitva == 1 ? 'exonéré' : ''}: <b>{formatCurrency(tva)} </b>|| Total TTC:{' '}
            <b>{formatCurrency(total)}</b>
        </p>
    );
}
function formatCurrency(value) {
    return new Intl.NumberFormat().format(Math.round(value));
}
