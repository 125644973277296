import React from "react";
import GridTable from '@nadavshaar/react-grid-table';
import 'assets/css/tableGar.css';

export function MyAwesomeTable ({columns,rows, isLoading}){
    const config = { search: 'Recherche:', totalRows: 'Total ligne:', rows: 'Ligne:', selected: 'Selected', rowsPerPage: 'Ligne par page:', page: 'Page:', of: 'sur', prev: 'Prec', next: 'Suiv', columnVisibility: 'Colonne visible' }
    return (
        <div id="table2">
            <GridTable 
                columns={columns} 
                texts={config} 
                isLoading={isLoading} 
                //onRowsRequest={getArticle}
                rows={rows} 
                // onRowsChange={setRows}
                // totalRows={totalRows}
                // onTotalRowsChange={setTotalRows}
                minColumnResizeWidth={30}/>
        </div>
    )
} 
export function MyAwesomeTablemin ({columns,rows, isLoading}){
    const config = { search: 'Recherche:', totalRows: 'Total ligne:', rows: 'Ligne:', selected: 'Selected', rowsPerPage: 'Ligne par page:', page: 'Page:', of: 'sur', prev: 'Prec', next: 'Suiv', columnVisibility: 'Colonne visible' }
    return (
        <div id="table3">
            <GridTable 
                columns={columns} 
                texts={config} 
                isLoading={isLoading} 
                //onRowsRequest={getArticle}
                rows={rows} 
                // onRowsChange={setRows}
                // totalRows={totalRows}
                // onTotalRowsChange={setTotalRows}
                minColumnResizeWidth={30}/>
        </div>
    )
} 