import React, { useEffect, useState } from 'react';
import { Form, InputGroup, Toast, Spinner, ButtonGroup, Modal } from 'react-bootstrap';
import { useParams, useHistory } from 'react-router-dom';
import { Card, Button, Input, Row, Col, CardBody, InputGroupText, InputGroupAddon } from 'reactstrap';
// import BarcodeScannerComponent from "react-qr-barcode-scanner";
import Axios from 'axios';
import { ArrowLeft, Save2Fill, Star } from 'react-bootstrap-icons';
import common from '../../commonData';

var count = 0;

function EditArticle() {
    const [validated, setValidated] = useState(false);
    const [codeart, setCodeart] = useState('');
    const [sortlyid, setSortlyid] = useState('');
    const [descriptionart, setDescriptionart] = useState('');
    const [descriptionartfr, setDescriptionartFr] = useState('');
    const [qtestock, setQtestock] = useState(0);
    const [qteseuil, setQteseuil] = useState(0);
    const [coeficient, setCoeficient] = useState(1.25);
    const [prixachat, setPrixachat] = useState(0);
    const [prixvente, setPrixvente] = useState(0);
    const [emplacement, setEmplacement] = useState('');
    const [img, setImg] = useState('');
    const [file, setFile] = useState('');
    const [files, setFil] = useState('');
    const [famille_id, setFamille] = useState(0);
    const [marque_id, setMarque] = useState(0);
    const [entrepot_id, setEntrepot] = useState(0);
    const [marque, setMarqueData] = useState([]);
    const [famille, setfamilleData] = useState([]);
    const [entrepot, setEntrData] = useState([]);
    const [load, setLoad] = useState(true);
    const [isSave, setIsSave] = useState(false);
    const [msgGravity, setMsgravity] = useState('info');
    const [msg, setMsg] = useState('');
    const [shows, setShows] = useState(false);
    const [barQrCode, setBarCode] = useState('');
    const [barQrCodecourt, setLongBar] = useState('');
    const history = useHistory();

    let { id } = useParams();
    useEffect(() => {
        if (!localStorage.getItem('user-info')) {
            history.push('/dashboard');
        }
        (async function anyNameFunction() {
            setLoad(true);
            const getArticle = Axios.get(common.ipapi + '/api/article/edit/' + id);
            const getFamille = Axios.get(common.ipapi + '/api/famille/index');
            const getMarque = Axios.get(common.ipapi + '/api/marque/index');
            const getEntrepot = Axios.get(common.ipapi + '/api/entrepot/index');
            await Axios.all([getFamille, getMarque, getArticle, getEntrepot])
                .then(
                    Axios.spread((...responses) => {
                        setfamilleData(responses[0].data);
                        setMarqueData(responses[1].data);
                        setEntrData(responses[3].data);
                        setCodeart(responses[2].data.codeart);
                        setDescriptionart(responses[2].data.descriptionart);
                        setQtestock(responses[2].data.qtestock ? responses[2].data.qtestock : 0);
                        setQteseuil(responses[2].data.qteseuil ? responses[2].data.qteseuil : 0);
                        setCoeficient(responses[2].data.coeficient);
                        setBarCode(responses[2].data.qrbarre);
                        setLongBar(responses[2].data.qrbarreshort);
                        setSortlyid(responses[2].data.sortlyid);
                        setDescriptionartFr(responses[2].data.descriptionartfr || '');
                        setEmplacement(responses[2].data.emplacement || '');
                        setImg(responses[2].data.urlart);
                        if (responses[2].data.famille) {
                            setFamille(responses[2].data.famille.id);
                        }
                        if (responses[2].data.marque) {
                            setMarque(responses[2].data.marque.id);
                        }
                        if (responses[2].data.entrepot) {
                            setEntrepot(responses[2].data.entrepot.id);
                        }
                        setPrixachat(responses[2].data.prixachat ? responses[2].data.prixachat : 0);
                        setPrixvente(responses[2].data.prixachat * responses[2].data.coeficient);
                        setLoad(false);
                    })
                )
                .catch((errors) => {
                    // react on errors.
                });
        })();
    }, []);

    function saveArticle(e) {
        setLoad(true);
        var formData = new FormData();
        formData.append('file', files);
        formData.append('codeart', codeart);
        formData.append('qrbarreshort', barQrCodecourt);
        formData.append('qrbarre', barQrCode);
        formData.append('descriptionart', descriptionart);
        formData.append('descriptionartfr', descriptionartfr);
        formData.append('qtestock', qtestock);
        formData.append('qteseuil', qteseuil);
        formData.append('coeficient', coeficient);
        formData.append('famille_id', famille_id);
        formData.append('marque_id', marque_id);
        formData.append('entrepot_id', entrepot_id);
        formData.append('user_id', common.userid);
        formData.append('prixachat', prixachat);
        formData.append('prixvente', prixvente);
        formData.append('emplacement', emplacement);

        Axios({
            url: common.ipapi + '/api/article/update/' + id,
            method: 'POST',
            headers: {
                'Content-Type': 'form-data'
            },
            data: formData
        })
            .then((res) => {
                setIsSave(true);
                setMsg(res.data.msg);
                setMsgravity('success');
                setLoad(false);
                count++;
            })
            .catch((error) => {
                setIsSave(true);
                setMsg(error);
                setMsgravity('danger');
                setLoad(false);
            });
    }
    function setFiles(e) {
        setFile(e.target.value);
        setFil(e.target.files[0]);
        e.preventDefault();
    }

    const handleSubmit = (event) => {
        event.preventDefault();
        const form = event.currentTarget;
        if (form.checkValidity() === false) {
            event.stopPropagation();
        }
        setValidated(true);
        saveArticle();
    };
    const setCoeficients = (tag) => {
        setCoeficient(tag);
        setPrixvente(tag * prixachat);
    };
    const setPrixachats = (tag) => {
        setPrixachat(tag);
        setPrixvente(coeficient * tag);
    };
    function cancel(e) {
        e.preventDefault();
        window.history.back();
    }
    const handleClose = () => {
        setShows(false);
    };
    return (
        <>
            {load ? <Spinner animation="grow" variant="warning" /> : ''}
            <Toast id="toast" onClose={() => setIsSave(false)} show={isSave} delay={3000} bg={msgGravity} autohide>
                <Toast.Header>
                    <img src="holder.js/20x20?text=%20" className="rounded me-2" alt="" />
                    <strong className="me-auto">Info</strong>
                </Toast.Header>
                <Toast.Body>{msg}</Toast.Body>
            </Toast>
            <div className="content">
                <Form noValidate validated={validated} onSubmit={handleSubmit}>
                    <Card>
                        <div className="ml-auto">
                            <ButtonGroup aria-label="Basic example">
                                <Button type="submit" color="" onClick={cancel}>
                                    <ArrowLeft color="red" size={20} /> Annuler
                                </Button>
                                <Button type="submit" color="default">
                                    <Save2Fill color="#ff7300" size={20} /> Enregister
                                </Button>
                            </ButtonGroup>
                        </div>
                    </Card>

                    <Row>
                        <Col sm={4}>
                            <Card>
                                <CardBody>
                                    {img ? (
                                        img.indexOf('sortly') == -1 ? (
                                            <img className="d-block w-100" src={common.ipapi + img}></img>
                                        ) : (
                                            <img className="d-block w-100" src={img}></img>
                                        )
                                    ) : (
                                        ''
                                    )}
                                </CardBody>
                            </Card>
                        </Col>
                        <Col>
                            <Card>
                                <CardBody>
                                    <Form.Group as={Col} md="" controlId="validationCustomUsername">
                                        <InputGroup hasValidation>
                                            <InputGroup.Text id="inputGroupPrepend">Code 1:</InputGroup.Text>
                                            <Form.Control
                                                type="text"
                                                placeholder="Code article"
                                                aria-describedby="inputGroupPrepend"
                                                disabled
                                                size="sm"
                                                value={codeart}
                                            />
                                        </InputGroup>
                                    </Form.Group>

                                    <Form.Group as={Col} md="" controlId="validationCustomUsername">
                                        <InputGroup hasValidation>
                                            <InputGroup.Text id="inputGroupPrepend">Code sortly:</InputGroup.Text>
                                            <Form.Control
                                                type="text"
                                                placeholder="Code sortly"
                                                aria-describedby="inputGroupPrepend"
                                                disabled
                                                size="sm"
                                                value={sortlyid}
                                            />
                                        </InputGroup>
                                    </Form.Group>
                                    <Form.Group as={Col} md="" controlId="barcode">
                                        <InputGroup md="">
                                            <InputGroupAddon addonType="prepend">
                                                <InputGroupText>
                                                    <i className="nc-icon nc-zoom-split"></i>
                                                </InputGroupText>
                                            </InputGroupAddon>
                                            <Input
                                                type="text"
                                                placeholder="Code barre / QR"
                                                aria-describedby="inputGroupPrepend"
                                                required
                                                size="sm"
                                                value={barQrCode}
                                                onChange={(e) => setBarCode(e.target.value, setLongBar(e.target.value))}
                                                onClick={(e) => setShows(true)}
                                            />
                                        </InputGroup>
                                    </Form.Group>
                                    <Form.Group as={Col} md="" controlId="barcodesh">
                                        <InputGroup hasValidation>
                                            <InputGroup.Text id="inputGroupPrepend">Code Bare court:</InputGroup.Text>
                                            <Form.Control
                                                type="text"
                                                placeholder="Code article court:"
                                                aria-describedby="inputGroupPrepend"
                                                required
                                                size="sm"
                                                value={barQrCodecourt}
                                                onChange={(e) => setLongBar(e.target.value)}
                                            />
                                            <Form.Control.Feedback type="invalid">Veuillez saisir le code article</Form.Control.Feedback>
                                        </InputGroup>
                                    </Form.Group>
                                    <Form.Group as={Col} md="" controlId="validationCustomUsername">
                                        <InputGroup hasValidation>
                                            <InputGroup.Text id="inputGroupPrepend">Description article:</InputGroup.Text>
                                            <Form.Control
                                                type="text"
                                                placeholder="Description article"
                                                aria-describedby="inputGroupPrepend"
                                                required
                                                size="sm"
                                                value={descriptionart}
                                                onChange={(e) => setDescriptionart(e.target.value)}
                                            />
                                            <Form.Control.Feedback type="invalid">
                                                Veuillez saisir la description article
                                            </Form.Control.Feedback>
                                        </InputGroup>
                                    </Form.Group>
                                    <Form.Group as={Col} md="6" controlId="descrart">
                                        <InputGroup hasValidation>
                                            <InputGroup.Text id="inputGroupPrepend">
                                                Désignation fr.: <Star color="red" size={10} />
                                            </InputGroup.Text>
                                            <Form.Control
                                                type="text"
                                                placeholder="Désignation française"
                                                aria-describedby="inputGroupPrepend"
                                                required
                                                size="sm"
                                                value={descriptionartfr}
                                                onChange={(e) => setDescriptionartFr(e.target.value)}
                                            />
                                            <Form.Control.Feedback type="invalid">
                                                Veuillez saisir la description article
                                            </Form.Control.Feedback>
                                        </InputGroup>
                                    </Form.Group>

                                    <Form.Group as={Col} md="" controlId="validationCustomUsername">
                                        <InputGroup hasValidation>
                                            <InputGroup.Text id="inputGroupPrepend">Entrepot:</InputGroup.Text>
                                            <Input type="select" value={entrepot_id} onChange={(e) => setEntrepot(e.target.value)}>
                                                <option key="ll" value="0"></option>
                                                {entrepot.map((d, index) => {
                                                    return (
                                                        <option key={index} value={d.id}>
                                                            {d.codeentrepot + '/' + d.libelleentrepot}
                                                        </option>
                                                    );
                                                })}
                                            </Input>

                                            <Form.Control.Feedback type="invalid">Veuillez selectionnez la famille.</Form.Control.Feedback>
                                        </InputGroup>
                                    </Form.Group>
                                    <Form.Group as={Col} md="" controlId="validationCustomUsername">
                                        <InputGroup hasValidation>
                                            <InputGroup.Text id="inputGroupPrepend">Famille article:</InputGroup.Text>
                                            <Input
                                                type="select"
                                                aria-label="Sélectionner"
                                                value={famille_id}
                                                onChange={(e) => setFamille(e.target.value)}
                                            >
                                                <option value="0"></option>
                                                {famille.map((d, index) => {
                                                    return (
                                                        <option key={d.id} value={d.id}>
                                                            {d.codefam + '/' + d.libellefam}
                                                        </option>
                                                    );
                                                })}
                                            </Input>
                                            <Form.Control.Feedback type="invalid">Veuillez selectionnez la famille.</Form.Control.Feedback>
                                        </InputGroup>
                                    </Form.Group>
                                    <Form.Group as={Col} md="" controlId="validationCustomUsername">
                                        <InputGroup hasValidation>
                                            <InputGroup.Text id="inputGroupPrepend">Marque article:</InputGroup.Text>
                                            <Input
                                                type="select"
                                                aria-label="Sélectionner"
                                                value={marque_id}
                                                onChange={(e) => setMarque(e.target.value)}
                                            >
                                                <option value="0"></option>
                                                {marque.map((d, index) => {
                                                    return (
                                                        <option key={d.id} value={d.id}>
                                                            {d.libellemarq}
                                                        </option>
                                                    );
                                                })}
                                            </Input>
                                            <Form.Control.Feedback type="invalid">Veuillez Sélectionner la marque.</Form.Control.Feedback>
                                        </InputGroup>
                                    </Form.Group>
                                    <Form.Group as={Col} md="" controlId="validationCustomUsername">
                                        <InputGroup hasValidation>
                                            <InputGroup.Text id="inputGroupPrepend">Quantité initiale</InputGroup.Text>
                                            <Form.Control
                                                type="number"
                                                placeholder="Quantité initiale"
                                                aria-describedby="inputGroupPrepend"
                                                required
                                                size="sm"
                                                value={qtestock}
                                                onChange={(e) => setQtestock(e.target.value)}
                                            />
                                            <Form.Control.Feedback type="invalid">
                                                Veuillez saisir la quantité article.
                                            </Form.Control.Feedback>
                                        </InputGroup>
                                    </Form.Group>
                                    <Form.Group as={Col} md="" controlId="validationCustomUsername">
                                        <InputGroup hasValidation>
                                            <InputGroup.Text id="inputGroupPrepend">Quantité seuil:</InputGroup.Text>
                                            <Form.Control
                                                type="number"
                                                placeholder="Quantité seuil"
                                                aria-describedby="inputGroupPrepend"
                                                required
                                                size="sm"
                                                value={qteseuil}
                                                onChange={(e) => setQteseuil(e.target.value)}
                                            />
                                            <Form.Control.Feedback type="invalid">
                                                Veuillez saisir la quantité seuil article.
                                            </Form.Control.Feedback>
                                        </InputGroup>
                                    </Form.Group>
                                    <Form.Group as={Col} md="" controlId="validationCustomUsername">
                                        <InputGroup hasValidation>
                                            <InputGroup.Text id="inputGroupPrepend">Prix d'achat:</InputGroup.Text>
                                            <Form.Control
                                                type="number"
                                                placeholder="Prix d'achat"
                                                aria-describedby="inputGroupPrepend"
                                                required
                                                size="sm"
                                                value={prixachat}
                                                onChange={(e) => setPrixachats(e.target.value)}
                                            />
                                            <Form.Control.Feedback type="invalid">Veuillez saisir le prix d'achat.</Form.Control.Feedback>
                                        </InputGroup>
                                    </Form.Group>
                                    <Form.Group as={Col} md="" controlId="validationCustomUsername">
                                        <InputGroup hasValidation>
                                            <InputGroup.Text id="inputGroupPrepend">Coeficient:</InputGroup.Text>
                                            <Form.Control
                                                type="number"
                                                placeholder="Coeficient"
                                                aria-describedby="inputGroupPrepend"
                                                required
                                                size="sm"
                                                value={coeficient}
                                                onChange={(e) => setCoeficients(e.target.value)}
                                            />
                                            <Form.Control.Feedback type="invalid">Veuillez saisir le prix de vente.</Form.Control.Feedback>
                                        </InputGroup>
                                    </Form.Group>
                                    <Form.Group as={Col} md="" controlId="validationCustomUsername">
                                        <InputGroup hasValidation>
                                            <InputGroup.Text id="inputGroupPrepend">Prix de vente:</InputGroup.Text>
                                            <Form.Control
                                                disabled
                                                type="number"
                                                placeholder="Prix de vente"
                                                aria-describedby="inputGroupPrepend"
                                                required
                                                size="sm"
                                                value={prixvente}
                                                onChange={(e) => setPrixvente(e.target.value)}
                                            />
                                            <Form.Control.Feedback type="invalid">Please choose a username.</Form.Control.Feedback>
                                        </InputGroup>
                                    </Form.Group>
                                    <Form.Group as={Col} md="">
                                        <Form.Control
                                            type="file"
                                            id="file-id"
                                            value={file}
                                            onChange={(e) => setFiles(e)}
                                            size="sm"
                                            placeholder="Choisir une image"
                                            accept=".png, .jpeg"
                                        />
                                        <Form.Control.Feedback type="invalid">Veuillez Sélectionner une image</Form.Control.Feedback>
                                    </Form.Group>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                    <Card>
                        <div className="ml-auto">
                            <ButtonGroup aria-label="Basic example">
                                <Button type="submit" color="" onClick={cancel}>
                                    <ArrowLeft color="red" size={20} /> Annuler
                                </Button>
                                <Button type="submit" color="default">
                                    <Save2Fill color="#ff7300" size={20} /> Enregister
                                </Button>
                            </ButtonGroup>
                        </div>
                    </Card>
                </Form>
            </div>
            <Modal show={shows} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Scanner le code bar ou le QR</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {/* <BarcodeScannerComponent
                  style={{ width: '100%' }}
                  onUpdate={(err, result) => {
                    if (result) {
                      setBarCode(result.text);
                      setLongBar(result.text);
                      setShows(false)
                    }else setBarCode("");
                  }}
                /> */}
                </Modal.Body>
                <Modal.Footer>
                    <ArrowLeft onClick={handleClose} color="red" size={25} />
                </Modal.Footer>
            </Modal>
        </>
    );
}

export default EditArticle;
