import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import ReactDataGrid from '@inovua/reactdatagrid-community';
import '@inovua/reactdatagrid-community/index.css';
import { jsPDF } from 'jspdf';
import XLSX from 'sheetjs-style';
import 'jspdf-autotable';
import 'assets/css/table.css';
import dateformat from 'dateformat';
import common from '../../commonData';
import NavMenu from './navbar';
import ActionBtn from './gridactionbtn';
import AddSortie from './addSortie';

function MyAwesomeTable({
    rows,
    isLoading,
    onDelete,
    viewSortie,
    setShowAddOrEdit,
    printFacture,
    printBl,
    handleShow,
    setLoading,
    loading,
    setIsSave,
    setMsg,
    setMsgravity
}) {
    const [selected, setSelected] = useState({});
    const [gridRef, setGridRef] = useState(null);
    const [showAddLiv, setShowAddLiv] = useState(false);
    const [data, setData] = useState();
    const filterValue = [
        { name: 'nofact', operator: 'contains', type: 'string', value: '' },
        { name: 'client', operator: 'contains', type: 'string', value: '' },
        // { name: 'datefact', operator: 'inrange', type: 'date', value: null},
        { name: 'noprof', operator: 'contains', type: 'string', value: '' },
        // { name: 'dateprof', operator: 'contains', type: 'date', value: '' },
        { name: 'user', operator: 'contains', type: 'string', value: '' },
        { name: 'useru', operator: 'contains', type: 'string', value: '' }
    ];

    const columnss = [
        {
            name: 'id',
            header: 'Id',
            defaultVisible: false,
            defaultWidth: 80,
            type: 'number'
        },
        {
            header: 'Action',
            defaultWidth: 155,
            type: 'string',
            render: ({ value, data }) => (
                <ActionBtn
                    data={data}
                    handleShow={handleShow}
                    viewSortie={viewSortie}
                    printFacture={printFacture}
                    setShowAddOrEdit={setShowAddOrEdit}
                    onDelete={onDelete}
                    printBl={printBl}
                    setShowAdds={setShowAdds}
                />
            )
        },

        {
            name: 'nofact',
            header: 'N° facture',
            defaultWidth: 100,
            type: 'string',
            render: ({ data }) => (
                <div className="rgt-cell-inner" style={{ display: 'flex', alignItems: 'center', overflow: 'hidden' }}>
                    <OverlayTrigger placement="right" overlay={<Tooltip id="tooltip-disabled">Cliquer pour plus de détails</Tooltip>}>
                        <Link to="/admin/factures" onClick={() => viewSortie(data.id, 1)} id="actionbtn">
                            {data.sortie != null && data.nofact != null ? (
                                <p id="successs">{data.nofact || 'néant'}</p>
                            ) : data.sortie != null && data.nofact == null ? (
                                <p id="warning">{data.nofact || 'néant'}</p>
                            ) : data.sortie == null && data.nofact != null ? (
                                <p id="orange">{data.nofact || 'néant'}</p>
                            ) : (
                                <p id="light">{data.nofact || 'néant'}</p>
                            )}
                        </Link>
                    </OverlayTrigger>
                </div>
            )
        },

        {
            name: 'datefact',
            header: 'Date facture',
            defaultWidth: 100,
            type: 'string',
            render: ({ value }) => (value ? dateformat(value, 'dd-mm-yyyy') : 'neant')
        },
        { name: 'noprof', header: 'N° proforma', defaultWidth: 90, type: 'string' },
        {
            name: 'dateprof',
            header: 'Date proforma',
            defaultWidth: 100,
            type: 'string',
            render: ({ value }) => (value ? dateformat(value, 'dd-mm-yyyy') : 'neant')
        },
        {
            name: 'client',
            header: 'Clients',
            defaultWidth: 170,
            type: 'string'
        },
        {
            name: 'prix_ttc',
            header: 'Prix TTC',
            defaultWidth: 90,
            textAlign: 'center',
            type: 'number',
            render: ({ value }) => numberformat(value)
        },
        {
            name: 'montant_avoir',
            header: 'Avoir',
            defaultWidth: 90,
            textAlign: 'center',
            type: 'number',
            render: ({ value }) => numberformat(value)
        },
        {
            name: 'reglement',
            header: 'Mont. payé',
            defaultWidth: 90,
            textAlign: 'center',
            type: 'number',
            render: ({ value }) => numberformat(value)
        },
        {
            name: 'reste',
            header: 'Reste à payé',
            defaultWidth: 90,
            textAlign: 'center',
            type: 'number',
            render: ({ value }) => numberformat(value)
        },
        { name: 'user', header: 'Crée par', defaultWidth: 100, type: 'string' },
        {
            name: 'created_at',
            header: 'Crée le',
            defaultWidth: 130,
            type: 'string',
            render: ({ value }) => dateformat(value, 'dd-mm-yyyy HH:MM')
        },
        { name: 'useru', header: 'Modif. par', defaultWidth: 100, type: 'string' },
        {
            name: 'updated_at',
            header: 'Modif. le',
            defaultWidth: 130,
            type: 'string',
            render: ({ value }) => dateformat(value, 'dd-mm-yyyy HH:MM')
        }
    ];

    const gridStyle = { minHeight: window.screen.height - 200 };

    function setShowAdds(data) {
        setData(data);
        setShowAddLiv(true);
    }

    function GetSortOrder(prop) {
        return function (a, b) {
            if (a[prop] > b[prop]) {
                return 1;
            } else if (a[prop] < b[prop]) {
                return -1;
            }
            return 0;
        };
    }
    const exportCSV = () => {
        //--------------- EXPORT VERS EXCEL ------------------
        const raw_data = gridRef.current.data.sort(GetSortOrder('client'));
        /* flatten objects */
        const rows = [];
        raw_data.map((row, index) => {
            rows.push({
                client: row.client,
                nofact: row.nofact,
                datefact: dateformat(row.datefact, 'dd-mm-yyyy'),
                noprof: row.noprof,
                dateprof: dateformat(row.dateprof, 'dd-mm-yyyy'),
                prix_ttc: row.prix_ttc,
                reglement: row.reglement,
                reste: row.reste
            });
        });

        /* generate worksheet and workbook */
        const worksheet = XLSX.utils.json_to_sheet(rows);
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, 'Mes factures');
        /* fix headers */
        XLSX.utils.sheet_add_aoa(
            worksheet,
            [['Clients', 'N° facture', 'Date facture', 'N° proforma', 'Date proforma', 'Montant TTC', 'Montant reglé', 'Reste']],
            { origin: 'A1' }
        );
        /* calculate column width */
        const max_width = rows.reduce((w, r) => Math.max(w, r.client.length), 10);
        worksheet['!cols'] = [{ wch: max_width }];
        XLSX.writeFile(workbook, 'facture.xlsx');
    };

    //-------------IMPRIMER LES LISTE ----------------
    function printListe() {
        if (gridRef.current.data.length <= 0) {
            setMsg('Aucune donnée à imprimer');
            setIsSave(true);
            setMsgravity('danger');
            return;
        }
        const doc = new jsPDF({
            orientation: 'l',
            unit: 'mm',
            format: 'a4',
            putOnlyUsedFonts: true
        });
        var width = doc.internal.pageSize.getWidth();
        const data = [];
        let mont = 0;
        let reglement = 0;
        let reste = 0;
        common.ipapi == 'https://192.168.8.109:81' || common.ipapi == 'https://api.tracky-entrepot.com'
            ? doc.addImage(common.logo, 'jpg', 14, 10, 30, 30)
            : doc.addImage(common.logo1, 'jpg', 14, 10, 30, 30);
        doc.setFontSize(16);
        common.ipapi == 'https://192.168.8.109:81' || common.ipapi == 'https://api.tracky-entrepot.com'
            ? doc.text('CLUB AUTO', 90, 14)
            : doc.text('HR MOTORS', 90, 14);
        doc.setFontSize(12);
        doc.setFillColor(225, 206, 154);
        doc.rect(65, 15, width - 80, 10, 'F');
        doc.setTextColor(0, 0, 0);
        doc.text('LISTE DES FACTURES ET DEVIS CLIENTS', 90, 22);

        doc.setFontSize(12);
        doc.setTextColor(0, 0, 0);
        // doc.text("Période du: "+dateformat(datedeb || gridRef.current.data[gridRef.current.data.length-1].datesort,"dd-mm-yyyy")+" Au : "+dateformat(datefin || gridRef.current.data[0].datesort,"dd-mm-yyyy") ,65,32)
        gridRef.current.data.forEach((row) => {
            mont += row.prix_ttc;
            reglement += row.reglement;
            reste += row.reste;
            data.push([
                { content: row.client },
                { content: row.nofact !== null ? row.nofact : '' },
                { content: row.datefact !== null ? dateformat(row.datefact, 'dd-mm-yyyy') : '' },
                { content: row.noprof ? row.noprof : '' },
                { content: dateformat(row.dateprof, 'dd-mm-yyyy') },

                {
                    content: new Intl.NumberFormat('de-DE', {
                        style: 'decimal',
                        currency: 'CFA'
                    }).format(row.prix_ttc)
                },

                {
                    content: new Intl.NumberFormat('de-DE', {
                        style: 'decimal',
                        currency: 'CFA'
                    }).format(row.reglement)
                },

                {
                    content: new Intl.NumberFormat('de-DE', {
                        style: 'decimal',
                        currency: 'CFA'
                    }).format(row.reste)
                }
            ]);
        });
        data.push([
            { content: 'TOTAL', colSpan: 5, styles: { fontStyle: 'bold', fontSize: 12 } },
            {
                content: new Intl.NumberFormat('de-DE', {
                    style: 'decimal',
                    currency: 'CFA'
                }).format(mont),
                styles: { fontStyle: 'bold', fontSize: 12 }
            },
            {
                content: new Intl.NumberFormat('de-DE', {
                    style: 'decimal',
                    currency: 'CFA'
                }).format(reglement),
                styles: { fontStyle: 'bold', fontSize: 12 }
            },
            {
                content: new Intl.NumberFormat('de-DE', {
                    style: 'decimal',
                    currency: 'CFA'
                }).format(reste),
                styles: { fontStyle: 'bold', fontSize: 12 }
            }
        ]);
        doc.autoTable({
            styles: {
                fillColor: [225, 206, 154],
                textColor: [0, 0, 0],
                halign: 'center',
                fontSize: 10,
                cellPadding: 0.75
            },
            columnStyles: {
                0: {
                    halign: 'left',
                    fillColor: [255, 255, 255],
                    textColor: [0, 0, 0],
                    fontSize: 8
                },
                1: {
                    halign: 'center',
                    fillColor: [255, 255, 255],
                    textColor: [0, 0, 0],
                    fontSize: 8
                },
                2: {
                    halign: 'center',
                    fillColor: [255, 255, 255],
                    textColor: [0, 0, 0],
                    fontSize: 8
                },
                3: {
                    halign: 'center',
                    fillColor: [255, 255, 255],
                    textColor: [0, 0, 0],
                    fontSize: 8
                },
                4: {
                    halign: 'center',
                    fillColor: [255, 255, 255],
                    textColor: [0, 0, 0],
                    fontSize: 8
                },
                5: {
                    halign: 'center',
                    fillColor: [255, 255, 255],
                    textColor: [0, 0, 0],
                    fontSize: 8
                },
                6: {
                    halign: 'right',
                    fillColor: [255, 255, 255],
                    textColor: [0, 0, 0],
                    fontSize: 8
                },
                7: {
                    halign: 'right',
                    fillColor: [255, 255, 255],
                    textColor: [0, 0, 0],
                    fontSize: 8
                },
                8: {
                    halign: 'right',
                    fillColor: [255, 255, 255],
                    textColor: [0, 0, 0],
                    fontSize: 8
                }
            },
            theme: 'grid',
            startY: 35,
            fontSize: 6,
            head: [['Clients', 'N° facture', 'Date facture', 'N° proforma', 'Date proforma', 'Montant TTC', 'Montant reglé', 'Reste']],
            body: data
        });
        const pageCount = doc.internal.getNumberOfPages();
        for (var i = 1; i <= pageCount; i++) {
            doc.setPage(i);
            doc.setFontSize(10);
            doc.text('Page ' + String(i) + ' sur ' + String(pageCount), 210 - 55, 200, null, null, 'right');
        }
        doc.setFontSize(8);
        doc.setTextColor(254, 0, 0);
        // doc.text('* Aucun produit ne peut être retourné 15 jours après livraison', 14, doc.lastAutoTable.finalY + 3);
        doc.text('* Aucun produit ne peut être retourné', 14, doc.lastAutoTable.finalY + 3);

        doc.save('liste facture.pdf');
    }

    function numberformat(data) {
        return new Intl.NumberFormat().format(data);
    }

    return (
        <div>
            {showAddLiv ? (
                <AddSortie
                    setShowAddLiv={setShowAddLiv}
                    show={showAddLiv}
                    setIsSave={setIsSave}
                    setMsg={setMsg}
                    setMsgravity={setMsgravity}
                    data={data}
                    setLoading={setLoading}
                    loading={loading}
                />
            ) : (
                <></>
            )}
            <NavMenu printListe={printListe} selected={selected} setShowAddOrEdit={setShowAddOrEdit} exportCSV={exportCSV} />
            <ReactDataGrid
                idProperty="id"
                handle={setGridRef}
                style={gridStyle}
                loading={isLoading}
                reorderColumns={false}
                pagination
                selected={selected}
                // checkboxColumn
                // onSelectionChange={onSelectionChange}
                pageSizes={[200, 400, 600, 800, 1000]}
                defaultLimit={400}
                showHoverRows={true}
                editable={true}
                showColumnMenuTool={false}
                defaultFilterValue={filterValue}
                columns={columnss}
                dataSource={rows}
            />
        </div>
    );
}

export default MyAwesomeTable;
