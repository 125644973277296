import React, { useEffect } from 'react';
// javascript plugin used to create scrollbars on windows
import PerfectScrollbar from 'perfect-scrollbar';
import { Route, Switch, useLocation, useHistory } from 'react-router-dom';

import DemoNavbar from 'components/Navbars/DemoNavbar.js';
import Footer from 'components/Footer/Footer.js';
import Sidebar from 'components/Sidebar/Sidebar.js';

import routes from 'routes.js';

import EditArticle from '../views/stocks/articles/editArticle';
import FicheArticle from '../views/stocks/articles/ficheArticle';
import AddEntre from '../views/stocks/entrees/addEntree';
import AddSortie from '../views/stocks/sorties/addSortie';
import AddFacture from '../views/stocks/factures/addFacture';

import AddArticle from '../views/stocks/articles/addArticle';
import AddClient from '../views/stocks/clients/addClient';
import ListeArticleHs from '../views/stocks/articles/listeArticleHs';
import ListeArticle from '../views/stocks/articles/listeArticleValeur';
import ListeArticleLost from '../views/stocks/articles/listeArticleLost';
import FamilleArticle from 'views/stocks/familles/articleFamille';
import FournisseurArticle from 'views/stocks/articles/articleFournisseur';
import MarqueArticle from 'views/stocks/articles/articleMarque';
import EntrepotArticle from 'views/stocks/articles/articleEntrepot';
import Config from '../views/Config';
import UserApdate from '../views/UserUpdate';
import UserAdd from '../views/UserAdd';
import FamilleArticleMarque from 'views/stocks/articles/articleFamilleMarque';
import DetailClients from 'views/stocks/clients/detailClients';
var ps;

function Dashboard(props) {
    const mainPanel = React.useRef();
    const location = useLocation();
    React.useEffect(() => {
        if (navigator.platform.indexOf('Win') > -1) {
            ps = new PerfectScrollbar(mainPanel.current);
            document.body.classList.toggle('perfect-scrollbar-ons');
        }
        return function cleanup() {
            if (navigator.platform.indexOf('Win') > -1) {
                ps.destroy();
                document.body.classList.toggle('perfect-scrollbar-ons');
            }
        };
    });
    React.useEffect(() => {
        mainPanel.current.scrollTop = 0;
        document.scrollingElement.scrollTop = 0;
    }, [location]);

    const history = useHistory();
    const checkActivityTime = () => {
        const expireTime = localStorage.getItem('expireTime-s');
        if (expireTime < Date.now()) {
            localStorage.clear();
            history.push('/login');
        }
    };

    const UpdateActivity = () => {
        const expireTime = Date.now() + 1200000;
        localStorage.setItem('expireTime-s', expireTime);
    };

    useEffect(() => {
        const interval = setInterval(() => {
            checkActivityTime();
        }, 5000);
        return () => {
            clearInterval(interval);
        };
    }, []);

    useEffect(() => {
        UpdateActivity();
        window.addEventListener('click', UpdateActivity);
        window.addEventListener('keypress', UpdateActivity);
        window.addEventListener('scroll', UpdateActivity);
        window.addEventListener('mousemove', UpdateActivity);
        return () => {
            window.removeEventListener('click', UpdateActivity);
            window.removeEventListener('keypress', UpdateActivity);
            window.removeEventListener('scroll', UpdateActivity);
            window.removeEventListener('mousemove', UpdateActivity);
        };
    }, []);

    return (
        <div className="wrapper">
            <Sidebar {...props} routes={routes} bgColor="black" activeColor="info" />
            <div className="main-panel" ref={mainPanel}>
                <DemoNavbar {...props} />
                <Switch>
                    {routes.map((prop, key) => {
                        return <Route path={prop.layout + prop.path} component={prop.component} key={key} />;
                    })}
                    <Route path="/admin/usersup/:id" component={UserApdate} key={12}></Route>
                    <Route path="/admin/usersadd" component={UserAdd} key={13}></Route>
                    <Route path="/admin/config" component={Config} key={14}></Route>
                    <Route path="/admin/addClient" component={AddClient} key={15} exact></Route>
                    <Route path="/admin/client/:id/contents" component={DetailClients} key={18}></Route>
                    <Route path="/admin/addentrees" component={AddEntre} key={17}></Route>
                    <Route path="/admin/addsortie" component={AddSortie} key={19}></Route>
                    <Route path="/admin/addfacture" component={AddFacture} key={21}></Route>
                    <Route path="/admin/addarticle" component={AddArticle} key={23}></Route>
                    <Route path="/admin/articles-hs" component={ListeArticleHs} key={24} exact></Route>
                    <Route path="/admin/articles-valeur-stock" component={ListeArticle} key={24} exact></Route>
                    <Route path="/admin/articles-lost" component={ListeArticleLost} key={25} exact></Route>
                    <Route path="/admin/article/edit/:id" component={EditArticle} key={26}></Route>
                    <Route path="/admin/article/fiche/:id" component={FicheArticle} key={27}></Route>
                    <Route path="/admin/famille/:id/content" component={FamilleArticle} key={28}></Route>
                    <Route path="/admin/article/sfamille/marque/:id" component={FamilleArticleMarque} key={28}></Route>
                    <Route path="/admin/article/fournisseur/:id" component={FournisseurArticle} key={29}></Route>
                    <Route path="/admin/marque/:id" component={MarqueArticle} key={30}></Route>
                    <Route path="/admin/article/entrepot/:id" component={EntrepotArticle} key={31}></Route>
                </Switch>
                <Footer fluid />
            </div>
            {/* <FixedPlugin
        bgColor={backgroundColor}
        activeColor={activeColor}
        handleActiveClick={handleActiveClick}
        handleBgClick={handleBgClick}
      /> */}
        </div>
    );
}

export default Dashboard;
