import React from 'react';
import { Container, Nav, NavDropdown, Navbar, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { Printer, FileExcel, PlusCircleDotted, Search, CardChecklist } from 'react-bootstrap-icons';

import 'jspdf-autotable';
import 'assets/css/table.css';

function NavMenu({ printListe, setAdds, selected, exportCSV, setFacts, setShowFilterModals, printListeModel, exportCSVModel }) {
    return (
        <Navbar expand="lg" bg="light" variant="light">
            <Container>
                {/* {
       JSON.parse(selected).forEach((d)=>{
            console.log(selected )
          })}  */}
                {/* <Navbar.Brand href="#/admin/sorties">LISTE DES SORTIES D'ARTICLES </Navbar.Brand> */}
                <Nav className="me-auto">
                    <OverlayTrigger placement="top" overlay={<Tooltip id="tooltip-disabled">Faire une sortie de stock</Tooltip>}>
                        <Nav.Link href="#/admin/sorties" onClick={() => setAdds(1)} title="Faire une sortie de stock">
                            <PlusCircleDotted color="orange" size={25} /> Créer
                        </Nav.Link>
                    </OverlayTrigger>
                    {/* <OverlayTrigger placement="top" overlay={<Tooltip id="tooltip-disabled">Exporter vers Excel</Tooltip>}>
                        <Nav.Link href="#/admin/sorties" onClick={() => exportCSV()} title="Exporter vers excel">
                            <FileExcel color="#0080ff" size={25} /> Excel
                        </Nav.Link>
                    </OverlayTrigger> */}
                    {/* <OverlayTrigger placement="top" overlay={<Tooltip id="tooltip-disabled">Exporter vers pdf</Tooltip>}>
                        <Nav.Link href="#/admin/sorties" onClick={() => printListe()} title="Exporter vers pdf">
                            <Printer color="#0080ff" size={25} /> Imprimer
                        </Nav.Link>
                    </OverlayTrigger> */}
                    <NavDropdown title="Exporter" id="basic-nav-dropdown">
                        <NavDropdown.Item href="#/admin/sorties" onClick={() => printListe()}>
                            <Printer color="#0080ff" size={20} />
                            Liste sortie par client (pdf)
                        </NavDropdown.Item>
                        <NavDropdown.Item href="#/admin/sorties" onClick={() => exportCSV()}>
                            <FileExcel color="green" size={20} />
                            Liste sortie par client (Excel)
                        </NavDropdown.Item>
                        <NavDropdown.Item href="#/admin/sorties" onClick={() => setShowFilterModals(2)}>
                            <Printer color="#0080ff" size={20} />
                            Liste sortie par model (pdf)
                        </NavDropdown.Item>
                        {/* <NavDropdown.Item href="#/admin/sorties" onClick={() => setShowFilterModals(3)}>
                            <FileExcel color="green" size={20} />
                            Liste sortie par model (Excel)
                        </NavDropdown.Item> */}
                    </NavDropdown>
                    {Object.keys(JSON.parse(selected)).length > 0 ? (
                        <OverlayTrigger placement="top" overlay={<Tooltip id="tooltip-disabled">Facturer des BLs</Tooltip>}>
                            <Nav.Link href="#/admin/sorties" onClick={() => setFacts(0)} title="Facturer des BLs">
                                <CardChecklist color="#0dd1fd" size={25} /> Facturer
                            </Nav.Link>
                        </OverlayTrigger>
                    ) : (
                        <></>
                    )}
                </Nav>
                <Nav>
                    <OverlayTrigger placement="top" overlay={<Tooltip id="tooltip-disabled">Recherche filtre</Tooltip>}>
                        <Nav.Link href="#/admin/sorties" onClick={() => setShowFilterModals(1)}>
                            <Search color="#0080ff" size={25} />
                        </Nav.Link>
                    </OverlayTrigger>
                </Nav>
            </Container>
        </Navbar>
    );
}
export default NavMenu;
