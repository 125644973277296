import React, { useState } from 'react';
import { Container, Dropdown, Nav, Navbar, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { FileExcel, Printer } from 'react-bootstrap-icons';

import ReactDataGrid from '@inovua/reactdatagrid-community';
import '@inovua/reactdatagrid-community/index.css';
import moment from 'moment';
import XLSX from 'sheetjs-style';
import { jsPDF } from 'jspdf';
import 'jspdf-autotable';
import 'assets/css/table.css';
import dateformat from 'dateformat';
import common from '../../commonData';
import DateFilter from '@inovua/reactdatagrid-community/DateFilter';

function MyAwesomeTable({ rows, isLoading }) {
    window.moment = moment;
    const [gridRef, setGridRef] = useState(null);

    const filterValue = [
        { name: 'qtestock', operator: 'gte', type: 'number', value: 0 },
        { name: 'valeurachat', operator: 'gte', type: 'number', value: 0 },
        { name: 'valeurvente', operator: 'gte', type: 'number', value: 0 },
        { name: 'created_at', operator: 'inrange', type: 'date', value: '' }
    ];

    function numberformat(data) {
        return new Intl.NumberFormat('de-DE').format(data);
    }

    const columns = [
        {
            name: 'id',
            header: 'Id',
            defaultVisible: false,
            defaultWidth: 80,
            type: 'number'
        },

        {
            name: 'qtestock',
            header: 'Quantité',
            defaultWidth: 140,
            type: 'number',
            textAlign: 'center',
            render: ({ value }) => numberformat(value)
        },
        {
            name: 'valeurachat',
            header: 'Valeur stock',
            defaultWidth: 140,
            type: 'number',
            textAlign: 'center',
            render: ({ value }) => numberformat(value)
        },
        {
            name: 'valeurvente',
            header: 'Valeur vente',
            defaultWidth: 140,
            type: 'number',
            textAlign: 'center',
            render: ({ value }) => numberformat(value)
        },
        {
            name: 'created_at',
            header: 'Date sauvegarde',
            defaultWidth: 300,
            type: 'date',
            dateFormat: 'DD-MM-YYYY',
            filterEditor: DateFilter,
            filterEditorProps: (props, { index }) => {
                return {
                    dateFormat: 'DD-MM-YYYY',
                    placeholder: index === 1 ? 'Date fin' : 'Date debut'
                };
            },
            render: ({ value, cellProps: { dateFormat } }) => moment(value).format(dateFormat)
        }
    ];
    const gridStyle = { minHeight: window.screen.height - 200 };

    function GetSortOrder(prop) {
        return function (a, b) {
            if (a[prop] < b[prop]) {
                return 1;
            } else if (a[prop] > b[prop]) {
                return -1;
            }
            return 0;
        };
    }
    async function printListe() {
        // setLoad(true)
        const data = [];
        var tablo = [];
        tablo = gridRef.current.data.sort(GetSortOrder('created_at'));
        tablo.forEach((row, index) => {
            data.push([
                { content: index + 1 },
                { content: numberformat(row.qtestock) },
                { content: numberformat(row.valeurachat) },
                { content: numberformat(row.valeurvente) },
                { content: row.created_at ? dateformat(row.created_at, 'dd-mm-yyyy HH:MM') : 'Néant' }
            ]);
        });
        const doc = new jsPDF();
        var width = doc.internal.pageSize.getWidth();

        doc.setFontSize(16);
        common.ipapi == 'https://192.168.8.109:81' || common.ipapi == 'https://api.tracky-entrepot.com'
            ? doc.addImage(common.logo, 'jpg', 10, 6, 30, 30)
            : doc.addImage(common.logo1, 'jpg', 10, 6, 30, 30);
        doc.setFontSize(12);
        doc.setFillColor(225, 206, 154);
        doc.rect(40, 15, width - 55, 10, 'F');
        doc.setTextColor(0, 0, 0);
        doc.text('LISTE DES VALEURS DU STOCK', 50, 22);
        doc.setTextColor(0, 0, 0);

        doc.autoTable({
            styles: {
                fillColor: [255, 255, 255],
                textColor: [0, 0, 0],
                cellPadding: 0.75,
                halign: 'center',
                lineColor: [0, 0, 0],
                fontSize: 9
            },
            columnStyles: {
                0: {
                    halign: 'center',
                    fillColor: [255, 255, 255],
                    textColor: [0, 0, 0],
                    fontSize: 8
                },
                1: {
                    halign: 'left',
                    fillColor: [255, 255, 255],
                    textColor: [0, 0, 0],
                    fontSize: 8
                },
                2: {
                    halign: 'left',
                    fillColor: [255, 255, 255],
                    textColor: [0, 0, 0],
                    fontSize: 8
                },
                3: {
                    halign: 'left',
                    fillColor: [255, 255, 255],
                    textColor: [0, 0, 0],
                    fontSize: 8
                },
                4: {
                    halign: 'center',
                    fillColor: [255, 255, 255],
                    textColor: [0, 0, 0],
                    fontSize: 8
                }
            },
            theme: 'grid',
            startY: 35,
            fontSize: 9,
            head: [['Ordre', 'Quantité', 'Valeur stock', 'Valeur vente', 'Date']],
            body: data
        });
        const pageCount = doc.internal.getNumberOfPages();

        for (var i = 1; i <= pageCount; i++) {
            doc.setPage(i);
            doc.setFontSize(10);
            doc.text('Page ' + String(i) + ' sur ' + String(pageCount), 210 - 18, 292, null, null, 'right');
        }
        doc.save('article-valeur-stock.pdf');
    }

    //--------------- EXPORT VERS EXCEL ------------------

    const exportToExcel = async () => {
        const raw_data = gridRef.current.data.sort(GetSortOrder('created_at'));
        const rows = raw_data.map((row, index) => ({
            Ordre: index + 1,
            qtestock: numberformat(row.qtestock),
            valeurachat: numberformat(row.valeurachat),
            valeurvente: numberformat(row.valeurvente),
            created_at: row.created_at ? dateformat(row.created_at, 'dd-mm-yyyy HH:MM') : 'Néant'
        }));
        const worksheet = XLSX.utils.json_to_sheet(rows);
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, 'Mon stock');
        XLSX.utils.sheet_add_aoa(worksheet, [['Ordre', 'Quantité', 'Valeur stock', 'Valeur vente', 'Date']], { origin: 'A1' });
        const max_width = rows.reduce((w, r) => Math.max(w, r.created_at.length), 10);
        worksheet['!cols'] = [{ wch: max_width }];
        XLSX.writeFile(workbook, 'article-valeur-stock.xlsx');
    };

    async function saveAsExcel() {
        exportToExcel();
    }

    return (
        <div>
            <Navbar expand="lg" bg="light" variant="light">
                <Container>
                    <Navbar.Brand href="#/admin/articles">Historique des valeurs du stock</Navbar.Brand>
                    <Nav className="me-auto">
                        <OverlayTrigger placement="top" overlay={<Tooltip id="tooltip-disabled">Exporter vers Excel</Tooltip>}>
                            <Nav.Link title="Exporter vers excel" onClick={() => saveAsExcel()}>
                                <FileExcel color="#088d5a" size={25} /> Excel
                            </Nav.Link>
                        </OverlayTrigger>
                        <OverlayTrigger placement="top" overlay={<Tooltip id="tooltip-disabled">Exporter vers pdf</Tooltip>}>
                            <Nav.Link onClick={() => printListe()} title="Exporter vers pdf">
                                <Printer color="#0080ff" size={25} /> Imprimer
                            </Nav.Link>
                        </OverlayTrigger>
                    </Nav>
                    <Nav></Nav>
                </Container>
            </Navbar>
            <ReactDataGrid
                idProperty="id"
                handle={setGridRef}
                style={gridStyle}
                loading={isLoading}
                reorderColumns={false}
                pagination
                pageSizes={[200, 400, 600, 800, rows.length]}
                defaultLimit={400}
                showHoverRows={true}
                editable={true}
                showColumnMenuTool={false}
                defaultFilterValue={filterValue}
                columns={columns}
                dataSource={rows}
            />
        </div>
    );
}

export default MyAwesomeTable;
