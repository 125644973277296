import * as React from 'react';

import config from '../../../commonData';

import { Container, Nav, Navbar, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { Box, Box2, Box2Fill, Cash, CashCoin, Key, Pencil, PlusCircleDotted, Printer, X } from 'react-bootstrap-icons';

export default function AccessibilityTooltips({
    selected,
    handleClickOpen,
    setLoading,
    setLoad,
    load,
    setOpenSnak,
    setMsg,
    setSeverity,
    gridRef,
    setAdds,
    handleClickOpenDep
}) {
    const toArray = (selected) => Object.keys(selected).map((id) => id * 1);
    const id = JSON.stringify(toArray(selected)).replace('[', '').replace(']', '');

    async function handleClickDeleteLigne(id) {
        const log = config.search(config.userdroit, 'SAD');
        const log1 = config.search(config.userdroit, 'DSP');
        if (log == undefined) {
            if (log1 == undefined) {
                setMsg("Vous n'avez pas le droit d'effectuer cette action, merci de contacter l'administrateur");
                setSeverity('danger');
                setOpenSnak(true);
                return;
            }
        }
        if (id === '') {
            setOpenSnak(true);
            setMsg('Veuillez sélectionner une ligne');
            setSeverity('error');
            return;
        }
        let text = 'Voulez-vous supprimer cette ligne';
        let res = window.confirm(text);
        if (res === false) {
            return;
        }
        setLoading(true);
        const response = await fetch(config.ipapi + '/api/del-depense/' + id, {
            method: 'DELETE'
        });
        const json = await response.json();
        if (json.status) {
            setOpenSnak(true);
            setMsg(json.msg);
            setSeverity('success');
            setLoad(!load);
        } else {
            setOpenSnak(true);
            setMsg(json.msg);
            setSeverity('error');
        }
        setLoading(false);
    }

    return (
        <Navbar expand="lg" bg="light" variant="light">
            <Container>
                {/* <Navbar.Brand href="#/admin/sorties">LISTE DES FACTURES CLIENTS </Navbar.Brand> */}
                <Nav className="me-auto">
                    <OverlayTrigger placement="top" overlay={<Tooltip id="tooltip-disabled">Ouverture de caisse</Tooltip>}>
                        <Nav.Link href="#/admin/caisses" onClick={() => handleClickOpen(0)}>
                            <Box2 color="orange" size={25} /> Ouverture caisse
                        </Nav.Link>
                    </OverlayTrigger>
                    {/* 
                    <OverlayTrigger placement="top" overlay={<Tooltip id="tooltip-disabled">Imprimer le journal de la caisse</Tooltip>}>
                        <Nav.Link href="#/admin/caisses" onClick={() => handlePrintListe()}>
                            <Printer color="#0080ff" size={25} /> Pdf
                        </Nav.Link>
                    </OverlayTrigger>
                    <OverlayTrigger placement="top" overlay={<Tooltip id="tooltip-disabled">Exporter vers Excel</Tooltip>}>
                        <Nav.Link href="#/admin/caisses">
                            <X color="#0080ff" size={25} /> Excel
                        </Nav.Link>
                    </OverlayTrigger> */}
                </Nav>
                <Nav>
                    <OverlayTrigger placement="top" overlay={<Tooltip id="tooltip-disabled"> Faire un reglement de facture</Tooltip>}>
                        <Nav.Link href="#/admin/caisses" onClick={() => setAdds()}>
                            <CashCoin color="orange" size={25} /> Faire un reglement
                        </Nav.Link>
                    </OverlayTrigger>
                    <OverlayTrigger placement="top" overlay={<Tooltip id="tooltip-disabled">Faire une sortie d'argent</Tooltip>}>
                        <Nav.Link href="#/admin/caisses" onClick={() => handleClickOpenDep()}>
                            <Cash color="orange" size={25} /> Faire une dépense
                        </Nav.Link>
                    </OverlayTrigger>
                </Nav>
            </Container>
        </Navbar>
    );
}
