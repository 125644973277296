import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

import IconButtons from './actionButton';
import DataGrid from './dataGrid';
import config from '../../../commonData';
import { Card, Modal, Placeholder, Spinner, Toast } from 'react-bootstrap';
import AjouterTypeDepense from './ajType';
import { CardBody, CardText, CardTitle } from 'reactstrap';
import { TriangleHalf } from 'react-bootstrap-icons';

const TypeDepense = () => {
    const history = useHistory();
    const [isLoading, setLoading] = useState(true);
    const [load, setLoad] = useState(false);
    const [openSnak, setOpenSnak] = useState(false);
    const [typeDepense, setTypeDepense] = useState(null);
    const [open, setOpen] = React.useState(false);
    const [idModif, setIdModif] = React.useState(0);
    const [msg, setMsg] = React.useState('');
    const [severity, setSeverity] = React.useState('success');
    const [selected, setSelected] = React.useState({});
    const log = config.search(config.userdroit, 'SAD');
    const log1 = config.search(config.userdroit, 'GTD');

    const handleClickOpen = (param, idModif) => {
        if (idModif) {
            const log = config.search(config.userdroit, 'SAD');
            const log1 = config.search(config.userdroit, 'TMO');
            if (log == undefined) {
                if (log1 == undefined) {
                    setMsg("Vous n'avez pas le droit d'effectuer cette action, merci de contacter l'administrateur");
                    setSeverity('danger');
                    setOpenSnak(true);
                    return;
                }
            }
        } else {
            const log = config.search(config.userdroit, 'SAD');
            const log1 = config.search(config.userdroit, 'TAJ');
            if (log == undefined) {
                if (log1 == undefined) {
                    setMsg("Vous n'avez pas le droit d'effectuer cette action, merci de contacter l'administrateur");
                    setSeverity('danger');
                    setOpenSnak(true);
                    return;
                }
            }
        }

        setIdModif(idModif);
        setOpen(true);
    };

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpenSnak(false);
    };
    const handleCloseModal = () => {
        setOpen(false);
    };

    useEffect(() => {
        if (!localStorage.getItem('user-info')) {
            history.push('/login');
        }
        setLoading(true);
        async function fetchProduct() {
            const response = await fetch(config.ipapi + '/api/liste-typedep');
            const json = await response.json();
            setTypeDepense(json);
            setLoading(false);
        }
        fetchProduct();
    }, [history, load]);

    return (
        <>
            {log != undefined || log1 != undefined ? (
                <div className="content contents">
                    <IconButtons
                        selected={selected}
                        handleClickOpen={handleClickOpen}
                        setLoading={setLoading}
                        setOpenSnak={setOpenSnak}
                        setLoad={setLoad}
                        load={load}
                        idModif={idModif}
                        setMsg={setMsg}
                        setSeverity={setSeverity}
                    />
                    <Toast id="toast" onClose={() => handleClose(false)} show={openSnak} delay={3000} className={'bg-' + severity} autohide>
                        <Toast.Header>
                            <img src="holder.js/20x20?text=%20" className="rounded me-2" alt="" />
                            <strong className="me-auto">Info</strong>
                        </Toast.Header>
                        <Toast.Body className="dark">{msg}</Toast.Body>
                    </Toast>

                    <Modal show={isLoading} size="sm" aria-labelledby="contained-modal-title-vcenter" centered>
                        <Modal.Body>
                            Veuillez patienter.......
                            <Spinner animation="border" variant="success" />
                        </Modal.Body>
                    </Modal>
                    <Card sx={{ overflow: 'hidden' }}>
                        {typeDepense ? (
                            <DataGrid setSelected={setSelected} selected={selected} typeDepense={typeDepense} isLoading={isLoading} />
                        ) : (
                            <></>
                        )}
                    </Card>

                    {open ? (
                        <AjouterTypeDepense
                            open={open}
                            handleCloseModal={handleCloseModal}
                            setOpenSnak={setOpenSnak}
                            setLoad={setLoad}
                            load={load}
                            idModif={idModif}
                            setMsg={setMsg}
                            setSeverity={setSeverity}
                        />
                    ) : (
                        <></>
                    )}
                </div>
            ) : (
                <Card style={{ width: '25rem' }} className="centerCard">
                    <CardBody>
                        <CardTitle tag="h5">
                            <TriangleHalf size={60} color="red"></TriangleHalf> Avertissement
                        </CardTitle>
                        <CardText>Vous n'êtes pas autoriser à utiliser cette section. Merci de contacter votre administrateur</CardText>
                    </CardBody>
                </Card>
            )}
        </>
    );
};

export default TypeDepense;
