import React, { useEffect, useState } from 'react';
// react plugin used to create charts
import { Line } from 'react-chartjs-2';
import { useHistory } from 'react-router-dom';
// reactstrap components
import { CardHeader, Card, CardBody, CardTitle, Row, Col, CardFooter, CardText } from 'reactstrap';
// core components
// import {
//   dashboard24HoursPerformanceChart,
//   dashboardEmailStatisticsChart,
//   dashboardNASDAQChart,
// } from "variables/charts.js";
import { Spinner, Modal } from 'react-bootstrap';
import common from './commonData';
import Axios from 'axios';
import { TriangleHalf } from 'react-bootstrap-icons';

function Dashboard() {
    const [stat, setArtStat] = useState([]);
    const history = useHistory();
    const [load, setLoad] = useState(false);
    const [datamois, setDataMois] = useState([]);
    var tabdata = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
    const [msg, setMsg] = useState('');
    const [isSave, setIsSave] = useState(false);
    const [msgGravity, setMsgravity] = useState('success');

    const log = common.search(common.userdroit, 'SAD');
    const log1 = common.search(common.userdroit, 'DAS');

    useEffect(() => {
        if (localStorage.getItem('user-info')) {
            if (log == undefined) {
                if (log1 == undefined) {
                    setMsg("Vous n'avez pas le droit d'effectuer cette action, merci de contacter l'administrateur");
                    setMsgravity('danger');
                    setIsSave(true);
                    return;
                }
            }
            setLoad(true);
            const getartStat = Axios.get(common.ipapi + '/api/article/stat');
            const getMarque = Axios.get(common.ipapi + '/api/marque/index');
            const getEntrepot = Axios.get(common.ipapi + '/api/entrepot/index/1');
            Axios.all([getartStat, getMarque, getEntrepot])
                .then(
                    Axios.spread((...responses) => {
                        setArtStat(responses[0].data);
                        responses[0].data[1].forEach((element) => {
                            //alert(element.datesort)
                            var ladate = new Date(element.datesort);
                            switch (ladate.getMonth()) {
                                case 0:
                                    tabdata[0] += element.quantite;
                                    break;
                                case 1:
                                    tabdata[1] += element.quantite;
                                    break;
                                case 2:
                                    tabdata[2] += element.quantite;
                                    break;
                                case 3:
                                    tabdata[3] += element.quantite;
                                    break;
                                case 4:
                                    tabdata[4] += element.quantite;
                                    break;
                                case 5:
                                    tabdata[5] += element.quantite;
                                    break;
                                case 6:
                                    tabdata[6] += element.quantite;
                                    break;
                                case 7:
                                    tabdata[7] += element.quantite;
                                    break;
                                case 8:
                                    tabdata[8] += element.quantite;
                                    break;
                                case 9:
                                    tabdata[9] += element.quantite;
                                    break;
                                case 10:
                                    tabdata[10] += element.quantite;
                                    break;
                                case 11:
                                    tabdata[11] += element.quantite;
                                    break;
                                default:
                                    tabdata[12] += element.quantite;
                                    break;
                            }
                        });
                        setDataMois(tabdata);
                        setLoad(false);
                    })
                )
                .catch((errors) => {
                    setLoad(false);
                });
            setLoad(true);
        } else {
            history.push('/login');
        }
    }, [history]);
    const dashboardNASDAQCharts = {
        data: (canvas) => {
            return {
                labels: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
                datasets: [
                    {
                        data: datamois,
                        fill: true,
                        borderColor: '#51CACF',
                        backgroundColor: 'rgba(8, 253, 255, 0.5)',
                        pointBorderColor: '#51CACF',
                        pointRadius: 4,
                        pointHoverRadius: 4,
                        pointBorderWidth: 8,
                        tension: 0.4
                    }
                ]
            };
        },
        options: {
            plugins: {
                legend: { display: false }
            }
        }
    };

    const outint = {
        data: (canvas) => {
            return {
                labels: [1, 2, 3],
                datasets: [
                    {
                        label: 'Emails',
                        pointRadius: 0,
                        pointHoverRadius: 0,
                        backgroundColor: ['#e3e3e3', '#4acccd'],
                        borderWidth: 0,
                        data: [stat[0], stat[0] - stat[1]]
                    }
                ]
            };
        },
        options: {
            plugins: {
                legend: { display: false },
                tooltip: { enabled: false }
            },
            maintainAspectRatio: false,
            pieceLabel: {
                render: 'percentage',
                fontColor: ['white'],
                precision: 2
            },
            scales: {
                y: {
                    ticks: {
                        display: false
                    },
                    grid: {
                        drawBorder: false,
                        display: false
                    }
                },
                x: {
                    barPercentage: 1.6,
                    grid: {
                        drawBorder: false,
                        display: false
                    },
                    ticks: {
                        display: false
                    }
                }
            }
        }
    };
    function numFormatter(num) {
        if (num > 999 && num < 1000000) {
            return (num / 1000).toFixed(1) + 'K'; // convert to K for number from > 1000 < 1 million
        } else if (num > 1000000) {
            return (num / 1000000).toFixed(1) + 'M'; // convert to M for number from > 1 million
        } else if (num < 999) {
            return num; // if value < 1000, nothing to do
        }
    }

    return (
        <>
            <Modal show={load} size="sm" aria-labelledby="contained-modal-title-vcenter" centered>
                <Modal.Body>
                    Veuillez patienter.......
                    <Spinner animation="grow" variant="warning" />
                </Modal.Body>
            </Modal>
            {log != undefined || log1 != undefined ? (
                <div className="content contents">
                    <Row>
                        <Col lg="3" md="6" sm="6">
                            <Card className="card-stats">
                                <CardBody>
                                    <Row>
                                        <Col md="4" xs="5">
                                            <div className="icon-big text-center icon-warning">
                                                <i className="nc-icon nc-bullet-list-67 text-warning" />
                                            </div>
                                        </Col>
                                        <Col md="8" xs="7">
                                            <div className="numbers">
                                                <p className="card-category">Articles</p>
                                                <CardTitle tag="p">
                                                    {stat.length > 0 && stat[0][0].nb != null ? numFormatter(stat[0][0].nb) : 0}
                                                </CardTitle>
                                                <p />
                                            </div>
                                        </Col>
                                    </Row>
                                </CardBody>
                                <CardFooter>
                                    <hr />
                                    <div className="stats">
                                        <i className="fas fa-sync-alt" /> Nombre de ligne
                                    </div>
                                </CardFooter>
                            </Card>
                        </Col>
                        <Col lg="3" md="6" sm="6">
                            <Card className="card-stats">
                                <CardBody>
                                    <Row>
                                        <Col md="4" xs="5">
                                            <div className="icon-big text-center icon-warning">
                                                <i className="nc-icon nc-layout-11 text-success" />
                                            </div>
                                        </Col>
                                        <Col md="8" xs="7">
                                            <div className="numbers">
                                                <p className="card-category">Articles</p>
                                                <CardTitle tag="p">
                                                    {stat.length > 0 && stat[0][0].qte != null ? numFormatter(stat[0][0].qte) : 0}
                                                </CardTitle>
                                                <p />
                                            </div>
                                        </Col>
                                    </Row>
                                </CardBody>
                                <CardFooter>
                                    <hr />
                                    <div className="stats">
                                        <i className="far fa-calendar" /> Quantité d'article
                                    </div>
                                </CardFooter>
                            </Card>
                        </Col>
                        <Col lg="3" md="6" sm="6">
                            <Card className="card-stats">
                                <CardBody>
                                    <Row>
                                        <Col md="4" xs="5">
                                            <div className="icon-big text-center icon-warning">
                                                <i className="nc-icon nc-cart-simple text-danger" />
                                            </div>
                                        </Col>
                                        <Col md="8" xs="7">
                                            <div className="numbers">
                                                <p className="card-category">Coût d'achat</p>
                                                <CardTitle tag="p">
                                                    {stat.length > 0 && stat[0][0].pa != null ? numFormatter(stat[0][0].pa) : 0}
                                                </CardTitle>
                                                <p />
                                            </div>
                                        </Col>
                                    </Row>
                                </CardBody>
                                <CardFooter>
                                    <hr />
                                    <div className="stats">
                                        <i className="far fa-clock" /> Total coût d'achat
                                    </div>
                                </CardFooter>
                            </Card>
                        </Col>
                        <Col lg="3" md="6" sm="6">
                            <Card className="card-stats">
                                <CardBody>
                                    <Row>
                                        <Col md="4" xs="5">
                                            <div className="icon-big text-center icon-warning">
                                                <i className="nc-icon nc-money-coins text-primary" />
                                            </div>
                                        </Col>
                                        <Col md="8" xs="7">
                                            <div className="numbers">
                                                <p className="card-category">Coût de vente</p>
                                                <CardTitle tag="p">
                                                    {stat.length > 0 && stat[0][0].pv != null ? numFormatter(stat[0][0].pv) : 0}
                                                </CardTitle>
                                                <p />
                                            </div>
                                        </Col>
                                    </Row>
                                </CardBody>
                                <CardFooter>
                                    <hr />
                                    <div className="stats">
                                        <i className="fas fa-sync-alt" /> Total coût de vente
                                    </div>
                                </CardFooter>
                            </Card>
                        </Col>
                    </Row>

                    <Row>
                        <Col md="12">
                            <Card className="card-chart">
                                <CardHeader>
                                    <CardTitle tag="h5">SORTIES ARTICLES</CardTitle>
                                    <p className="card-category">Quantité article sortie par mois (Année en cours)</p>
                                </CardHeader>
                                <CardBody>
                                    <Line
                                        data={dashboardNASDAQCharts.data}
                                        options={dashboardNASDAQCharts.options}
                                        width={400}
                                        height={150}
                                    />
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </div>
            ) : (
                <Card style={{ width: '25rem' }} className="centerCard">
                    <CardBody>
                        <CardTitle tag="h5">
                            <TriangleHalf size={60} color="red"></TriangleHalf> Avertissement
                        </CardTitle>
                        <CardText>Vous n'êtes pas autoriser à utiliser cette section. Merci de contacter votre administrateur</CardText>
                    </CardBody>
                </Card>
            )}
        </>
    );
}

export default Dashboard;
