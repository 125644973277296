import React, { useState, useCallback } from 'react';
import ReactDataGrid from '@inovua/reactdatagrid-community';
import '@inovua/reactdatagrid-community/index.css';

import dateformat from 'dateformat';

function DataGrid({ selected, setSelected, typeDepense, isLoading }) {
    const [gridRef, setGridRef] = useState(null);

    const filterValue = [
        { name: 'lib_type_dep', operator: 'contains', type: 'string', value: '' },
        { name: 'observation', operator: 'contains', type: 'string', value: '' }
    ];

    const User = ({ data }) => {
        return data.user.name;
    };

    const columnss = [
        {
            name: 'id',
            header: 'Id',
            defaultVisible: false,
            defaultWidth: 80,
            type: 'number'
        },
        {
            name: 'lib_type_dep',
            header: 'Libellé type',
            defaultWidth: 150,
            type: 'string'
        },
        {
            name: 'observation',
            header: 'Observations',
            defaultWidth: 170,
            type: 'string'
        },
        { name: 'user', header: 'Crée par', defaultWidth: 100, type: 'string', render: ({ data }) => <User data={data} /> },
        {
            name: 'created_at',
            header: 'Crée le',
            defaultWidth: 130,
            type: 'string',
            render: ({ value }) => dateformat(value, 'dd-mm-yyyy HH:MM')
        },
        {
            name: 'updated_at',
            header: 'Modif. le',
            defaultWidth: 130,
            type: 'string',
            render: ({ value }) => dateformat(value, 'dd-mm-yyyy HH:MM')
        }
    ];

    function numberformat(data) {
        return new Intl.NumberFormat().format(data);
    }
    const gridStyle = { minHeight: window.screen.height - 200 };
    const onSelectionChange = useCallback(({ selected }) => {
        setSelected(selected);
    }, []);

    return (
        <div>
            <ReactDataGrid
                idProperty="id"
                handle={setGridRef}
                style={gridStyle}
                loading={isLoading}
                reorderColumns={false}
                pagination
                selected={selected}
                onSelectionChange={onSelectionChange}
                pageSizes={[200, 400, 600, 800, 1000]}
                defaultLimit={400}
                showHoverRows={true}
                editable={true}
                showColumnMenuTool={false}
                defaultFilterValue={filterValue}
                columns={columnss}
                dataSource={typeDepense}
            />
        </div>
    );
}

export default DataGrid;
