import React, { useEffect, useState } from 'react';
import { useHistory, Link } from 'react-router-dom';
// reactstrap components
import { Card, Row, Col, Button, CardBody, CardTitle, CardText } from 'reactstrap';
// core components
import { PencilFill, PencilSquare, PlayCircle, PlusCircle, PlusCircleDotted, Trash, TriangleHalf } from 'react-bootstrap-icons';
import { Spinner, Tab, Nav, ButtonGroup, OverlayTrigger, Tooltip } from 'react-bootstrap';
import ReactDataGrid from '@inovua/reactdatagrid-community';
import '@inovua/reactdatagrid-community/index.css';
import common from './commonData';
import Axios from 'axios';
import dateformat from 'dateformat';
import AddUserRight from './AddUserRight';

function Config() {
    const [users, setUsers] = useState([]);
    const history = useHistory();
    const [load, setLoad] = useState(true);
    const [gridRef, setGridRef] = useState(null);
    const [showAddRight, setShowAddRight] = useState(false);
    const [data, setdata] = useState();

    const [msg, setMsg] = useState('');
    const [msgGravity, setMsgravity] = useState('info');
    const [isSave, setIsSave] = useState(false);

    const log = common.search(common.userdroit, 'SAD');
    const gridStyle = { minHeight: window.screen.height - 200 };
    const filterValue = [
        { name: 'name', operator: 'contains', type: 'string', value: '' },
        { name: 'email', operator: 'contains', type: 'string', value: '' }
    ];
    const columnss = [
        {
            name: 'id',
            header: 'Id',
            defaultVisible: false,
            defaultWidth: 80,
            type: 'number'
        },
        {
            defaultWidth: 70,
            render: ({ data }) => (
                <div className="rgt-cell-inner" style={{ display: 'flex', alignItems: 'center', overflow: 'hidden' }}>
                    <Link to={`/admin/usersup/${data.id}`}>
                        {<img alt="" className="imgart rounded" src={common.ipapi + '/' + data.photo}></img>}
                    </Link>
                </div>
            )
        },
        {
            name: 'name',
            header: 'Noms',
            defaultWidth: 200,
            type: 'string'
        },
        {
            name: 'email',
            header: 'Email',
            defaultWidth: 200,
            type: 'string'
        },
        {
            name: 'created_at',
            header: 'Crée le',
            defaultWidth: 130,
            type: 'string',
            render: ({ value }) => dateformat(value, 'dd-mm-yyyy HH:MM')
        },
        {
            header: 'Action',
            defaultWidth: 130,
            type: 'string',
            render: ({ data }) => (
                <div className="menudrops">
                    <ButtonGroup aria-label="Basic example">
                        <OverlayTrigger overlay={<Tooltip id="tooltip-disabled">Modifier</Tooltip>}>
                            <Link to={`/admin/usersup/${data.id}`} id="actionbtn">
                                <PencilSquare size={25} color="green" />
                            </Link>
                        </OverlayTrigger>
                        <OverlayTrigger overlay={<Tooltip id="tooltip-disabled">Ajouter des droits</Tooltip>}>
                            <PlusCircle id="actionbtn" onClick={() => setShowAddRights(data)} size={25} color="blue" />
                        </OverlayTrigger>
                        <OverlayTrigger overlay={<Tooltip id="tooltip-disabled">Supprimer</Tooltip>}>
                            <Trash id="actionbtn" onClick={() => onDelete(data.id)} color="red" size={25} />
                        </OverlayTrigger>
                    </ButtonGroup>
                </div>
            )
        }
    ];

    function setShowAddRights(val) {
        setShowAddRight(true);
        setdata(val);
    }
    useEffect(() => {
        if (localStorage.getItem('user-info')) {
            if (log != undefined) {
                setLoad(true);
                const getUser = Axios.get(common.ipapi + '/api/user/indexAll');
                Axios.all([getUser])
                    .then(
                        Axios.spread((...responses) => {
                            setUsers(responses[0].data);
                            setLoad(false);
                        })
                    )
                    .catch((errors) => {
                        setLoad(false);
                    });
                setLoad(true);
            }
        } else {
            history.push('/login');
        }
    }, [history]);

    function onDelete(id) {
        if (window.confirm('Voulez-vous supprimer ce utilisateur ?')) {
        }
    }

    return (
        <>
            {load ? <Spinner animation="grow" variant="warning" /> : ''}
            {showAddRight ? (
                <AddUserRight
                    data={data}
                    setShowAddRight={setShowAddRight}
                    showAddRight={showAddRight}
                    setIsSave={setIsSave}
                    setMsg={setMsg}
                    setMsgravity={setMsgravity}
                    setLoad={setLoad}
                />
            ) : (
                <></>
            )}
            {log != undefined ? (
                <div className="content">
                    <Row>
                        <Col md="12">
                            <Card>
                                <Tab.Container id="left-tabs-example" defaultActiveKey="first">
                                    <Row>
                                        <Col sm={3}>
                                            <Nav variant="pills" className="flex-column">
                                                <Nav.Item>
                                                    <Nav.Link eventKey="first">Général</Nav.Link>
                                                </Nav.Item>
                                                <Nav.Item>
                                                    <Nav.Link eventKey="second">Utilisateurs</Nav.Link>
                                                </Nav.Item>
                                                <Nav.Item>
                                                    <Nav.Link eventKey="third">Droit & permissions</Nav.Link>
                                                </Nav.Item>
                                            </Nav>
                                        </Col>
                                        <Col sm={9}>
                                            <Tab.Content>
                                                <Tab.Pane eventKey="first">Général</Tab.Pane>
                                                <Tab.Pane eventKey="second">
                                                    <Nav variant="pills" activeKey="1" className="justify-content-end">
                                                        <Link to="/admin/usersadd">
                                                            <Button variant="light">
                                                                <PlusCircleDotted color="red" size={20} /> Nouvel utilisateur
                                                            </Button>
                                                        </Link>
                                                    </Nav>
                                                    {/* <Tables columns={columns} rows={users} /> */}

                                                    <ReactDataGrid
                                                        idProperty="id"
                                                        handle={setGridRef}
                                                        style={gridStyle}
                                                        // loading={isLoading}
                                                        reorderColumns={false}
                                                        pagination
                                                        pageSizes={[200, 400, 600, 800, 1000]}
                                                        defaultLimit={400}
                                                        showHoverRows={true}
                                                        editable={true}
                                                        showColumnMenuTool={false}
                                                        defaultFilterValue={filterValue}
                                                        columns={columnss}
                                                        dataSource={users}
                                                    />
                                                </Tab.Pane>
                                                <Tab.Pane eventKey="third">permissions</Tab.Pane>
                                            </Tab.Content>
                                        </Col>
                                    </Row>
                                </Tab.Container>
                            </Card>
                        </Col>
                    </Row>
                </div>
            ) : (
                <Card style={{ width: '25rem' }} className="centerCard">
                    <CardBody>
                        <CardTitle tag="h5">
                            <TriangleHalf size={60} color="red"></TriangleHalf> Avertissement
                        </CardTitle>
                        <CardText>Vous n'êtes pas autorisé à utiliser cette section. Merci de contacter votre administrateur</CardText>
                    </CardBody>
                </Card>
            )}
        </>
    );
}

export default Config;
