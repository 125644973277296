import React, { useEffect, useState, useRef } from 'react';
import { Form, InputGroup, Spinner, Modal, Card, Alert } from 'react-bootstrap';
import { Button, ButtonGroup, Row, Col } from 'reactstrap';
import Axios from 'axios';
import { Backspace, Save2Fill, Trash, TriangleHalf } from 'react-bootstrap-icons';
import 'react-bootstrap-typeahead/css/Typeahead.css';
import dateformat from 'dateformat';
import * as Yup from 'yup';
import { Formik } from 'formik';

import common from '../../commonData';
import Table from '../../tableaddedit';

var count = 0;

function AddSortie({ setShowAddLiv, show, setIsSave, setMsg, setMsgravity, data, setLoading, loading }) {
    var ladate = new Date();
    const [load, setLoad] = useState(false);
    const [qtesup, setQtesup] = useState(false);
    const [qtesupmsg, setQtesupmsg] = useState(false);
    const [listeSelect, setListeSelect] = useState([]);

    useEffect(() => {
        var t = [];
        data.lignefacture.forEach((ent, index) => {
            let d = {
                id: 0,
                article_id: ent.id,
                descriptionart: ent.pivot.descriptionart,
                quantite: ent.pivot.quantite,
                qrbarre: ent.qrbarre,
                prix: ent.prixvente
            };
            t.push(d);
        });
        setListeSelect(t);
    }, []);

    const columns = [
        {
            Header: 'Code art.',
            accessor: 'qrbarre'
        },
        {
            Header: 'Descr.art.',
            accessor: 'descriptionart'
        },
        {
            Header: 'Qté',
            accessor: 'quantite'
        },
        {
            Header: '#',
            accessor: '[editButton]',
            Cell: (row) => (
                <ButtonGroup aria-label="Basic example">
                    <Trash onClick={() => onDelete(row)} color="red" size={25} />
                </ButtonGroup>
            )
        }
    ];

    function onDelete(row) {
        setLoad(true);
        const del = listeSelect.filter((d) => d.article_id !== row.row.original.article_id);
        setListeSelect(del);
        setMsg('Ligne supprimée');
        setMsgravity('success');
        setIsSave(true);
        setLoad(false);
    }

    function saveSortie(values) {
        if (window.confirm('Voulez-vous confirmer cette action') === false) {
            return;
        }
        try {
            if (listeSelect.length === 0) {
                setMsg('Veuillez sélectionner les produits');
                setMsgravity('warning');
                setIsSave(true);
                setLoad(false);
                return;
            }
            setLoad(true);
            var formData = new FormData();
            formData.append('fact', 0);
            formData.append('datesort', values.datesort);
            formData.append('clients_id', data.clientid);
            formData.append('idfact', data.id);
            formData.append('nobon', values.numbon);
            formData.append('demand_par', '');
            formData.append('engin', '');
            formData.append('codeart', JSON.stringify(listeSelect));
            formData.append('user_id', common.userid);
            Axios({
                url: common.ipapi + '/api/sortie/create',
                method: 'POST',
                headers: {
                    'Content-Type': 'form-data'
                },
                data: formData
            })
                .then((res) => {
                    if (res.status !== 200) {
                        setMsg('Veuillez vérifier votre connexion internet');
                        setMsgravity('warning');
                        setIsSave(false);
                        return;
                    }
                    if (res.data.status === 1) {
                        setMsg(res.data.msg);
                        setMsgravity('info');
                        setIsSave(true);
                        count++;
                        setLoading(!loading);
                        setLoad(false);
                        setTimeout(handleCloses, 600);
                    } else if (res.data.status === 2) {
                        setMsg(res.data.msg);
                        setMsgravity('danger');
                        setIsSave(true);
                        setLoad(false);
                        setQtesup(true);
                        setQtesupmsg(res.data.msg);
                    } else {
                        if (res.data.code === 23000) {
                            setMsg('Ce numéro de bon existe déja');
                            setMsgravity('danger');
                            setIsSave(true);
                            setLoad(false);
                        } else {
                            setMsg(res.data.msg);
                            setMsgravity('danger');
                            setIsSave(true);
                            setLoad(false);
                        }
                    }
                })
                .catch((error) => {
                    setMsg('Veuillez vérifier votre connexion internet');
                    setIsSave(true);
                    setMsgravity('danger');
                    setLoad(false);
                });
        } catch (error) {
            setMsg('Veuillez vérifier votre connexion internet');
            setIsSave(true);
            setMsgravity('danger');
            setLoad(false);
        }
    }

    const handleCloses = () => {
        setShowAddLiv(false);
        setIsSave(false);
    };

    return (
        <>
            <Modal show={load} size="sm" aria-labelledby="contained-modal-title-vcenter" centered>
                <Modal.Body>
                    Veuillez patienter.......
                    <Spinner animation="border" variant="success" />
                </Modal.Body>
            </Modal>

            <Formik
                initialValues={{
                    numbon: '',
                    datesort: dateformat(ladate.getMonth() + 1 + '/' + ladate.getDate() + '/' + ladate.getFullYear(), 'yyyy-mm-dd'),
                    user_id: common.userid,
                    submit: null
                }}
                // enableReinitialize
                validationSchema={Yup.object().shape({
                    datesort: Yup.date().required(common.msg)
                })}
                onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
                    try {
                        saveSortie(values);
                        setSubmitting(false);
                    } catch (err) {
                        setMsg('Une erreur à eu lieu');
                        setMsgravity('danger');
                        setIsSave(true);
                        setLoad(false);
                    }
                }}
            >
                {({ errors, handleBlur, handleChange, handleSubmit, isSubmitting, touched, values, setFieldValue, setFieldTouched }) => (
                    <Modal show={show} fullscreen={true}>
                        <div className="content">
                            <Form noValidate onSubmit={handleSubmit}>
                                {/*noValidate */}
                                <Modal.Header>
                                    FAIRE UNE SORTIE DE STOCK
                                    <Backspace onClick={handleCloses} color="red" size={30} />
                                </Modal.Header>
                                <Modal.Body>
                                    {qtesup ? (
                                        <Alert key="danger" transition="Fade" variant="danger">
                                            <TriangleHalf color="red" size={30} />
                                            {qtesupmsg}
                                        </Alert>
                                    ) : (
                                        <></>
                                    )}
                                    <div id="lightbody">
                                        <Row>
                                            <Col md={4}>
                                                <Card bg="light" border="dark">
                                                    <Card.Body>
                                                        <Card.Title>Entête de la facture </Card.Title>
                                                        <Form.Group as={Row} className="mb-3">
                                                            <Form.Label column sm={3}>
                                                                Nom client:
                                                            </Form.Label>
                                                            <Col sm={9}>
                                                                <InputGroup>{data.client}</InputGroup>
                                                            </Col>
                                                        </Form.Group>

                                                        <Form.Group as={Row} className="mb-3">
                                                            <Form.Label column sm={3}>
                                                                N° bon:
                                                            </Form.Label>
                                                            <Col sm={9}>
                                                                <Form.Control
                                                                    id="numbon"
                                                                    type="text"
                                                                    placeholder="N° de bon n'est pas obligatoire"
                                                                    aria-describedby="inputGroupPrepend"
                                                                    size="sm"
                                                                    value={values.numbon}
                                                                    onBlur={handleBlur}
                                                                    onChange={handleChange}
                                                                    // onChange={(e) => setNobon(e.target.value)}
                                                                />
                                                            </Col>
                                                        </Form.Group>

                                                        <Form.Group as={Row} className="mb-3">
                                                            <Form.Label column sm={3}>
                                                                Date sortie:
                                                            </Form.Label>
                                                            <Col sm={9}>
                                                                <Form.Control
                                                                    id="datesort"
                                                                    type="date"
                                                                    placeholder="Date sortie"
                                                                    aria-describedby="inputGroupPrepend"
                                                                    required
                                                                    size="sm"
                                                                    value={values.datesort}
                                                                    onBlur={handleBlur}
                                                                    onChange={handleChange}
                                                                />
                                                                {errors.datesort && <div id="feedback">{errors.datesort}</div>}
                                                            </Col>
                                                        </Form.Group>
                                                    </Card.Body>
                                                </Card>
                                            </Col>
                                            <Col md={8}>
                                                <Card bg="light" border="dark">
                                                    <Card.Body>
                                                        <Card.Title>Liste des articles à livrer </Card.Title>
                                                        <Table columns={columns} data={listeSelect} />
                                                    </Card.Body>
                                                </Card>
                                            </Col>
                                        </Row>
                                    </div>
                                </Modal.Body>
                                <Modal.Footer>
                                    <div className="ml-auto">
                                        <ButtonGroup size="sm">
                                            <Button color="secondary" onClick={handleCloses}>
                                                <Backspace color="red" size={25} />
                                            </Button>
                                            <Button type="submit" color="warning">
                                                <Save2Fill color="#000" size={25} /> Enregistrer
                                            </Button>
                                        </ButtonGroup>
                                    </div>
                                </Modal.Footer>
                            </Form>
                        </div>
                    </Modal>
                )}
            </Formik>
        </>
    );
}

export default AddSortie;
