import { useHistory } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import { Spinner, Toast, Modal } from 'react-bootstrap';
import { Card, CardBody, CardText, CardTitle, Row, Col } from 'reactstrap';
import { TriangleHalf } from 'react-bootstrap-icons';
import Tables from './gridtables';
import 'react-bootstrap-typeahead/css/Typeahead.css';
import Axios from 'axios';
import 'jspdf-autotable';
import dateformat from 'dateformat';

import common from '../../commonData';
import AddInventaire from './addInventaire';

var dta = [];
function ListeInventaire() {
    var ladate = new Date();
    const history = useHistory();
    const [datas, setDatas] = useState([]);
    const [articles, setArticles] = useState([]);
    const [load, setLoad] = useState(false);
    const [actualise, setActualise] = useState(false);
    const [msg, setMsg] = useState('');
    const [isSave, setIsSave] = useState(false);
    const [idInventaire, setId] = useState(0);
    const [states, setState] = useState({
        commentaire: '',
        titre: '',
        dateInventaire: dateformat(ladate.getMonth() + 1 + '/' + ladate.getDate() + '/' + ladate.getFullYear(), 'yyyy-mm-dd')
    });
    // const { commentaire, titre, dateInventaire } = states;

    const [msgGravity, setMsgravity] = useState('success');
    const [showAdd, setShowAdd] = useState(false);

    useEffect(() => {
        if (!localStorage.getItem('user-info')) {
            history.push('/login');
        }
        const log = common.search(common.userdroit, 'SAD');
        const log1 = common.search(common.userdroit, 'FAC');
        if (log === undefined) {
            if (log1 === undefined) {
                setMsg("Vous n'avez pas le droit d'effectuer cette action, merci de contacter l'administrateur");
                setMsgravity('danger');
                setIsSave(true);
                return;
            }
        }
        setLoad(true);
        // if (localStorage.getItem('user-info')) {
        (async function anyNameFunction() {
            const getInventaire = Axios.get(common.ipapi + '/api/inventaire/index');
            await Axios.all([getInventaire])
                .then(
                    Axios.spread((...response) => {
                        dta = response[0].data;
                        setDatas(response[0].data);
                        setLoad(false);
                    })
                )
                .catch((errors) => {
                    setLoad(false);
                });
        })();
        // } else {
        //     history.push('/login');
        // }
    }, [history, actualise]);

    function onDelete(id) {
        const log = common.search(common.userdroit, 'SAD');
        const log1 = common.search(common.userdroit, 'SINV');
        if (log === undefined) {
            if (log1 === undefined) {
                setMsg("Vous n'avez pas le droit d'effectuer cette action, merci de contacter l'administrateur");
                setMsgravity('danger');
                setIsSave(true);
                return;
            }
        }
        if (window.confirm('Voulez-vous supprimer cette ligne ?')) {
            setLoad(true);
            Axios.delete(common.ipapi + '/api/inventaire/destroy/' + id).then((response) => {
                if (response.data.status === 1) {
                    const del = datas.filter((d) => d.id !== id);
                    dta = del;
                    setDatas(del);
                    setMsg(response.data.msg);
                    setMsgravity('success');
                    setIsSave(true);
                    setLoad(false);
                } else {
                    setMsg(response.data.msg);
                    setIsSave(true);
                    setLoad(false);
                    setMsgravity('danger');
                }
            });
        }
    }

    function setAdds() {
        const log = common.search(common.userdroit, 'SAD');
        const log1 = common.search(common.userdroit, 'ADS');
        if (log === undefined) {
            if (log1 === undefined) {
                setMsg("Vous n'avez pas le droit d'effectuer cette action, merci de contacter l'administrateur");
                setMsgravity('danger');
                setIsSave(true);
                return;
            }
        }
        setId(0);
        setShowAdd(true);
    }
    function setIds(id) {
        const log = common.search(common.userdroit, 'SAD');
        const log1 = common.search(common.userdroit, 'ADS');
        if (log === undefined) {
            if (log1 === undefined) {
                setMsg("Vous n'avez pas le droit d'effectuer cette action, merci de contacter l'administrateur");
                setMsgravity('danger');
                setIsSave(true);
                return;
            }
        }
        setLoad(true);
        Axios.get(common.ipapi + '/api/inventaire/edit/' + id)
            .then((response) => {
                setArticles(response.data.datas[0]);
                setState({
                    commentaire: response.data.datas[1][0].commentaire,
                    titre: response.data.datas[1][0].titre,
                    dateInventaire: response.data.datas[1][0].dateinventaire
                });
                setId(id);
                setLoad(false);
                setShowAdd(true);
            })
            .catch((errors) => {
                setLoad(false);
            });
    }

    return (
        <>
            <Toast id="toast" onClose={() => setIsSave(false)} show={isSave} delay={3000} className={'bg-' + msgGravity} autohide>
                <Toast.Header>
                    <img src="holder.js/20x20?text=%20" className="rounded me-2" alt="" />
                    <strong className="me-auto">Info</strong>
                </Toast.Header>
                <Toast.Body className="dark">{msg}</Toast.Body>
            </Toast>
            <Modal show={load} size="sm" aria-labelledby="contained-modal-title-vcenter" centered>
                <Modal.Body>
                    Veuillez patienter.......
                    <Spinner animation="border" variant="success" />
                </Modal.Body>
            </Modal>
            {showAdd ? (
                <AddInventaire
                    id={idInventaire}
                    articlesListe={articles}
                    statesData={states}
                    setShowAdd={setShowAdd}
                    show={showAdd}
                    setIsSave={setIsSave}
                    isSave={isSave}
                    setMsg={setMsg}
                    setMsgravity={setMsgravity}
                    setActualise={setActualise}
                    actualise={actualise}
                />
            ) : (
                <></>
            )}
            {/* {showEdit ? (
                <UpdateInventaire
                    id={idInventaire}
                    setShowEdit={setShowEdit}
                    show={showEdit}
                    setIsSave={setIsSave}
                    setId={setId}
                    setMsg={setMsg}
                    setMsgravity={setMsgravity}
                    setActualise={setActualise}
                    actualise={actualise}
                />
            ) : (
                <></>
            )} */}

            {common.search(common.userdroit, 'SAD') !== undefined || common.search(common.userdroit, 'FAC') !== undefined ? (
                <div className="content contents">
                    <Row>
                        <Col md="12">
                            <div id="tablecontent">
                                <Tables
                                    rows={datas}
                                    isLoading={load}
                                    onDelete={onDelete}
                                    setIds={setIds}
                                    setIsSave={setIsSave}
                                    setMsg={setMsg}
                                    setAdds={setAdds}
                                />
                            </div>
                        </Col>
                    </Row>
                </div>
            ) : (
                <Card style={{ width: '25rem' }} className="centerCard">
                    <CardBody>
                        <CardTitle tag="h5">
                            <TriangleHalf size={60} color="red"></TriangleHalf> Avertissement
                        </CardTitle>
                        <CardText>Vous n'êtes pas autorisé à utiliser cette section. Merci de contacter votre administrateur</CardText>
                    </CardBody>
                </Card>
            )}
        </>
    );
}
export default ListeInventaire;
