import React from 'react';
import { Container, Nav, Navbar, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { PlusCircleDotted, PencilSquare, Trash } from 'react-bootstrap-icons';

import 'jspdf-autotable';
import 'assets/css/table.css';

function NavMenu({ selected, setIds, setAdds, onRemoveLigne }) {
    const toArray = (selected) => Object.keys(selected).map((id) => id * 1);
    const id = JSON.stringify(toArray(selected)).replace('[', '').replace(']', '');
    var qte_livre = 0;
    if (id) {
        qte_livre = selected[id].qte_livre;
    }

    return (
        <Navbar expand="lg" bg="light" variant="light">
            <Container>
                {/* <Navbar.Brand href="#/admin/sorties">LISTE DES FACTURES CLIENTS </Navbar.Brand> */}
                <Nav className="me-auto">
                    <OverlayTrigger placement="top" overlay={<Tooltip id="tooltip-disabled">Editer une commande fournisseur</Tooltip>}>
                        <Nav.Link to="#/admin/commandefrs" onClick={() => setAdds()}>
                            <PlusCircleDotted color="orange" size={20} /> Créer
                        </Nav.Link>
                    </OverlayTrigger>
                    {!qte_livre ? (
                        <>
                            <OverlayTrigger overlay={<Tooltip id="tooltip-disabled">Modifier la sélectiontion</Tooltip>}>
                                <Nav.Link to="#/admin/commandefrs" onClick={() => setIds(id)}>
                                    <PencilSquare color="blue" size={20} /> Modifier
                                </Nav.Link>
                            </OverlayTrigger>
                            <OverlayTrigger overlay={<Tooltip id="tooltip-disabled">Supprimer la sélectiontion</Tooltip>}>
                                <Nav.Link to="#/admin/commandefrs" onClick={() => onRemoveLigne(id)}>
                                    <Trash color="#ff031b" size={20} /> Supprimer
                                </Nav.Link>
                            </OverlayTrigger>
                        </>
                    ) : (
                        <></>
                    )}
                </Nav>
            </Container>
        </Navbar>
    );
}
export default NavMenu;
