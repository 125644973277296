import React from 'react';
import GridTable from '@nadavshaar/react-grid-table';
import 'assets/css/table.css';
function MyAwesomeTable({ columns, rows, isLoading }) {
    const config = {
        search: 'Recherche:',
        totalRows: 'Total ligne:',
        rows: 'Ligne:',
        selected: 'Selected',
        rowsPerPage: 'Ligne par page:',
        page: 'Page:',
        of: 'sur',
        prev: 'Prec',
        next: 'Suiv',
        columnVisibility: 'Colonne visible'
    };
    return (
        <div id="table1">
            <GridTable
                columns={columns}
                texts={config}
                isLoading={isLoading}
                isVirtualScroll={false}
                pageSizes={[200, 500, 1000]}
                rows={rows}
                minColumnResizeWidth={30}
            />
        </div>
    );
}

export default MyAwesomeTable;
