import React, { useEffect } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import { Collapse, Navbar, NavbarToggler, NavbarBrand, Nav, Container } from 'reactstrap';
import Axios from 'axios';
import common from '../../views/commonData';
import { BoxArrowRight, Gear } from 'react-bootstrap-icons';

import routes from 'routes.js';
import { Badge, Button, OverlayTrigger, Spinner, Tooltip } from 'react-bootstrap';

function Header(props) {
    const [isOpen, setIsOpen] = React.useState(false);
    // const [dropdownOpen, setDropdownOpen] = React.useState(false);
    const [color, setColor] = React.useState('transparent');
    const sidebarToggle = React.useRef();
    const location = useLocation();
    var [nb, setNB] = React.useState(0);
    const toggle = () => {
        if (isOpen) {
            setColor('transparent');
        } else {
            setColor('dark');
        }
        setIsOpen(!isOpen);
    };
    const history = useHistory();
    function signOut(e) {
        e.preventDefault();
        localStorage.clear();
        history.push('/login');
    }
    useEffect(() => {
        (async function anyNameFunction() {
            await Axios.get(common.ipapi + '/api/commandecli/nonvalid/dcount')
                .then((response) => {
                    setNB(response.data);
                })
                .catch((errors) => {});
        })();
    });

    async function myCallback() {
        await Axios.get(common.ipapi + '/api/commandecli/nonvalid/dcount')
            .then((response) => {
                setNB(response.data);
            })
            .catch((errors) => {});
        return;
    }
    setInterval(myCallback, 180000);
    // const dropdownToggle = (e) => {
    //   setDropdownOpen(!dropdownOpen);
    // };
    const getBrand = () => {
        let brandName = 'Default Brand';
        routes.map((prop, key) => {
            if (window.location.href.indexOf(prop.layout + prop.path) !== -1) {
                brandName = prop.title;
            }
            return null;
        });

        if (window.location.hash.indexOf('article/famille') !== -1) {
            brandName = 'Liste de article de famille';
        } else if (window.location.hash.indexOf('article/edit') !== -1) {
            brandName = "Modification d'article";
        } else if (window.location.hash.indexOf('article/fiche') !== -1) {
            brandName = 'Fiche article';
        } else if (window.location.hash.indexOf('addfacture') !== -1) {
            brandName = 'Editer une facture';
        } else if (window.location.hash.indexOf('addsortie') !== -1) {
            brandName = "Faire une sortie d'article";
        } else if (window.location.hash.indexOf('addentrees') !== -1) {
            brandName = "Faire une entrée d'article";
        } else if (window.location.hash.indexOf('entree/edit') !== -1) {
            brandName = "Modification entrée d'article";
        } else if (window.location.hash.indexOf('sortie/edit') !== -1) {
            brandName = "Modification sortie d'article";
        } else if (window.location.hash.indexOf('facture/edit') !== -1) {
            brandName = 'Modification de facture';
        } else if (window.location.hash.indexOf('enginhist') !== -1) {
            brandName = 'Historique des engins';
        }
        return brandName;
    };
    const openSidebar = () => {
        document.documentElement.classList.toggle('nav-open');
        sidebarToggle.current.classList.toggle('toggled');
    };
    // function that adds color dark/transparent to the navbar on resize (this is for the collapse)
    const updateColor = () => {
        if (window.innerWidth < 993 && isOpen) {
            setColor('dark');
        } else {
            setColor('transparent');
        }
    };
    React.useEffect(() => {
        window.addEventListener('resize', updateColor.bind(this));
    });
    React.useEffect(() => {
        if (window.innerWidth < 993 && document.documentElement.className.indexOf('nav-open') !== -1) {
            document.documentElement.classList.toggle('nav-open');
            sidebarToggle.current.classList.toggle('toggled');
        }
    }, [location]);
    return (
        // add or remove classes depending if we are on full-screen-maps page or not
        <Navbar
            color={props.location.pathname.indexOf('full-screen-maps') !== -1 ? 'dark' : color}
            expand="lg"
            className={
                props.location.pathname.indexOf('full-screen-maps') !== -1
                    ? 'navbar-absolute fixed-top'
                    : 'navbar-absolute fixed-top ' + (color === 'transparent' ? 'navbar-transparent ' : '')
            }
        >
            <Container fluid>
                <div className="navbar-wrapper">
                    <div className="navbar-toggle">
                        <button type="button" ref={sidebarToggle} className="navbar-toggler" onClick={() => openSidebar()}>
                            <span className="navbar-toggler-bar bar1" />
                            <span className="navbar-toggler-bar bar2" />
                            <span className="navbar-toggler-bar bar3" />
                        </button>
                    </div>
                    <NavbarBrand>{getBrand()}</NavbarBrand>
                </div>
                <NavbarToggler onClick={toggle}>
                    <span className="navbar-toggler-bar navbar-kebab" />
                    <span className="navbar-toggler-bar navbar-kebab" />
                    <span className="navbar-toggler-bar navbar-kebab" />
                </NavbarToggler>
                <Collapse isOpen={isOpen} navbar className="justify-content-end">
                    {/* <form>
            <InputGroup className="no-border">
              <Input placeholder="Search..." />
              <InputGroupAddon addonType="append">
                <InputGroupText>
                  <i className="nc-icon nc-zoom-split" />
                </InputGroupText>
              </InputGroupAddon>
            </InputGroup>
          </form> */}
                    <Nav>
                        <div>
                            <div className="content">
                                {/* <div className="content">
                                    <a href="#/admin/config" id="actionbtn">
                                        <Gear size={25} color="orange" />
                                    </a>
                                </div> */}
                                {nb && common.userclientid == undefined ? (
                                    <OverlayTrigger
                                        placement="bottom"
                                        overlay={<Tooltip id="tooltip-disabled">Nouvelle commande client en attente de livraison</Tooltip>}
                                    >
                                        <a href="#/admin/commandecli">
                                            <Button variant="dark" size="sm">
                                                <Spinner
                                                    as="span"
                                                    animation="grow"
                                                    variant="light"
                                                    size="sm"
                                                    role="status"
                                                    aria-hidden="true"
                                                />
                                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                                <Badge bg="dark">{nb}</Badge>
                                            </Button>
                                        </a>
                                    </OverlayTrigger>
                                ) : (
                                    <></>
                                )}
                            </div>
                            {/* <div className="content">
                                <img alt="" className="userimg rounded" src={common.ipapi + '/' + common.usertof}></img>
                            </div> */}
                            <div className="content">
                                <span className="user"> {common.user}</span>
                            </div>

                            <div className="content">
                                <OverlayTrigger placement="bottom" overlay={<Tooltip id="tooltip-disabled">Se déconnecter</Tooltip>}>
                                    <BoxArrowRight onClick={signOut} size={40} color="orange" />
                                </OverlayTrigger>
                            </div>
                        </div>
                    </Nav>
                </Collapse>
            </Container>
        </Navbar>
    );
}

export default Header;
