import React, { useState, useCallback, useEffect } from 'react';

import moment from 'moment';
import ReactDataGrid from '@inovua/reactdatagrid-community';
import '@inovua/reactdatagrid-community/index.css';
import 'jspdf-autotable';
import 'assets/css/table.css';

function MyAwesomeTable({ articles, setArticles, isLoading, isEdit, setListeSelect }) {
    window.moment = moment;
    // const [selected, setSelected] = useState({});
    const [gridRef, setGridRef] = useState(null);
    const filterValue = [
        { name: 'qrbarre', operator: 'contains', type: 'string', value: '' },
        { name: 'marque', operator: 'contains', type: 'string', value: '' },
        { name: 'famille', operator: 'contains', type: 'string', value: '' },
        { name: 'descriptionartfr', operator: 'contains', type: 'string', value: '' },
        { name: 'emplacement', operator: 'contains', type: 'string', value: '' },
        { name: 'qtestock', operator: 'gte', type: 'number', value: 0 }
    ];

    const columnss = [
        {
            name: 'id',
            header: 'Id',
            defaultVisible: false,
            defaultWidth: 80,
            type: 'number'
        },
        {
            name: 'qrbarre',
            header: 'Code article',
            defaultWidth: 150,
            type: 'string',
            editable: false,
            render: ({ value, data }) => <p id={data.etat == 0 || data.etat == undefined ? 'successs' : 'success'}>{data.qrbarre}</p>
        },
        {
            name: 'descriptionartfr',
            header: 'Description',
            defaultWidth: 180,
            type: 'string',
            editable: false
        },
        {
            name: 'marque',
            header: 'Marque',
            defaultWidth: 130,
            type: 'string',
            editable: false,
            render: ({ value, data }) => <p id={data.idmarque == 0 || data.idmarque == undefined ? 'success' : 'successs'}>{data.marque}</p>
        },
        {
            name: 'famille',
            header: 'Model',
            defaultWidth: 130,
            type: 'string',
            editable: false,
            render: ({ value, data }) => (
                <p id={data.famille_id == 0 || data.famille_id == undefined ? 'success' : 'successs'}>{data.famille}</p>
            )
        },
        {
            name: 'emplacement',
            header: 'Emplacement',
            defaultWidth: 130,
            type: 'string',
            editable: false
        },
        {
            name: 'qtestock',
            header: 'Stock réel',
            textAlign: 'center',
            defaultWidth: 130,
            type: 'string',
            editable: false
        },
        {
            name: 'qtestocks',
            header: 'Nouveau ',
            defaultWidth: 130,
            type: 'string'
        },
        {
            name: 'qtediff',
            header: 'Différence',
            defaultWidth: 130,
            type: 'string',
            editable: false
        }
    ];

    const gridStyle = { minHeight: window.screen.height - 200 };

    const isStartEditKeyPressed = ({ event }) => event.key === 'k' && event.ctrlKey;

    const onEditComplete = useCallback(
        ({ value, columnId, rowId }) => {
            const data = [...articles];
            if (value !== undefined && value >= 0) {
                const isLargeNumber = (element) => element.id === rowId;
                data[data.findIndex(isLargeNumber)].qtestocks = value;
                data[data.findIndex(isLargeNumber)].qtediff = value - data[data.findIndex(isLargeNumber)].qtestock;
            }
            setArticles(data);
            const filteredProduct = data.filter((product, index) => {
                return product.qtestocks !== undefined && product.qtediff !== 0;
            });
            setListeSelect(filteredProduct);
        },
        [articles]
    );

    return (
        <div>
            <ReactDataGrid
                idProperty="id"
                handle={setGridRef}
                style={gridStyle}
                loading={isLoading}
                reorderColumns={false}
                pagination
                // selected={selected}
                isStartEditKeyPressed={isStartEditKeyPressed}
                pageSizes={[200, 400, 600, 800, 10000]}
                defaultLimit={10000}
                showHoverRows={true}
                editable={isEdit}
                onEditComplete={onEditComplete}
                showColumnMenuTool={false}
                defaultFilterValue={filterValue}
                columns={columnss}
                dataSource={articles}
            />
        </div>
    );
}

export default MyAwesomeTable;
