import {useHistory,Link} from 'react-router-dom'
import React,{useEffect,useState} from 'react'
import {Spinner,Modal,Form,InputGroup,Toast,FormGroup, Navbar, Container, Nav} from "react-bootstrap";
import {
  Card,Button,NavItem,NavLink,
  Row,
  Col,
  CardBody,
} from "reactstrap";
import { PencilSquare,Eye, Trash, CartCheck, ArrowBarUp, ArrowBarDown, Printer, Save2Fill, ArrowLeft, FileExcel, PlusCircleDotted } from 'react-bootstrap-icons';
import {
  ButtonGroup,
  
} from "react-bootstrap";
import Axios from 'axios';
import {Typeahead} from 'react-bootstrap-typeahead';
import 'react-bootstrap-typeahead/css/Typeahead.css';
import dateformat from 'dateformat';
import Tables from '../../tableData'
import common from '../../commonData'

var dta = []
function ListeArticleLost() {

  const history = useHistory();
  const [validated, setValidated] = useState(false);
  const [datas,setDatas]=useState([])
  const [load,setLoad]=useState(false)
  const [show, setShow] = useState(false);
  const [codeart,setCodeart]=useState([])
  const [article,setArticle]=useState([])
  const [quantite,setQuantite]=useState(1)
  const [date,setDate]=useState("")
  const [motif,setMotif]=useState("")
  const [isSave,setIsSave]=useState(false)
  const [msgGravity,setMsgravity]=useState("success")
  const [msg,setMsg]=useState("")
  const [id,setId]=useState(0)
  const [title,setTitle]=useState('Ajouter un article perdus')
  const handleClose = () => {
    setShow(false)
    setTitle('Ajouter un article perdus')
    setCodeart([])
  }
  const handleShow = () => {
    setShow(true);
    setCodeart([])
    setQuantite(1)
    setMotif("")
  }
  useEffect(()=>{
    setLoad(true)
    if (localStorage.getItem("user-info")) {
        (async function anyNameFunction() {
            const getarticle = Axios.get(common.ipapi+'/api/article/indexall')
            const getArticleLost = Axios.get(common.ipapi+'/api/article/articlelost')
            await Axios.all([getarticle,getArticleLost]).then(Axios.spread((...responses) => {
            setArticle(responses[0].data)
            setDatas(responses[1].data)
            dta = responses[1].data
            setLoad(false)
            })).catch(errors => {
            setLoad(false)
            })
        })();
    }else{
      history.push("/login")
    }
  },[])
  
  const handleSubmit = (event) => {
    event.preventDefault();
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
        event.stopPropagation();
    }
    setValidated(true);
    saveDamag()
  };
  function saveDamag(e){
    setLoad(true)
    var url = ""
    var formData = new FormData();
    formData.append('codeart',codeart[0].id);
    formData.append('motif',motif);
    formData.append('date',date);
    formData.append('quantite',quantite);
    formData.append('user_id',common.userid);
    if(id !== 0){
        url=common.ipapi+"/api/article/updatelost/"+id
    }else{
        url=common.ipapi+"/api/article/storelost"
    }
    Axios({
      url: url,
      method:'POST',
      headers:{
        'Content-Type':'form-data'
      },data:formData})
    .then(res=>{
        setIsSave(true)
        setCodeart("")
        setMotif("")
        setQuantite(1)
        setShow(false)
        setMsgravity("info")
        setMsg(res.data.msg)
        setIsSave(true)
        setId(0)
        setLoad(false)
        
    }).catch(error=>{
        setMsgravity("danger")
        setMsg(error)
        setIsSave(true)
        setLoad(false)
    }) 
}
  function  onDelete (id) {
    if(window.confirm("Voulez-vous supprimer cette ligne ?")){
      setLoad(true)
      Axios.delete(common.ipapi+'/api/article/destroylost/'+id)
      .then(response=>{
        if(response.data.status==1){
          const del = dta.filter(d=>d.id !== id)
          dta=del
          setDatas(del)
          setLoad(false)
        }else{
         
        }
      })
    }
  }
  function IsModif(ids){
    setLoad(true)
    Axios.get(common.ipapi+'/api/article/editlost/'+ids)
        .then(response=>{
          setMotif(response.data.motif)
          setQuantite(response.data.quantite)
          setDate(response.data.dateope)
          setId(response.data.id)
          const t = []
            t.push({
              id:response.data.article.id,
              article_id: response.data.article.article_id,
              codeart: response.data.article.codeart,
              descriptionart:response.data.article.descriptionart,
              urlart: response.data.article.urlart,
              qteseuil: response.data.article.qteseuil,
              qtestock: response.data.article.qtestock,
              qtestockinit: response.data.article.qtestockinit,
              coeficient: response.data.article.coeficient,
              prixachat: response.data.article.prixachat,
              famille_id: response.data.article.famille_id,
              marque_id: response.data.article.marque_id,
              fournisseur_id: response.data.article.fournisseur_id,
              entrepot_id: response.data.article.entrepot_id,
              user_id: response.data.article.user_id,
              created_at: response.data.article.created_at,
              updated_at: response.data.article.updated_at
            })
          setCodeart(t)
          setShow(true)
          setTitle('Modifier un article perdu')
          setLoad(false)
    })
  }
  
  const Images = ({ tableManager, value, field, data, column, colIndex, rowIndex }) => {
    return (
        <div className='rgt-cell-inner' style={{display: 'flex', alignItems: 'center', overflow: 'hidden'}}>
            <Link to={`#/admin/article/fiche/${data.article.id}`}>{data.article.urlart ? data.article.urlart.indexOf('sortly') ==- 1 ? <img className="imgart rounded" src={common.ipapi+data.article.urlart}></img> : <img className='imgart rounded' src={data.article.urlart}></img>  : "" }</Link>
        </div>
    )
  }
  const Operation = ({ tableManager, value, field, data, column, colIndex, rowIndex }) => {
    return (
      <div className='menudrop'>
      <ButtonGroup aria-label="Basic example">
          <ButtonGroup aria-label="Basic example">
          <Link to={`#/admin/article/fiche/${data.article_id}`}><Eye  size={23}  color='royalblue'/></Link>
          <a href="#/admin/articles-lost" onClick={()=>IsModif(data.id)}><PencilSquare  size={20}  color='green'/></a>
          <a href="articles-lost" onClick={()=>onDelete(data.id)}><Trash color="red" size={20} /></a>
        </ButtonGroup>
      </ButtonGroup>
      </div>
    )
  }
  const CPrivente = ({ tableManager, value, field, data, column, colIndex, rowIndex }) => {
    return (
      new Intl.NumberFormat('fr-FR', { style: 'currency', currency: 'CFA' }).format(data.article.prixachat*data.article.coeficient)
    )
  }
  const Perte = ({ tableManager, value, field, data, column, colIndex, rowIndex }) => {
    return (
      new Intl.NumberFormat('fr-FR', { style: 'currency', currency: 'CFA' }).format(data.article.prixachat*data.article.coeficient*data.quantite)
    )
  }
  const dates = ({ tableManager, value, field, data, column, colIndex, rowIndex }) => {
    return (
      dateformat(data.created_at,"dd-mm-yyyy HH:MM")
    )
  }

  const fam = ({ tableManager, value, field, data, column, colIndex, rowIndex }) => {
    return (
      data.article.famille ? data.article.famille.libellefam : ""
    )
  }
  const marq = ({ tableManager, value, field, data, column, colIndex, rowIndex }) => {
    return (
      data.article.marque ? data.article.marque.libellemarq : ""
    )
  }
  const entr = ({ tableManager, value, field, data, column, colIndex, rowIndex }) => {
    return (
      data.article.entrepot ? data.article.entrepot.libelleentrepot : ""
    )
  }
  const descr = ({ tableManager, value, field, data, column, colIndex, rowIndex }) => {
    return (
      data.article.descriptionart
    )
  }
  const codearts = ({ tableManager, value, field, data, column, colIndex, rowIndex }) => {
    return (
      data.article.qrbarreshort
    )
  }
  
  const columns = [
    {
      id: 1,  
      label: 'Images',
      cellRenderer: Images,
      sortable: false,
      pinned: true,
      width: '40px',
      minResizeWidth: 40,
    },  
    {
        id: 2, 
        cellRenderer: codearts, 
        label: 'Code article',
        width: '110px'
    }, 
    {
        id: 3, 
        cellRenderer: descr,
        label: 'Descriptions',
    },
    {
        id: 4, 
        cellRenderer: fam, 
        label: 'Famille',
    },
    {
        id: 5, 
        cellRenderer: marq, 
        label: 'Models',
        // getValue: ({value, column}) => value.x + value.y
    },
    {
      id: 6, 
      cellRenderer: entr, 
      label: 'Entrepots',
    // getValue: ({value, column}) => value.x + value.y
    },
    {
      id: 7, 
      field: 'quantite', 
      label: 'Quantité',
    // getValue: ({value, column}) => value.x + value.y
    },
    {
      id: 8, 
      field: 'article.prixachat', 
      label: 'Prix',
    // getValue: ({value, column}) => value.x + value.y
    },
    {
      id: 9,  
      label: 'P.vente.',
      cellRenderer: CPrivente
    // getValue: ({value, column}) => value.x + value.y
    },
    {
      id: 10,  
      label: 'Perte',
      cellRenderer: Perte
    // getValue: ({value, column}) => value.x + value.y
    },
    {
      id: 11, 
      field: 'motif', 
      label: 'Motifs',
    // getValue: ({value, column}) => value.x + value.y
    },
    {
      id: 12, 
      field: 'created_at', 
      label: 'Crée le',
      cellRenderer: dates
      // getValue: ({value, column}) => dateformat(created_at,"dd-mm-yyyy HH:MM")
    },
    {
      id: 13,  
      label: 'Actions',
      cellRenderer: Operation,
      pinned: true,
      sortable: false,
      width: '100px',
    },
  ];
  function saveAsExcel(){

  }
  function printListe(){

  }
    return (     
      <>
       <Toast id="toast" onClose={() => setIsSave(false)} show={isSave} delay={3000} bg={msgGravity} autohide>
              <Toast.Header>
                  <img
                    src="holder.js/20x20?text=%20"
                    className="rounded me-2"
                    alt=""
                  />
                  <strong className="me-auto">Info</strong>
              </Toast.Header>
              <Toast.Body>{msg}</Toast.Body>
          </Toast>
      <Modal show={load} size="sm" aria-labelledby="contained-modal-title-vcenter" centered>
        <Modal.Body>Veuillez patienter.......<Spinner animation="grow" variant="warning"/></Modal.Body>
      </Modal>
      <div className="content contents">
        <Navbar expand="lg" bg="light" variant="light">
          <Container>
            <Navbar.Brand href="#/admin/articles-lost">LISTE DES ARTICLES PERDUS</Navbar.Brand>
            <Nav className="me-auto">
              <Nav.Link href="#/admin/articles-lost" onClick={handleShow} title='Ajouter un article endommagé'><PlusCircleDotted color="#ee1111" size={20}/> Créer</Nav.Link>
              <Nav.Link href="#/admin/articles-lost" onClick={() => saveAsExcel(datas)} title='Exporter vers excel'><FileExcel color="#0080ff" size={20}/> Excel</Nav.Link>
              <Nav.Link href="#/admin/articles-lost" onClick={() => printListe(datas)} title='Exporter vers pdf'><Printer color="#0080ff" size={20}/> Imprimer</Nav.Link>
            </Nav>
          </Container>
        </Navbar>
        <Row>
          <Col md="12">

      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>{title}</Modal.Title>
        </Modal.Header>
        
        <Form noValidate validated={validated} onSubmit={handleSubmit}>
            <Modal.Body>
                    <Form.Group as={Col} md="12" controlId="validationCustomUsername">
                    <InputGroup hasValidation>
                        <InputGroup.Text id="inputGroupPrepend">Code article:</InputGroup.Text>
                        <Typeahead
                        id="my-typeahead-id" 
                        labelKey={option => `${option.libellefam || option.libellemarq } / ${option.qrbarre} / ${option.descriptionart} / qte (${option.qte})`}
                        filterBy={["codeart", "qrbarre", "sortlysid"]}
                        selected={codeart}
                        options={article}
                        multiple
                        onChange={(codeart)=>setCodeart(codeart)}
                    />
                        <Form.Control.Feedback type="invalid">
                        Veuillez saisir le code article
                        </Form.Control.Feedback>
                    </InputGroup>
                    </Form.Group><br/>
                    <Form.Group as={Col} md="12" controlId='qte'>
                        <InputGroup hasValidation>
                            <InputGroup.Text id="inputGroupPrepend">Qté perdus:</InputGroup.Text>
                                <Form.Control
                                    type="number"
                                    placeholder="Quantité perdus"
                                    aria-describedby="inputGroupPrepend"
                                    required
                                    size="sm"
                                    min="1"
                                    value={quantite}
                                    onChange={(e)=>setQuantite(e.target.value)}
                                />
                            <Form.Control.Feedback type="invalid">
                                Veuillez saisir la quantité article.
                            </Form.Control.Feedback>
                        </InputGroup>
                    </Form.Group><br/>
                    <Form.Group as={Col} md="12" controlId="validationCustomUsername">
                        <InputGroup hasValidation>
                        <InputGroup.Text id="inputGroupPrepend">Date entrées:</InputGroup.Text>
                        <Form.Control
                            type="date"
                            placeholder="Date entrées"
                            aria-describedby="inputGroupPrepend"
                            required
                            size="sm"
                            value={date}
                            onChange={(e)=>setDate(e.target.value)}
                        />
                        <Form.Control.Feedback type="invalid">
                        Veuillez saisir la date de perte
                        </Form.Control.Feedback>
                        </InputGroup>
                    </Form.Group><br/>
                    <Form.Group as={Col} md="12" controlId='qte'>
                        <InputGroup hasValidation>
                            <InputGroup.Text id="inputGroupPrepend">Motif:</InputGroup.Text>
                                <Form.Control
                                    type="text"
                                    placeholder="Motif"
                                    aria-describedby="inputGroupPrepend"
                                    required
                                    size="sm"
                                    rows={3} 
                                    min="1"
                                    value={motif}
                                    onChange={(e)=>setMotif(e.target.value)}
                                />
                            <Form.Control.Feedback type="invalid">
                                Veuillez saisir la quantité article.
                            </Form.Control.Feedback>
                        </InputGroup>
                    </Form.Group>
            </Modal.Body>
            <Modal.Footer>
                <Button type="reset" variant="ligth" onClick={handleClose}><ArrowLeft color="red" size={20}/> Annuler</Button>
                <Button type="submit" variant="ligth"><Save2Fill color="#ff7300" size={20}/> Enregister</Button>
            </Modal.Footer>
        </Form>
      </Modal>
      <Tables columns={columns} rows={datas} isLoading={load}/>
      </Col>
      </Row>
      </div>
  </>
    );
  }
  
  export default ListeArticleLost;