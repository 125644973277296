/*!

=========================================================
* TRACKY ENTREPOT - v1.0.0
=========================================================

* Copyright 2021 KLEVINT (https://web.facebook.com/klev.entertement/)

* Coded by KLEVINT

*/
import React from 'react';
import ReactDOM from 'react-dom';
import { HashRouter, Route, Switch, Redirect } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'assets/scss/paper-dashboard.scss?v=1.3.0';
import 'assets/demo/demo.css';
import 'perfect-scrollbar/css/perfect-scrollbar.css';
import 'assets/css/App.css';
import Login from './views/login';
import Register from './views/register';
import AdminLayout from 'layouts/Admin.js';

// window.addEventListener('beforeunload', function (e) {
//     e.preventDefault();
//     e.returnValue = '';
//     localStorage.removeItem('user-info');
// });

ReactDOM.render(
    <HashRouter>
        <Switch>
            <Route path="/admin" render={(props) => <AdminLayout {...props} />} />
            <Route path="/login" component={Login} key={14}></Route>
            <Route path="/register" component={Register} key={15}></Route>
            <Redirect to="/admin/dashboard" />
        </Switch>
    </HashRouter>,
    document.getElementById('root')
);
