import React, { useState, useCallback } from 'react';
import { Link } from 'react-router-dom';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import ReactDataGrid from '@inovua/reactdatagrid-community';
import '@inovua/reactdatagrid-community/index.css';
import { jsPDF } from 'jspdf';
import XLSX from 'sheetjs-style';
import 'jspdf-autotable';
import 'assets/css/table.css';

import dateformat from 'dateformat';
import common from '../../commonData';
import NavMenu from './navbar';
import ActionBtn from './gridactionbtn';
import AddSortie from './addSortie';

function MyAwesomeTable({
    rows,
    isLoading,
    setAdds,
    setIds,
    onRemoveLigne,
    setLoading,
    loading,
    setIsSave,
    setMsg,
    setMsgravity,
    setLoad
}) {
    const [showAdd, setShowAdd] = useState(false);
    const [data, setData] = useState();
    const [selected, setSelected] = useState({});
    const [gridRef, setGridRef] = useState(null);

    const filterValue = [
        { name: 'nocommande', operator: 'contains', type: 'string', value: '' },
        { name: 'client', operator: 'contains', type: 'string', value: '' },
        { name: 'numbon', operator: 'contains', type: 'string', value: '' },
        { name: 'datecommande', operator: 'inrange', type: 'date', value: null },
        { name: 'observation', operator: 'contains', type: 'string', value: '' },
        { name: 'datelivraisonprevu', operator: 'inrange', type: 'date', value: null },
        { name: 'datesort', operator: 'inrange', type: 'date', value: null },
        { name: 'user', operator: 'contains', type: 'string', value: '' },
        { name: 'noprof', operator: 'contains', type: 'string', value: '' },
        { name: 'nofact', operator: 'contains', type: 'string', value: '' }
    ];

    const columnss = [
        {
            name: 'id',
            header: 'Id',
            defaultVisible: false,
            defaultWidth: 80,
            type: 'number'
        },
        {
            header: '#',
            defaultWidth: 120,
            type: 'string',
            render: ({ value, data }) => (
                <ActionBtn
                    data={data}
                    setLoading={setLoading}
                    loading={loading}
                    setIsSave={setIsSave}
                    setMsg={setMsg}
                    setMsgravity={setMsgravity}
                    setLoad={setLoad}
                    setShowAdds={setShowAdds}
                />
            )
        },

        {
            name: 'nocommande',
            header: 'N° commande',
            defaultWidth: 140,
            type: 'string',
            render: ({ data }) => (
                <div className="rgt-cell-inner" style={{ display: 'flex', alignItems: 'center', overflow: 'hidden' }}>
                    <Link to="#/admin/commandecli" id="actionbtn">
                        {data.statut == 1 ? (
                            <p id="blue">{data.nocommande}</p>
                        ) : data.statut == 2 ? (
                            <p id="orange">{data.nocommande}</p>
                        ) : data.statut == 3 && data.sortieid == 0 ? (
                            <p id="warning">{data.nocommande}</p>
                        ) : data.statut == 3 && data.sortieid != 0 ? (
                            <p id="successs">{data.nocommande}</p>
                        ) : (
                            <p id="light">{data.nocommande}</p>
                        )}
                    </Link>
                </div>
            )
        },

        {
            name: 'datecommande',
            header: 'Date commande',
            defaultWidth: 130,
            type: 'date',
            render: ({ value }) => (value ? dateformat(value, 'dd-mm-yyyy') : null)
        },

        {
            name: 'datelivraisonprevu',
            header: 'Date livraison prévu',
            defaultWidth: 100,
            type: 'date',
            render: ({ value }) => (value ? dateformat(value, 'dd-mm-yyyy') : null)
        },
        {
            name: 'client',
            header: 'Clients',
            defaultWidth: 200,
            type: 'string'
        },
        {
            name: 'numbon',
            header: 'N° BL',
            defaultWidth: 100,
            type: 'string'
        },
        {
            name: 'datesort',
            header: 'Date livraison',
            defaultWidth: 130,
            type: 'date',
            render: ({ value }) => (value ? dateformat(value, 'dd-mm-yyyy') : null)
        },
        { name: 'observation', header: 'Observation', defaultWidth: 120, type: 'string' },
        { name: 'noprof', header: 'N° proforma', defaultWidth: 80, type: 'string' },
        { name: 'nofact', header: 'N° facture', defaultWidth: 80, type: 'string' },

        { name: 'user', header: 'Crée par', defaultWidth: 100, type: 'string' },
        {
            name: 'created_at',
            header: 'Crée le',
            defaultWidth: 130,
            type: 'date',
            render: ({ value }) => dateformat(value, 'dd-mm-yyyy HH:MM')
        },
        {
            name: 'updated_at',
            header: 'Modif. le',
            defaultWidth: 130,
            type: 'date',
            render: ({ value }) => (value ? dateformat(value, 'dd-mm-yyyy HH:MM') : '')
        }
    ];

    const gridStyle = { minHeight: window.screen.height - 200 };

    function setShowAdds(data) {
        setData(data);
        setShowAdd(true);
    }

    function GetSortOrder(prop) {
        return function (a, b) {
            if (a[prop] > b[prop]) {
                return 1;
            } else if (a[prop] < b[prop]) {
                return -1;
            }
            return 0;
        };
    }
    const exportCSV = () => {
        //--------------- EXPORT VERS EXCEL ------------------
        const raw_data = gridRef.current.data.sort(GetSortOrder('client'));
        /* flatten objects */
        const rows = [];
        raw_data.map((row, index) => {
            rows.push({
                client: row.client,
                nofact: row.nofact,
                datefact: dateformat(row.datefact, 'dd-mm-yyyy'),
                noprof: row.noprof,
                dateprof: dateformat(row.dateprof, 'dd-mm-yyyy'),
                prix_ttc: row.prix_ttc,
                reglement: row.reglement,
                reste: row.reste
            });
        });

        /* generate worksheet and workbook */
        const worksheet = XLSX.utils.json_to_sheet(rows);
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, 'Mes factures');
        /* fix headers */
        XLSX.utils.sheet_add_aoa(
            worksheet,
            [['Clients', 'N° facture', 'Date facture', 'N° proforma', 'Date proforma', 'Montant TTC', 'Montant reglé', 'Reste']],
            { origin: 'A1' }
        );
        /* calculate column width */
        const max_width = rows.reduce((w, r) => Math.max(w, r.client.length), 10);
        worksheet['!cols'] = [{ wch: max_width }];
        XLSX.writeFile(workbook, 'facture.xlsx');
    };

    //-------------IMPRIMER LES LISTE ----------------
    function printListe() {
        if (gridRef.current.data.length <= 0) {
            // setMsg("Aucune donnée à imprimer")
            // setIsSave(true)
            // setMsgravity("danger")
            // setLoad(false)
            return;
        }
        const doc = new jsPDF({
            orientation: 'l',
            unit: 'mm',
            format: 'a4',
            putOnlyUsedFonts: true
        });
        var width = doc.internal.pageSize.getWidth();
        const data = [];
        let mont = 0;
        let qte = 0;
        common.ipapi == 'https://192.168.8.109:81' || common.ipapi == 'https://api.tracky-entrepot.com'
            ? doc.addImage(common.logo, 'jpg', 14, 10, 30, 30)
            : doc.addImage(common.logo1, 'jpg', 14, 10, 30, 30);
        doc.setFontSize(16);
        common.ipapi == 'https://192.168.8.109:81' || common.ipapi == 'https://api.tracky-entrepot.com'
            ? doc.text('CLUB AUTO', 90, 14)
            : doc.text('HR MOTORS', 90, 14);
        doc.setFontSize(12);
        doc.setFillColor(0, 0, 0);
        doc.rect(65, 15, width - 80, 10, 'F');
        doc.setTextColor(255, 255, 255);
        doc.text('LISTE DES FACTURES ET DEVIS CLIENTS', 90, 22);
        doc.setTextColor(0, 0, 0);
        // doc.text("Période du: "+dateformat(datedeb || gridRef.current.data[gridRef.current.data.length-1].datesort,"dd-mm-yyyy")+" Au : "+dateformat(datefin || gridRef.current.data[0].datesort,"dd-mm-yyyy") ,65,32)
        gridRef.current.data.forEach((row) => {
            data.push([
                row.client,
                row.nofact,
                dateformat(row.datefact, 'dd-mm-yyyy'),
                row.noprof,
                dateformat(row.dateprof, 'dd-mm-yyyy'),
                row.prix_ttc,
                row.reglement,
                row.reste
            ]);
        });
        doc.autoTable({
            styles: {
                fillColor: [225, 206, 154],
                textColor: [255, 0, 0],
                halign: 'center',
                fontSize: 10,
                cellPadding: 0.75
            },
            columnStyles: {
                0: {
                    halign: 'left',
                    fillColor: [255, 255, 255],
                    textColor: [0, 0, 0],
                    fontSize: 8
                },
                1: {
                    halign: 'center',
                    fillColor: [255, 255, 255],
                    textColor: [0, 0, 0],
                    fontSize: 8
                },
                2: {
                    halign: 'center',
                    fillColor: [255, 255, 255],
                    textColor: [0, 0, 0],
                    fontSize: 8
                },
                3: {
                    halign: 'center',
                    fillColor: [255, 255, 255],
                    textColor: [0, 0, 0],
                    fontSize: 8
                },
                4: {
                    halign: 'center',
                    fillColor: [255, 255, 255],
                    textColor: [0, 0, 0],
                    fontSize: 8
                },
                5: {
                    halign: 'center',
                    fillColor: [255, 255, 255],
                    textColor: [0, 0, 0],
                    fontSize: 8
                },
                6: {
                    halign: 'center',
                    fillColor: [255, 255, 255],
                    textColor: [0, 0, 0],
                    fontSize: 8
                },
                7: {
                    halign: 'center',
                    fillColor: [255, 255, 255],
                    textColor: [0, 0, 0],
                    fontSize: 8
                },
                8: {
                    halign: 'center',
                    fillColor: [255, 255, 255],
                    textColor: [0, 0, 0],
                    fontSize: 8
                }
            },
            theme: 'grid',
            startY: 35,
            fontSize: 6,
            head: [['Clients', 'N° facture', 'Date facture', 'N° proforma', 'Date proforma', 'Montant TTC', 'Montant reglé', 'Reste']],
            body: data
        });
        const pageCount = doc.internal.getNumberOfPages();
        for (var i = 1; i <= pageCount; i++) {
            doc.setPage(i);
            doc.setFontSize(10);
            doc.text('Page ' + String(i) + ' sur ' + String(pageCount), 210 - 55, 200, null, null, 'right');
        }
        doc.save('liste facture.pdf');
    }

    const onSelectionChange = useCallback(({ selected }) => {
        setSelected(selected);
    }, []);
    function numberformat(data) {
        return new Intl.NumberFormat().format(data);
    }

    return (
        <div>
            {showAdd ? (
                <AddSortie
                    setShowAdd={setShowAdd}
                    show={showAdd}
                    setIsSave={setIsSave}
                    setMsg={setMsg}
                    setMsgravity={setMsgravity}
                    data={data}
                    setLoading={setLoading}
                    loading={loading}
                />
            ) : (
                <></>
            )}
            <NavMenu printListe={printListe} selected={selected} setAdds={setAdds} setIds={setIds} onRemoveLigne={onRemoveLigne} />
            <ReactDataGrid
                idProperty="id"
                handle={setGridRef}
                style={gridStyle}
                loading={isLoading}
                reorderColumns={false}
                pagination
                selected={selected}
                // checkboxColumn
                onSelectionChange={onSelectionChange}
                pageSizes={[200, 400, 600, 800, 1000]}
                defaultLimit={400}
                showHoverRows={true}
                editable={true}
                showColumnMenuTool={false}
                defaultFilterValue={filterValue}
                columns={columnss}
                dataSource={rows}
            />
        </div>
    );
}

export default MyAwesomeTable;
