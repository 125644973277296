import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import {
  Spinner,
  Form,
  InputGroup,
  ButtonGroup,
  Toast,
  Modal,
} from 'react-bootstrap';
import { Button, Card, CardBody, CardTitle, Row, Col } from 'reactstrap';
import Axios from 'axios';
import {
  FileExcel,
  ArrowLeft,
  Printer,
  Save2Fill,
  Trash,
  PencilFill,
} from 'react-bootstrap-icons';
import { jsPDF } from 'jspdf';
import 'jspdf-autotable';
import dateformat from 'dateformat';
import Tables from '../../tableData';
import common from '../../commonData';

function Fournisseurs() {
  const [validated, setValidated] = useState(false);
  const [nomfourn, setNomfourn] = useState('');
  const [telfourn, setTelfourn] = useState('');
  const [adressfourn, setAdressfourn] = useState('');
  const [isSave, setIsSave] = useState(false);
  const [datas, setData] = useState([]);
  const history = useHistory();
  const [load, setLoad] = useState(true);
  const [idfourn, setId] = useState(0);
  const [msgGravity, setMsgravity] = useState('success');
  const [msg, setMsg] = useState('');
  useEffect(() => {
    if (localStorage.getItem('user-info')) {
      (async function anyNameFunction() {
        await getFournisseurs();
      })();
    } else {
      history.push('/login');
    }
  }, [history]);

  async function getFournisseurs() {
    setLoad(true);
    Axios.get(common.ipapi + '/api/fournisseur/index')
      .then((response) => {
        setData(response.data);
        setLoad(false);
      })
      .catch((errors) => {
        setLoad(false);
      });
  }
  function saveFournisseur(e) {
    setLoad(true);
    var formData = new FormData();
    // formData.append('codefourn',codefourn);
    formData.append('nomfourn', nomfourn);
    formData.append('telfourn', telfourn);
    formData.append('adressfourn', adressfourn);
    formData.append('user_id', common.userid);
    Axios({
      url: common.ipapi + '/api/fournisseur/create',
      method: 'POST',
      headers: {
        'Content-Type': 'form-data',
      },
      data: formData,
    })
      .then((res) => {
        if (res.status !== 200) {
          setMsg('Veuillez vérifier votre connexion internet');
          setMsgravity('info');
          setIsSave(false);
          return;
        }
        if (res.data.status === 1) {
          setIsSave(true);
          setId(0);
          setMsgravity('success');
          setMsg(res.data.msg);
          getFournisseurs();
          initialise();
          setLoad(false);
        } else {
          setMsg(res.data.msg);
          setMsgravity('danger');
          setIsSave(true);
          setLoad(false);
        }
      })
      .catch((error) => {
        setMsg('error');
        setMsgravity('danger');
        setIsSave(true);
        setLoad(false);
      });
  }

  function updateFournisseur(idf) {
    setLoad(true);
    var formData = new FormData();
    //formData.append('codefourn',codefourn);
    formData.append('nomfourn', nomfourn);
    formData.append('telfourn', telfourn);
    formData.append('adressfourn', adressfourn);
    formData.append('user_id', 1);
    Axios({
      url: common.ipapi + '/api/fournisseur/update/' + idf,
      method: 'POST',
      headers: {
        'Content-Type': 'form-data',
      },
      data: formData,
    })
      .then((res) => {
        if (res.status !== 200) {
          setMsg('Veuillez vérifier votre connexion internet');
          setMsgravity('info');
          setIsSave(false);
          return;
        }
        if (res.data.status === 1) {
          setIsSave(true);
          setId(0);
          setMsgravity('success');
          setMsg(res.data.msg);
          getFournisseurs();
          initialise();
          setLoad(false);
        } else {
          setMsg(res.data.msg);
          setMsgravity('danger');
          setIsSave(true);
          setLoad(false);
        }
      })
      .catch((error) => {
        setMsg('error');
        setMsgravity('danger');
        setIsSave(true);
        setLoad(false);
      });
  }
  const handleSubmit = (event) => {
    event.preventDefault();
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.stopPropagation();
    }
    setValidated(true);
    if (idfourn !== 0) {
      updateFournisseur(idfourn);
    } else {
      saveFournisseur();
    }
  };

  function IsModif(ids) {
    setLoad(true);
    Axios.get(common.ipapi + '/api/fournisseur/edit/' + ids).then(
      (response) => {
        setNomfourn(response.data.nomfourn);
        setTelfourn(response.data.telfourn);
        setAdressfourn(response.data.adressfourn);
        setId(response.data.id);
        window.scrollTo({
          top: 0,
          behavior: 'smooth',
        });
        setLoad(false);
      }
    );
  }

  const Operation = ({ data }) => {
    return (
      <div className="menudrop">
        <ButtonGroup aria-label="Basic example">
          {/* <a href={`#/admin/article/entrepot/${data.id}`} id="actionbtn" title="Voir les articles de ce fournisseur"><Eye  size={23}  color='blue'/></a> */}
          <a
            href="#/admin/fournisseurs"
            id="actionbtn"
            onClick={() => IsModif(data.id)}
            title="Modifier ce fournisseur"
          >
            <PencilFill size={20} color="green" />
          </a>
          <a
            href="#/admin/fournisseurs"
            id="actionbtn"
            onClick={() => onDelete(data.id)}
            title="Supprimer ce fournisseur"
          >
            <Trash color="red" size={20} />
          </a>
        </ButtonGroup>
      </div>
    );
  };
  const date = ({ data }) => {
    return dateformat(data.created_at, 'dd-mm-yyyy HH:MM');
  };
  const columns = [
    {
      id: 1,
      field: 'id',
      label: '#',
      width: '50px',
    },
    {
      id: 2,
      field: 'codefourn',
      label: 'Code',
      width: '120px',
    },
    {
      id: 3,
      field: 'nomfourn',
      label: 'Noms',
    },
    {
      id: 4,
      field: 'telfourn',
      label: 'Contacts',
      width: '130px',
    },
    {
      id: 5,
      field: 'adressfourn',
      label: 'Adresse',
    },
    {
      id: 6,
      field: 'created_at',
      label: 'Crée le',
      cellRenderer: date,
    },
    {
      id: 7,
      label: 'Actions',
      cellRenderer: Operation,
      pinned: true,
      width: '100px',
      minResizeWidth: 90,
    },
  ];

  //------- IMPRIMER LA LISTE DE FACTURES -----------------
  function printListeFacture() {
    const doc = new jsPDF();
    doc.text('LISTE DES FOURNISSEURS', 105, 20, null, null, 'center');
    // <table id="my-table"><!-- ... --></table>
    doc.autoTable({
      styles: {
        fillColor: [255, 127, 0],
        textColor: [0, 0, 0],
        halign: 'center',
      },
      theme: 'grid',
      margin: { top: 30 },
      html: '#table',
      columnStyles: {
        0: { halign: 'center', fillColor: [255, 255, 255] },
        1: { halign: 'center', fillColor: [255, 255, 255] },
        2: { halign: 'center', fillColor: [255, 255, 255] },
        3: { halign: 'center', fillColor: [255, 255, 255] },
        4: { halign: 'center', fillColor: [255, 255, 255] },
        5: { halign: 'left', fillColor: [255, 255, 255] },
        6: { halign: 'center', fillColor: [255, 255, 255] },
        7: { halign: 'center', fillColor: [255, 255, 255] },
      },
    });
    //doc.autoPrint({variant: 'non-conform'});
    doc.save('facture.pdf');
  }

  function onDelete(id) {
    if (window.confirm('Voulez-vous supprimer ce fournisseur ?')) {
      Axios.delete(common.ipapi + '/api/fournisseur/destroy/' + id).then(
        (response) => {
          if (response.data.status === 1) {
            //const del = datas.filter(d=>d.id !== id)
            getFournisseurs();
            setMsg(response.data.msg);
            setMsgravity('success');
            setIsSave(true);
          } else {
            setMsg(response.data.msg);
            setMsgravity('danger');
            setIsSave(true);
          }
        }
      );
    }
  }
  function cancel(e) {
    e.preventDefault();
    e.stopPropagation();
    initialise();
  }
  function initialise() {
    setNomfourn('');
    setTelfourn('');
    setAdressfourn('');
    setId(0);
  }
  return (
    <>
      <Toast
        id="toast"
        onClose={() => setIsSave(false)}
        show={isSave}
        delay={3000}
        className={'bg-' + msgGravity}
        autohide
      >
        <Toast.Header>
          <img src="holder.js/20x20?text=%20" className="rounded me-2" alt="" />
          <strong className="me-auto">Info</strong>
        </Toast.Header>
        <Toast.Body className="dark">{msg}</Toast.Body>
      </Toast>
      <Modal
        show={load}
        size="sm"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Body>
          Veuillez patienter.......
          <Spinner animation="border" variant="success" />
        </Modal.Body>
      </Modal>
      <div className="content contents">
        <Card>
          <div className="ml-auto">
            <ButtonGroup size="sm">
              <Button>
                <a href="#/admin/fournisseurs">
                  <FileExcel color="#807f7e" size={20} /> Excel
                </a>
              </Button>
              <Button>
                <a href="#/admin/fournisseurs">
                  <Printer color="#807f7e" size={20} /> Imprimer
                </a>
              </Button>
            </ButtonGroup>
          </div>
        </Card>
        <Row>
          <Col md="12">
            <Row>
              <Col sm={4}>
                <Card>
                  <CardBody>
                    <CardTitle>Ajouter un nouveau fournisseur</CardTitle>
                    <Form
                      noValidate
                      validated={validated}
                      onSubmit={handleSubmit}
                    >
                      <Row className="mb-3">
                        <Form.Group as={Col} md="12" controlId="raison">
                          <InputGroup hasValidation>
                            <InputGroup.Text id="inputGroupPrepend">
                              Raison sociale:
                            </InputGroup.Text>
                            <Form.Control
                              type="text"
                              placeholder="Nom société"
                              aria-describedby="inputGroupPrepend"
                              required
                              size="sm"
                              value={nomfourn}
                              onChange={(e) =>
                                setNomfourn(e.target.value.toUpperCase())
                              }
                            />
                            <Form.Control.Feedback type="invalid">
                              Veuillez saisir le nom du fournisseur.
                            </Form.Control.Feedback>
                          </InputGroup>
                        </Form.Group>
                        <br />
                        <br />
                        <Form.Group as={Col} md="12" controlId="contact">
                          <InputGroup hasValidation>
                            <InputGroup.Text id="inputGroupPrepend">
                              Contact:
                            </InputGroup.Text>
                            <Form.Control
                              type="text"
                              placeholder="Contact"
                              aria-describedby="inputGroupPrepend"
                              required
                              size="sm"
                              value={telfourn}
                              onChange={(e) => setTelfourn(e.target.value)}
                            />
                            <Form.Control.Feedback type="invalid">
                              Veuillez saisir le contact du fournisseur.
                            </Form.Control.Feedback>
                          </InputGroup>
                        </Form.Group>
                        <br />
                        <br />
                        <Form.Group as={Col} md="12" controlId="adresse">
                          <InputGroup>
                            <InputGroup.Text id="inputGroupPrepend">
                              Adresse:
                            </InputGroup.Text>
                            <Form.Control
                              type="text"
                              placeholder="Adresse"
                              aria-describedby="inputGroupPrepend"
                              required
                              size="sm"
                              value={adressfourn}
                              onChange={(e) => setAdressfourn(e.target.value)}
                            />
                          </InputGroup>
                        </Form.Group>
                      </Row>
                      <ButtonGroup size="sm">
                        <Button type="submit" color="danger" onClick={cancel}>
                          <ArrowLeft color="red" size={20} /> Annuler
                        </Button>
                        <Button type="submit" variant="ligth">
                          <Save2Fill color="#807f7e" size={20} /> Enregister
                        </Button>
                      </ButtonGroup>
                    </Form>
                  </CardBody>
                </Card>
              </Col>
              <Col sm={8}>
                <Card>
                  <CardBody>
                    <Tables columns={columns} rows={datas} />
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Col>
        </Row>
      </div>
    </>
  );
}

export default Fournisseurs;
