import React, { useEffect, useState, useRef } from 'react';
import { Form, InputGroup, Spinner, ButtonGroup, Modal, ToggleButton } from 'react-bootstrap';
import { Button, Card, CardBody, Row, Col, CardTitle } from 'reactstrap';
import Axios from 'axios';
import { useHistory } from 'react-router-dom';
import { Backspace, Save2Fill } from 'react-bootstrap-icons';
import { Typeahead } from 'react-bootstrap-typeahead';
import 'react-bootstrap-typeahead/css/Typeahead.css';
import dateformat from 'dateformat';
import common from '../../commonData';

var count = 0;

function UpdateReglement({ ids, setShowEdit, show, setIsSave, isSave, setDatas, setMsg, setMsgravity, setId, setActualise, actualise }) {
    const [validated, setValidated] = useState(false);
    const [sicheqVir, setsicheqVir] = useState(false);
    const [type, setType] = useState('');
    const [date_regl, setDate] = useState('');
    const [norecu, setNorecu] = useState('');
    const [nocheq, setCheq] = useState('');
    const [montant, setMontant] = useState('');
    const [montantFact, setMontantFact] = useState('');
    const [montantPaye, setMontantPaye] = useState('');
    const [idprof, setProfid] = useState('');
    const [proforma, setProforma] = useState([]);
    const [load, setLoad] = useState(true);
    const history = useHistory();
    const typeaheadnofact = useRef(null);

    useEffect(() => {
        if (!localStorage.getItem('user-info')) {
            history.push('/login');
        }
        (async function anyNameFunction() {
            setLoad(true);
            const getprof = Axios.get(common.ipapi + '/api/facture');
            const getRegl = Axios.get(common.ipapi + '/api/reglement/edit/' + ids);
            await Axios.all([getprof, getRegl])
                .then(
                    Axios.spread((...responses) => {
                        setProforma(responses[0].data.datas);
                        setProfid(responses[1].data.idprof);
                        setNorecu(responses[1].data.norecu);
                        setType(responses[1].data.type_reglement);
                        setCheq(responses[1].data.nocheq);
                        setMontant(responses[1].data.montant);
                        setDate(dateformat(responses[1].data.date_regl, 'yyyy-mm-dd'));
                        var regl = 0;
                        responses[1].data.proforma.reglement.forEach((element) => {
                            regl += element.montant;
                        });
                        setMontantPaye(regl);
                        var ttc = 0;
                        var remise = 0;
                        responses[1].data.proforma.lignefacture.forEach((element) => {
                            responses[1].data.proforma.client && responses[1].data.proforma.client.remise
                                ? (remise =
                                      element.pivot.prix *
                                      element.pivot.quantite *
                                      (responses[1].data.proforma.client.remise.pourcentage / 100))
                                : (remise = element.pivot.prix * element.pivot.quantite * (element.pivot.remise / 100));
                            ttc +=
                                element.pivot.prix * element.pivot.quantite -
                                remise +
                                (element.pivot.prix * element.pivot.quantite - remise) * 0.18;
                        });
                        setMontantFact(Math.round(ttc));
                        setLoad(false);
                    })
                )
                .catch((errors) => {
                    setLoad(false);
                });
        })();
    }, [history, ids]);

    const handleClose = () => {
        localStorage.removeItem('showl');
        setId(0);
        setShowEdit(false);
        setIsSave(false);
    };

    function saveFacture(e) {
        setLoad(true);
        var formData = new FormData();
        formData.append('date_regl', date_regl);
        formData.append('type', type);
        formData.append('norecu', norecu);
        formData.append('nocheq', nocheq);
        formData.append('user_id', common.userid);
        formData.append('montant', montant);
        formData.append('idprof', idprof);
        Axios({
            url: common.ipapi + '/api/reglement/update/' + ids,
            method: 'POST',
            data: formData
        })
            .then((res) => {
                if (res.status !== 200) {
                    setMsg('Veuillez vérifier votre connexion internet');
                    setMsgravity('info');
                    setIsSave(false);
                    return;
                }
                if (res.data.status === 1) {
                    setMsg(res.data.msg);
                    setMsgravity('success');
                    setIsSave(true);
                    setActualise(!actualise);
                    setLoad(false);
                    handleClose();
                } else {
                    setMsg("Une erreur c'est produite veuillez verifier les données saisies");
                    setMsgravity('danger');
                    setIsSave(true);
                    setLoad(false);
                }
            })
            .catch((error) => {
                setIsSave(true);
                setMsgravity('danger');
                setLoad(false);
            });
    }
    const handleSubmit = (event) => {
        const form = event.currentTarget;
        if (form.checkValidity() === false) {
            event.preventDefault();
            event.stopPropagation();
            setValidated(true);
        } else {
            saveFacture();
        }
    };

    function setTypes(s) {
        setType(s);
        s === 'Chèque' || s === 'Virement' ? setsicheqVir(true) : setsicheqVir(false);
    }

    function setProfids(proforma) {
        setProfid(proforma.length > 0 ? proforma[0].id : 0);
        setMontantFact(proforma.length > 0 ? proforma[0].montant : 0);
        if (proforma.length > 0) {
            var regl = 0;
            proforma[0].reglement.forEach((element) => {
                regl += element.montant;
            });
            setMontantPaye(regl);
            var ttc = 0;
            var remise = 0;

            proforma[0].lignefacture.forEach((element) => {
                proforma[0].client && proforma[0].client.remise
                    ? (remise = element.pivot.prix * element.pivot.quantite * (proforma[0].client.remise.pourcentage / 100))
                    : (remise = element.pivot.prix * element.pivot.quantite * (element.pivot.remise / 100));
                ttc += element.pivot.prix * element.pivot.quantite - remise + (element.pivot.prix * element.pivot.quantite - remise) * 0.18;
            });
            setMontantFact(proforma[0].client.sitva == 0 ? Math.round(ttc + ttc * 0.18) : Math.round(ttc));
        }
    }
    return (
        <>
            <Modal show={load} size="sm" aria-labelledby="contained-modal-title-vcenter" centered>
                <Modal.Body>
                    Veuillez patienter.......
                    <Spinner animation="grow" variant="warning" />
                </Modal.Body>
            </Modal>
            <Modal show={show} size="lg" onHide={handleClose}>
                <div className="content">
                    <Form noValidate validated={validated} onSubmit={handleSubmit}>
                        <Modal.Header>
                            MODIFICATION REGLEMENT <Backspace onClick={handleClose} color="red" size={30} />
                        </Modal.Header>
                        <Modal.Body>
                            <Row>
                                <Col md={12}>
                                    <Form.Group as={Col} md="12" controlId="client">
                                        <InputGroup hasValidation>
                                            <InputGroup.Text id="inputGroupPrepend">Facture:</InputGroup.Text>
                                            <Typeahead
                                                id="my-typeahead-id"
                                                key={10}
                                                labelKey={(proforma) => `${proforma.nofact}`}
                                                filterBy={['nofact']}
                                                value={idprof}
                                                options={proforma}
                                                selected={proforma.slice(
                                                    proforma.findIndex((element) => element.id === idprof),
                                                    proforma.findIndex((element) => element.id === idprof) + 1
                                                )}
                                                onChange={(proforma) => setProfids(proforma)}
                                                ref={typeaheadnofact}
                                                inputProps={{ required: true }}
                                            />
                                            <Form.Control.Feedback type="invalid">Veuillez saisir le client</Form.Control.Feedback>
                                        </InputGroup>
                                    </Form.Group>
                                    <Row>
                                        <Col lg="4" md="6" sm="6">
                                            <Card className="card-stats">
                                                <CardBody>
                                                    <Row>
                                                        <Col md="12" xs="7">
                                                            <div className="numbers p">
                                                                <p className="card-category">Montant facture</p>
                                                                <CardTitle tag="p">{new Intl.NumberFormat().format(montantFact)}</CardTitle>
                                                                <p />
                                                            </div>
                                                        </Col>
                                                    </Row>
                                                </CardBody>
                                            </Card>
                                        </Col>
                                        <Col lg="4" md="6" sm="6">
                                            <Card className="card-stats">
                                                <CardBody>
                                                    <Row>
                                                        <Col md="12" xs="7">
                                                            <div className="numbers p">
                                                                <p className="card-category">Montant reglé</p>
                                                                <CardTitle tag="p">{new Intl.NumberFormat().format(montantPaye)}</CardTitle>
                                                                <p />
                                                            </div>
                                                        </Col>
                                                    </Row>
                                                </CardBody>
                                            </Card>
                                        </Col>
                                        <Col lg="4" md="6" sm="6">
                                            <Card className="card-stats">
                                                <CardBody>
                                                    <Row>
                                                        <Col md="12" xs="7">
                                                            <div className="numbers p">
                                                                <p className="card-category">Reste</p>
                                                                <CardTitle tag="p">
                                                                    {new Intl.NumberFormat().format(montantFact - montantPaye)}
                                                                </CardTitle>
                                                                <p />
                                                            </div>
                                                        </Col>
                                                    </Row>
                                                </CardBody>
                                            </Card>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col md={6}>
                                            <Form.Group as={Col} md="12" controlId="validationCustomUsername">
                                                <InputGroup hasValidation>
                                                    <InputGroup.Text id="inputGroupPrepend">Montant:</InputGroup.Text>
                                                    <Form.Control
                                                        type="number"
                                                        placeholder="Montant"
                                                        aria-describedby="inputGroupPrepend"
                                                        required
                                                        size="sm"
                                                        value={montant}
                                                        onChange={(e) =>
                                                            setMontant(
                                                                montantFact >= e.target.value ? e.target.value : montantFact - montantPaye
                                                            )
                                                        }
                                                    />
                                                    <Form.Control.Feedback type="invalid">
                                                        Veuillez saisir le code article
                                                    </Form.Control.Feedback>
                                                </InputGroup>
                                            </Form.Group>
                                        </Col>
                                        <Col md={6}>
                                            <Form.Group as={Col} md="12" controlId="validationCustomUsername">
                                                <InputGroup hasValidation>
                                                    <InputGroup.Text id="inputGroupPrepend">Date édition:</InputGroup.Text>
                                                    <Form.Control
                                                        type="date"
                                                        placeholder="Date édition"
                                                        aria-describedby="inputGroupPrepend"
                                                        required
                                                        size="sm"
                                                        value={date_regl}
                                                        onChange={(e) => setDate(e.target.value)}
                                                    />
                                                    <Form.Control.Feedback type="invalid">
                                                        Veuillez saisir le code article
                                                    </Form.Control.Feedback>
                                                </InputGroup>
                                            </Form.Group>
                                        </Col>
                                        <Col md={6}>
                                            <Form.Group as={Col} md="12" controlId="validationCustomUsername">
                                                <InputGroup hasValidation>
                                                    <InputGroup.Text id="inputGroupPrepend">N° de reçu:</InputGroup.Text>
                                                    <Form.Control
                                                        type="text"
                                                        placeholder="N° de reçu"
                                                        aria-describedby="inputGroupPrepend"
                                                        size="sm"
                                                        value={norecu}
                                                        onChange={(e) => setNorecu(e.target.value)}
                                                    />
                                                    <Form.Control.Feedback type="invalid">
                                                        Veuillez saisir le code article
                                                    </Form.Control.Feedback>
                                                </InputGroup>
                                            </Form.Group>
                                        </Col>
                                        <Col md={6}>
                                            <Form.Group as={Col} md="12" controlId="revision">
                                                <InputGroup hasValidation>
                                                    <ButtonGroup>
                                                        {[
                                                            { name: 'Espèce', value: 'Espèce' },
                                                            { name: 'Chèque', value: 'Chèque' },
                                                            { name: 'Virement', value: 'Virement' }
                                                        ].map((radio, idx) => (
                                                            <ToggleButton
                                                                key={idx}
                                                                id={`radiorevision-${idx}`}
                                                                type="radio"
                                                                size="sm"
                                                                variant={idx % 2 ? 'outline-warning' : 'outline-warning'}
                                                                value={radio.value}
                                                                checked={type === radio.value}
                                                                onChange={(e) => setTypes(e.currentTarget.value)}
                                                            >
                                                                {radio.name}
                                                            </ToggleButton>
                                                        ))}
                                                    </ButtonGroup>
                                                    <Form.Control.Feedback type="invalid">
                                                        Veuillez selectionnez la revision.
                                                    </Form.Control.Feedback>
                                                </InputGroup>
                                            </Form.Group>
                                        </Col>

                                        {sicheqVir ? (
                                            <Col md={6}>
                                                <Form.Group as={Col} md="12" controlId="validationCustomUsername">
                                                    <InputGroup hasValidation>
                                                        <InputGroup.Text id="inputGroupPrepend">N° chèque/virement:</InputGroup.Text>
                                                        <Form.Control
                                                            type="text"
                                                            placeholder="N° chèque/virement"
                                                            aria-describedby="inputGroupPrepend"
                                                            required
                                                            size="sm"
                                                            value={nocheq}
                                                            onChange={(e) => setCheq(e.target.value)}
                                                        />
                                                        <Form.Control.Feedback type="invalid">
                                                            Veuillez saisir le code article
                                                        </Form.Control.Feedback>
                                                    </InputGroup>
                                                </Form.Group>
                                            </Col>
                                        ) : (
                                            <></>
                                        )}
                                    </Row>
                                    <Modal.Footer>
                                        <div className="ml-auto">
                                            <ButtonGroup size="sm">
                                                <Button color="secondary" onClick={handleClose}>
                                                    <Backspace color="red" size={20} />
                                                </Button>
                                                <Button type="submit" variant="ligth">
                                                    <Save2Fill color="#807f7e" size={20} /> Enregister
                                                </Button>
                                            </ButtonGroup>
                                        </div>
                                    </Modal.Footer>
                                </Col>
                            </Row>
                        </Modal.Body>
                    </Form>
                </div>
            </Modal>
        </>
    );
}

export default UpdateReglement;
