import React,{useState,useRef,useEffect} from 'react'
import {Link,useParams,useHistory} from 'react-router-dom'
import {ButtonGroup,Toast,Spinner,Popover,OverlayTrigger} from "react-bootstrap";
import {Card,Collapse,Button,FormGroup,Row,Col, CardBody} from "reactstrap";
import Axios from 'axios';
import { jsPDF } from "jspdf";
import 'jspdf-autotable'
import { Eye,Trash,PencilSquare,Printer,GearFill, BackspaceFill} from 'react-bootstrap-icons';
import Tables from '../../tableData'
import common from '../../commonData'

var dta = []
function EntrepotArticle() {
  const [msgGravity,setMsgravity]=useState("success")
  const [msg,setMsg]=useState("")
    const [isSave,setIsSave]=useState(false)
    const [load,setLoading]=useState(true)
    const sidebarToggle = useRef();
    const [datas,setDatas]=useState([])
    const [libelleentrepot,setEntrepot]=useState("")
    let { id } = useParams();
    const history = useHistory();

    useEffect(()=>{
      setLoading(true)
      if (!localStorage.getItem("user-info")) {
        history.push("/login")
      }else{
        (async function anyNameFunction() {
          await Axios.get(common.ipapi+'/api/article/entrepot/'+id)
          .then(response=>{
            setEntrepot(response.data.length>0 ? response.data[0].libelleentrepot : "")
            setDatas(response.data)
            dta  = response.data
            setLoading(false)
          })
        })();
      }
    },[history,id])
    const Images = ({ tableManager, value, field, data, column, colIndex, rowIndex }) => {
      return (
          <div>
              <Link to={`/admin/article/fiche/${data.id}`}>{data.urlart ? data.urlart.indexOf('sortly') === -1 ? <img alt={rowIndex} className="imgart rounded" src={common.ipapi+data.urlart}></img> : <img alt={rowIndex} className='imgart rounded' src={data.urlart}></img>  : "" }</Link>
          </div>
      )
    }
    const Operation = ({ tableManager, value, field, data, column, colIndex, rowIndex }) => {
      return (
        <OverlayTrigger
            trigger="click"
            placement='left'
            overlay={
              <Popover id='popover-positioned'>
                <Popover.Header as="h3">Options</Popover.Header>
                <Popover.Body>
                  <ButtonGroup aria-label="Basic example">
                    <Link to={`/admin/article/fiche/${data.id}`} id="actionbtn"><Eye  size={25}  color='royalblue'/></Link>
                    <Link to={`/admin/article/edit/${data.id}`} id="actionbtn"><PencilSquare  size={20}  color='green'/></Link>
                    <a href="#/admin/articles" onClick={()=>onDelete(data.id)} id="actionbtn"><Trash color="red" size={20} /></a>
                  </ButtonGroup>
                </Popover.Body>
              </Popover>
            }
          >
          <GearFill title="Options" size={20} color="orange"/>
        </OverlayTrigger>
      )
    }
    const CPrivente = ({ tableManager, value, field, data, column, colIndex, rowIndex }) => {
      return (
        new Intl.NumberFormat('fr-FR', { style: 'currency', currency: 'CFA' }).format(data.prixachat*data.coeficient)
      )
    }

  
    const columns = [
      {
        id: 1,  
        label: 'Images',
        cellRenderer: Images,
        pinned:true,
        sortable: false,
        width: '40px',
      minResizeWidth: 40,
      },  
      {
        id: 2, 
        field: 'codeart', 
        label: 'Code article',
        width:'130px',
        minResizeWidth: 70,
      }, 
      {
        id: 3, 
        field: 'qrbarreshort',
        label: 'Code',
        width:'130px',
        minResizeWidth: 70,
      }, 
      {
          id: 4, 
          field: 'descriptionart', 
          label: 'Descriptions',
      },
      {
        id: 5, 
        field: 'libellefam',
        label: 'Famille',
      },
      {
          id: 6, 
          field: 'libellemarq',
          label: 'Models',
          width:'150px',
      },
      {
        id: 7, 
        field:'libelleentrepot',
        label: 'Entrepots',
      },
      {
        id: 8, 
        field: 'qtestock', 
        label: 'Quantité',
      },
      {
        id: 9, 
        field: 'qteseuil', 
        label: 'Qte seuil.',
      },
      {
        id: 10, 
        field: 'prixachat', 
        label: 'Prix',
      },
      {
        id: 11, 
        field: 'coeficient', 
        label: 'Coef',
      },
      {
        id: 12,  
        label: 'P. vente',
        cellRenderer: CPrivente
      },
      {
        id: 13,  
        label: 'Actions',
        cellRenderer: Operation,
        pinned:true,
        width: '20px',
        sortable: false,
        minResizeWidth: 20,
      },
  ];
   

    function printListeFacture(){
      const doc = new jsPDF();
      doc.text("LISTE DES ARTICLES DE L'ENTREPOT "+libelleentrepot, 105, 20, null, null, "center");
      // <table id="my-table"><!-- ... --></table>
      doc.autoTable({ 
        styles: { fillColor: [255, 127, 0],textColor:[0,0,0],halign: 'center' },
        theme: 'grid',
        margin: { top: 30 },
        html: '#table',
        columnStyles: { 
          0: { halign: 'center',fillColor: [255, 255, 255]},
         1: { halign: 'center',fillColor: [255, 255, 255]}, 
         2: { halign: 'center',fillColor: [255, 255, 255]},
         3: { halign: 'center',fillColor: [255, 255, 255]},
         4: { halign: 'center',fillColor: [255, 255, 255]},
         5: { halign: 'left',fillColor: [255, 255, 255]}, 
         6: { halign: 'center',fillColor: [255, 255, 255]},
         7: { halign: 'center',fillColor: [255, 255, 255]},
     },
      })
      //doc.autoPrint({variant: 'non-conform'});
      doc.save('facture.pdf');
    }
    function  onDelete (id) {
      if(window.confirm("Voulez-vous supprimer cet article ?")){
        setLoading(true)
        Axios.delete(common.ipapi+'/api/article/destroy/'+id)
        .then(response=>{
          if(response.data.status===1){
            const del = dta.filter(d=>d.id !== id)
            dta=del
            setDatas(del)
            setMsgravity("success")
            setMsg(response.data.msg)
            setIsSave(true)
            setLoading(false)
          }else{
            setMsgravity("danger")
            setMsg('Une erreur a eu lieu')
            setIsSave(true)
            setLoading(false)
          }
        })
      }
    }
    function cancel(e){
      e.preventDefault()
      window.history.back();
    }
    const openSidebar = () => {
      document.documentElement.classList.toggle("nav-open");
      sidebarToggle.current.classList.toggle("toggled");
    };
    return (
      <>
      <Toast onClose={() => setIsSave(false)} show={isSave} delay={3000} bg={msgGravity} autohide>
          <Toast.Header>
            <img
              src="holder.js/20x20?text=%20"
              className="rounded me-2"
              alt=""
            />
            <strong className="me-auto">Info</strong>
          </Toast.Header>
          <Toast.Body>{msg}</Toast.Body>
      </Toast>
      {
        load ?
        <Spinner animation="grow" variant="warning"/>
        :
        ""
      }
      <div className="content contents">
      <Collapse isOpen={true} navbar>
                        
                        <div className="ml-auto">
                            <FormGroup className="no-border">
                              <Button type="submit" variant="ligth" onClick={cancel}><BackspaceFill color="#000" size={20}/> Retour</Button>
                              <Button variant="light" onClick={()=>printListeFacture()}><Printer color="#ff7300" size={20}/> Imprimer</Button>
                            </FormGroup>
                        </div>
                    </Collapse>
        <Row>
          <Col md="12">
        <Row>
            <Col md={12}>
              <Card>
                  <CardBody>
                  <Tables columns={columns} rows={datas}/>
                  </CardBody>
              </Card>
          </Col>
          </Row>
          </Col>
          </Row>
          </div>
      </>
    );
  }

export default EntrepotArticle;