import React, { useEffect, useState } from 'react';
import { Form, InputGroup, Spinner, Modal, Card } from 'react-bootstrap';
import { Button, ButtonGroup, Row, Col, CardBody } from 'reactstrap';
import Axios from 'axios';
import { useHistory } from 'react-router-dom';
import { Backspace, Save2Fill, Star } from 'react-bootstrap-icons';
import { Typeahead } from 'react-bootstrap-typeahead';
import 'react-bootstrap-typeahead/css/Typeahead.css';
import * as Yup from 'yup';
import { Field, Formik } from 'formik';

import dateformat from 'dateformat';

import common from '../../commonData';
import { ProductList, Summary } from './cardadd';

function FactureMultiple({ setShowFact, show, setIsSave, setMsg, setMsgravity, selectedRows, actualise, setActualise }) {
    var ladate = new Date();
    const [codeart, setCodeart] = useState([]);
    const [client, setDataClient] = useState([]);
    const [load, setLoad] = useState(false);
    const history = useHistory();
    const [listeSelect, setListeSelect] = useState([]);

    const [states, setState] = useState({
        nobon: '',
        datesort: dateformat(ladate.getMonth() + 1 + '/' + ladate.getDate() + '/' + ladate.getFullYear(), 'yyyy-mm-dd'),
        sitva: '0',
        typeFact: 'Proforma',
        client_id: ''
    });
    const { nobon, datesort, sitva, typeFact, client_id } = states;
    useEffect(() => {
        if (!localStorage.getItem('user-info')) {
            history.push('/login');
        }
        (async function anyNameFunction() {
            setLoad(true);
            const getCli = Axios.get(common.ipapi + '/api/client/indexmin');
            const getLigne = Axios.get(common.ipapi + '/api/sortieligne/get/' + selectedRows);
            await Axios.all([getLigne, getCli])
                .then(
                    Axios.spread((...response) => {
                        var t = [];
                        var tab = [];
                        var clientchange = false;
                        var idcli = response[0].data.datas[0].clients_id;
                        setDataClient(response[1].data || []);
                        setState({
                            sitva: response[0].data.datas[0].client.sitva,
                            client_id: response[0].data.datas[0].clients_id
                        });
                        response[0].data.datas.forEach((element) => {
                            if (element.facture != null) {
                                setIsSave(true);
                                setMsgravity('danger');
                                setMsg('Veuillez saisir des BLs non facturés');
                                setShowFact(false);

                                return false;
                            }
                            if (idcli !== element.clients_id) {
                                clientchange = true;
                            }
                            if (clientchange === true) {
                                setIsSave(true);
                                setMsgravity('warning');
                                setMsg('Les BLs sélectionnés doivent appartenir au même client');
                                setShowFact(false);
                                return false;
                            }
                            tab.push(element.numbon);

                            element.lignesort.forEach((elt) => {
                                t.push({
                                    id: elt.id,
                                    numbon: element.numbon,
                                    prixvente: elt.pivot.prix
                                        ? elt.pivot.prix
                                        : elt.prixachat * elt.coeficient * (elt.pivot.quantite - elt.pivot.quantiteretour),
                                    nobon: element.numbon,
                                    qrbarre: elt.qrbarre,
                                    descriptionart: elt.descriptionart,
                                    quantite: elt.pivot.quantite - elt.pivot.quantiteretour,
                                    remise: 0
                                });
                            });
                        });
                        tab.concat(codeart);
                        setCodeart(selectedRows);
                        setListeSelect(t);
                        setLoad(false);
                    })
                )
                .catch((errors) => {
                    setLoad(false);
                });
        })();
    }, [history, codeart, setShowFact]);

    function groupBy(tableauObjets) {
        var result = [];
        return tableauObjets.reduce(function (res, value) {
            if (!res[value.id]) {
                res[value.id] = {
                    id: value.id,
                    quantite: 0,
                    prixvente: value.prixvente,
                    descriptionart: value.descriptionart,
                    remise: value.remise
                };
                result.push(res[value.id]);
            }
            res[value.id].quantite += value.quantite;
            return res;
        }, {});
    }
    //console.log(result)

    const subTotal = listeSelect.reduce((total, product) => {
        return (
            total +
            (product.quantite * product.prixvente - product.quantite * product.prixvente * (product.remise / 100)) +
            (product.quantite * product.prixvente - product.quantite * product.prixvente * (product.remise / 100)) * 0.18
        ); //total + product.prixprestation * +product.qte;
    }, 0);
    const onChangeProductQuantity = (index, event) => {
        const cloneProducts = [...listeSelect];
        cloneProducts[index].quantite = event.target.value;
        setListeSelect(cloneProducts);
    };

    const onChangePrestation = (index, event) => {
        const value = event.target.value;
        const cloneProducts = [...listeSelect];
        cloneProducts[index].descriptionart = value;
        setListeSelect(cloneProducts);
    };

    const onChangeRemise = (index, event) => {
        const value = event.target.value;
        const cloneProducts = [...listeSelect];
        cloneProducts[index].remise = value;

        setListeSelect(cloneProducts);
    };

    const onChangePrix = (index, event) => {
        const value = event.target.value;
        const valueInt = parseInt(value);
        const cloneProducts = [...listeSelect];
        cloneProducts[index].prixvente = valueInt;

        setListeSelect(cloneProducts);
    };

    const onRemoveProduct = (i, id) => {
        var filteredProduct = [];
        setLoad(true);
        filteredProduct = listeSelect.filter((product, index) => {
            return index !== i;
        });
        setListeSelect(filteredProduct);
        setMsg('Ligne supprimée');
        setMsgravity('info');
        setIsSave(true);
        setLoad(false);
    };

    const handleClose = () => {
        setShowFact(false);
        setIsSave(false);
    };

    return (
        <>
            <Modal show={load} size="sm" aria-labelledby="contained-modal-title-vcenter" centered>
                <Modal.Body>
                    Veuillez patienter.......
                    <Spinner animation="border" variant="success" />
                </Modal.Body>
            </Modal>

            <Formik
                initialValues={{
                    numbon: nobon,
                    date: datesort || dateformat(ladate.getMonth() + 1 + '/' + ladate.getDate() + '/' + ladate.getFullYear(), 'yyyy-mm-dd'),
                    client_id: client_id,
                    user_id: common.userid,
                    sitva: sitva || '0',
                    type: typeFact || 'Proforma',
                    blsids: JSON.stringify(selectedRows),
                    codeart: JSON.stringify(groupBy(listeSelect)),
                    submit: null
                }}
                enableReinitialize
                validationSchema={Yup.object().shape({
                    client_id: Yup.number().required(common.msg),
                    date: Yup.date().required(common.msg)
                })}
                onSubmit={async (values, { setSubmitting }) => {
                    try {
                        setLoad(true);
                        let url = common.ipapi + '/api/facture/create';
                        await Axios.post(url, values)
                            .then((res) => {
                                if (res.data.status === 1) {
                                    setMsg(res.data.msg);
                                    setMsgravity('success');
                                    setIsSave(true);
                                    setLoad(false);
                                    setShowFact(false);
                                    setActualise(!actualise);
                                } else if (res.data.status === 2) {
                                    setMsg(res.data.msg);
                                    setMsgravity('danger');
                                    setIsSave(true);
                                    setLoad(false);
                                } else {
                                    if (res.data.code === 23000) {
                                        setMsg('Ce numéro de bon existe déja');
                                        setMsgravity('danger');
                                        setIsSave(true);
                                        setLoad(false);
                                    } else {
                                        setMsg(res.data.msg);
                                        setMsgravity('danger');
                                        setIsSave(true);
                                        setLoad(false);
                                    }
                                }
                            })
                            .catch((error) => {
                                setIsSave(true);
                                setMsgravity('danger');
                                setLoad(false);
                            });
                        setSubmitting(false);
                    } catch (err) {
                        setMsg('Une erreur à eu lieu');
                        setMsgravity('danger');
                        setIsSave(true);
                        setLoad(false);
                    }
                }}
            >
                {({ errors, handleBlur, handleChange, handleSubmit, isSubmitting, touched, values, setFieldValue, setFieldTouched }) => (
                    <Modal show={show} fullscreen={true} onHide={handleClose}>
                        <div className="content">
                            <Form noValidate onSubmit={handleSubmit}>
                                <Modal.Header>
                                    FACTURATION D'ARTICLES LIVREES
                                    <Backspace onClick={handleClose} color="red" size={30} />
                                </Modal.Header>
                                <Modal.Body>
                                    <div id="lightbody">
                                        <Card>
                                            <CardBody>
                                                <Row>
                                                    <Col md={4}>
                                                        <Form.Group as={Row} className="mb-3" controlId="formHorizontalEmail">
                                                            <Form.Label column sm={3}>
                                                                Type:
                                                            </Form.Label>
                                                            <Col sm={9}>
                                                                <div role="group" aria-labelledby="my-radio-group">
                                                                    <label className="radio">
                                                                        <Field type="radio" name="type" value="Proforma" />
                                                                        Proforma
                                                                    </label>
                                                                    <label className="radio">
                                                                        <Field type="radio" name="type" value="Facture" />
                                                                        Facture
                                                                    </label>
                                                                </div>
                                                            </Col>
                                                        </Form.Group>
                                                        <Form.Group as={Row} className="mb-3">
                                                            <Form.Label column sm={3}>
                                                                Nom client:
                                                            </Form.Label>
                                                            <Col sm={9}>
                                                                <InputGroup>
                                                                    <Typeahead
                                                                        id="client_id"
                                                                        multiple={false}
                                                                        onChange={(selected) => {
                                                                            const value = selected.length > 0 ? selected[0].id : 0;
                                                                            setFieldValue('client_id', value);
                                                                        }}
                                                                        onInputChange={(text, event) => setFieldValue('client_id', text)}
                                                                        onBlur={(e) => setFieldTouched('client_id', true)}
                                                                        labelKey="raisonsocial"
                                                                        options={client}
                                                                        selected={client.slice(
                                                                            client.findIndex((element) => element.id === values.client_id),
                                                                            client.findIndex((element) => element.id === values.client_id) +
                                                                                1
                                                                        )}
                                                                    />
                                                                </InputGroup>
                                                                {errors.client_id && <div id="feedback">{errors.client_id}</div>}
                                                            </Col>
                                                        </Form.Group>
                                                        <Form.Group as={Row} className="mb-3">
                                                            <Form.Label column sm={3}>
                                                                N° pièce:
                                                            </Form.Label>
                                                            <Col sm={4}>
                                                                <Form.Control
                                                                    id="numbon"
                                                                    type="text"
                                                                    placeholder="N° de facture ou devis"
                                                                    aria-describedby="inputGroupPrepend"
                                                                    size="sm"
                                                                    value={values.numbon}
                                                                    onBlur={handleBlur}
                                                                    onChange={handleChange}
                                                                    // onChange={(e) => setNobon(e.target.value)}
                                                                />
                                                            </Col>
                                                        </Form.Group>
                                                        <Form.Group as={Row} className="mb-3">
                                                            <Form.Label column sm={3}>
                                                                Date facture: <Star color="red" size={10} />
                                                            </Form.Label>
                                                            <Col sm={4}>
                                                                <Form.Control
                                                                    id="date"
                                                                    type="date"
                                                                    placeholder="Date facture"
                                                                    aria-describedby="inputGroupPrepend"
                                                                    onBlur={handleBlur}
                                                                    onChange={handleChange}
                                                                    size="sm"
                                                                    value={values.date}
                                                                    // onChange={(e) => setDatesort(e.target.value)}
                                                                />
                                                            </Col>
                                                            {errors.date && <div id="feedback">{errors.date}</div>}
                                                        </Form.Group>
                                                        <div role="group" aria-labelledby="my-radio-group">
                                                            <label className="radio">
                                                                <Field type="radio" name="sitva" value="0" />
                                                                TVA applicable
                                                            </label>
                                                            <label className="radio">
                                                                <Field type="radio" name="sitva" value="1" />
                                                                TVA non applicable
                                                            </label>
                                                        </div>
                                                    </Col>
                                                    <Col md={8}>
                                                        <div>
                                                            <ProductList
                                                                products={listeSelect}
                                                                onChangeProductQuantity={onChangeProductQuantity}
                                                                onRemoveProduct={onRemoveProduct}
                                                                onChangePrestation={onChangePrestation}
                                                                onChangeRemise={onChangeRemise}
                                                                onChangePrix={onChangePrix}
                                                            />
                                                        </div>
                                                    </Col>
                                                </Row>
                                            </CardBody>
                                        </Card>
                                    </div>
                                </Modal.Body>
                                <Modal.Footer>
                                    <Summary subTotal={subTotal} sitva={values.sitva} />
                                    <div className="ml-auto">
                                        <ButtonGroup size="sm">
                                            <Button color="secondary" onClick={handleClose}>
                                                <Backspace color="red" size={20} />
                                            </Button>
                                            <Button type="submit" color="warning">
                                                <Save2Fill color="#807f7e" size={20} /> Enregister
                                            </Button>
                                        </ButtonGroup>
                                    </div>
                                </Modal.Footer>
                            </Form>
                        </div>
                    </Modal>
                )}
            </Formik>
        </>
    );
}

export default FactureMultiple;
