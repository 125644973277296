import React, { useEffect, useState, useRef } from 'react';
import { Form, InputGroup, Spinner, Modal, Card, OverlayTrigger, Tooltip, Alert } from 'react-bootstrap';
import { Button, ButtonGroup, Row, Col } from 'reactstrap';
import Axios from 'axios';
import { Backspace, PlusCircle, Save2Fill, Star, Trash, TriangleHalf } from 'react-bootstrap-icons';
import 'react-bootstrap-typeahead/css/Typeahead.css';
import dateformat from 'dateformat';
import common from '../../commonData';
import Table from '../../tableaddedit';

var count = 0;

function AddSortie({ setShowAdd, show, setIsSave, setMsg, setMsgravity, data, setLoading, loading }) {
    var ladate = new Date();
    const [validated, setValidated] = useState(false);
    const [nobon, setNobon] = useState('');
    const [datesort, setDatesort] = useState(
        dateformat(ladate.getMonth() + 1 + '/' + ladate.getDate() + '/' + ladate.getFullYear(), 'yyyy-mm-dd')
    );
    const [load, setLoad] = useState(false);
    const [qtesup, setQtesup] = useState(false);
    const [qtesupmsg, setQtesupmsg] = useState(false);
    const typeaheadRef = useRef(null);
    const [listeSelect, setListeSelect] = useState([]);

    useEffect(() => {
        let t = [];
        data.lignecommande.forEach((ent, index) => {
            t.push({
                article_id: ent.id,
                descriptionart: ent.pivot.descriptionart,
                quantite: ent.pivot.quantite,
                qrbarre: ent.qrbarre,
                prix: ent.prixvente
            });
            setListeSelect(t);
        });
    }, []);

    const Editableqte = ({ value: initialValue, row: { index }, column: { id } }) => {
        const [value, setValue] = React.useState(initialValue);
        const onChange = (e) => {
            setValue(e.target.value);
        };
        const onBlur = () => {
            listeSelect[index].quantite = parseInt(value);
        };
        React.useEffect(() => {
            setValue(initialValue);
        }, [initialValue]);
        return (
            <Form.Control
                type="number"
                className="prix"
                aria-describedby="inputGroupPrepend"
                required
                size="sm"
                min={1}
                value={value}
                onChange={onChange}
                onBlur={onBlur}
            />
        );
    };

    const EditableCellart = ({ value: initialValue, row: { index } }) => {
        const [value, setValue] = React.useState(initialValue);
        const onChange = (e) => {
            setValue(e.target.value);
        };
        const onBlur = () => {
            listeSelect[index].descriptionart = value;
        };
        React.useEffect(() => {
            setValue(initialValue);
        }, [initialValue]);
        return (
            <Form.Control
                type="text"
                className="prestation"
                aria-describedby="inputGroupPrepend"
                required
                size="sm"
                step="any"
                min="1"
                value={value}
                onChange={onChange}
                onBlur={onBlur}
            />
        );
    };

    const columns = [
        {
            Header: 'Code art.',
            accessor: 'qrbarre'
        },
        {
            Header: 'Descr.art.',
            accessor: 'descriptionart',
            Cell: EditableCellart
        },
        {
            Header: 'Qté',
            accessor: 'quantite',
            Cell: Editableqte
        },
        {
            Header: '#',
            accessor: '[editButton]',
            Cell: (row) => (
                <ButtonGroup aria-label="Basic example">
                    <Trash onClick={() => onDelete(row)} color="red" size={15} />
                </ButtonGroup>
            )
        }
    ];

    function onDelete(row) {
        setLoad(true);
        const del = listeSelect.filter((d) => d.article_id != row.row.original.article_id);
        setListeSelect(del);
        setMsg('Ligne supprimée');
        setMsgravity('success');
        setIsSave(true);
        setLoad(false);
    }

    function saveSortie(e) {
        try {
            if (listeSelect.length == 0) {
                setMsg('Veuillez sélectionner les produits');
                setMsgravity('info');
                setIsSave(true);
                setLoad(false);
                return;
            }
            setLoad(true);
            var formData = new FormData();
            formData.append('fact', 0);
            formData.append('datesort', datesort);
            formData.append('clients_id', data.clientid);
            formData.append('commandeid', data.id);
            formData.append('numbon', nobon);
            formData.append('demand_par', '');
            formData.append('engin', '');
            formData.append('codeart', JSON.stringify(listeSelect));
            formData.append('user_id', common.userid);

            Axios({
                url: common.ipapi + '/api/sortie/create',
                method: 'POST',
                headers: {
                    'Content-Type': 'form-data'
                },
                data: formData
            })
                .then((res) => {
                    if (res.status !== 200) {
                        setMsg('Veuillez vérifier votre connexion internet');
                        setMsgravity('info');
                        setIsSave(false);
                        return;
                    }
                    if (res.data.status == 1) {
                        setMsg(res.data.msg);
                        setMsgravity('success');
                        setIsSave(true);
                        count++;
                        setListeSelect([]);
                        typeaheadRef.current.clear();
                        setNobon('');
                        setLoad(false);
                        setLoading(!loading);
                        handleCloses();
                    } else if (res.data.status == 2) {
                        setMsg(res.data.msg);
                        setMsgravity('danger');
                        setIsSave(true);
                        setLoad(false);
                        setQtesup(true);
                        setQtesupmsg(res.data.msg);
                    } else {
                        if (res.data.code == 23000) {
                            setMsg('Ce numéro de bon existe déja');
                            setMsgravity('danger');
                            setIsSave(true);
                            setLoad(false);
                        } else {
                            setMsg(res.data.msg);
                            setMsgravity('danger');
                            setIsSave(true);
                            setLoad(false);
                        }
                    }
                })
                .catch((error) => {
                    setMsg('Veuillez vérifier votre connexion internet');
                    setIsSave(true);
                    setMsgravity('danger');
                    setLoad(false);
                });
        } catch (error) {
            setMsg('Veuillez vérifier votre connexion internet');
            setIsSave(true);
            setMsgravity('danger');
            setLoad(false);
        }
    }

    const handleSubmit = (event) => {
        const form = event.currentTarget;
        if (form.checkValidity() === false) {
            event.preventDefault();
            event.stopPropagation();
            setValidated(true);
        } else {
            saveSortie();
        }
    };

    const handleCloses = () => {
        setShowAdd(false);
        setIsSave(false);
    };

    return (
        <>
            <Modal show={load} size="sm" aria-labelledby="contained-modal-title-vcenter" centered>
                <Modal.Body>
                    Veuillez patienter.......
                    <Spinner animation="border" variant="success" />
                </Modal.Body>
            </Modal>

            <Modal show={show} fullscreen={true}>
                <div className="content">
                    <Form validated={validated} onSubmit={handleSubmit}>
                        {/*noValidate */}
                        <Modal.Header>
                            FAIRE UNE SORTIE DE STOCK
                            <Backspace onClick={handleCloses} color="red" size={30} />
                        </Modal.Header>
                        <Modal.Body>
                            {qtesup ? (
                                <Alert key="danger" transition="Fade" variant="danger">
                                    <TriangleHalf color="red" size={30} />
                                    {qtesupmsg}
                                </Alert>
                            ) : (
                                <></>
                            )}
                            <div id="lightbody">
                                <Row>
                                    <Col md={4}>
                                        <Card bg="light" border="dark">
                                            <Card.Body>
                                                <Card.Title>Entête de la facture </Card.Title>
                                                <Form.Group as={Row} className="mb-3">
                                                    <Form.Label column sm={3}>
                                                        Nom client:
                                                    </Form.Label>
                                                    <Col sm={9}>
                                                        <InputGroup>
                                                            {data.client}
                                                            {/* <Typeahead
                                                                id="my-typeahead-id"
                                                                labelKey={(option) => `${option.raisonsocial}`}
                                                                filterBy={['raisonsocial']}
                                                                value={client_id}
                                                                ref={typeaheadRef}
                                                                inputProps={{ required: true }}
                                                                options={clients}
                                                                // onChange={(cli) => setClientids(cli)}
                                                            /> */}
                                                        </InputGroup>
                                                    </Col>
                                                </Form.Group>

                                                <Form.Group as={Row} className="mb-3">
                                                    <Form.Label column sm={3}>
                                                        N° bon:
                                                    </Form.Label>
                                                    <Col sm={9}>
                                                        <Form.Control
                                                            type="text"
                                                            placeholder="N° de bon n'est pas obligatoire"
                                                            aria-describedby="inputGroupPrepend"
                                                            size="sm"
                                                            value={nobon}
                                                            onChange={(e) => setNobon(e.target.value)}
                                                        />
                                                    </Col>
                                                </Form.Group>

                                                <Form.Group as={Row} className="mb-3">
                                                    <Form.Label column sm={3}>
                                                        Date sortie:
                                                    </Form.Label>
                                                    <Col sm={9}>
                                                        <Form.Control
                                                            type="date"
                                                            placeholder="Date sortie"
                                                            aria-describedby="inputGroupPrepend"
                                                            required
                                                            size="sm"
                                                            value={datesort}
                                                            onChange={(e) => setDatesort(e.target.value)}
                                                        />
                                                    </Col>
                                                </Form.Group>
                                            </Card.Body>
                                        </Card>
                                    </Col>
                                    <Col md={8}>
                                        <Card bg="light" border="dark">
                                            <Card.Body>
                                                <Card.Title>Sélection des articles à livrer </Card.Title>
                                                <Table columns={columns} data={listeSelect} />
                                            </Card.Body>
                                        </Card>
                                    </Col>
                                </Row>
                            </div>
                        </Modal.Body>
                        <Modal.Footer>
                            <div className="ml-auto">
                                <ButtonGroup size="sm">
                                    <Button color="secondary" onClick={handleCloses}>
                                        <Backspace color="red" size={25} />
                                    </Button>
                                    <Button type="submit" color="warning">
                                        <Save2Fill color="#000" size={25} /> Enregistrer
                                    </Button>
                                </ButtonGroup>
                            </div>
                        </Modal.Footer>
                    </Form>
                </div>
            </Modal>
        </>
    );
}

export default AddSortie;
