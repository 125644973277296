import React from 'react';
import { Container, Nav, Navbar, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { Printer, FileExcel, PlusCircleDotted, List } from 'react-bootstrap-icons';

import 'jspdf-autotable';
import 'assets/css/table.css';

function NavMenu({ setAdds, selected, setFacts }) {
    return (
        <Navbar expand="lg" bg="light" variant="light">
            <Container>
                <Nav className="me-auto">
                    <OverlayTrigger placement="bottom" overlay={<Tooltip id="tooltip-disabled">Faire un nouvel inventaire</Tooltip>}>
                        <Nav.Link href="#/admin/inventaire" onClick={() => setAdds()}>
                            <PlusCircleDotted color="orange" size={35} /> Créer
                        </Nav.Link>
                    </OverlayTrigger>
                </Nav>
            </Container>
        </Navbar>
    );
}
export default NavMenu;
