import React, { useEffect, useState } from 'react';
import { Form, Spinner, Modal, OverlayTrigger, Tooltip, Navbar, Container, Nav, Stack, Badge } from 'react-bootstrap';
import { Button, ButtonGroup, Row, Col, Label } from 'reactstrap';
import Axios from 'axios';
import readXlsxFile from 'read-excel-file';
import { useHistory } from 'react-router-dom';
import { Backspace, File, FiletypeXlsx, Save2Fill } from 'react-bootstrap-icons';
import 'react-bootstrap-typeahead/css/Typeahead.css';
import * as Yup from 'yup';
import { Field, Formik } from 'formik';

import common from '../../commonData';
import Tables from './gridtableModal';

function AddInventaire({ id, articlesListe, statesData, setShowAdd, show, setIsSave, setMsg, setMsgravity, setActualise, actualise }) {
    const history = useHistory();
    const [load, setLoad] = useState(false);
    const [listeSelect, setListeSelect] = useState([]);
    const [articles, setArticles] = useState(articlesListe);
    const [articlesBis, setArticlesBis] = useState(articlesListe);
    var [nbError, setNbError] = useState({
        nbNouveauArt: 0,
        nbErrorMarq: 0,
        nbErrorFam: 0
    });
    const [states, setState] = useState({
        commentaire: statesData.commentaire,
        titre: statesData.titre,
        dateInventaire: statesData.dateInventaire
    });
    const { commentaire, titre, dateInventaire } = states;

    var { nbNouveauArt, nbErrorMarq, nbErrorFam } = nbError;
    useEffect(() => {
        if (!localStorage.getItem('user-info')) {
            history.push('/login');
        }
        if (id === 0) {
            (async function anyNameFunction() {
                setLoad(true);
                await Axios.get(common.ipapi + '/api/article/indexall')
                    .then((response) => {
                        setArticles(response.data);
                        setArticlesBis(response.data);
                        setLoad(false);
                    })
                    .catch((errors) => {
                        setLoad(false);
                    });
            })();
        }
    }, [history]);

    function saveInventaire(values, setSubmitting) {
        if (window.confirm('Voulez-vous confirmer cette action') == false) {
            setLoad(false);
            return;
        }
        if (listeSelect.length === 0) {
            setMsg('Impossible, car aucune quantité modifiée');
            setMsgravity('info');
            setIsSave(true);
            setLoad(false);
            return;
        }
        setLoad(true);
        setSubmitting(false);
        const filteredProduct = listeSelect.filter((product, index) => {
            return product.quantite !== '0';
        });
        var formData = new FormData();
        formData.append('dateInventaire', values.dateInventaires);
        formData.append('commentaire', values.commentaires);
        formData.append('titre', values.titres);
        if (id === 0) {
            formData.append('codeart', JSON.stringify(filteredProduct));
        }
        formData.append('user_id', common.userid);
        Axios({
            url: id ? common.ipapi + '/api/inventaire/update/' + id : common.ipapi + '/api/inventaire/store',
            method: 'POST',
            headers: {
                'Content-Type': 'form-data'
            },
            data: formData
        })
            .then((res) => {
                if (res.status !== 200) {
                    setMsg('Veuillez vérifier votre connexion internet');
                    setMsgravity('info');
                    setIsSave(false);
                    setSubmitting(true);
                    return;
                }
                if (res.data.status === 1) {
                    setMsg(res.data.msg);
                    setMsgravity('success');
                    setIsSave(true);
                    setActualise(!actualise);
                    setLoad(false);
                    setSubmitting(true);
                    setTimeout(handleClose, 600);
                } else if (res.data.status === 2) {
                    setMsg(res.data.msg);
                    setMsgravity('danger');
                    setIsSave(true);
                    setLoad(false);
                    setSubmitting(true);
                } else {
                    setMsg(res.data.msg);
                    setMsgravity('danger');
                    setIsSave(true);
                    setLoad(false);
                    setSubmitting(true);
                }
            })
            .catch((error) => {
                setMsg(error);
                setIsSave(true);
                setMsgravity('danger');
                setLoad(false);
            });
    }

    const handleClose = () => {
        setShowAdd(false);
        setIsSave(false);
    };

    const setLoadFile = (files) => {
        setLoad(true);
        if (files[0]) {
            var data;
            setListeSelect([]);
            setNbError((previousState) => {
                return { ...previousState, nbErrorFam: 0, nbErrorMarq: 0, nbNouveauArt: 0 };
            });
            readXlsxFile(files[0]).then((rows) => {
                data = groupBy(rows);
                setArticles(data);
                setListeSelect(data);
                setLoad(false);
            });
        }
    };

    function groupBy(tableauObjets) {
        let t = [];
        setLoad(true);
        return tableauObjets.reduce(function (res, value, index) {
            if (value[0] != '' || index == 0) {
                var idart = 0,
                    idmarque = 0,
                    idfamille = 0,
                    prixachat = 0;
                var libmarque;
                var etat = 1;
                var findIdMarq = -1,
                    findIdFammille = -1,
                    findQr = -1;
                var isLargeNumber = (element) => element.qrbarre === value[0];
                findQr = articlesBis.findIndex(isLargeNumber);
                var familleFilter = (element) => element.famille === value[3];
                findIdFammille = articlesBis.findIndex(familleFilter);
                var marqueFilter = (element) => element.marque === value[2];
                findIdMarq = articlesBis.findIndex(marqueFilter);

                if (findQr >= 0) {
                    idart = articlesBis[findQr].id;
                    prixachat = articlesBis[findQr].prixachat;
                    etat = 0;
                    if (findIdFammille >= 0) {
                        idfamille = articlesBis[findIdFammille].famille_id;
                        idmarque = articlesBis[findIdFammille].idmarque;
                        libmarque = articlesBis[findIdFammille].marque;
                        if (findIdMarq >= 0) {
                            idmarque = articlesBis[findIdMarq].idmarque;
                        }
                    } else {
                        setNbError((previousState) => {
                            return { ...previousState, nbErrorFam: nbErrorFam++ };
                        });
                        console.log(nbErrorFam);
                        if (findIdMarq >= 0) {
                            idmarque = articlesBis[findIdMarq].idmarque;
                            libmarque = articlesBis[findIdMarq].marque;
                        }
                    }

                    if (idmarque == 0 || idmarque == null) {
                        idmarque = articlesBis[findQr].idmarque;
                        libmarque = articlesBis[findQr].marque;
                    } else {
                        setNbError(() => {
                            return { nbErrorMarq: nbErrorMarq++ };
                        });
                    }
                } else {
                    setNbError(() => {
                        return { nbNouveauArt: nbNouveauArt++ };
                    });
                    if (findIdFammille >= 0) {
                        idfamille = articlesBis[findIdFammille].famille_id;
                        idmarque = articlesBis[findIdFammille].idmarque;
                        libmarque = articlesBis[findIdFammille].marque;
                    } else {
                        setNbError((previousState) => {
                            return { ...previousState, nbErrorFam: nbErrorFam++ };
                        });
                        if (findIdMarq >= 0) {
                            idmarque = articlesBis[findIdMarq].idmarque;
                            libmarque = articlesBis[findIdMarq].marque;
                        }
                    }
                }

                t.push({
                    id: idart,
                    qrbarre: value[0],
                    descriptionartfr: value[1],
                    marque: libmarque || value[2],
                    idmarque: idmarque,
                    famille: value[3],
                    famille_id: idfamille,
                    emplacement: value[4],
                    qtestock: value[5],
                    qtestocks: value[6] || 0,
                    qtediff: value[7],
                    prixachat: prixachat,
                    etat: etat
                });
                return t;
            }
        }, {});
    }

    return (
        <>
            <Modal show={load} size="sm" aria-labelledby="contained-modal-title-vcenter" centered>
                <Modal.Body>
                    Veuillez patienter.......
                    <Spinner animation="border" variant="success" />
                </Modal.Body>
            </Modal>

            <Formik
                initialValues={{
                    dateInventaires: dateInventaire,
                    titres: titre,
                    commentaires: commentaire,
                    submit: null
                }}
                enableReinitialize
                validationSchema={Yup.object().shape({
                    dateInventaires: Yup.date().required(common.msg),
                    titres: Yup.string().required(common.msg)
                })}
                onSubmit={async (values, { setSubmitting }) => {
                    try {
                        setLoad(true);
                        saveInventaire(values, setSubmitting);
                    } catch (err) {
                        setMsg('Une erreur à eu lieu');
                        setMsgravity('danger');
                        setIsSave(true);
                        setLoad(false);
                    }
                }}
            >
                {({ errors, handleBlur, handleChange, handleSubmit, isSubmitting, touched, values, setFieldValue, setFieldTouched }) => (
                    <Modal show={show} fullscreen={true} onHide={handleClose}>
                        <div className="content">
                            <Form noValidate onSubmit={handleSubmit}>
                                <Modal.Header>
                                    FAIRE UN NOUVEL INVENTAIRE
                                    <Backspace onClick={handleClose} color="red" size={30} />
                                </Modal.Header>
                                <Modal.Body>
                                    <div id="lightbody">
                                        <Row>
                                            <Col md={3}>
                                                <Form.Group as={Row} className="mb-3">
                                                    <Form.Label column sm={2}>
                                                        Titre:
                                                    </Form.Label>
                                                    <Col sm={10}>
                                                        <Form.Control
                                                            id="titres"
                                                            type="text"
                                                            placeholder="Titre"
                                                            value={values.titres}
                                                            onBlur={handleBlur}
                                                            onChange={handleChange}
                                                            // onChange={(e) => setTitre(e.target.value)}
                                                        />
                                                        {errors.titres && <div id="feedback">{errors.titres}</div>}
                                                    </Col>
                                                </Form.Group>
                                                <Form.Group as={Row} className="mb-3">
                                                    <Form.Label column sm={3}>
                                                        Date:
                                                    </Form.Label>
                                                    <Col sm={9}>
                                                        <Form.Control
                                                            id="dateInventaires"
                                                            type="date"
                                                            // required
                                                            value={values.dateInventaires}
                                                            onBlur={handleBlur}
                                                            onChange={handleChange}
                                                            // onChange={(e) => setDateInventaire(e.target.value)}
                                                        />
                                                    </Col>
                                                    {errors.dateInventaires && <div id="feedback">{errors.dateInventaires}</div>}
                                                </Form.Group>
                                                <Form.Group as={Row} className="mb-3">
                                                    <Form.Label column sm={3}>
                                                        Commentaire:
                                                    </Form.Label>
                                                    <Col sm={9}>
                                                        <Form.Control
                                                            id="commentaires"
                                                            as="textarea"
                                                            rows={6}
                                                            type="text"
                                                            placeholder="Commentaire"
                                                            value={values.commentaires}
                                                            onBlur={handleBlur}
                                                            onChange={handleChange}
                                                            // onChange={(e) => setCommentaire(e.target.value)}
                                                        />
                                                    </Col>
                                                </Form.Group>
                                            </Col>
                                            <Col md={9}>
                                                <Row>
                                                    <Col md="12">
                                                        <Navbar expand="lg" bg="light" variant="light">
                                                            <Container>
                                                                <Nav className="me-auto">
                                                                    <OverlayTrigger
                                                                        placement="top"
                                                                        overlay={
                                                                            <Tooltip id="tooltip-disabled">
                                                                                Inventaire à partir du stock
                                                                            </Tooltip>
                                                                        }
                                                                    >
                                                                        <Nav.Link
                                                                            href="#/admin/inventaire"
                                                                            onClick={(e) => setArticles(articlesBis)}
                                                                        >
                                                                            <Label className="custom-file-upload">
                                                                                Stock articles
                                                                                <File color="orange" size={35} />
                                                                            </Label>
                                                                        </Nav.Link>
                                                                    </OverlayTrigger>
                                                                    <OverlayTrigger
                                                                        placement="top"
                                                                        overlay={
                                                                            <Tooltip id="tooltip-disabled">
                                                                                Inventaire à partir d'un fichier Excel
                                                                            </Tooltip>
                                                                        }
                                                                    >
                                                                        <Nav.Link href="#/admin/inventaire">
                                                                            <Label className="custom-file-upload">
                                                                                Importer Fichier Excel
                                                                                <input
                                                                                    type="file"
                                                                                    accept=".xlsx, .xls"
                                                                                    onChange={(e) => setLoadFile(e.target.files)}
                                                                                />
                                                                                <FiletypeXlsx color="orange" size={35} />
                                                                            </Label>
                                                                            {/* <FiletypeXlsx color="orange" size={25} /> Fichier excel */}
                                                                        </Nav.Link>
                                                                    </OverlayTrigger>
                                                                </Nav>

                                                                <Stack direction="horizontal" gap={2}>
                                                                    {nbNouveauArt ? (
                                                                        <OverlayTrigger
                                                                            placement="top"
                                                                            overlay={
                                                                                <Tooltip id="tooltip-disabled">
                                                                                    {nbNouveauArt +
                                                                                        ' nouveau produit(s) détecté(s) dans le fichier importé'}
                                                                                </Tooltip>
                                                                            }
                                                                        >
                                                                            <Badge bg="warning" text="dark">
                                                                                {nbNouveauArt}
                                                                            </Badge>
                                                                        </OverlayTrigger>
                                                                    ) : (
                                                                        <></>
                                                                    )}
                                                                    {nbErrorMarq ? (
                                                                        <OverlayTrigger
                                                                            placement="top"
                                                                            overlay={
                                                                                <Tooltip id="tooltip-disabled">
                                                                                    Inventaire à partir d'un fichier Excel
                                                                                </Tooltip>
                                                                            }
                                                                        >
                                                                            <Badge bg="danger">{nbErrorMarq}</Badge>
                                                                        </OverlayTrigger>
                                                                    ) : (
                                                                        <></>
                                                                    )}
                                                                    {nbErrorFam ? (
                                                                        <OverlayTrigger
                                                                            placement="top"
                                                                            overlay={
                                                                                <Tooltip id="tooltip-disabled">
                                                                                    Inventaire à partir d'un fichier Excel
                                                                                </Tooltip>
                                                                            }
                                                                        >
                                                                            <Badge bg="danger">{nbErrorFam}</Badge>
                                                                        </OverlayTrigger>
                                                                    ) : (
                                                                        <></>
                                                                    )}
                                                                </Stack>
                                                            </Container>
                                                        </Navbar>
                                                    </Col>
                                                    <Col md="12">
                                                        <Tables
                                                            articles={articles}
                                                            setArticles={setArticles}
                                                            isLoading={load}
                                                            isEdit={id ? false : true}
                                                            setListeSelect={setListeSelect}
                                                        />
                                                    </Col>
                                                </Row>
                                            </Col>
                                        </Row>
                                    </div>
                                </Modal.Body>
                                <Modal.Footer>
                                    <div className="ml-auto">
                                        <ButtonGroup size="sm">
                                            <Button variant="secondary" onClick={handleClose}>
                                                <Backspace color="red" size={20} />
                                            </Button>
                                            <Button type="submit" variant="warning" disabled={isSubmitting}>
                                                {isSubmitting ? (
                                                    <Spinner animation="border" size="20" variant="success" />
                                                ) : (
                                                    <Save2Fill color="#807f7e" size={20} />
                                                )}
                                                Enregister
                                            </Button>
                                        </ButtonGroup>
                                    </div>
                                </Modal.Footer>
                            </Form>
                        </div>
                    </Modal>
                )}
            </Formik>
        </>
    );
}

export default AddInventaire;
