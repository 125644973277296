import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import {
    Form,
    InputGroup,
    Modal,
    Toast,
    Spinner,
    Tabs,
    Tab,
    Carousel,
    ButtonGroup,
    OverlayTrigger,
    Tooltip,
    Nav,
    Alert
} from 'react-bootstrap';
import { Card, Button, Input, Row, Col, CardBody } from 'reactstrap';
import Axios from 'axios';
import {
    Save2Fill,
    ArrowLeft,
    Printer,
    Backspace,
    DashCircleDotted,
    FileEarmarkPlus,
    FileEarmarkMinus,
    PencilSquare
} from 'react-bootstrap-icons';
import dateformat from 'dateformat';
import { jsPDF } from 'jspdf';
import 'jspdf-autotable';

import EditArticle from './editLight';
import common from '../../commonData';

function FicheArticle({ id, setShowView, show, setDatas, data, getArticle }) {
    const [shows, setShow] = useState(false);
    const [validated, setValidated] = useState(false);
    const [codeart, setCodeart] = useState('');
    const [pmp, setPmp] = useState('');
    const [sortlyid, setSortlyid] = useState('');
    const [dateentr, setDateentr] = useState('');
    const [descriptionart, setDescriptionart] = useState('');
    const [descriptionartfr, setDescriptionartFr] = useState('');
    const [qtestock, setQtestock] = useState(0);
    const [qte, setQte] = useState(1);
    const [qtestockinit, setQtestockinit] = useState(0);
    const [qteseuil, setQteseuil] = useState(0);
    const [coeficient, setCoeficient] = useState(0);
    const [prixachat, setPrixachat] = useState(0);
    const [prixvente, setPrixvente] = useState(0);
    const [famille_id, setFamille] = useState('');
    const [marque_id, setMarque] = useState('');
    const [img, setImg] = useState([]);
    const [imgb, setImgb] = useState('');
    const [fournisseur_id, setFournisseur] = useState('');
    const [isSave, setIsSave] = useState(false);
    const [load, setLoad] = useState(false);
    const [listHs, setListHs] = useState([]);
    const [listEntre, setListEntr] = useState([]);
    const [listSort, setListSor] = useState([]);
    const [listefacture, setListeFacture] = useState([]);
    const [listLost, setListLost] = useState([]);
    const [fournisseur, setFournData] = useState([]);
    const [msgGravity, setMsgravity] = useState('success');
    const [barQrCode, setBarCode] = useState('');
    const [barQrCodecourt, setLongBar] = useState('');
    const [msg, setMsg] = useState('');
    const [showEdit, setShowEdit] = useState(false);
    const [ids, setId] = useState(0);
    const sidebarToggle = React.useRef();
    const history = useHistory();
    const [emplacement, setEmplacement] = useState('');

    var qtee = 0,
        qtes = 0,
        nbe = 0,
        nbs = 0,
        pae = 0,
        pas = 0,
        pve = 0,
        pvs = 0;
    //let { id } = useParams();
    useEffect(() => {
        if (!localStorage.getItem('user-info')) {
            history.push('/login');
        }
        (async function anyNameFunction() {
            setLoad(true);
            const getArticle = Axios.get(common.ipapi + '/api/article/edit/' + id);
            const getArticleHs = Axios.get(common.ipapi + '/api/article/showhs/' + id);
            const getArticleLost = Axios.get(common.ipapi + '/api/article/showlost/' + id);
            const getEntree = Axios.get(common.ipapi + '/api/article/entreebyidart/' + id);
            const getSortie = Axios.get(common.ipapi + '/api/article/sortiebyidart/' + id);
            const getFacture = Axios.get(common.ipapi + '/api/article/facturebyidart/' + id);
            const getFourn = Axios.get(common.ipapi + '/api/fournisseur/all');
            await Axios.all([getArticleHs, getArticleLost, getArticle, getEntree, getSortie, getFourn, getFacture])
                .then(
                    Axios.spread((...responses) => {
                        setListHs(responses[0].data.articlehs);
                        setListLost(responses[1].data.articlelost);
                        setCodeart(responses[2].data.codeart);
                        setDescriptionart(responses[2].data.descriptionart);
                        setDescriptionartFr(responses[2].data.descriptionartfr);
                        setQtestock(responses[2].data.qtestock);
                        setQtestockinit(responses[2].data.qtestockinit);
                        setQteseuil(responses[2].data.qteseuil);
                        setCoeficient(responses[2].data.coeficient);
                        setPrixachat(responses[2].data.prixachat);
                        setBarCode(responses[2].data.qrbarre);
                        setLongBar(responses[2].data.qrbarreshort);
                        setCodeart(responses[2].data.codeart);
                        setPmp(responses[2].data.pmp);
                        setImg(responses[2].data.image);
                        setImgb(responses[2].data.urlart);
                        setSortlyid(responses[2].data.sortlysid);
                        setPrixvente(responses[2].data.prixachat * responses[2].data.coeficient);
                        if (responses[3].data) {
                            setListEntr(responses[3].data.ligneentree);
                        }
                        if (responses[4].data.lingesort) {
                            setListSor(responses[4].data.lingesort);
                        }
                        if (responses[2].data.fournisseur) {
                            setFournisseur(responses[2].data.fournisseur.nomfourn);
                        }
                        if (responses[2].data.famille) {
                            setFamille(responses[2].data.famille.libellefam);
                        }
                        if (responses[2].data.marque) {
                            setMarque(responses[2].data.marque.libellemarq);
                        }
                        if (responses[6].data.lignefacture) {
                            setListeFacture(responses[6].data.lignefacture);
                        }
                        setFournData(responses[5].data);
                        setLoad(false);
                    })
                )
                .catch((errors) => {
                    //setIsSave()
                });
        })();
    }, [id]);

    const handleClose = () => {
        setShowView(false);
        // setCodeart([])
    };

    function setIds(id) {
        setId(id);
        setShowEdit(true);
    }
    const handleSubmit = (event) => {
        event.preventDefault();
        const form = event.currentTarget;
        if (form.checkValidity() === false) {
            event.stopPropagation();
        }
        setValidated(true);
        saveEntree();
    };

    function saveEntree(e) {
        setLoad(true);
        var formData = new FormData();
        formData.append('dateentr', dateentr);
        formData.append('fournisseur_id', fournisseur_id);
        formData.append('arrivage', '');
        formData.append('quantite', qte);
        formData.append('articleid', id);
        formData.append('user_id', common.userid);
        Axios({
            url: common.ipapi + '/api/entrees/create',
            method: 'POST',
            headers: {
                'Content-Type': 'form-data'
            },
            data: formData
        })
            .then((res) => {
                if (res.data.status == 1) {
                    setCodeart([]);
                    setDateentr('');
                    setMsg(res.data.msg);
                    setIsSave(true);
                    setMsgravity('info');
                    setFournisseur([]);
                    setLoad(false);
                    setShow(false);
                } else {
                    if (res.data.msg == 23000) {
                        setMsg(res.data.data);
                    } else {
                        setMsg('Une erreur à eu lieu lors de la création');
                    }
                    setMsgravity('danger');
                    setIsSave(true);
                    setLoad(false);
                }
            })
            .catch((error) => {
                setIsSave(false);
                setMsgravity('danger');
                setLoad(false);
            });
    }

    //-------------IMPRIMER LA LISTE SORTIE PAR ARTICLE----------------
    function printListeSort() {
        if (listSort.length <= 0) {
            setMsg('Aucune donnée à imprimer');
            setIsSave(true);
            setMsgravity('danger');
            setLoad(false);
            return;
        }
        const doc = new jsPDF();
        var width = doc.internal.pageSize.getWidth();
        const data = [];
        let mont = 0;
        let qte = 0;
        doc.addImage(common.logo, 'jpg', 14, 10, 30, 30);
        doc.setFontSize(16);
        doc.text('CLUB AUTO ENTREPOT', 67, 14);
        doc.setFontSize(12);
        doc.setFillColor(0, 0, 0);
        doc.rect(65, 15, width - 80, 17, 'F');
        doc.setTextColor(255, 255, 255);
        doc.text('LISTE SORTIE DE: ' + descriptionart.toUpperCase(), 67, 22);
        doc.text('MARQUE: ' + marque_id.toUpperCase(), 67, 28);
        doc.setTextColor(0, 0, 0);
        listSort.forEach((lsort) => {
            mont += Math.round(prixachat * coeficient * lsort.pivot.quantite);
            qte += lsort.pivot.quantite;
            data.push([
                lsort.demand_par,
                lsort.pivot.quantite,
                lsort.pivot.prix !== 0
                    ? lsort.pivot.prix * (lsort.pivot.quantite - lsort.pivot.quantiteretour)
                    : prixachat * coeficient * (lsort.pivot.quantite - lsort.pivot.quantiteretour),
                lsort.numbon,
                dateformat(lsort.datesort, 'dd-mm-yyyy')
            ]);
        });
        data.push([
            'Total',
            '',
            qte,
            new Intl.NumberFormat('de-DE', {
                style: 'decimal',
                currency: 'CFA'
            }).format(mont),
            ''
        ]);
        doc.autoTable({
            styles: {
                fillColor: [0, 0, 0],
                textColor: [255, 255, 255],
                halign: 'center',
                fontSize: 10
            },
            columnStyles: {
                0: {
                    halign: 'center',
                    fillColor: [255, 255, 255],
                    textColor: [0, 0, 0]
                },
                1: {
                    halign: 'center',
                    fillColor: [255, 255, 255],
                    textColor: [0, 0, 0]
                },
                2: {
                    halign: 'center',
                    fillColor: [255, 255, 255],
                    textColor: [0, 0, 0]
                },
                3: {
                    halign: 'center',
                    fillColor: [255, 255, 255],
                    textColor: [0, 0, 0]
                },
                4: {
                    halign: 'center',
                    fillColor: [255, 255, 255],
                    textColor: [0, 0, 0]
                }
            },
            theme: 'grid',
            startY: 35,
            fontSize: 6,
            head: [['Demandeur', 'Qté', 'Prix', 'N° bl', 'Date sortie']],
            body: data
        });
        const pageCount = doc.internal.getNumberOfPages();
        for (var i = 1; i <= pageCount; i++) {
            doc.setPage(i);
            doc.setFontSize(10);
            doc.text('Page ' + String(i) + ' / ' + String(pageCount), 210 - 20, 285, null, null, 'right');
        }
        doc.save('Listesortie.pdf');
    }
    function droitSup(obj) {
        if (obj.code_Droit === 'SAD' || obj.code_Droit === 'SART') {
            return true;
        } else {
            return false;
        }
    }
    function onDelete(id) {
        var arrByID = common.userdroit.filter(droitSup);
        if (arrByID.length == 0) {
            setIsSave(true);
            setMsg("Vous n'avez pas le droit de mener cette action, merci de contacter l'administrateur");
            setMsgravity('danger');
            return false;
        }
        if (window.confirm('Voulez-vous supprimer cet article ?')) {
            setLoad(true);
            Axios.delete(common.ipapi + '/api/article/destroy/' + id).then((response) => {
                if (response.data.status == 1) {
                    const del = data.filter((d) => d.id !== id);
                    setDatas(del);
                    setShowView(false);
                    setMsgravity('info');
                    setMsg(response.data.msg);
                    setIsSave(true);
                    setLoad(false);
                } else if (response.data.status == 0) {
                    setMsgravity('info');
                    setMsg(response.data.msg);
                    setIsSave(true);
                    setLoad(false);
                } else {
                    setMsgravity('danger');
                    setMsg('Une erreur a eu lieu');
                    setIsSave(true);
                    setLoad(false);
                }
            });
        }
    }

    return (
        <>
            <Toast onClose={() => setIsSave(false)} show={isSave} delay={3000} bg={msgGravity} autohide>
                <Toast.Header>
                    <img src="holder.js/20x20?text=%20" className="rounded me-2" alt="" />
                    <strong className="me-auto">Info</strong>
                </Toast.Header>
                <Toast.Body>{msg}</Toast.Body>
            </Toast>
            <Modal show={load} size="sm" aria-labelledby="contained-modal-title-vcenter" centered>
                <Modal.Body>
                    Veuillez patienter.......
                    <Spinner animation="grow" variant="warning" />
                </Modal.Body>
            </Modal>
            {ids != 0 ? (
                <EditArticle
                    id={ids}
                    setShowEdit={setShowEdit}
                    show={showEdit}
                    setIsSave={setIsSave}
                    isSave={false}
                    setDatas={setDatas}
                    setMsg={setMsg}
                    setMsgravity={setMsgravity}
                    getArticle={getArticle}
                />
            ) : (
                <></>
            )}
            <Modal show={show} onHide={handleClose} fullscreen={true}>
                <Modal.Header>
                    {descriptionart.toUpperCase()} <Backspace onClick={handleClose} color="red" size={30} />
                </Modal.Header>
                <Modal.Body>
                    <div id="lightbody">
                        <Row>
                            <Col md="12">
                                <Row>
                                    <Col md={4}>
                                        <Card>
                                            <CardBody>
                                                {img ? (
                                                    <Carousel
                                                        slide={false}
                                                        pause="hover"
                                                        fade={true}
                                                        variant="dark"
                                                        nextLabel=""
                                                        prevLabel=""
                                                    >
                                                        {img.map((d, index) => {
                                                            return (
                                                                <Carousel.Item interval={5000} key={index}>
                                                                    <img
                                                                        className="d-block w-100"
                                                                        src={
                                                                            d.link.indexOf('sortly') == -1
                                                                                ? common.ipapi + '/' + d.link
                                                                                : d.link
                                                                        }
                                                                        alt="First slide"
                                                                    />
                                                                </Carousel.Item>
                                                            );
                                                        })}
                                                    </Carousel>
                                                ) : (
                                                    <Carousel slide={false} fade={true} variant="dark" nextLabel="" prevLabel="">
                                                        <Carousel.Item interval={5000}>
                                                            {imgb ? (
                                                                imgb.indexOf('sortly') == -1 ? (
                                                                    <img
                                                                        alt="img"
                                                                        className="d-block w-100"
                                                                        src={common.ipapi + imgb}
                                                                    ></img>
                                                                ) : (
                                                                    <img className="d-block w-100" src={imgb} alt="img"></img>
                                                                )
                                                            ) : (
                                                                ''
                                                            )}
                                                        </Carousel.Item>
                                                    </Carousel>
                                                )}
                                            </CardBody>
                                        </Card>
                                    </Col>
                                    <Col md={8}>
                                        <Card>
                                            <CardBody>
                                                <Tabs id="uncontrolled-tab-exemple">
                                                    <Tab eventKey="Fiche" title="Fiche article">
                                                        <br />
                                                        <Form.Group as={Row} className="mb-3" controlId="formHorizontalEmail">
                                                            <Form.Label column sm={2}>
                                                                Code article:
                                                            </Form.Label>
                                                            <Col sm={10}>
                                                                <Input
                                                                    type="text"
                                                                    aria-describedby="inputGroupPrepend"
                                                                    disabled
                                                                    bsSize="sm"
                                                                    value={codeart}
                                                                />
                                                            </Col>
                                                        </Form.Group>
                                                        <Form.Group as={Row} className="mb-3" controlId="formHorizontalEmail">
                                                            <Form.Label column sm={2}>
                                                                Code qr 1:
                                                            </Form.Label>
                                                            <Col sm={10}>
                                                                <Input
                                                                    type="text"
                                                                    aria-describedby="inputGroupPrepend"
                                                                    disabled
                                                                    bsSize="sm"
                                                                    value={barQrCode}
                                                                />
                                                            </Col>
                                                        </Form.Group>
                                                        <Form.Group as={Row} className="mb-3" controlId="formHorizontalEmail">
                                                            <Form.Label column sm={2}>
                                                                Code qr 2:
                                                            </Form.Label>
                                                            <Col sm={10}>
                                                                <Input
                                                                    type="text"
                                                                    aria-describedby="inputGroupPrepend"
                                                                    disabled
                                                                    bsSize="sm"
                                                                    value={barQrCodecourt}
                                                                />
                                                            </Col>
                                                        </Form.Group>
                                                        <Form.Group as={Row} className="mb-3" controlId="formHorizontalEmail">
                                                            <Form.Label column sm={2}>
                                                                Libellé EN.:
                                                            </Form.Label>
                                                            <Col sm={10}>
                                                                <Form.Control
                                                                    type="text"
                                                                    placeholder="Description article"
                                                                    aria-describedby="inputGroupPrepend"
                                                                    size="sm"
                                                                    value={descriptionart}
                                                                    disabled
                                                                />
                                                            </Col>
                                                        </Form.Group>
                                                        <Form.Group as={Row} className="mb-3" controlId="formHorizontalEmail">
                                                            <Form.Label column sm={2}>
                                                                Libellé FR.:
                                                            </Form.Label>
                                                            <Col sm={10}>
                                                                <Form.Control
                                                                    type="text"
                                                                    placeholder="Désignation française"
                                                                    aria-describedby="inputGroupPrepend"
                                                                    size="sm"
                                                                    value={descriptionartfr}
                                                                    disabled
                                                                />
                                                            </Col>
                                                        </Form.Group>

                                                        <Form.Group as={Row} className="mb-3" controlId="formHorizontalEmail">
                                                            <Form.Label column sm={2}>
                                                                Quantité stock:
                                                            </Form.Label>
                                                            <Col sm={10}>
                                                                <Form.Control
                                                                    bg="success"
                                                                    type="number"
                                                                    placeholder="Quantité stock"
                                                                    aria-describedby="inputGroupPrepend"
                                                                    size="sm"
                                                                    value={qtestock}
                                                                    disabled
                                                                />
                                                            </Col>
                                                        </Form.Group>
                                                        <Form.Group as={Row} className="mb-3" controlId="formHorizontalEmail">
                                                            <Form.Label column sm={2}>
                                                                Quantité initiale:
                                                            </Form.Label>
                                                            <Col sm={10}>
                                                                <Form.Control
                                                                    type="number"
                                                                    placeholder="Quantité initiale"
                                                                    aria-describedby="inputGroupPrepend"
                                                                    size="sm"
                                                                    value={qtestockinit}
                                                                    disabled
                                                                />
                                                            </Col>
                                                        </Form.Group>
                                                        <Form.Group as={Row} className="mb-3" controlId="formHorizontalEmail">
                                                            <Form.Label column sm={2}>
                                                                Quantité seuil:
                                                            </Form.Label>
                                                            <Col sm={10}>
                                                                <Form.Control
                                                                    type="number"
                                                                    placeholder="Quantité seuil"
                                                                    aria-describedby="inputGroupPrepend"
                                                                    size="sm"
                                                                    value={qteseuil}
                                                                    disabled
                                                                />
                                                            </Col>
                                                        </Form.Group>
                                                        {common.search(common.userdroit, 'SAD') === undefined ? (
                                                            common.search(common.userdroit, 'VPA') === undefined ? (
                                                                <Form.Group as={Row} className="mb-3" controlId="formHorizontalEmail">
                                                                    <Form.Label column sm={2}>
                                                                        Prix d'achat:
                                                                    </Form.Label>
                                                                    <Col sm={10}>
                                                                        <Form.Control
                                                                            type="text"
                                                                            placeholder="Prix d'achat"
                                                                            aria-describedby="inputGroupPrepend"
                                                                            size="sm"
                                                                            value="######"
                                                                            disabled
                                                                        />
                                                                    </Col>
                                                                </Form.Group>
                                                            ) : (
                                                                <Form.Group as={Row} className="mb-3" controlId="formHorizontalEmail">
                                                                    <Form.Label column sm={2}>
                                                                        Prix d'achat:
                                                                    </Form.Label>
                                                                    <Col sm={10}>
                                                                        <Form.Control
                                                                            type="number"
                                                                            placeholder="Prix d'achat"
                                                                            aria-describedby="inputGroupPrepend"
                                                                            size="sm"
                                                                            value={prixachat}
                                                                            disabled
                                                                        />
                                                                    </Col>
                                                                </Form.Group>
                                                            )
                                                        ) : (
                                                            <Form.Group as={Row} className="mb-3" controlId="formHorizontalEmail">
                                                                <Form.Label column sm={2}>
                                                                    Prix d'achat:
                                                                </Form.Label>
                                                                <Col sm={10}>
                                                                    <Form.Control
                                                                        type="number"
                                                                        placeholder="Prix d'achat"
                                                                        aria-describedby="inputGroupPrepend"
                                                                        size="sm"
                                                                        value={prixachat}
                                                                        disabled
                                                                    />
                                                                </Col>
                                                            </Form.Group>
                                                        )}

                                                        {common.search(common.userdroit, 'SAD') === undefined ? (
                                                            common.search(common.userdroit, 'VPA') === undefined ? (
                                                                <Form.Group as={Row} className="mb-3" controlId="formHorizontalEmail">
                                                                    <Form.Label column sm={2}>
                                                                        Prix moyen pondéré:
                                                                    </Form.Label>
                                                                    <Col sm={10}>
                                                                        <Form.Control
                                                                            type="text"
                                                                            placeholder="Prix d'achat"
                                                                            aria-describedby="inputGroupPrepend"
                                                                            size="sm"
                                                                            value="######"
                                                                            disabled
                                                                        />
                                                                    </Col>
                                                                </Form.Group>
                                                            ) : (
                                                                <Form.Group as={Row} className="mb-3" controlId="formHorizontalEmail">
                                                                    <Form.Label column sm={2}>
                                                                        Prix moyen pondéré:
                                                                    </Form.Label>
                                                                    <Col sm={10}>
                                                                        <Form.Control
                                                                            type="number"
                                                                            placeholder="Prix moyen pondéré"
                                                                            aria-describedby="inputGroupPrepend"
                                                                            size="sm"
                                                                            value={pmp}
                                                                            disabled
                                                                        />
                                                                    </Col>
                                                                </Form.Group>
                                                            )
                                                        ) : (
                                                            <Form.Group as={Row} className="mb-3" controlId="formHorizontalEmail">
                                                                <Form.Label column sm={2}>
                                                                    Prix moyen pondéré:
                                                                </Form.Label>
                                                                <Col sm={10}>
                                                                    <Form.Control
                                                                        type="number"
                                                                        placeholder="Prix moyen pondéré"
                                                                        aria-describedby="inputGroupPrepend"
                                                                        size="sm"
                                                                        value={pmp}
                                                                        disabled
                                                                    />
                                                                </Col>
                                                            </Form.Group>
                                                        )}

                                                        <Form.Group as={Row} className="mb-3" controlId="formHorizontalEmail">
                                                            <Form.Label column sm={2}>
                                                                Coéficient:
                                                            </Form.Label>
                                                            <Col sm={10}>
                                                                <Form.Control
                                                                    type="number"
                                                                    placeholder="Coeficient"
                                                                    aria-describedby="inputGroupPrepend"
                                                                    size="sm"
                                                                    value={coeficient}
                                                                    disabled
                                                                />
                                                            </Col>
                                                        </Form.Group>
                                                        <Form.Group as={Row} className="mb-3" controlId="formHorizontalEmail">
                                                            <Form.Label column sm={2}>
                                                                Prix de vente:
                                                            </Form.Label>
                                                            <Col sm={10}>
                                                                <Form.Control
                                                                    disabled
                                                                    type="number"
                                                                    placeholder="Prix de vente"
                                                                    aria-describedby="inputGroupPrepend"
                                                                    size="sm"
                                                                    value={prixvente}
                                                                />
                                                            </Col>
                                                        </Form.Group>
                                                        <Form.Group as={Row} className="mb-3" controlId="formHorizontalEmail">
                                                            <Form.Label column sm={2}>
                                                                Famille:
                                                            </Form.Label>
                                                            <Col sm={10}>
                                                                <Form.Control
                                                                    type="text"
                                                                    aria-describedby="inputGroupPrepend"
                                                                    size="sm"
                                                                    value={famille_id}
                                                                    disabled
                                                                />
                                                            </Col>
                                                        </Form.Group>
                                                        <Form.Group as={Row} className="mb-3" controlId="formHorizontalEmail">
                                                            <Form.Label column sm={2}>
                                                                Marque:
                                                            </Form.Label>
                                                            <Col sm={10}>
                                                                <Form.Control
                                                                    type="text"
                                                                    aria-describedby="inputGroupPrepend"
                                                                    size="sm"
                                                                    value={marque_id}
                                                                    disabled
                                                                />
                                                            </Col>
                                                        </Form.Group>

                                                        <Form.Group as={Row} className="mb-3" controlId="formHorizontalEmail">
                                                            <Form.Label column sm={2}>
                                                                Emplacement:
                                                            </Form.Label>
                                                            <Col sm={10}>
                                                                <Form.Control
                                                                    type="text"
                                                                    placeholder="Emplacement dans l'entrepot"
                                                                    aria-describedby="inputGroupPrepend"
                                                                    size="sm"
                                                                    disabled
                                                                    value={emplacement}
                                                                />
                                                            </Col>
                                                        </Form.Group>
                                                    </Tab>

                                                    <Tab eventKey="Entrées" title="Les entrées">
                                                        {listEntre.length > 0 ? (
                                                            <div className="table-responsive">
                                                                <table className="table table-striped-hover table-sm">
                                                                    <thead>
                                                                        <tr>
                                                                            <th scope="col">#</th>
                                                                            <th scope="col">N° Bon</th>
                                                                            <th scope="col">Fournisseurs</th>
                                                                            <th scope="col">Quantité</th>
                                                                            <th scope="col">Prix achat</th>
                                                                            <th scope="col">Remise</th>
                                                                            <th scope="col">Arrivage</th>
                                                                            <th scope="col">Date entreé.</th>
                                                                            <th scope="col">Date enreg.</th>
                                                                        </tr>
                                                                    </thead>
                                                                    <tbody>
                                                                        {listEntre.map((d, index) => {
                                                                            qtee += d.pivot.quantite;
                                                                            nbe += 1;
                                                                            pae += prixachat;
                                                                            pve += prixachat * coeficient;
                                                                            return (
                                                                                <tr key={index} hover="true">
                                                                                    <td>{index + 1}</td>
                                                                                    <td>{d.codeentre}</td>
                                                                                    <td>
                                                                                        {d.fournisseur !== null
                                                                                            ? d.fournisseur.nomfourn
                                                                                            : ''}
                                                                                    </td>
                                                                                    <td>{d.pivot.quantite}</td>
                                                                                    <td>{d.pivot.prixachat}</td>
                                                                                    <td>{d.pivot.remise}</td>
                                                                                    <td>{d.arrivage}</td>
                                                                                    <td>{dateformat(d.dateentr, 'dd-mm-yyyy')}</td>
                                                                                    <td>{dateformat(d.created_at, 'dd-mm-yyyy')}</td>
                                                                                </tr>
                                                                            );
                                                                        })}
                                                                    </tbody>
                                                                    <thead>
                                                                        <tr>
                                                                            <th scope="col">{nbe}</th>
                                                                            <th scope="col"></th>
                                                                            <th scope="col"></th>
                                                                            <th scope="col">{qtee}</th>
                                                                            <th scope="col"></th>
                                                                            <th scope="col"></th>
                                                                            <th scope="col"></th>
                                                                            <th scope="col"></th>
                                                                            <th scope="col"></th>
                                                                        </tr>
                                                                    </thead>
                                                                </table>
                                                            </div>
                                                        ) : (
                                                            <Alert key="alert-1" variant="info">
                                                                Aucune donnée
                                                            </Alert>
                                                        )}
                                                    </Tab>
                                                    <Tab eventKey="Sorties" title="Les sorties">
                                                        {listSort.length > 0 ? (
                                                            <div className="table-responsive">
                                                                <table className="table table-striped-hover table-sm">
                                                                    <thead>
                                                                        <tr>
                                                                            <th scope="col">#</th>
                                                                            <th scope="col">Demandeur</th>
                                                                            <th scope="col">Qté livré</th>
                                                                            <th scope="col">Qté retour</th>
                                                                            <th scope="col">Qté reste</th>
                                                                            <th scope="col">Montant</th>
                                                                            <th scope="col">N° bon</th>
                                                                            <th scope="col">
                                                                                Date{' '}
                                                                                <Printer
                                                                                    onClick={printListeSort}
                                                                                    color="#ff7300"
                                                                                    size={20}
                                                                                />
                                                                            </th>
                                                                            <th scope="col">Date enregistrement</th>
                                                                        </tr>
                                                                    </thead>
                                                                    <tbody>
                                                                        {listSort.map((d, index) => {
                                                                            qtes += d.pivot.quantite - d.pivot.quantiteretour;
                                                                            nbs += 1;
                                                                            pas =
                                                                                // prixachat *
                                                                                // coeficient *
                                                                                d.pivot.prix !== 0
                                                                                    ? d.pivot.prix *
                                                                                      (d.pivot.quantite - d.pivot.quantiteretour)
                                                                                    : prixachat *
                                                                                      coeficient *
                                                                                      (d.pivot.quantite - d.pivot.quantiteretour);
                                                                            pvs += prixachat * coeficient;
                                                                            return (
                                                                                <tr key={index} hover="true">
                                                                                    <td>{index + 1}</td>
                                                                                    <td>{d.demand_par}</td>
                                                                                    <td>{d.pivot.quantite}</td>
                                                                                    <td>{d.pivot.quantiteretour || 0}</td>
                                                                                    <td>{d.pivot.quantite - d.pivot.quantiteretour}</td>
                                                                                    <td>{pas}</td>
                                                                                    <td>{d.numbon}</td>
                                                                                    <td>{dateformat(d.datesort, 'dd-mm-yyyy')}</td>
                                                                                    <td>{dateformat(d.created_at, 'dd-mm-yyyy')}</td>
                                                                                </tr>
                                                                            );
                                                                        })}
                                                                    </tbody>
                                                                    <thead>
                                                                        <tr>
                                                                            <th scope="col">{nbs}</th>
                                                                            <th scope="col"></th>
                                                                            <th scope="col"></th>
                                                                            <th scope="col"></th>
                                                                            <th scope="col">{qtes}</th>
                                                                            <th scope="col"></th>
                                                                            <th scope="col"></th>
                                                                            <th scope="col"></th>
                                                                            <th scope="col"></th>
                                                                        </tr>
                                                                    </thead>
                                                                </table>
                                                            </div>
                                                        ) : (
                                                            <Alert key="alert-1" variant="info">
                                                                Aucune donnée
                                                            </Alert>
                                                        )}
                                                    </Tab>

                                                    <Tab eventKey="devis" title="Les devis">
                                                        {listefacture.length > 0 ? (
                                                            <div className="table-responsive">
                                                                <table className="table table-striped-hover table-sm">
                                                                    <thead>
                                                                        <tr>
                                                                            <th scope="col">#</th>
                                                                            <th scope="col">Proforma</th>
                                                                            <th scope="col">Date</th>
                                                                            <th scope="col">Facture</th>
                                                                            <th scope="col">Date</th>
                                                                        </tr>
                                                                    </thead>
                                                                    <tbody>
                                                                        {listefacture.map((d, index) => {
                                                                            return (
                                                                                <tr key={index} hover="true">
                                                                                    <td>{index + 1}</td>
                                                                                    <td>{d.noprof}</td>
                                                                                    <td>{d.dateprof}</td>
                                                                                    <td>{d.nofact}</td>
                                                                                    <td>{d.datefact}</td>
                                                                                </tr>
                                                                            );
                                                                        })}
                                                                    </tbody>
                                                                </table>
                                                            </div>
                                                        ) : (
                                                            <Alert key="alert-1" variant="info">
                                                                Aucune donnée
                                                            </Alert>
                                                        )}
                                                    </Tab>
                                                    <Tab eventKey="hs" title="Les HS">
                                                        {listHs.length > 0 ? (
                                                            <div className="table-responsive">
                                                                <table className="table table-striped-hover table-sm">
                                                                    <thead>
                                                                        <tr>
                                                                            <th scope="col">#</th>
                                                                            <th scope="col">Qté</th>
                                                                            <th scope="col">Prix achat</th>
                                                                            <th scope="col">Prix vente</th>
                                                                            <th scope="col">Mont. perdu</th>
                                                                            <th scope="col">Motifs</th>
                                                                        </tr>
                                                                    </thead>
                                                                    <tbody>
                                                                        {listHs ? (
                                                                            listHs.map((d, index) => {
                                                                                return (
                                                                                    <tr key={index} hover="true">
                                                                                        <td>{d.id}</td>
                                                                                        <td>{d.quantite}</td>
                                                                                        <td>{prixachat}</td>
                                                                                        <td>{prixachat * coeficient}</td>
                                                                                        <td>{prixachat * coeficient * d.quantite}</td>
                                                                                        <td>{d.motif}</td>
                                                                                    </tr>
                                                                                );
                                                                            })
                                                                        ) : (
                                                                            <></>
                                                                        )}
                                                                    </tbody>
                                                                </table>
                                                            </div>
                                                        ) : (
                                                            <Alert key="alert-1" variant="info">
                                                                Aucune donnée
                                                            </Alert>
                                                        )}
                                                    </Tab>
                                                    <Tab eventKey="loss" title="Les perdus">
                                                        {listLost.length > 0 ? (
                                                            <div className="table-responsive">
                                                                <table className="table table-striped-hover table-sm">
                                                                    <thead>
                                                                        <tr>
                                                                            <th scope="col">#</th>
                                                                            <th scope="col">Qté</th>
                                                                            <th scope="col">Prix achat</th>
                                                                            <th scope="col">Prix vente</th>
                                                                            <th scope="col">Mont. perdu</th>
                                                                            <th scope="col">Motifs</th>
                                                                        </tr>
                                                                    </thead>
                                                                    <tbody>
                                                                        {listLost ? (
                                                                            listLost.map((d, index) => {
                                                                                return (
                                                                                    <tr key={index} hover="true">
                                                                                        <td>{d.id}</td>
                                                                                        <td>{d.quantite}</td>
                                                                                        <td>{prixachat}</td>
                                                                                        <td>{prixachat * coeficient}</td>
                                                                                        <td>{prixachat * coeficient * d.quantite}</td>
                                                                                        <td>{d.motif}</td>
                                                                                    </tr>
                                                                                );
                                                                            })
                                                                        ) : (
                                                                            <></>
                                                                        )}
                                                                    </tbody>
                                                                </table>
                                                            </div>
                                                        ) : (
                                                            <Alert key="alert-1" variant="info">
                                                                Aucune donnée
                                                            </Alert>
                                                        )}
                                                    </Tab>
                                                </Tabs>
                                            </CardBody>
                                        </Card>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <div className="ml-auto">
                        <ButtonGroup size="sm">
                            <Button color="danger" onClick={handleClose}>
                                <Backspace color="#000" size={20} />
                            </Button>
                        </ButtonGroup>
                    </div>
                </Modal.Footer>
            </Modal>
            <Modal show={shows} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Ajouter en quantité sur ce article ({descriptionart})</Modal.Title>
                </Modal.Header>

                <Form noValidate validated={validated} onSubmit={handleSubmit}>
                    <Modal.Body>
                        <Form.Group as={Col} md="12" controlId="famille">
                            <InputGroup>
                                <InputGroup.Text id="inputGroupPrepend">Fournisseur:</InputGroup.Text>
                                <Input type="select" value={fournisseur_id} onChange={(e) => setFournisseur(e.target.value)}>
                                    <option value={0}></option>
                                    {fournisseur.map((d, index) => {
                                        return (
                                            <option key={index} value={d.id}>
                                                {d.codefourn + '/' + d.nomfourn}
                                            </option>
                                        );
                                    })}
                                </Input>
                            </InputGroup>
                        </Form.Group>
                        <Form.Group as={Col} md="12" controlId="validationCustomUsername">
                            <InputGroup hasValidation>
                                <InputGroup.Text id="inputGroupPrepend">Qté entrées:</InputGroup.Text>
                                <Form.Control
                                    type="number"
                                    placeholder="Qté entrées"
                                    aria-describedby="inputGroupPrepend"
                                    min="1"
                                    size="sm"
                                    value={qte}
                                    onChange={(e) => setQte(e.target.value)}
                                />
                                <Form.Control.Feedback type="invalid">Veuillez saisir le code article</Form.Control.Feedback>
                            </InputGroup>
                        </Form.Group>
                        <Form.Group as={Col} md="12" controlId="validationCustomUsername">
                            <InputGroup hasValidation>
                                <InputGroup.Text id="inputGroupPrepend">Date entrées:</InputGroup.Text>
                                <Form.Control
                                    type="date"
                                    placeholder="Date entrées"
                                    aria-describedby="inputGroupPrepend"
                                    size="sm"
                                    value={dateentr}
                                    onChange={(e) => setDateentr(e.target.value)}
                                />
                                <Form.Control.Feedback type="invalid">Veuillez saisir le code article</Form.Control.Feedback>
                            </InputGroup>
                        </Form.Group>
                        <Form.Group as={Col} md="12" controlId="validationCustomUsername">
                            <InputGroup hasValidation>
                                <InputGroup.Text id="inputGroupPrepend">Article:</InputGroup.Text>
                                <Form.Control
                                    type="text"
                                    placeholder="Article"
                                    aria-describedby="inputGroupPrepend"
                                    size="sm"
                                    value={descriptionart}
                                />
                                <Form.Control.Feedback type="invalid">Veuillez saisir le code article</Form.Control.Feedback>
                            </InputGroup>
                        </Form.Group>
                        <br />
                    </Modal.Body>
                    <Modal.Footer>
                        <Button type="reset" variant="ligth" onClick={handleClose}>
                            <ArrowLeft color="red" size={20} /> Annuler
                        </Button>
                        <Button type="submit" variant="ligth">
                            <Save2Fill color="#ff7300" size={20} /> Enregister
                        </Button>
                    </Modal.Footer>
                </Form>
            </Modal>
        </>
    );
}
export default FicheArticle;
