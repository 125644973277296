import React from 'react';
import { NavLink, useHistory } from 'react-router-dom';
import { Nav } from 'reactstrap';
// javascript plugin used to create scrollbars on windows
import PerfectScrollbar from 'perfect-scrollbar';
import common from '../../views/commonData';
import { FileLock2Fill, Gear } from 'react-bootstrap-icons';

var ps;

function Sidebar(props) {
    const sidebar = React.useRef();
    // verifies if routeName is the one active (in browser input)
    const activeRoute = (routeName) => {
        var extactrout = props.location.pathname.split('/');
        return props.location.pathname.indexOf(routeName) > -1 ||
            routeName.includes(extactrout[2]) === true ||
            extactrout[2].includes(routeName) === true
            ? 'active'
            : '';
    };
    React.useEffect(() => {
        if (navigator.platform.indexOf('Win') > -1) {
            ps = new PerfectScrollbar(sidebar.current, {
                suppressScrollX: true,
                suppressScrollY: false
            });
        }
        return function cleanup() {
            if (navigator.platform.indexOf('Win') > -1) {
                ps.destroy();
            }
        };
    });

    const ColoredLine = ({ color }) => (
        <div
            style={{
                color: color,
                backgroundColor: color,
                height: 1,
                width: '100%'
                // boxShadow: '1px 2px 10px #F4AAB9',
            }}
        />
    );

    const history = useHistory();
    function signOut(e) {
        e.preventDefault();
        localStorage.clear();
        history.push('/login');
        //window.location.reload()
    }
    return (
        <div className="sidebar" data-color={props.bgColor} data-active-color={props.activeColor}>
            <div className="logo">
                <a href="#/admin/dashboard" className="simple-text logo-mini">
                    <div className="logo-img">
                        <img alt="react-logo" src="/logoz.jpg" />
                    </div>
                </a>
                <a href="#/admin/dashboard" className="simple-text logo-normal">
                    I-AUTOPARTS
                </a>
                {/* <div>
              <span className="user"> {common.user}</span>
              <FileLock2Fill onClick={signOut} title="Se déconnecter" size={25} color="#d0ffdb"/>
              <a href="#/admin/config" id="actionbtn"><Gear  title="Se déconnecter" size={25} color="#d0ffdb"/></a>
            </div> */}
            </div>

            <div className="sidebar-wrapper" ref={sidebar}>
                <Nav>
                    {props.routes.map((prop, key) => {
                        return (
                            <li className={activeRoute(prop.path) + (prop.pro ? ' active-pro' : '')} key={key}>
                                <NavLink to={prop.layout + prop.path} className="nav-link" activeClassName="active">
                                    <i className={prop.icon} />
                                    <p>{prop.name}</p>
                                </NavLink>
                                {prop.separe ? <ColoredLine color="#333" /> : <></>}
                            </li>
                        );
                    })}

                    <hr />
                </Nav>
            </div>
        </div>
    );
}

export default Sidebar;
