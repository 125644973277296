import React from 'react';
import { Container, Nav, Navbar, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { Printer, FileExcel, PlusCircleDotted, List, Trash } from 'react-bootstrap-icons';

import 'jspdf-autotable';
import 'assets/css/table.css';

function NavMenu({ printListeAvoir, setShowAddOrEditAvoir, selected, exportCSVAvoir }) {
    return (
        <Navbar expand="lg" bg="light" variant="light">
            <Container>
                <Nav className="me-auto">
                    <OverlayTrigger placement="top" overlay={<Tooltip id="tooltip-disabled">Editer une facture d'avoir</Tooltip>}>
                        <Nav.Link href="#/admin/factures" onClick={() => setShowAddOrEditAvoir(0)}>
                            <PlusCircleDotted color="orange" size={25} /> Créer
                        </Nav.Link>
                    </OverlayTrigger>
                    <OverlayTrigger placement="top" overlay={<Tooltip id="tooltip-disabled">Exporter vers Excel</Tooltip>}>
                        <Nav.Link href="#/admin/factures" onClick={() => exportCSVAvoir()}>
                            <FileExcel color="#0080ff" size={25} /> Excel
                        </Nav.Link>
                    </OverlayTrigger>
                    <OverlayTrigger placement="top" overlay={<Tooltip id="tooltip-disabled">Exporter vers pdf</Tooltip>}>
                        <Nav.Link href="#/admin/factures" onClick={() => printListeAvoir()}>
                            <Printer color="#0080ff" size={25} /> Imprimer
                        </Nav.Link>
                    </OverlayTrigger>
                    {selected.length > 0 ? (
                        <OverlayTrigger placement="top" overlay={<Tooltip id="tooltip-disabled">Facturer des BLs</Tooltip>}>
                            <Nav.Link href="#/admin/factures">
                                <List color="#ec8407" size={25} /> Facturer
                            </Nav.Link>
                        </OverlayTrigger>
                    ) : (
                        <></>
                    )}
                </Nav>
            </Container>
        </Navbar>
    );
}
export default NavMenu;
