import {useHistory,Link} from 'react-router-dom'
import Axios from 'axios';
import {Form,Button,Card} from "react-bootstrap";
import { Save2Fill } from 'react-bootstrap-icons';
import React,{useEffect,useState} from 'react'
import common from './commonData'

function Register() {
    const history = useHistory();
    useEffect(()=>{
        if (localStorage.getItem("user-info")) {
          history.push("/dashboard")
        }
    })
    const [name,setName]=useState("")
    const [email,setEmail]=useState("")
    const [password,setPassword]=useState("")

    function signUp(e){
        e.preventDefault()
        const values={
            name: name,
            email: email,
            password: password,
            photo: ""
        }
        Axios.post(common.ipapi+'/api/register',values)
        .then(res=>{
            if(res.data.status==1){
                //localStorage.setItem("user-info", JSON.stringify(res.data.datas))
                history.push("/login")
                //window.location.reload()
            }else{
                if(res.data.msg == 23000){
                    alert("L'adresse email saisi exite déjà")
                }else{
                    alert("Une erreur à eu lieu lors de la création")
                }
            }
        }).catch(error=>{
            alert("2: Une erreur à eu lieu lors de la création")
        })
    }

    return (
        
            <Card style={{ width: '25rem' }} className='centerCard'>
                <Card.Body>
                    <Card.Title>Création de compte utilisateur</Card.Title>
                    <Form>
                        <Form.Group className="mb-3" controlId="formBasicName">
                            <Form.Label>Nom d'utilisateur</Form.Label>
                                <Form.Control type="text" value={name} onChange={(e)=>setName(e.target.value)} placeholder="Nom d'utilisateur" />
                            </Form.Group>
                            <Form.Group className="mb-3" controlId="formBasicEmail">
                                <Form.Label>Email address</Form.Label>
                                <Form.Control type="email" value={email} onChange={(e)=>setEmail(e.target.value)} placeholder="Enter email" />
                            </Form.Group>
                            <Form.Group className="mb-3" controlId="formBasicPassword">
                                <Form.Label>Password</Form.Label>
                                <Form.Control type="password" value={password} onChange={(e)=>setPassword(e.target.value)} placeholder="Password" />
                            </Form.Group>
                            <Form.Group className="mb-3" controlId="formBasicCheckbox">
                                <Button variant="ligth" type="submit" onClick={signUp}><Save2Fill color="green" size={20}/> Se connecter </Button>
                            </Form.Group>
                            <Form.Group className="mb-3" controlId="formBasicPassword">
                                Avez vous une compte ? <Link to={`/login`}>Se connecter</Link>
                            </Form.Group>
                        </Form>
                </Card.Body>
            </Card>
    );
}

export default Register;