import React, { useEffect, useState, useRef } from 'react';
import { Form, InputGroup, Modal, Spinner, ButtonGroup, Placeholder } from 'react-bootstrap';
import { Button, Row, Col } from 'reactstrap';
import Axios from 'axios';
import { useHistory } from 'react-router-dom';
import { Backspace, Save2Fill } from 'react-bootstrap-icons';
import { Typeahead } from 'react-bootstrap-typeahead';
import 'react-bootstrap-typeahead/css/Typeahead.css';
import * as Yup from 'yup';
import { Field, Formik } from 'formik';

import common from '../../commonData';
import { AvoirListAdd, Summary } from './cardAvoir';

var count = 0;
function AddFactureAvoir({ setShowAddOrEdit, show, setIsSave, setMsg, setMsgravity, ids, getFactureAvoir, setLoading, loading }) {
    var ladate = new Date();
    const [facture, setProforma] = useState([]);
    const [load, setLoad] = useState(false);
    const [listeSelect, setListeSelect] = useState([]);
    const [states, setStates] = useState({
        facture_id: '',
        observation_avoir: '',
        motif_avoir: '1',
        date_avoir: '',
        sitva: '0'
    });
    const { facture_id, observation_avoir, motif_avoir, date_avoir, sitva } = states;
    const history = useHistory();
    const idclient = useRef(null);
    useEffect(() => {
        if (!localStorage.getItem('user-info')) {
            history.push('/login');
        }
        (async function anyNameFunction() {
            setLoad(true);
            setLoading(true);
            await Axios.get(common.ipapi + '/api/facture')
                .then((response) => {
                    setProforma(response.data.datas);
                    setLoad(false);
                    setLoading(false);
                })
                .catch((errors) => {
                    setLoad(false);
                    setLoading(false);
                });
        })();
    }, [history]);

    useEffect(() => {
        if (ids) {
            setLoad(true);
            setLoading(true);
            Axios.get(common.ipapi + '/api/avoir/edit/' + ids)
                .then((response) => {
                    setStates({
                        facture_id: response.data.facture_id,
                        observation_avoir: response.data.observation_avoir,
                        motif_avoir: response.data.motif_avoir,
                        date_avoir: response.data.date_avoir,
                        sitva: response.data.sitva
                    });
                    setListeSelect(response.data.ligneavoir);

                    setLoad(false);
                    setLoading(false);
                })
                .catch((errors) => {
                    setLoad(false);
                    setLoading(false);
                });
        }
    }, [ids]);

    function AddLigneAvoir() {
        console.log(listeSelect);
        var t = [];
        let d = {
            id: 0,
            ordre: listeSelect.length + 1,
            libelle_avoir: '',
            montant_avoir: 0,
            qte_avoir: 1
        };
        t.push(d);
        t = t.concat(listeSelect);
        setListeSelect(t);
    }
    const subTotal = listeSelect.reduce((total, product) => {
        return total + product.qte_avoir * product.montant_avoir;
    }, 0);
    const onChangeProductQuantity = (index, event) => {
        const cloneProducts = [...listeSelect];
        cloneProducts[index].qte_avoir = event.target.value;
        setListeSelect(cloneProducts);
    };

    const onChangePrestation = (index, event) => {
        const cloneProducts = [...listeSelect];
        cloneProducts[index].libelle_avoir = event.target.value;
        setListeSelect(cloneProducts);
    };

    const onChangePrix = (index, event) => {
        const valueInt = parseInt(event.target.value);
        const cloneProducts = [...listeSelect];
        cloneProducts[index].montant_avoir = valueInt;

        setListeSelect(cloneProducts);
    };

    const onRemoveProduct = (i, id) => {
        if (ids) {
            if (id != undefined) {
                if (window.confirm('Voulez-vous supprimer cette ligne ? ')) {
                    setLoad(true);
                    Axios.delete(common.ipapi + '/api/avoir/destroyligne/' + id).then((response) => {
                        if (response.status !== 200) {
                            setMsg('Veuillez vérifier votre connexion internet');
                            setMsgravity('warning');
                            setIsSave(true);
                            setLoad(false);
                            return;
                        }
                        if (response.data.status === 1) {
                            //sidelete = 1
                            const filteredProduct = listeSelect.filter((product, index) => {
                                return index != i;
                            });
                            setListeSelect(filteredProduct);
                            setMsg(response.data.msg);
                            setIsSave(true);
                            setLoad(false);
                        } else {
                            setMsg(response.data.msg);
                            setIsSave(true);
                            setLoad(false);
                        }
                    });
                }
            } else {
                const filteredProduct = listeSelect.filter((product, index) => {
                    return index != i;
                });
                setListeSelect(filteredProduct);
                setMsg('Ligne supprimée');
                setMsgravity('success');
                setIsSave(true);
                setLoad(false);
            }
        } else {
            var filteredProduct = [];
            setLoad(true);
            filteredProduct = listeSelect.filter((product, index) => {
                return index != i;
            });
            setListeSelect(filteredProduct);
            setMsg('Ligne supprimée');
            setMsgravity('info');
            setIsSave(true);
            setLoad(false);
        }
    };

    function saveFacture(values, setSubmitting, resetForm) {
        setSubmitting(true);
        if (window.confirm('Voulez-vous confirmer cette action') == false) {
            return;
        }
        if (!navigator.onLine) {
            setMsg('Veuillez vérifier votre connexion internet');
            setMsgravity('warning');
            setIsSave(true);
            return;
        }

        setLoad(true);

        var formData = new FormData();
        formData.append('observation_avoir', values.observation_avoir);
        formData.append('motif_avoir', values.motif_avoir);
        formData.append('user_id', common.userid);
        formData.append('date_avoir', values.date_avoir);
        formData.append('sitva', values.sitva);
        formData.append('facture_id', values.facture_id);
        formData.append('ligneavoir', JSON.stringify(listeSelect));
        Axios({
            url: ids ? common.ipapi + '/api/avoir/update/' + ids : common.ipapi + '/api/avoir/create',
            method: 'POST',
            data: formData
        })
            .then((res) => {
                if (res.status !== 200) {
                    setMsg('Veuillez vérifier votre connexion internet');
                    setMsgravity('warning');
                    setIsSave(true);
                    setLoad(false);
                    setSubmitting(false);
                    return;
                }
                if (res.data.status === 1) {
                    resetForm();
                    setListeSelect([]);
                    setMsg(res.data.msg);
                    setMsgravity('info');
                    count++;
                    setIsSave(true);
                    setLoad(false);
                    setSubmitting(false);
                    //setActualise(!actualise);
                    if (ids) {
                        handleClose();
                    }
                } else {
                    setMsg(res.data.msg);
                    setMsgravity('danger');
                    setIsSave(true);
                    setLoad(false);
                    setSubmitting(false);
                }
            })
            .catch((error) => {
                setIsSave(true);
                setMsgravity('danger');
                setMsg('error');
                setLoad(false);
                setSubmitting(false);
            });
    }

    const handleClose = () => {
        if (count) {
            getFactureAvoir();
        }
        idclient.current.clear();
        setShowAddOrEdit(false);
        setIsSave(false);
    };

    return (
        <>
            <Modal show={load} size="sm" aria-labelledby="contained-modal-title-vcenter" centered>
                <Modal.Body>
                    Veuillez patienter.......
                    <Spinner animation="grow" variant="warning" />
                </Modal.Body>
            </Modal>
            <Formik
                initialValues={{
                    facture_id: facture_id,
                    observation_avoir: observation_avoir,
                    motif_avoir: motif_avoir,
                    date_avoir: date_avoir,
                    sitva: sitva,
                    submit: null
                }}
                enableReinitialize
                validationSchema={Yup.object().shape({
                    facture_id: Yup.number().typeError('Aucune correspondance').required(common.msg),
                    date_avoir: Yup.date().required(common.msg)
                })}
                onSubmit={async (values, { setSubmitting, resetForm }) => {
                    try {
                        saveFacture(values, setSubmitting, resetForm);
                    } catch (err) {
                        setMsg('Une erreur à eu lieu');
                        setMsgravity('danger');
                        setIsSave(true);
                        setLoad(false);
                    }
                }}
            >
                {({ errors, handleBlur, handleChange, handleSubmit, isSubmitting, touched, values, setFieldValue, setFieldTouched }) => (
                    <Modal show={show} size="lg">
                        {/* <div className="content"> */}
                        <Form noValidate onSubmit={handleSubmit}>
                            <Modal.Header>
                                {ids ? 'MOFIFIER UNE FACTURE AVOIR' : 'FAIRE UNE FACTURE AVOIR'}
                                <Backspace onClick={handleClose} color="red" size={30} />
                            </Modal.Header>
                            <Modal.Body>
                                <div id="lightbody">
                                    <Row>
                                        <Col md={12}>
                                            <Form.Group as={Row} className="mb-3">
                                                <Form.Label column sm={3}>
                                                    N° facture:
                                                </Form.Label>
                                                <Col sm={4}>
                                                    {loading ? (
                                                        <Placeholder xs={12} bg="secondary" size="lg" />
                                                    ) : (
                                                        <InputGroup hasValidation>
                                                            <Typeahead
                                                                id="facture_id"
                                                                ref={idclient}
                                                                multiple={false}
                                                                onChange={(selected) => {
                                                                    const value = selected.length > 0 ? selected[0].id : 0;
                                                                    setFieldValue('facture_id', value);
                                                                }}
                                                                onInputChange={(text, event) => setFieldValue('facture_id', text)}
                                                                onBlur={(e) => setFieldTouched('facture_id', true)}
                                                                labelKey="nofact"
                                                                options={facture}
                                                                selected={facture.slice(
                                                                    facture.findIndex((element) => element.id === values.facture_id),
                                                                    facture.findIndex((element) => element.id === values.facture_id) + 1
                                                                )}
                                                            />
                                                        </InputGroup>
                                                    )}
                                                </Col>
                                                {errors.facture_id && <div id="feedback">{errors.facture_id}</div>}
                                            </Form.Group>

                                            <Form.Group as={Row} className="mb-3">
                                                <Form.Label column sm={3}>
                                                    Date édition:
                                                </Form.Label>
                                                <Col sm={4}>
                                                    {loading ? (
                                                        <Placeholder xs={12} bg="secondary" size="lg" />
                                                    ) : (
                                                        <Form.Control
                                                            id="date_avoir"
                                                            type="date"
                                                            placeholder="Date édition"
                                                            aria-describedby="inputGroupPrepend"
                                                            required
                                                            size="sm"
                                                            value={values.date_avoir}
                                                            onBlur={handleBlur}
                                                            onChange={handleChange}
                                                        />
                                                    )}
                                                </Col>
                                                {errors.date_avoir && <div id="feedback">{errors.date_avoir}</div>}
                                            </Form.Group>
                                            <Form.Group as={Row} className="mb-3">
                                                <Form.Label column sm={3}>
                                                    Application TVA:
                                                </Form.Label>
                                                <Col sm={9}>
                                                    <div role="group" aria-labelledby="my-radio-group">
                                                        <label className="radio">
                                                            <Field type="radio" name="sitva" value="0" />
                                                            TVA applicable
                                                        </label>
                                                        <label className="radio">
                                                            <Field type="radio" name="sitva" value="1" />
                                                            TVA non applicable
                                                        </label>
                                                    </div>
                                                </Col>
                                            </Form.Group>
                                            <Form.Group as={Row} className="mb-3">
                                                <Form.Label column sm={3}>
                                                    Motif de l'avoir:
                                                </Form.Label>
                                                <Col sm={9}>
                                                    <div role="group" aria-labelledby="my-radio-group">
                                                        <label className="radio">
                                                            <Field type="radio" name="motif_avoir" value="1" />
                                                            Avoir commerciale
                                                        </label>
                                                        <label className="radio">
                                                            <Field type="radio" name="motif_avoir" value="2" />
                                                            Avoir pour annulation
                                                        </label>
                                                        <label className="radio">
                                                            <Field type="radio" name="motif_avoir" value="3" />
                                                            Avoir d'erreur
                                                        </label>
                                                    </div>
                                                </Col>
                                            </Form.Group>
                                            <Form.Group as={Row} className="mb-3">
                                                <Form.Label column sm={3}>
                                                    Observation:
                                                </Form.Label>
                                                <Col sm={9}>
                                                    {loading ? (
                                                        <Placeholder xs={12} bg="secondary" size="lg" />
                                                    ) : (
                                                        <Form.Control
                                                            id="observation_avoir"
                                                            as="textarea"
                                                            placeholder="Observation"
                                                            aria-describedby="inputGroupPrepend"
                                                            size="sm"
                                                            rows={4}
                                                            value={values.observation_avoir}
                                                            onBlur={handleBlur}
                                                            onChange={handleChange}
                                                        />
                                                    )}
                                                </Col>
                                            </Form.Group>
                                        </Col>
                                        <Col md="12">
                                            <div>
                                                <AvoirListAdd
                                                    products={listeSelect}
                                                    onChangeProductQuantity={onChangeProductQuantity}
                                                    onRemoveProduct={onRemoveProduct}
                                                    onChangePrestation={onChangePrestation}
                                                    onChangePrix={onChangePrix}
                                                    AddLigneAvoir={AddLigneAvoir}
                                                />
                                                <Summary subTotal={subTotal} sitva={values.sitva} />
                                            </div>
                                        </Col>
                                    </Row>
                                </div>
                            </Modal.Body>
                            <Modal.Footer>
                                <div className="ml-auto">
                                    <ButtonGroup size="sm">
                                        <Button color="secondary" onClick={handleClose}>
                                            <Backspace color="red" size={25} />
                                        </Button>
                                        <Button type="submit" color="warning" disabled={isSubmitting}>
                                            {isSubmitting ? (
                                                <div>
                                                    <Spinner animation="border" variant="dark" size="sm" />
                                                    Patientez...
                                                </div>
                                            ) : (
                                                <div>
                                                    <Save2Fill color="#807f7e" size={20} />
                                                    Enregistrer
                                                </div>
                                            )}
                                        </Button>
                                    </ButtonGroup>
                                </div>
                            </Modal.Footer>
                        </Form>
                        {/* </div> */}
                    </Modal>
                )}
            </Formik>
        </>
    );
}

export default AddFactureAvoir;
