import React, { useEffect, useState } from 'react';
import { Form, ButtonGroup, Spinner, Modal, Row } from 'react-bootstrap';
import { Button, Input, Col } from 'reactstrap';
import Axios from 'axios';
import * as Yup from 'yup';
import { Field, Formik } from 'formik';
import { Backspace, BackspaceFill, Save2Fill, Star } from 'react-bootstrap-icons';
import common from '../../commonData';
var count = 0;

function AddClient({ id, setShowAddclient, show, setIsSave, setClient, setMsg, setMsgravity }) {
    const [load, setLoad] = useState(false);
    const [states, setState] = useState({
        raisonsocial: '',
        telcli: '',
        sisociete: 'Particulier',
        bp: '',
        pourcentage: 0,
        sitva: '0',
        typereglement: 'Espèce',
        email: '',
        nocompte: '',
        nocomptec: ''
    });
    const { raisonsocial, telcli, sisociete, bp, pourcentage, sitva, typereglement, email, nocompte, nocomptec } = states;

    useEffect(() => {
        setLoad(true);
        Axios.get(common.ipapi + '/api/client/edit/' + id)
            .then((response) => {
                setState({
                    raisonsocial: response.data.raisonsocial,
                    telcli: response.data.telcli,
                    sisociete: response.data.sisociete,
                    bp: response.data.bp,
                    pourcentage: response.data.remise ? response.data.remise.pourcentage : 0,
                    sitva: response.data.sitva,
                    typereglement: response.data.typereglement,
                    email: response.data.email,
                    nocompte: response.data.nocompte,
                    nocomptec: response.data.nocomptec
                });
                setLoad(false);
            })
            .catch((errors) => {
                setLoad(false);
            });
    }, [id]);

    function getClient() {
        setLoad(true);
        Axios.get(common.ipapi + '/api/client/index')
            .then((response) => {
                setClient(response.data.datas);
                setLoad(false);
            })
            .catch((errors) => {
                setLoad(false);
            });
    }
    const handleClose = () => {
        if (count !== 0) {
            getClient();
        }
        count = 0;
        setShowAddclient(false);
        setIsSave(false);
    };

    return (
        <>
            <Modal show={load} size="sm" aria-labelledby="contained-modal-title-vcenter" centered>
                <Modal.Body>
                    Veuillez patienter.......
                    <Spinner animation="grow" variant="warning" />
                </Modal.Body>
            </Modal>
            <Formik
                initialValues={{
                    raisonsocial: raisonsocial,
                    telcli: telcli,
                    sisociete: sisociete || 'Particulier',
                    bp: bp,
                    pourcentage: pourcentage,
                    sitva: sitva || '0',
                    typereglement: typereglement || 'Espèce',
                    email: email,
                    nocompte: nocompte,
                    nocomptec: nocomptec,
                    user_id: common.userid,
                    app: 1,
                    submit: null
                }}
                enableReinitialize
                validationSchema={Yup.object().shape({
                    raisonsocial: Yup.string().required('Veuillez saisir cette donnée'),
                    sisociete: Yup.string().required('Veuillez saisir cette donnée'),
                    sitva: Yup.string().required('Veuillez saisir cette donnée')
                })}
                onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
                    try {
                        setLoad(true);
                        let url = id ? common.ipapi + '/api/client/update/' + id : common.ipapi + '/api/client/create';
                        await Axios.post(url, values)
                            .then((res) => {
                                if (res.data.status) {
                                    setMsg(res.data.msg);
                                    setMsgravity('success');
                                    setIsSave(true);
                                    count++;
                                    handleClose();
                                    setLoad(false);
                                } else {
                                    setMsg(res.data.msg);
                                    setMsgravity('warning');
                                    setIsSave(true);
                                    setLoad(false);
                                }
                            })
                            .catch(() => {
                                // setStatus({ success: false });
                            });
                        setSubmitting(false);
                    } catch (err) {
                        setMsg('Une erreur à eu lieu');
                        setMsgravity('danger');
                        setIsSave(true);
                        setLoad(false);
                    }
                }}
            >
                {({ errors, handleBlur, handleChange, handleSubmit, isSubmitting, touched, values, setFieldValue, setFieldTouched }) => (
                    <Modal show={show} size="md">
                        <Form noValidate onSubmit={handleSubmit}>
                            <Modal.Header>
                                {id ? 'MODIFIER AJOUTER UN CLIENT' : 'AJOUTER UN NOUVEAU CLIENT'}{' '}
                                <Backspace onClick={handleClose} color="red" size={30} />
                            </Modal.Header>
                            <Modal.Body>
                                <Form.Group as={Row} className="mb-3">
                                    <Form.Label column sm={4}>
                                        Raisonsocial: <Star color="red" size={10} />
                                    </Form.Label>
                                    <Col sm={8}>
                                        <Form.Control
                                            id="raisonsocial"
                                            type="text"
                                            placeholder="Raison social"
                                            aria-describedby="inputGroupPrepend"
                                            required
                                            size="sm"
                                            value={values.raisonsocial}
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                        />
                                        {errors.raisonsocial && <div id="feedback">{errors.raisonsocial}</div>}
                                    </Col>
                                </Form.Group>
                                <Form.Group as={Row} className="mb-3">
                                    <Form.Label column sm={4}>
                                        Téléphone:
                                    </Form.Label>
                                    <Col sm={8}>
                                        <Form.Control
                                            id="telcli"
                                            type="text"
                                            placeholder="Telephone"
                                            aria-describedby="inputGroupPrepend"
                                            size="sm"
                                            value={values.telcli}
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            // onChange={(e) => SetTelcli(e.target.value)}
                                        />
                                    </Col>
                                </Form.Group>
                                <Form.Group as={Row} className="mb-3">
                                    <Form.Label column sm={4}>
                                        Type client:
                                    </Form.Label>
                                    <Col sm={8}>
                                        <Input
                                            id="sisociete"
                                            type="select"
                                            size="sm"
                                            value={values.sisociete}
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            // onChange={(e) => setSisociete(e.target.value)}
                                        >
                                            <option value="Assurance">Assurance</option>
                                            <option value="Societé">Société</option>
                                            <option value="Particulier">Particulier</option>
                                        </Input>
                                        {errors.sisociete && <div id="feedback">{errors.sisociete}</div>}
                                    </Col>
                                </Form.Group>
                                <Form.Group as={Row} className="mb-3">
                                    <Form.Label column sm={4}>
                                        Boite postale:
                                    </Form.Label>
                                    <Col sm={8}>
                                        <Form.Control
                                            id="bp"
                                            type="text"
                                            placeholder="Boite postale"
                                            aria-describedby="inputGroupPrepend"
                                            size="sm"
                                            value={values.bp}
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            // onChange={(e) => setBp(e.target.value)}
                                        />
                                    </Col>
                                </Form.Group>
                                <Form.Group as={Row} className="mb-3">
                                    <Form.Label column sm={4}>
                                        N° compte tier:
                                    </Form.Label>
                                    <Col sm={8}>
                                        <Form.Control
                                            id="nocompte"
                                            type="text"
                                            placeholder="Numéro de compte tier"
                                            aria-describedby="inputGroupPrepend"
                                            size="sm"
                                            value={values.nocompte}
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                        />
                                    </Col>
                                </Form.Group>
                                <Form.Group as={Row} className="mb-3">
                                    <Form.Label column sm={4}>
                                        N° compte contribuable:
                                    </Form.Label>
                                    <Col sm={8}>
                                        <Form.Control
                                            id="nocomptec"
                                            type="text"
                                            placeholder="N° compte contribuable"
                                            aria-describedby="inputGroupPrepend"
                                            size="sm"
                                            value={values.nocomptec}
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                        />
                                    </Col>
                                </Form.Group>
                                <Form.Group as={Row} className="mb-3">
                                    <Form.Label column sm={4}>
                                        Email:
                                    </Form.Label>
                                    <Col sm={8}>
                                        <Form.Control
                                            id="email"
                                            type="text"
                                            placeholder="email"
                                            aria-describedby="inputGroupPrepend"
                                            size="sm"
                                            value={values.email}
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                        />
                                    </Col>
                                </Form.Group>
                                <Form.Group as={Row} className="mb-3">
                                    <Form.Label column sm={4}>
                                        Pourcentage:
                                    </Form.Label>
                                    <Col sm={8}>
                                        <Form.Control
                                            id="pourcentage"
                                            type="number"
                                            placeholder="Pourcentage rabais"
                                            aria-describedby="inputGroupPrepend"
                                            size="sm"
                                            value={values.pourcentage}
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                        />
                                    </Col>
                                </Form.Group>
                                <Row>
                                    <Col md={5}>
                                        <div role="group" aria-labelledby="my-radio-group">
                                            <label>
                                                <Field type="radio" name="typereglement" value="Espèce" />
                                                Espèce
                                            </label>
                                            <label>
                                                <Field type="radio" name="typereglement" value="Chèque" />
                                                Chèque
                                            </label>
                                        </div>
                                    </Col>
                                    <Col md={7}>
                                        <div role="group" aria-labelledby="my-radio-group">
                                            <label className="radio">
                                                <Field type="radio" name="sitva" value="0" />
                                                TVA applicable
                                            </label>
                                            <label className="radio">
                                                <Field type="radio" name="sitva" value="1" />
                                                TVA non applicable
                                            </label>
                                        </div>
                                    </Col>
                                </Row>
                                <br></br>
                                <Modal.Footer>
                                    <input type="hidden" id="app" value={0}></input>
                                    <ButtonGroup size="sm">
                                        <Button color="secondary" onClick={handleClose}>
                                            <BackspaceFill color="red" size={20} />
                                        </Button>
                                        <Button type="submit" color="info">
                                            <Save2Fill color="#000" size={20} /> Enregister
                                        </Button>
                                    </ButtonGroup>
                                </Modal.Footer>
                            </Modal.Body>
                        </Form>
                    </Modal>
                )}
            </Formik>
        </>
    );
}

export default AddClient;
