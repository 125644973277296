import React, { useState, useEffect } from 'react';

// reactstrap components
import {
    Button,
    Card,
    CardHeader,
    CardBody,
    CardFooter,
    CardTitle,
    FormGroup,
    Form,
    Input,
    Row,
    Collapse,
    ButtonGroup,
    Col,
    Navbar,
    NavbarBrand,
    Container
} from 'reactstrap';
import { ArrowLeft } from 'react-bootstrap-icons';
import { Modal, Spinner, Toast } from 'react-bootstrap';
import { useParams, useHistory } from 'react-router-dom';
import common from './commonData';
import Axios from 'axios';
function User() {
    const sidebarToggle = React.useRef();
    let { id } = useParams();
    const history = useHistory();
    const [load, setLoad] = useState(true);
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [file, setFile] = useState('');
    const [imgs, setImage] = useState('');
    const [imgsTf, setImageTf] = useState('');
    const [password, setPassword] = useState('');
    const [validated, setValidated] = useState(false);
    const [isSave, setIsSave] = useState(false);
    const [msg, setMsg] = useState('');
    const [msgGravity, setMsgravity] = useState('success');
    const openSidebar = () => {
        document.documentElement.classList.toggle('nav-open');
        sidebarToggle.current.classList.toggle('toggled');
    };
    useEffect(() => {
        if (localStorage.getItem('user-info')) {
            (async function anyNameFunction() {
                await Axios.get(common.ipapi + '/api/user/edit/' + id).then((response) => {
                    setEmail(response.data.email);
                    setName(response.data.name);
                    setImage(common.ipapi + '/' + response.data.photo);
                    setLoad(false);
                });
            })();
        } else {
            history.push('/login');
        }
    }, [history]);

    const handleSubmit = (event) => {
        event.preventDefault();
        const form = event.currentTarget;
        if (form.checkValidity() === false) {
            event.stopPropagation();
        }
        signUp();
    };

    function signUp(e) {
        setLoad(true);
        const values = new FormData();
        values.append('name', name);
        values.append('email', email);
        values.append('password', password);
        values.append('photo', imgsTf);
        Axios({
            url: common.ipapi + '/api/user/update/' + id,
            method: 'POST',
            headers: {
                'Content-Type': 'form-data'
            },
            data: values
        })
            .then((res) => {
                if (res.data.status === 1) {
                    setIsSave(true);
                    setMsg(res.data.msg);
                    setMsgravity('info');
                    setLoad(false);
                } else {
                    if (res.data.msg == 23000) {
                        setIsSave(true);
                        setMsg("L'adresse email saisi exite déjà");
                        setMsgravity('danger');
                        setLoad(false);
                    } else {
                        setIsSave(true);
                        setMsg('Une erreur à eu lieu lors de la création');
                        setMsgravity('danger');
                        setLoad(false);
                    }
                }
            })
            .catch((error) => {
                setIsSave(true);
                setMsg('Une erreur à eu lieu lors de la création');
                setMsgravity('danger');
                setLoad(false);
            });
    }

    function onChangeImage(e) {
        let files = e.target.files || e.dataTransfer.files;
        if (!files.length) {
            return;
        }
        setFile(e.target.value);
        setImage(URL.createObjectURL(e.target.files[0]));
        setImageTf(files[0]);
    }
    function cancel(e) {
        e.preventDefault();
        window.history.back();
    }

    return (
        <>
            <Toast onClose={() => setIsSave(false)} show={isSave} delay={3000} bg={msgGravity} autohide>
                <Toast.Header>
                    <img src="holder.js/20x20?text=%20" className="rounded me-2" alt="" />
                    <strong className="me-auto">Info</strong>
                </Toast.Header>
                <Toast.Body>{msg}</Toast.Body>
            </Toast>
            <Modal show={load} size="sm" aria-labelledby="contained-modal-title-vcenter" centered>
                <Modal.Body>
                    Veuillez patienter.......
                    <Spinner animation="border" variant="success" />
                </Modal.Body>
            </Modal>
            {/* {load ? <Spinner animation="grow" variant="warning" /> : ''} */}
            <div className="content">
                <Row>
                    <Col md="4">
                        <Card className="card-user">
                            <div className="image">
                                <img alt="..." src={require('assets/img/damir-bosnjak.jpg').default} />
                            </div>
                            <CardBody>
                                <div className="author">
                                    <a href="#pablo" onClick={(e) => e.preventDefault()}>
                                        <img alt="..." className="avatar border-gray" src={imgs} />
                                        <h5 className="title">{name}</h5>
                                    </a>
                                    <p className="description">{email}</p>
                                </div>
                            </CardBody>
                        </Card>
                    </Col>
                    <Col md="8">
                        <Card className="card-user">
                            <CardHeader>
                                <CardTitle tag="h5">Nouvel utilisateur</CardTitle>
                            </CardHeader>
                            <CardBody>
                                <Form noValidate validated={validated} onSubmit={handleSubmit}>
                                    <Row>
                                        <Col className="px-1" md="6">
                                            <FormGroup>
                                                <label>Nom d'utilisateur</label>
                                                <Input
                                                    placeholder="Nom d'utilisateur"
                                                    type="text"
                                                    value={name}
                                                    onChange={(e) => setName(e.target.value)}
                                                />
                                            </FormGroup>
                                        </Col>
                                        <Col className="pl-1" md="6">
                                            <FormGroup>
                                                <label htmlFor="exampleInputEmail1">Email</label>
                                                <Input
                                                    placeholder="Email"
                                                    type="email"
                                                    value={email}
                                                    onChange={(e) => setEmail(e.target.value)}
                                                />
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col className="pr-1" md="6">
                                            <FormGroup>
                                                <label>Mot de passe:</label>
                                                <Input
                                                    placeholder="Mot de passe"
                                                    type="password"
                                                    value={password}
                                                    onChange={(e) => setPassword(e.target.value)}
                                                />
                                            </FormGroup>
                                        </Col>
                                        <Col className="pl-1" md="6">
                                            <FormGroup>
                                                <label>Photo de profile</label>
                                                <Input
                                                    placeholder="Choisir une photo"
                                                    type="file"
                                                    value={file}
                                                    accept=".jpeg,.png"
                                                    onChange={(e) => onChangeImage(e)}
                                                />
                                            </FormGroup>
                                        </Col>
                                    </Row>

                                    <Row>
                                        <div className="update ml-auto mr-auto">
                                            <Button className="btn-round" color="primary" type="submit">
                                                Valider
                                            </Button>
                                        </div>
                                    </Row>
                                </Form>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </div>
        </>
    );
}

export default User;
