import { useHistory } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import { Spinner, Toast, Modal } from 'react-bootstrap';
import { Card, CardBody, CardTitle, CardText } from 'reactstrap';
import { TriangleHalf } from 'react-bootstrap-icons';
import Tables from './gridtables';
import 'jspdf-autotable';
import Axios from 'axios';
import common from '../../commonData';
import AddCommande from './addCommande';
import UpdateCommande from './updateCommande';

function ListeCommandeFrs() {
    const history = useHistory();
    const [datas, setDatas] = useState([]);
    const [load, setLoad] = useState(false);
    const [loading, setLoading] = useState(false);
    const [msg, setMsg] = useState('');
    const [isSave, setIsSave] = useState(false);
    const [msgGravity, setMsgravity] = useState('success');
    const [showAdd, setShowAdd] = useState(false);
    const [showEdit, setShowEdit] = useState(false);
    const [ids, setId] = useState(0);
    const log = common.search(common.userdroit, 'SAD');
    const log1 = common.search(common.userdroit, 'GCF');

    useEffect(() => {
        setLoad(true);
        if (localStorage.getItem('user-info')) {
            (async function anyNameFunction() {
                getFacture();
            })();
        } else {
            history.push('/login');
        }
    }, [history, loading]);

    function getFacture() {
        setLoad(true);
        Axios.get(common.ipapi + '/api/commandefrs/index')
            .then((response) => {
                setDatas(response.data.datas);
                setLoad(false);
            })
            .catch((errors) => {
                setLoad(false);
            });
    }

    const onRemoveLigne = async (id) => {
        const log = common.search(common.userdroit, 'SAD');
        const log1 = common.search(common.userdroit, 'SCF');
        if (log == undefined) {
            if (log1 == undefined) {
                setMsg("Vous n'avez pas le droit d'effectuer cette action, merci de contacter l'administrateur");
                setMsgravity('danger');
                setIsSave(true);
                return;
            }
        }
        let text = 'Voulez-vous supprimer cette ligne';
        let res = window.confirm(text);
        if (res === false) {
            return;
        }
        setLoad(true);
        const response = await fetch(common.ipapi + '/api/commandefrs/delete/' + id, {
            method: 'DELETE'
        });
        const json = await response.json();
        setLoad(true);
        var filteredProduct = datas.filter((product) => {
            return product.id != id;
        });
        setDatas(filteredProduct);
        setMsg('Ligne supprimée');
        setMsgravity('info');
        setIsSave(true);
        setLoad(false);
    };
    function setAdds(val) {
        const log = common.search(common.userdroit, 'SAD');
        const log1 = common.search(common.userdroit, 'ACF');
        if (log == undefined) {
            if (log1 == undefined) {
                setMsg("Vous n'avez pas le droit d'effectuer cette action, merci de contacter l'administrateur");
                setMsgravity('danger');
                setIsSave(true);
                return;
            }
        }
        setShowAdd(true);
    }
    function setIds(id) {
        const log = common.search(common.userdroit, 'SAD');
        const log1 = common.search(common.userdroit, 'MCF');
        if (log == undefined) {
            if (log1 == undefined) {
                setMsg("Vous n'avez pas le droit d'effectuer cette action, merci de contacter l'administrateur");
                setMsgravity('danger');
                setIsSave(true);
                return;
            }
        }
        setId(id);
        setShowEdit(true);
    }

    const handleClose = () => {
        setShowAdd(false);
        setShowEdit(false);
    };

    return (
        <>
            {log != undefined || log1 != undefined ? (
                <>
                    <Toast id="toast" onClose={() => setIsSave(false)} show={isSave} delay={3000} className={'bg-' + msgGravity} autohide>
                        <Toast.Header>
                            <img src="holder.js/20x20?text=%20" className="rounded me-2" alt="" />
                            <strong className="me-auto">Info</strong>
                        </Toast.Header>
                        <Toast.Body className="dark">{msg}</Toast.Body>
                    </Toast>
                    <Modal show={load} size="sm" aria-labelledby="contained-modal-title-vcenter" centered>
                        <Modal.Body>
                            Veuillez patienter.......
                            <Spinner animation="border" variant="success" />
                        </Modal.Body>
                    </Modal>

                    {showAdd ? (
                        <AddCommande
                            show={showAdd}
                            setIsSave={setIsSave}
                            setMsg={setMsg}
                            setMsgravity={setMsgravity}
                            setLoad={setLoad}
                            handleClose={handleClose}
                            setLoading={setLoading}
                            load={load}
                            loading={loading}
                        />
                    ) : (
                        <></>
                    )}
                    {showEdit ? (
                        <UpdateCommande
                            ids={ids}
                            show={showEdit}
                            setIsSave={setIsSave}
                            setMsg={setMsg}
                            setMsgravity={setMsgravity}
                            setLoad={setLoad}
                            handleClose={handleClose}
                            setLoading={setLoading}
                            load={load}
                            loading={loading}
                        />
                    ) : (
                        <></>
                    )}
                    <div className="content contents">
                        <div id="tablecontent">
                            <Tables
                                rows={datas}
                                isLoading={load}
                                setIds={setIds}
                                setAdds={setAdds}
                                handleClose={handleClose}
                                onRemoveLigne={onRemoveLigne}
                                setLoading={setLoading}
                                loading={loading}
                                setIsSave={setIsSave}
                                setMsg={setMsg}
                                setMsgravity={setMsgravity}
                                setLoad={setLoad}
                            />
                        </div>
                    </div>
                </>
            ) : (
                <Card style={{ width: '25rem' }} className="centerCard">
                    <CardBody>
                        <CardTitle tag="h5">
                            <TriangleHalf size={60} color="red"></TriangleHalf> Avertissement
                        </CardTitle>
                        <CardText>Vous n'êtes pas autoriser à utiliser cette section. Merci de contacter votre administrateur</CardText>
                    </CardBody>
                </Card>
            )}
        </>
    );
}

export default ListeCommandeFrs;
