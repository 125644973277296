import React, { useEffect, useState } from 'react';
import XLSX from 'sheetjs-style';
import { Form, Button, Spinner, ButtonGroup, Modal } from 'react-bootstrap';
import { Row, Col } from 'reactstrap';
import { Typeahead } from 'react-bootstrap-typeahead';
import 'react-bootstrap-typeahead/css/Typeahead.css';
import Axios from 'axios';
import * as Yup from 'yup';
import { Field, Formik } from 'formik';
import dateformat from 'dateformat';
import { Backspace, Save2Fill, Star } from 'react-bootstrap-icons';
import common from '../../commonData';

function FilterModale({ showFilterModal, setMsg, setIsSave, setMsgravity, setLoad, load, handleClose }) {
    var ladate = new Date();
    const [marque, setMarqueData] = useState([]);
    const [states, setState] = useState({
        dateDebut: dateformat(ladate.getMonth() + 1 + '/' + ladate.getDate() + '/' + ladate.getFullYear(), 'yyyy-mm-dd'),
        dateFin: '',
        marque_id: ''
    });
    const { dateDebut, dateFin, marque_id } = states;
    useEffect(() => {
        (async function anyNameFunction() {
            // setLoad(true);
            Axios.get(common.ipapi + '/api/marque/index')
                .then((response) => {
                    setMarqueData(response.data);
                    //setLoad(false)
                })
                .catch((errors) => {
                    //setLoad(false)
                });
        })();
    }, []);

    function GetSortOrder(prop) {
        return function (a, b) {
            if (a[prop] > b[prop]) {
                return 1;
            } else if (a[prop] < b[prop]) {
                return -1;
            }
            return 0;
        };
    }
    const exportToExcel = async (data) => {
        const raw_data = data.sort(GetSortOrder('famille'));
        /* generate worksheet and workbook */
        const worksheet = XLSX.utils.json_to_sheet(raw_data);
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, 'Stock Analysis');
        /* fix headers */
        XLSX.utils.sheet_add_aoa(worksheet, [[]], { origin: 'A1' });
        /* calculate column width */
        const max_width = raw_data.reduce((w, r) => Math.max(w, r.parts.length), 10);
        worksheet['!cols'] = [{ wch: max_width }];
        /* create an XLSX file and try to save to Presidents.xlsx */
        XLSX.writeFile(workbook, 'stockAnalysis.xlsx');
    };

    async function saveArticle(values) {
        setLoad(true);
        await Axios({
            url: common.ipapi + '/api/article/analysis/' + values.marque_id + '/' + values.dateDebut + '/' + values.dateFin,
            method: 'GET'
        })
            .then((res) => {
                if (res.status !== 200) {
                    setMsg('Veuillez vérifier votre connexion internet');
                    setMsgravity('info');
                    setIsSave(false);
                    return;
                }
                if (res.data.status === 1) {
                    exportToExcel(res.data.datas);
                    setLoad(false);
                    handleClose();
                } else {
                    setIsSave(true);
                    setMsg(res.data.msg);
                    setMsgravity('danger');
                    setLoad(false);
                }
            })
            .catch((error) => {
                setIsSave(true);
                setMsg('Erreur');
                setMsgravity('danger');
                setLoad(false);
            });
    }

    // function setMarqueIds(d) {
    //     setMarqueId(d.length > 0 ? d[0].id : 0);
    // }

    return (
        <>
            <Modal show={load} size="sm" aria-labelledby="contained-modal-title-vcenter" centered>
                <Modal.Body>
                    Veuillez patienter.......
                    <Spinner animation="border" variant="warning" />
                </Modal.Body>
            </Modal>
            <Formik
                initialValues={{
                    dateDebut: dateDebut,
                    dateFin: dateFin,
                    marque_id: marque_id
                }}
                // enableReinitialize
                validationSchema={Yup.object().shape({
                    dateFin: Yup.date().required(common.msg),
                    dateDebut: Yup.date().required(common.msg),
                    marque_id: Yup.number().required(common.msg)
                })}
                onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
                    try {
                        saveArticle(values);
                    } catch (err) {
                        setMsg('Une erreur à eu lieu');
                        setMsgravity('danger');
                        setIsSave(true);
                        setLoad(false);
                    }
                }}
            >
                {({ errors, handleBlur, handleChange, handleSubmit, setFieldTouched, setFieldValue, values }) => (
                    <Modal show={showFilterModal} onHide={handleClose}>
                        <Form noValidate onSubmit={handleSubmit}>
                            <Modal.Header>
                                FILTRE DE SELECTION <Backspace onClick={handleClose} color="red" size={30} />
                            </Modal.Header>
                            <Modal.Body>
                                <Form.Group as={Row} className="mb-3">
                                    <Form.Label column sm={5}>
                                        Marque:
                                        <Star color="red" size={10} />
                                    </Form.Label>
                                    <Col sm={7}>
                                        {/* <Typeahead
                                            id="my-typeahead-id"
                                            labelKey={(option) => `${option.libellemarq}`}
                                            filterBy={['libellemarq']}
                                            value={marque_id}
                                            options={marque}
                                            placeholder="Sélectionner un marque"
                                            onChange={(selected) => setMarqueIds(selected)}
                                        /> */}
                                        <Typeahead
                                            id="marque_id"
                                            multiple={false}
                                            onChange={(selected) => {
                                                const value = selected.length > 0 ? selected[0].id : 0;
                                                setFieldValue('marque_id', value);
                                            }}
                                            onInputChange={(text, event) => setFieldValue('marque_id', text)}
                                            onBlur={(e) => setFieldTouched('marque_id', true)}
                                            labelKey="libellemarq"
                                            options={marque}
                                            selected={marque.slice(
                                                marque.findIndex((element) => element.id === values.marque_id),
                                                marque.findIndex((element) => element.id === values.marque_id) + 1
                                            )}
                                        />
                                        {errors.marque_id && <div id="feedback">{errors.marque_id}</div>}
                                    </Col>
                                </Form.Group>
                                <Form.Group as={Row} className="mb-3">
                                    <Form.Label column sm={5}>
                                        Date début période: <Star color="red" size={10} />
                                    </Form.Label>
                                    <Col sm={7}>
                                        <Form.Control
                                            id="dateDebut"
                                            type="date"
                                            placeholder="Date début période"
                                            aria-describedby="inputGroupPrepend"
                                            required
                                            size="sm"
                                            value={values.dateDebut}
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                        />
                                        {errors.dateDebut && <div id="feedback">{errors.dateDebut}</div>}
                                    </Col>
                                </Form.Group>
                                <Form.Group as={Row} className="mb-3">
                                    <Form.Label column sm={5}>
                                        Date fin période: <Star color="red" size={10} />
                                    </Form.Label>
                                    <Col sm={7}>
                                        <Form.Control
                                            id="dateFin"
                                            type="date"
                                            placeholder="Date fin période"
                                            aria-describedby="inputGroupPrepend"
                                            required
                                            size="sm"
                                            value={values.dateFin}
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                        />
                                        {errors.dateFin && <div id="feedback">{errors.dateFin}</div>}
                                    </Col>
                                </Form.Group>
                            </Modal.Body>
                            <Modal.Footer>
                                <div className="ml-auto">
                                    <ButtonGroup size="sm">
                                        <Button type="submit" variant="ligth">
                                            <Save2Fill color="#807f7e" size={20} /> Rechercher
                                        </Button>
                                    </ButtonGroup>
                                </div>
                            </Modal.Footer>
                        </Form>
                    </Modal>
                )}
            </Formik>
        </>
    );
}
export default FilterModale;
