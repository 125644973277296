import React, { useState } from 'react';
import { ButtonGroup, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { Eye, Trash, PencilSquare } from 'react-bootstrap-icons';
import XLSX from 'sheetjs-style';
import moment from 'moment';
import ReactDataGrid from '@inovua/reactdatagrid-community';
import '@inovua/reactdatagrid-community/index.css';
import DateFilter from '@inovua/reactdatagrid-community/DateFilter';
import { jsPDF } from 'jspdf';
import 'jspdf-autotable';
import 'assets/css/table.css';
import dateformat from 'dateformat';
import common from '../../commonData';
import NavMenu from './navbar';

function MyAwesomeTable({ rows, isLoading, onDelete, setIds, setAdds, viewEntre }) {
    window.moment = moment;
    const [gridRef, setGridRef] = useState(null);
    const filterValue = [
        { name: 'arrivage', operator: 'contains', type: 'string', value: '' },
        { name: 'remarque', operator: 'contains', type: 'string', value: '' },
        { name: 'fournisseur', operator: 'contains', type: 'string', value: '' },
        { name: 'dateentr', operator: 'inrange', type: 'date', value: '' },
        { name: 'datearriv', operator: 'inrange', type: 'date', value: '' },
        { name: 'user', operator: 'contains', type: 'string', value: '' },
        { name: 'useru', operator: 'contains', type: 'string', value: '' },
        { name: 'codeentre', operator: 'contains', type: 'string', value: '' },
        { name: 'codecommande', operator: 'contains', type: 'string', value: '' },
        { name: 'datecommande', operator: 'inrange', type: 'date', value: '' }
    ];
    const columnss = [
        {
            name: 'id',
            header: 'Id',
            defaultVisible: false,
            defaultWidth: 65,
            type: 'number'
        },
        {
            header: 'Action',
            defaultWidth: 110,
            type: 'string',
            render: ({ value, data }) => {
                return (
                    <div className="menudrops">
                        <ButtonGroup aria-label="Basic example">
                            <OverlayTrigger overlay={<Tooltip id="tooltip-disabled"> Voir détail</Tooltip>}>
                                <a href="#/admin/entrees" onClick={(e) => viewEntre(data.id)}>
                                    <Eye color="royalblue" size={25} />
                                </a>
                            </OverlayTrigger>
                            {data.inventaireid === null ? (
                                data.codecommande == 'neant' ? (
                                    <OverlayTrigger overlay={<Tooltip id="tooltip-disabled"> Modifier</Tooltip>}>
                                        <a href="#/admin/entrees" onClick={(e) => setIds(data.id)}>
                                            <PencilSquare color="blue" size={25} />
                                        </a>
                                    </OverlayTrigger>
                                ) : (
                                    <>CMD</>
                                )
                            ) : (
                                <>INV</>
                            )}
                            <OverlayTrigger overlay={<Tooltip id="tooltip-disabled"> Supprimer</Tooltip>}>
                                <a href="#/admin/entrees" onClick={() => onDelete(data.id)}>
                                    <Trash color="red" size={25} />
                                </a>
                            </OverlayTrigger>
                        </ButtonGroup>
                    </div>
                );
            }
        },
        {
            name: 'codeentre',
            header: 'N° entree',
            defaultWidth: 100,
            type: 'string'
        },
        {
            name: 'fournisseur',
            header: 'Fournisseurs',
            defaultWidth: 200,
            type: 'string'
        },
        {
            name: 'dateentr',
            header: 'Date entrées',
            defaultWidth: 200,
            type: 'date',
            dateFormat: 'DD-MM-YYYY',
            filterEditor: DateFilter,
            filterEditorProps: (props, { index }) => {
                // for range and notinrange operators, the index is 1 for the after field
                return {
                    dateFormat: 'DD-MM-YYYY',
                    placeholder: index === 1 ? 'Date fin' : 'Date debut'
                };
            },
            render: ({ value, cellProps: { dateFormat } }) => moment(value).format(dateFormat)
        },
        {
            name: 'arrivage',
            header: 'N° conteneur',
            defaultWidth: 150,
            type: 'string'
        },
        {
            name: 'datearriv',
            header: 'Date arrivage',
            defaultWidth: 120,
            type: 'string',
            render: ({ value }) => (value ? dateformat(value, 'dd-mm-yyyy') : 'néant')
        },

        { name: 'remarque', header: 'Remarque', defaultWidth: 220, type: 'string' },
        {
            name: 'codecommande',
            header: 'N° commande',
            defaultWidth: 100,
            type: 'string'
        },
        {
            name: 'datecommande',
            header: 'Date commande',
            defaultWidth: 100,
            type: 'date',
            render: ({ value }) => (value ? dateformat(value, 'dd-mm-yyyy') : null)
        },
        { name: 'user', header: 'Crée par', defaultWidth: 100, type: 'string' },
        {
            name: 'created_at',
            header: 'Crée le',
            defaultWidth: 130,
            type: 'string',
            render: ({ value }) => dateformat(value, 'dd-mm-yyyy HH:MM')
        },
        { name: 'useru', header: 'Modif. par', defaultWidth: 100, type: 'string' },
        {
            name: 'updated_at',
            header: 'Modif. le',
            defaultWidth: 130,
            type: 'string',
            render: ({ value }) => dateformat(value, 'dd-mm-yyyy HH:MM')
        }
    ];
    const gridStyle = { minHeight: window.screen.height - 200 };

    const downloadBlob = (blob, fileName = 'grid-data.csv') => {
        const link = document.createElement('a');
        const url = URL.createObjectURL(blob);

        link.setAttribute('href', url);
        link.setAttribute('download', fileName);
        link.style.position = 'absolute';
        link.style.visibility = 'hidden';

        document.body.appendChild(link);

        link.click();

        document.body.removeChild(link);
    };
    const [columns] = useState(columnss);

    //--------------- EXPORT VERS EXCEL ------------------
    const exportCSV = () => {
        const data = [];
        let mont = 0;
        let qte = 0;
        var remise = 0;
        var tremise = 0;
        var montantremise = 0;
        var Tmontantremise = 0;
        const raw_data = [...gridRef.current.data.sort(GetSortOrder('dateentr'))];
        /* flatten objects */
        raw_data.forEach((row) => {
            row.ligneentree.forEach((lsort) => {
                mont += Math.round(lsort.pivot.prixachat * lsort.pivot.quantite);
                qte += lsort.pivot.quantite;
                remise = lsort.pivot.remise ? Math.round(lsort.pivot.prixachat * lsort.pivot.quantite) * (lsort.pivot.remise / 100) : 0;
                tremise += remise;
                montantremise = lsort.pivot.prixachat * lsort.pivot.quantite - remise;
                Tmontantremise += lsort.pivot.prixachat * lsort.pivot.quantite - remise;
                data.push({
                    codeentre: row.codeentre || 'Néant',
                    fournisseur: row.fournisseur || 'Néant',
                    qrbarre: lsort.qrbarre,
                    descriptionart: lsort.descriptionart,
                    quantite: lsort.pivot.quantite,
                    prixachat: new Intl.NumberFormat('de-DE').format(lsort.prixachat),
                    total: new Intl.NumberFormat('de-DE').format(lsort.pivot.prixachat * lsort.pivot.quantite),
                    remise: lsort.pivot.remise,
                    mremise: new Intl.NumberFormat('de-DE').format(remise),
                    totalr: new Intl.NumberFormat('de-DE').format(montantremise),
                    date: dateformat(row.dateentr, 'dd-mm-yyyy')
                });
            });
        });

        data.push({
            codeentre: '',
            fournisseur: '',
            qrbarre: '',
            descriptionart: '',
            quantite: qte,
            prixachat: '',
            total: mont,
            remise: '',
            mremise: tremise,
            totalr: Tmontantremise,
            date: ''
        });
        /* generate worksheet and workbook */
        const worksheet = XLSX.utils.json_to_sheet(data);
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, 'Mes entées');
        /* fix headers */
        XLSX.utils.sheet_add_aoa(
            worksheet,
            [
                [
                    'N° bon',
                    'Fournisseurs',
                    'Code',
                    'Articles',
                    'Qté',
                    'Prix U',
                    'Total',
                    'Remise (%)',
                    'Montant remise',
                    'Total remisé',
                    'Date entrée'
                ]
            ],
            {
                origin: 'A1'
            }
        );
        /* calculate column width */
        const max_width = data.reduce((w, r) => Math.max(w, r.codeentre.length), 10);
        worksheet['!cols'] = [{ wch: max_width }];
        XLSX.writeFile(workbook, 'entree.xlsx');
    };

    const SEPARATOR = ',';

    function GetSortOrder(prop) {
        return function (a, b) {
            if (a[prop] > b[prop]) {
                return 1;
            } else if (a[prop] < b[prop]) {
                return -1;
            }
            return 0;
        };
    }
    //-------------IMPRIMER LES LISTE ----------------
    function printListe() {
        const raw_data = [...gridRef.current.data.sort(GetSortOrder('fournisseur'))];
        if (gridRef.current.data.length <= 0) {
            // setMsg("Aucune donnée à imprimer")
            // setIsSave(true)
            // setMsgravity("danger")
            // setLoad(false)
            return;
        }
        const doc = new jsPDF({
            orientation: 'l',
            unit: 'mm',
            format: 'a4',
            putOnlyUsedFonts: true
        });
        var width = doc.internal.pageSize.getWidth();
        const data = [];
        let mont = 0;
        let qte = 0;
        common.ipapi == 'https://192.168.8.109:81' || common.ipapi == 'https://api.tracky-entrepot.com'
            ? doc.addImage(common.logo, 'jpg', 14, 6, 30, 30)
            : doc.addImage(common.logo1, 'jpg', 14, 6, 30, 30);
        doc.setFontSize(16);
        common.ipapi == 'https://192.168.8.109:81' || common.ipapi == 'https://api.tracky-entrepot.com'
            ? doc.text('CLUB AUTO', 90, 14)
            : doc.text('HR MOTORS', 90, 14);
        doc.setFontSize(12);
        doc.setFillColor(0, 0, 0);
        doc.rect(65, 15, width - 80, 10, 'F');
        doc.setTextColor(255, 255, 255);
        doc.text("LISTE DES  ENTREES D'ARTICLES", 90, 22);
        doc.setTextColor(0, 0, 0);
        var remise = 0;
        var tremise = 0;
        raw_data.forEach((row) => {
            row.ligneentree.forEach((lsort) => {
                remise = lsort.pivot.remise ? Math.round(lsort.pivot.prixachat * lsort.pivot.quantite) * (lsort.pivot.remise / 100) : 0;
                tremise += remise;
                mont += Math.round(lsort.pivot.prixachat * lsort.pivot.quantite);
                qte += lsort.pivot.quantite;
                data.push([
                    row.codeentre,
                    row.fournisseur || 'Néant',
                    lsort.qrbarre,
                    lsort.descriptionart,
                    lsort.pivot.quantite,
                    new Intl.NumberFormat('de-DE').format(lsort.pivot.prixachat),
                    new Intl.NumberFormat('de-DE').format(lsort.pivot.prixachat * lsort.pivot.quantite),
                    new Intl.NumberFormat('de-DE').format(remise) + ' (' + lsort.pivot.remise + '%)',
                    new Intl.NumberFormat('de-DE').format(lsort.pivot.prixachat * lsort.pivot.quantite - remise),
                    dateformat(row.dateentr, 'dd-mm-yyyy')
                ]);
            });
        });

        data.push([
            'TOTAL',
            '',
            '',
            '',
            qte,
            '',
            new Intl.NumberFormat('de-DE').format(mont),
            new Intl.NumberFormat('de-DE').format(tremise),
            new Intl.NumberFormat('de-DE', {
                style: 'decimal',
                currency: 'CFA'
            }).format(mont - tremise),
            ''
        ]);
        doc.autoTable({
            styles: {
                fillColor: [206, 206, 206],
                textColor: [4, 4, 4],
                halign: 'center',
                fontSize: 10,
                cellPadding: 0.75
            },
            columnStyles: {
                0: {
                    halign: 'left',
                    fillColor: [255, 255, 255],
                    textColor: [0, 0, 0],
                    // cellWidth: 15,
                    fontSize: 8
                },
                1: {
                    halign: 'left',
                    fillColor: [255, 255, 255],
                    textColor: [0, 0, 0],
                    fontSize: 8
                },
                2: {
                    halign: 'left',
                    fillColor: [255, 255, 255],
                    textColor: [0, 0, 0],
                    fontSize: 8
                },
                3: {
                    halign: 'left',
                    fillColor: [255, 255, 255],
                    textColor: [0, 0, 0],
                    fontSize: 8
                },
                4: {
                    halign: 'center',
                    fillColor: [255, 255, 255],
                    textColor: [0, 0, 0],
                    fontSize: 8
                },
                5: {
                    halign: 'center',
                    fillColor: [255, 255, 255],
                    textColor: [0, 0, 0],
                    fontSize: 8
                },
                6: {
                    halign: 'center',
                    fillColor: [255, 255, 255],
                    textColor: [0, 0, 0],
                    fontSize: 8
                },
                7: {
                    halign: 'center',
                    fillColor: [255, 255, 255],
                    textColor: [0, 0, 0],
                    fontSize: 8
                },
                8: {
                    halign: 'center',
                    fillColor: [255, 255, 255],
                    textColor: [0, 0, 0],
                    fontSize: 8
                },
                9: {
                    halign: 'center',
                    fillColor: [255, 255, 255],
                    textColor: [0, 0, 0],
                    fontSize: 8
                }
            },
            theme: 'grid',
            startY: 35,
            fontSize: 6,
            head: [['N° Bon', 'Fournisseurs', 'Code', 'Articles', 'Qté', 'Prix U', 'Total', 'Remise', 'Total remisé', 'Date entrée']],
            body: data
        });
        const pageCount = doc.internal.getNumberOfPages();
        for (var i = 1; i <= pageCount; i++) {
            doc.setPage(i);
            doc.setFontSize(10);
            doc.text('Page ' + String(i) + ' sur ' + String(pageCount), 210 - 55, 200, null, null, 'right');
        }
        doc.save('listeentree.pdf');
    }

    return (
        <div>
            <NavMenu printListe={printListe} setAdds={setAdds} exportCSV={exportCSV} />
            <ReactDataGrid
                idProperty="id"
                handle={setGridRef}
                style={gridStyle}
                loading={isLoading}
                reorderColumns={false}
                pagination
                pageSizes={[200, 400, 600, 800, 1000]}
                defaultLimit={400}
                showHoverRows={true}
                editable={true}
                showColumnMenuTool={false}
                defaultFilterValue={filterValue}
                columns={columnss}
                dataSource={rows}
            />
        </div>
    );
}

export default MyAwesomeTable;
