import React, { useEffect, useState } from 'react';
import { Form, InputGroup, ButtonGroup, Spinner, Modal, ProgressBar } from 'react-bootstrap';
import { Card, CardHeader, Button, Input, Row, Col, Label } from 'reactstrap';
import { isMobile } from 'react-device-detect';
import { useHistory } from 'react-router-dom';
import readXlsxFile from 'read-excel-file';
import Axios from 'axios';
import { Save2Fill, Backspace, Images } from 'react-bootstrap-icons';
import { ProductList } from './cardadd';
import common from '../../commonData';
var count = 0;

function ImportedArticle({ show, setIsSave, setMsg, setLoad, setMsgravity, handleClose, showprogress, setShowProgress }) {
    const [validated, setValidated] = useState(false);
    const [proressValue, setProressValue] = useState(0);
    const [entrepot_id, setEntrepot] = useState(0);
    const [entrepot, setEntrData] = useState([]);
    const [files, setFile] = useState([]);
    const [listeSelect, setListeSelect] = useState([]);
    const history = useHistory();

    useEffect(() => {
        if (!localStorage.getItem('user-info')) {
            history.push('/login');
        }

        (async function anyNameFunction() {
            setLoad(true);
            const response = await fetch(common.ipapi + '/api/entrepot/index/1');
            const json = await response.json();
            setEntrData(json);
            setLoad(false);
        })();
    }, [history]);

    const onRemoveProduct = (i, id) => {
        var filteredProduct = [];
        setLoad(true);
        filteredProduct = listeSelect.filter((product, index) => {
            return index != i;
        });
        setListeSelect(filteredProduct);
        setMsg('Ligne supprimée');
        setMsgravity('info');
        setIsSave(true);
        setLoad(false);
    };

    function groupBy(tableauObjets) {
        var result = [];
        let t = [];
        return tableauObjets.reduce(function (res, value) {
            if (value[0] !== '') {
                t.push({
                    id: value[0],
                    qrbarre: value[1],
                    quantite: value[3],
                    descriptionart: value[2],
                    model: value[4],
                    marque: value[5]
                });
            }
            return t;
        }, {});
    }

    function setLoadFile(files) {
        console.log(files);
        return;
        var data;
        setLoad(true);
        readXlsxFile(files[0]).then((rows) => {
            data = groupBy(rows);
            setListeSelect(data);
        });
        setLoad(false);
    }
    function storeArticle() {
        setShowProgress(true);
        var data;
        var formData = new FormData();
        formData.append('data', JSON.stringify(listeSelect));
        formData.append('entrepotid', entrepot_id);
        formData.append('user_id', common.userid);
        Axios({
            url: common.ipapi + '/api/article/storeImport',
            method: 'POST',
            data: formData
        })
            .then((res) => {
                if (res.data.status == 1) {
                    window.location.reload();
                } else {
                    setIsSave(true);
                    setMsg(res.data.msg);
                    setMsgravity('danger');
                    setShowProgress(false);
                }
            })
            .catch((error) => {
                setIsSave(true);
                setMsg(error);
                setMsgravity('danger');
                setShowProgress(false);
            });
    }
    const handleSubmit = (event) => {
        const form = event.currentTarget;
        if (form.checkValidity() === false) {
            event.preventDefault();
            event.stopPropagation();
            setValidated(true);
        } else {
            storeArticle();
        }
    };

    return (
        <>
            <Modal show={show} onHide={handleClose} size="xl">
                <Form noValidate validated={validated} onSubmit={handleSubmit}>
                    <Modal.Header closeButton>IMPORTATION DE FICHIER ARTICLES EXCEL</Modal.Header>

                    <Modal.Body>
                        <Row>
                            <Col md={8}>
                                <Form.Group as={Col} md="12" controlId="entrepot">
                                    <InputGroup hasValidation>
                                        <InputGroup.Text id="inputGroupPrepend">Entrepot:</InputGroup.Text>
                                        <Input
                                            type="select"
                                            aria-label="Sélectionner"
                                            value={entrepot_id}
                                            onChange={(e) => setEntrepot(e.target.value)}
                                        >
                                            <option value="0"></option>
                                            {entrepot.map((d, index) => {
                                                return (
                                                    <option value={d.id} key={index}>
                                                        {d.codeentrepot + '/' + d.libelleentrepot}
                                                    </option>
                                                );
                                            })}
                                        </Input>
                                        <Form.Control.Feedback type="invalid">Veuillez selectionnez l'entrepot.</Form.Control.Feedback>
                                    </InputGroup>
                                </Form.Group>
                            </Col>
                            <Col md={4}>
                                <Label className="custom-file-upload" title="Sélectionner un fichier excel">
                                    Sélectionner un fichier
                                    <input type="file" accept=".xlsx, .xls" onChange={(e) => setLoadFile(e.target.files)} />
                                    <Images color="orange" size={30} />
                                </Label>
                            </Col>
                        </Row>
                        <div id="lightbody">
                            {showprogress ? <ProgressBar animated variant="warning" now={proressValue} /> : <></>}

                            <ProductList products={listeSelect} onRemoveProduct={onRemoveProduct} />
                        </div>
                    </Modal.Body>

                    <Modal.Footer>
                        <div className="ml-auto">
                            <ButtonGroup size="sm">
                                <Button color="danger" onClick={handleClose}>
                                    <Backspace color="#000" size={20} />
                                </Button>
                                <Button type="submit" variant="ligth">
                                    <Save2Fill color="#807f7e" size={20} /> Enregister
                                </Button>
                            </ButtonGroup>
                        </div>
                    </Modal.Footer>
                </Form>
            </Modal>
        </>
    );
}

export default ImportedArticle;
