import React, { useEffect, useState } from 'react';
import { Form, InputGroup, ButtonGroup, Spinner, Modal, Carousel } from 'react-bootstrap';
import { Card, CardHeader, Button, Input, Row, Col, CardBody, Label } from 'reactstrap';
// import { isMobile } from 'react-device-detect';
import { useHistory } from 'react-router-dom';
import * as Yup from 'yup';
import { Field, Formik } from 'formik';
import Axios from 'axios';
import { Save2Fill, Trash, Backspace, Images, Star } from 'react-bootstrap-icons';
import common from '../../commonData';
import SelectFolder from '../familles/folderselect';

var count = 0;
const imageTypeRegex = /image\/(png|jpg|jpeg)/gm;

function AddArticle({ ids, editData, setShowAdd, show, setIsSave, setMsg, setMsgravity, getArticle }) {
    const [marque, setMarqueData] = useState([]);
    const [entrepot, setEntrData] = useState([]);
    const [load, setLoad] = useState(false);
    const [folderSelect, setFolderSelect] = useState(editData.famille_id ? editData.famille.libellefam : '');
    const [showselectFolder, setShowSelectFolder] = useState(false);
    const [idParent, setsIdFolder] = useState(null);
    const [id, setIdFolder] = useState(editData.famille_id);
    const [imageFiles, setImageFiles] = useState([]);
    const [images, setImages] = useState([]);

    const [states, setState] = useState({
        barQrCode: editData.qrbarre || '',
        barQrCodecourt: editData.qrbarreshort || '',
        descriptionart: editData.descriptionart || '',
        descriptionartfr: editData.descriptionartfr || '',
        coeficient: editData.coeficient || 3,
        qteseuil: editData.qteseuil || 0,
        prixachat: editData.prixachat || 0,
        prixvente: editData.prixvente || 0,
        imgs: [],
        imgsTf: [],
        marque_id: editData.marque_id || null,
        entrepot_id: editData.entrepot_id || null,
        emplacement: editData.emplacement || '',
        poids: editData.poids || '',
        hauteur: editData.hauteur || '',
        largeur: editData.largeur || '',
        volume: editData.volume || '',
        actif: '' + editData.up + '' || '1',
        imgload: editData.image || []
    });
    const {
        barQrCode,
        barQrCodecourt,
        descriptionart,
        descriptionartfr,
        qteseuil,
        coeficient,
        prixachat,
        prixvente,
        imgs,
        imgsTf,
        marque_id,
        entrepot_id,
        emplacement,
        poids,
        hauteur,
        largeur,
        volume,
        actif,
        imgload
    } = states;
    const history = useHistory();

    useEffect(() => {
        if (!localStorage.getItem('user-info')) {
            history.push('/login');
        }

        (async function anyNameFunction() {
            setLoad(true);
            const getMarque = Axios.get(common.ipapi + '/api/marque/index');
            const getEntrepot = Axios.get(common.ipapi + '/api/entrepot/index/1');
            await Axios.all([getMarque, getEntrepot])
                .then(
                    Axios.spread((...responses) => {
                        setMarqueData(responses[0].data);
                        setEntrData(responses[1].data);
                        setLoad(false);
                    })
                )
                .catch((errors) => {
                    setLoad(false);
                });
        })();
    }, [history]);

    function saveArticle(values, resetForm) {
        try {
            setLoad(true);
            var formData = new FormData();
            formData.append('file[]', images.length > 0 ? images[0] : []);
            formData.append('qrbarre', values.barQrCode);
            formData.append('qrbarreshort', values.barQrCodecourt);
            formData.append('descriptionart', values.descriptionart);
            formData.append('descriptionartfr', values.descriptionartfr);
            formData.append('qteseuil', values.qteseuil);
            formData.append('coeficient', values.coeficient);
            formData.append('famille_id', id);
            formData.append('marque_id', values.marque_id);
            formData.append('entrepot_id', values.entrepot_id);
            formData.append('user_id', common.userid);
            formData.append('prixachat', values.prixachat);
            formData.append('prixvente', values.prixachat * values.coeficient);
            formData.append('emplacement', values.emplacement);
            formData.append('hauteur', values.hauteur);
            formData.append('largeur', values.largeur);
            formData.append('poids', values.poids);
            formData.append('volume', values.volume);
            formData.append('up', values.actif);
            Axios({
                url: ids ? common.ipapi + '/api/article/update/' + ids : common.ipapi + '/api/article/store',
                method: 'POST',
                headers: {
                    'Content-Type': 'form-data'
                },
                data: formData
            })
                .then((res) => {
                    if (res.status !== 200) {
                        setMsg('Veuillez vérifier votre connexion internet');
                        setMsgravity('warning');
                        setIsSave(false);
                        return;
                    }
                    if (res.data.status === 1) {
                        count++;
                        //if (!isMobile) {
                        var len = images.length;
                        if (len > 0) {
                            const formDat = new FormData();
                            for (var a = 0; a < images.length; a++) {
                                formDat.append('myFile[]', images[a]);
                            }
                            formDat.append('artid', res.data.datas.id);
                            Axios({
                                url: common.ipapi + '/api/article/storeimage',
                                method: 'POST',
                                headers: {
                                    'Content-Type': 'form-data'
                                },
                                data: formDat
                            })
                                .then((result) => {
                                    console.log(result);
                                    if (result.data.status === 1) {
                                        setIsSave(true);
                                        setMsg(result.data.msg);
                                        setMsgravity('info');
                                        setLoad(false);
                                        resetForm();
                                        count++;
                                    } else {
                                        setIsSave(true);
                                        setMsg(result.data.msg);
                                        setMsgravity('danger');
                                        setLoad(false);
                                    }
                                })
                                .catch((error) => {
                                    setIsSave(true);
                                    setMsg('error1');
                                    setMsgravity('danger');
                                    setLoad(false);
                                });
                        } else {
                            resetForm();
                            setIsSave(true);
                            count++;
                            setMsg(res.data.msg);
                            setMsgravity('info');
                            getArticle(1);
                            setLoad(false);
                            if (ids) {
                                setTimeout(handleClose, 600);
                            }
                        }
                    } else if (res.data.status === '23000') {
                        count = 0;
                        setIsSave(true);
                        setMsg('Le code existe déjà');
                        setMsgravity('warning');
                        setLoad(false);
                    } else {
                        setIsSave(true);
                        setMsg('Veuillez remplir les champs obligatoire');
                        setMsgravity('danger');
                        setLoad(false);
                    }
                })
                .catch((error) => {
                    setIsSave(true);
                    setMsg('error2');
                    setMsgravity('danger');
                    setLoad(false);
                });
        } catch (error) {
            setIsSave(true);
            setMsg('error3');
            setMsgravity('danger');
        }
    }

    function onDeleteImgSlide(ind, file) {
        if (window.confirm('Voulez-vous supprimer cette images ? ')) {
            setLoad(true);
            Axios.delete(common.ipapi + '/api/article/destroyimg/' + file.id).then((response) => {
                if (response.data.status === 1) {
                    const del = imgload.filter((product, index) => {
                        return index !== ind;
                    });
                    setState({ imgload: del });
                    setMsgravity('success');
                    setMsg(response.data.msg);
                    setIsSave(true);
                    setLoad(false);
                } else {
                    setMsgravity('error');
                    setMsg(response.data.msg);
                    setIsSave(true);
                    setLoad(false);
                }
            });
        }
    }

    const changeHandler = (e) => {
        const { files } = e.target;
        const validImageFiles = [...imageFiles];
        for (let i = 0; i < files.length; i++) {
            const file = files[i];
            if (file.type.match(imageTypeRegex)) {
                validImageFiles.push(file);
            }
        }
        if (validImageFiles.length) {
            setImageFiles(validImageFiles);
            createImage(files[0]);
            return;
        }
        alert('Selected images are not of valid type!');
    };
    function createImage(file) {
        const image = [...images];
        let reader = new FileReader();
        reader.onload = (e) => {
            image.push(e.target.result);
            console.log(image);
            setImages(image);
        };

        reader.readAsDataURL(file);
    }
    // useEffect(() => {
    //     const image = [],
    //         fileReaders = [];
    //     let isCancel = false;
    //     if (imageFiles.length) {
    //         imageFiles.forEach((file) => {
    //             const fileReader = new FileReader();
    //             fileReaders.push(fileReader);
    //             fileReader.onload = (e) => {
    //                 const { result } = e.target;
    //                 if (result) {
    //                     image.push(result);
    //                 }
    //                 console.log(image.length + '===' + imageFiles.length);
    //                 // if (images.length === imageFiles.length && !isCancel) {
    //                 setImages(image);
    //                 // }
    //             };
    //             console.log(images);
    //             fileReader.readAsDataURL(imageFiles[imageFiles.length]);
    //         });
    //     }
    //     return () => {
    //         isCancel = true;
    //         fileReaders.forEach((fileReader) => {
    //             if (fileReader.readyState === 1) {
    //                 fileReader.abort();
    //             }
    //         });
    //     };
    // }, [imageFiles]);

    // function onChangeImage(e) {
    //     let files = e.target.files || e.dataTransfer.files;
    //     if (!files.length) {
    //         return;
    //     }
    //     const im = imgs !== undefined ? [...imgs] : [];
    //     im.push(e.target.files[0]);
    //     // setState({ imgs: im });
    //     setState((previousState) => {
    //         return {
    //             ...previousState,
    //             imgs: im
    //         };
    //     });
    //     console.log(imgs);
    //     createImage(files[0]);
    // }

    function onDeleteImg(index, file) {
        const del = images.filter((d) => d !== file);
        setImages(del);
        imageFiles.splice(index, 1);
    }

    const handleClose = () => {
        setIsSave(false);
        setShowAdd(false);
    };

    return (
        <>
            <Modal show={load} size="sm" aria-labelledby="contained-modal-title-vcenter" centered>
                <Modal.Body>
                    Veuillez patienter.......
                    <Spinner animation="grow" variant="warning" />
                </Modal.Body>
            </Modal>
            {showselectFolder ? (
                <SelectFolder
                    setShowSelectFolder={setShowSelectFolder}
                    show={showselectFolder}
                    idParent={idParent}
                    setIdFolder={setIdFolder}
                    setsIdFolder={setsIdFolder}
                    setFolderSelect={setFolderSelect}
                ></SelectFolder>
            ) : (
                <></>
            )}
            <Formik
                initialValues={{
                    barQrCode: barQrCode,
                    barQrCodecourt: barQrCodecourt,
                    descriptionart: descriptionart,
                    descriptionartfr: descriptionartfr,
                    qteseuil: qteseuil,
                    coeficient: coeficient,
                    prixachat: prixachat,
                    prixvente: prixvente,
                    marque_id: marque_id,
                    entrepot_id: entrepot_id,
                    emplacement: emplacement,
                    poids: poids,
                    hauteur: hauteur,
                    largeur: largeur,
                    volume: volume,
                    actif: actif,
                    submit: null
                }}
                enableReinitialize
                validationSchema={Yup.object().shape({
                    barQrCode: Yup.string().required(common.msg),
                    entrepot_id: Yup.number().required(common.msg),
                    descriptionart: Yup.string().required(common.msg),
                    descriptionartfr: Yup.string().required(common.msg),
                    marque_id: Yup.number().required(common.msg)
                })}
                onSubmit={async (values, { resetForm }) => {
                    try {
                        saveArticle(values, resetForm);
                    } catch (err) {
                        setMsg('Une erreur à eu lieu');
                        setMsgravity('danger');
                        setIsSave(true);
                        setLoad(false);
                    }
                }}
            >
                {({ errors, handleBlur, handleChange, handleSubmit, values }) => (
                    <Modal show={show} size="lg">
                        <Modal.Header>
                            {ids ? 'MODIFICATION ARTICLE' : 'AJOUTER UN NOUVEL ARTICLE'}
                            <Backspace onClick={handleClose} color="red" size={30} />
                        </Modal.Header>
                        <Form noValidate onSubmit={handleSubmit}>
                            <Modal.Body>
                                <div id="lightbody">
                                    <Row>
                                        <Col md="6">
                                            <Form.Group as={Row} className="mb-3">
                                                <Form.Label column sm={4}>
                                                    Code 1: <Star color="red" size={10} />
                                                </Form.Label>
                                                <Col sm={8}>
                                                    <Input
                                                        id="barQrCode"
                                                        type="text"
                                                        placeholder="Code barre / QR"
                                                        aria-describedby="inputGroupPrepend"
                                                        bsSize="sm"
                                                        value={values.barQrCode}
                                                        onBlur={handleBlur}
                                                        onChange={handleChange}
                                                    />
                                                    {errors.barQrCode && <div id="feedback">{errors.barQrCode}</div>}
                                                </Col>
                                            </Form.Group>
                                            <Form.Group as={Row} className="mb-3">
                                                <Form.Label column sm={4}>
                                                    Code 2:
                                                </Form.Label>
                                                <Col sm={8}>
                                                    <Form.Control
                                                        id="barQrCodecourt"
                                                        type="text"
                                                        placeholder="Code article court:"
                                                        aria-describedby="inputGroupPrepend"
                                                        size="sm"
                                                        value={values.barQrCodecourt}
                                                        onBlur={handleBlur}
                                                        onChange={handleChange}
                                                    />
                                                    {errors.barQrCodecourt && <div id="feedback">{errors.barQrCodecourt}</div>}
                                                </Col>
                                            </Form.Group>
                                            <Form.Group as={Row} className="mb-3">
                                                <Form.Label column sm={4}>
                                                    Libellé EN.:
                                                    <Star color="red" size={10} />
                                                </Form.Label>
                                                <Col sm={8}>
                                                    <Form.Control
                                                        id="descriptionart"
                                                        type="text"
                                                        placeholder="Désignation article"
                                                        aria-describedby="inputGroupPrepend"
                                                        size="sm"
                                                        value={values.descriptionart}
                                                        onBlur={handleBlur}
                                                        onChange={handleChange}
                                                    />
                                                    {errors.descriptionart && <div id="feedback">{errors.descriptionart}</div>}
                                                </Col>
                                            </Form.Group>
                                            <Form.Group as={Row} className="mb-3">
                                                <Form.Label column sm={4}>
                                                    Libellé FR.:
                                                    <Star color="red" size={10} />
                                                </Form.Label>
                                                <Col sm={8}>
                                                    <Form.Control
                                                        id="descriptionartfr"
                                                        type="text"
                                                        placeholder="Désignation française"
                                                        aria-describedby="inputGroupPrepend"
                                                        size="sm"
                                                        value={values.descriptionartfr}
                                                        onBlur={handleBlur}
                                                        onChange={handleChange}
                                                    />
                                                    {errors.descriptionartfr && <div id="feedback">{errors.descriptionartfr}</div>}
                                                </Col>
                                            </Form.Group>

                                            <Form.Group as={Row} className="mb-3">
                                                <Form.Label column sm={4}>
                                                    Marque article:
                                                </Form.Label>
                                                <Col sm={8}>
                                                    <Input
                                                        id="marque_id"
                                                        type="select"
                                                        value={values.marque_id}
                                                        onBlur={handleBlur}
                                                        onChange={handleChange}
                                                    >
                                                        <option value="0"></option>
                                                        {marque.map((d, index) => {
                                                            return (
                                                                <option key={index} value={d.id}>
                                                                    {d.libellemarq}
                                                                </option>
                                                            );
                                                        })}
                                                    </Input>
                                                    {errors.marque_id && <div id="feedback">{errors.marque_id}</div>}
                                                </Col>
                                            </Form.Group>
                                            <Form.Group as={Row} className="mb-3">
                                                <Form.Label column sm={4}>
                                                    Famille:
                                                    <Star color="red" size={10} />
                                                </Form.Label>
                                                <Col sm={8}>
                                                    <InputGroup hasValidation>
                                                        <Form.Control
                                                            type="text"
                                                            placeholder="Cliquer ici pour sélectionner un dossier"
                                                            aria-describedby="inputGroupPrepend"
                                                            required
                                                            size="sm"
                                                            // onChange={(e) => setFolderSelect(e.target.value)}
                                                            value={folderSelect}
                                                            onClick={() => setShowSelectFolder(true)}
                                                        />
                                                        <Form.Control.Feedback type="invalid">
                                                            Veuillez renseigner le Libellé famille
                                                        </Form.Control.Feedback>
                                                    </InputGroup>
                                                </Col>
                                            </Form.Group>

                                            <Form.Group as={Row} className="mb-3">
                                                <Form.Label column sm={4}>
                                                    Entrepot:
                                                    <Star color="red" size={10} />
                                                </Form.Label>
                                                <Col sm={8}>
                                                    <Input
                                                        id="entrepot_id"
                                                        type="select"
                                                        aria-label="Sélectionner"
                                                        value={values.entrepot_id}
                                                        onBlur={handleBlur}
                                                        onChange={handleChange}
                                                    >
                                                        <option value="0"></option>
                                                        {entrepot.map((d, index) => {
                                                            return (
                                                                <option key={index} value={d.id}>
                                                                    {d.codeentrepot + '/' + d.libelleentrepot}
                                                                </option>
                                                            );
                                                        })}
                                                    </Input>
                                                    {errors.entrepot_id && <div id="feedback">{errors.entrepot_id}</div>}
                                                </Col>
                                            </Form.Group>

                                            <Form.Group as={Row} className="mb-3">
                                                <Form.Label column sm={4}>
                                                    Emplacement:
                                                </Form.Label>
                                                <Col sm={8}>
                                                    <Form.Control
                                                        id="emplacement"
                                                        type="text"
                                                        placeholder="Emplacement dans l'entrepot"
                                                        aria-describedby="inputGroupPrepend"
                                                        size="sm"
                                                        value={values.emplacement}
                                                        onBlur={handleBlur}
                                                        onChange={handleChange}
                                                    />
                                                </Col>
                                            </Form.Group>
                                            <Label className="custom-file-upload" title="Ajouter des images.">
                                                Cliquez ici pour ajouter des images
                                                <input
                                                    type="file"
                                                    accept=".jpg, .png, .jpeg"
                                                    onChange={changeHandler}
                                                    // onChange={(e) => onChangeImage(e)}
                                                />
                                                <Images color="orange" size={50} />
                                            </Label>
                                        </Col>
                                        <Col md="6">
                                            <Form.Group as={Row} className="mb-3">
                                                <Form.Label column sm={4}>
                                                    Qté reappro.:
                                                </Form.Label>
                                                <Col sm={8}>
                                                    <Form.Control
                                                        id="qteseuil"
                                                        type="number"
                                                        placeholder="Qté réapprovionnement."
                                                        aria-describedby="inputGroupPrepend"
                                                        size="sm"
                                                        min={0}
                                                        value={values.qteseuil}
                                                        onBlur={handleBlur}
                                                        onChange={handleChange}
                                                    />
                                                </Col>
                                            </Form.Group>
                                            <Form.Group as={Row} className="mb-3">
                                                <Form.Label column sm={4}>
                                                    Prix achat:
                                                </Form.Label>
                                                <Col sm={8}>
                                                    <Form.Control
                                                        id="prixachat"
                                                        type="number"
                                                        placeholder="Prix d'achat"
                                                        aria-describedby="inputGroupPrepend"
                                                        size="sm"
                                                        min={0}
                                                        value={values.prixachat}
                                                        onBlur={handleBlur}
                                                        onChange={handleChange}
                                                    />
                                                </Col>
                                            </Form.Group>
                                            <Form.Group as={Row} className="mb-3">
                                                <Form.Label column sm={4}>
                                                    Coéficient:
                                                </Form.Label>
                                                <Col sm={8}>
                                                    <Form.Control
                                                        id="coeficient"
                                                        type="number"
                                                        placeholder="Coeficient"
                                                        aria-describedby="inputGroupPrepend"
                                                        min={1}
                                                        step="any"
                                                        size="sm"
                                                        value={values.coeficient}
                                                        onBlur={handleBlur}
                                                        onChange={handleChange}
                                                    />
                                                    <Form.Control.Feedback type="invalid">Veuillez saisir le taux.</Form.Control.Feedback>
                                                </Col>
                                            </Form.Group>
                                            <Form.Group as={Row} className="mb-3">
                                                <Form.Label column sm={4}>
                                                    Prix de vente:
                                                </Form.Label>
                                                <Col sm={8}>
                                                    <Form.Control
                                                        id="prixvente"
                                                        type="number"
                                                        placeholder="Prix de vente"
                                                        aria-describedby="inputGroupPrepend"
                                                        size="sm"
                                                        min={0}
                                                        value={values.prixachat * values.coeficient}
                                                    />
                                                </Col>
                                            </Form.Group>
                                            <Form.Group as={Row} className="mb-3">
                                                <Form.Label column sm={4}>
                                                    Hauteur (m):
                                                </Form.Label>
                                                <Col sm={8}>
                                                    <Form.Control
                                                        id="hauteur"
                                                        type="number"
                                                        placeholder="Hauteur en mètre"
                                                        aria-describedby="inputGroupPrepend"
                                                        size="sm"
                                                        min={0}
                                                        value={values.hauteur}
                                                        onBlur={handleBlur}
                                                        onChange={handleChange}
                                                    />
                                                </Col>
                                            </Form.Group>

                                            <Form.Group as={Row} className="mb-3">
                                                <Form.Label column sm={4}>
                                                    Largeur (m):
                                                </Form.Label>
                                                <Col sm={8}>
                                                    <Form.Control
                                                        id="largeur"
                                                        type="number"
                                                        placeholder="Largeur en metre"
                                                        aria-describedby="inputGroupPrepend"
                                                        size="sm"
                                                        min={0}
                                                        value={values.largeur}
                                                        onBlur={handleBlur}
                                                        onChange={handleChange}
                                                    />
                                                </Col>
                                            </Form.Group>

                                            <Form.Group as={Row} className="mb-3">
                                                <Form.Label column sm={4}>
                                                    Poids (g):
                                                </Form.Label>
                                                <Col sm={8}>
                                                    <Form.Control
                                                        id="poids"
                                                        type="number"
                                                        placeholder="Poids en gramme"
                                                        aria-describedby="inputGroupPrepend"
                                                        size="sm"
                                                        min={0}
                                                        value={values.poids}
                                                        onBlur={handleBlur}
                                                        onChange={handleChange}
                                                    />
                                                </Col>
                                            </Form.Group>

                                            <Form.Group as={Row} className="mb-3">
                                                <Form.Label column sm={4}>
                                                    Volume (m3):
                                                </Form.Label>
                                                <Col sm={8}>
                                                    <Form.Control
                                                        id="volume"
                                                        type="number"
                                                        placeholder="Volume en metre cube"
                                                        aria-describedby="inputGroupPrepend"
                                                        size="sm"
                                                        min={0}
                                                        value={values.volume}
                                                        onBlur={handleBlur}
                                                        onChange={handleChange}
                                                    />
                                                </Col>
                                            </Form.Group>
                                            <Col>
                                                <div role="group" aria-labelledby="my-radio-group">
                                                    <label className="radio">
                                                        <Field type="radio" name="actif" value="1" />
                                                        Actif
                                                    </label>
                                                    <label className="radio">
                                                        <Field type="radio" name="actif" value="0" />
                                                        Inactif
                                                    </label>
                                                </div>
                                            </Col>
                                        </Col>
                                        <Col md={12}>
                                            <Row>
                                                {images.length > 0 ? (
                                                    images.map((d, index) => {
                                                        return (
                                                            <Col md="2" key={index}>
                                                                <Card>
                                                                    <CardBody>
                                                                        <CardHeader>
                                                                            <ButtonGroup aria-label="Basic example">
                                                                                <Trash
                                                                                    onClick={() => onDeleteImg(index, d)}
                                                                                    color="red"
                                                                                    size={15}
                                                                                />
                                                                            </ButtonGroup>
                                                                        </CardHeader>
                                                                        <img
                                                                            alt="index"
                                                                            key={index}
                                                                            className="imgartUpload rounded"
                                                                            src={d}
                                                                        ></img>
                                                                    </CardBody>
                                                                </Card>
                                                            </Col>
                                                        );
                                                    })
                                                ) : (
                                                    <></>
                                                )}
                                                {imgload ? (
                                                    <Carousel
                                                        slide={false}
                                                        pause="hover"
                                                        fade={true}
                                                        variant="dark"
                                                        nextLabel="Suivant"
                                                        prevLabel="Précédent"
                                                    >
                                                        {imgload.map((d, index) => {
                                                            return (
                                                                <Carousel.Item interval={5000} key={index}>
                                                                    <div id="carrouDel">
                                                                        <Button
                                                                            variant="info"
                                                                            size="sm"
                                                                            onClick={() => onDeleteImgSlide(index, d)}
                                                                        >
                                                                            <Trash size={30} color="red"></Trash>
                                                                        </Button>
                                                                    </div>
                                                                    <img
                                                                        className="d-block w-100"
                                                                        src={
                                                                            d.link.indexOf('sortly') === -1
                                                                                ? common.ipapi + '/public/' + d.link
                                                                                : d.link
                                                                        }
                                                                        alt="First slide"
                                                                    />
                                                                </Carousel.Item>
                                                            );
                                                        })}
                                                    </Carousel>
                                                ) : (
                                                    <></>
                                                )}
                                            </Row>
                                        </Col>
                                    </Row>
                                </div>
                            </Modal.Body>

                            <Modal.Footer>
                                <div className="ml-auto">
                                    <ButtonGroup size="sm">
                                        <Button onClick={handleClose}>
                                            <Backspace color="red" size={20} />
                                        </Button>
                                        <Button type="submit" color="warning">
                                            <Save2Fill color="#807f7e" size={20} /> Enregister
                                        </Button>
                                    </ButtonGroup>
                                </div>
                            </Modal.Footer>
                        </Form>
                    </Modal>
                )}
            </Formik>
        </>
    );
}

export default AddArticle;
