import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { ButtonGroup, Container, Dropdown, Nav, Navbar, OverlayTrigger, Tooltip } from 'react-bootstrap';
import {
    Eye,
    Trash,
    PencilFill,
    FileExcel,
    PlusCircleDotted,
    FolderX,
    Printer,
    Stars,
    FileEarmarkExcel,
    FileEarmarkMedical,
    FileEaselFill,
    Download,
    BoxArrowDownLeft,
    CartDash,
    FileEarmarkLock,
    CashCoin
} from 'react-bootstrap-icons';

import ReactDataGrid from '@inovua/reactdatagrid-community';
import '@inovua/reactdatagrid-community/index.css';

import XLSX from 'sheetjs-style';
import { jsPDF } from 'jspdf';
import 'jspdf-autotable';
import 'assets/css/table.css';
import dateformat from 'dateformat';
import common from '../../commonData';

function MyAwesomeTable({
    rows,
    isLoading,
    onDelete,
    setIds,
    setIdViews,
    min,
    zero,
    title,
    setAdds,
    getMin,
    getEmpty,
    setShow,
    setShowFilterModals,
    getArticle
}) {
    const [gridRef, setGridRef] = useState(null);

    const filterValue = [
        { name: 'qrbarre', operator: 'contains', type: 'string', value: '' },
        { name: 'qrbarreshort', operator: 'contains', type: 'string', value: '' },
        { name: 'descriptionart', operator: 'contains', type: 'string', value: '' },
        {
            name: 'descriptionartfr',
            operator: 'contains',
            type: 'string',
            value: ''
        },
        { name: 'famille', operator: 'contains', type: 'string', value: '' },
        { name: 'marque', operator: 'contains', type: 'string', value: '' },
        { name: 'entrepot', operator: 'contains', type: 'string', value: '' },
        { name: 'emplacement', operator: 'contains', type: 'string', value: '' },
        { name: 'usernames', operator: 'contains', type: 'string', value: '' },
        { name: 'username', operator: 'contains', type: 'string', value: '' },
        { name: 'qtestock', operator: 'gte', type: 'number', value: 0 }
    ];

    function numberformat(data) {
        return new Intl.NumberFormat().format(data);
    }

    const columns = [
        {
            name: 'id',
            header: 'Id',
            defaultVisible: false,
            defaultWidth: 80,
            type: 'number'
        },
        {
            name: 'urlart',
            header: '#',
            defaultWidth: 50,
            type: 'string',
            render: ({ data }) => {
                return (
                    <div>
                        <Link to={`/admin/article/fiche/${data.id}`}>
                            {data.urlart.indexOf('sortly') == -1 ? (
                                <img
                                    className="imgart rounded"
                                    src={data.urlart !== 'neant' ? common.ipapi + '/public' + data.urlart : '/logoz.jpg'}
                                ></img>
                            ) : data.urlart != 'neant' ? (
                                <img className="imgart rounded" src={data.urlart}></img>
                            ) : (
                                <img className="imgart rounded" src={common.defaultimg}></img>
                            )}
                        </Link>

                        <div className="menudrop">
                            <ButtonGroup aria-label="Basic example">
                                <OverlayTrigger overlay={<Tooltip id="tooltip-disabled">Consultation article</Tooltip>}>
                                    <Eye id="actionbtn" onClick={() => setIdViews(data.id)} size={25} color="black" />
                                </OverlayTrigger>
                                <OverlayTrigger overlay={<Tooltip id="tooltip-disabled">Modifier l'article</Tooltip>}>
                                    <PencilFill id="actionbtn" onClick={() => setIds(data.id)} size={25} color="blue" />
                                </OverlayTrigger>
                            </ButtonGroup>
                        </div>
                    </div>
                );
            }
        },
        {
            name: 'qrbarre',
            header: 'Code 1',
            defaultWidth: 140,
            type: 'string',
            textAlign: 'center'
        },
        {
            name: 'qrbarreshort',
            header: 'Code 2',
            defaultWidth: 140,
            type: 'string',
            textAlign: 'center'
        },
        {
            name: 'descriptionart',
            header: 'Descriptions',
            defaultWidth: 200,
            type: 'string'
        },
        {
            name: 'descriptionartfr',
            header: 'Descriptions Fr',
            defaultWidth: 190,
            type: 'string'
        },
        { name: 'famille', header: 'Familles', defaultWidth: 120, type: 'string' },
        {
            name: 'qtestock',
            header: 'Qté',
            defaultWidth: 50,
            textAlign: 'center',
            type: 'number',
            render: ({ value, data }) => (
                <p
                    id={data.qtestock < data.qteseuil ? 'success' : 'light'}
                    style={{ color: data.qtestock < data.qteseuil ? 'white' : 'black' }}
                >
                    {numberformat(value)}
                </p>
            )
        },
        {
            name: 'prixachat',
            header: 'P.achat',
            defaultWidth: 70,
            textAlign: 'center',
            type: 'number',
            render: ({ value }) =>
                //common.search(common.userdroit, 'SAD') === undefined
                common.search(common.userdroit, 'SAD') === undefined
                    ? common.search(common.userdroit, 'VPA') === undefined
                        ? '######'
                        : numberformat(value)
                    : numberformat(value)
            // : numberformat(value)
        },
        {
            name: 'pmp',
            header: 'PMP',
            defaultWidth: 70,
            textAlign: 'center',
            type: 'number',
            render: ({ value }) =>
                //common.search(common.userdroit, 'SAD') === undefined
                common.search(common.userdroit, 'SAD') === undefined
                    ? common.search(common.userdroit, 'VPA') === undefined
                        ? '######'
                        : numberformat(value)
                    : numberformat(value)
            // : numberformat(value)
        },
        {
            name: 'prixvente',
            header: 'P.vente',
            defaultWidth: 70,
            textAlign: 'center',
            type: 'number',
            render: ({ data }) => numberformat(Math.round(data.prixachat * data.coeficient, 0))
        },
        {
            name: 'coeficient',
            header: 'Coéficient',
            textAlign: 'center',
            defaultWidth: 80,
            type: 'number',
            render: ({ value }) =>
                //common.search(common.userdroit, 'SAD') === undefined
                common.search(common.userdroit, 'SAD') === undefined
                    ? common.search(common.userdroit, 'VPA') === undefined
                        ? '######'
                        : numberformat(value)
                    : numberformat(value)
            // : numberformat(value)
        },
        {
            name: 'qteseuil',
            header: 'Qté seuil.',
            textAlign: 'center',
            defaultWidth: 50,
            type: 'number'
        },

        { name: 'marque', header: 'Marques', defaultWidth: 80, type: 'string' },
        { name: 'entrepot', header: 'Entrepots', defaultWidth: 80, type: 'string' },
        {
            name: 'emplacement',
            header: 'Emplacement',
            defaultWidth: 80,
            type: 'string'
        },
        { name: 'username', header: 'Crée par', defaultWidth: 80, type: 'string' },
        {
            name: 'created_at',
            header: 'Crée le',
            defaultWidth: 130,
            type: 'string',
            render: ({ data }) => (data.created_at ? dateformat(data.created_at, 'dd-mm-yyyy HH:MM') : 'néant')
        },
        {
            name: 'usernames',
            header: 'Modif. par',
            defaultWidth: 80,
            type: 'string'
        },
        {
            name: 'updated_at',
            header: 'Modif. le',
            defaultWidth: 130,
            type: 'string',
            render: ({ data }) => (data.updated_at ? dateformat(data.updated_at, 'dd-mm-yyyy HH:MM') : 'néant')
        },
        {
            header: 'Action',
            defaultWidth: 120,
            type: 'string',
            render: ({ value, data }) => (
                <div className="menudrops">
                    <ButtonGroup aria-label="Basic example">
                        <OverlayTrigger overlay={<Tooltip id="tooltip-disabled">Consultation article</Tooltip>}>
                            <Eye id="actionbtn" onClick={() => setIdViews(data.id)} size={22} color="dark" />
                        </OverlayTrigger>
                        <OverlayTrigger overlay={<Tooltip id="tooltip-disabled">Modifier l'article</Tooltip>}>
                            <PencilFill id="actionbtn" onClick={() => setIds(data.id)} size={25} color="green" />
                        </OverlayTrigger>
                        <OverlayTrigger overlay={<Tooltip id="tooltip-disabled">Supprimer l'article</Tooltip>}>
                            <Trash id="actionbtn" onClick={() => onDelete(data.id)} color="red" size={25} />
                        </OverlayTrigger>
                    </ButtonGroup>
                </div>
            )
        }
    ];
    const gridStyle = { minHeight: window.screen.height - 200 };

    function GetSortOrder(prop) {
        return function (a, b) {
            if (a[prop] > b[prop]) {
                return 1;
            } else if (a[prop] < b[prop]) {
                return -1;
            }
            return 0;
        };
    }
    async function printListe() {
        // setLoad(true)
        const data = [];
        const datarecap = [];
        var tablo = [];
        let qte = 0,
            pvente = 0,
            pachat = 0,
            total = 0;
        tablo = gridRef.current.data.sort(GetSortOrder('famille'));
        var len = gridRef.current.data.length;
        var i = 0;
        tablo.forEach((row, index) => {
            pachat += row.prixachat * row.qtestock;
            pvente += row.prixvente * row.qtestock;
            total += row.prixvente * row.qtestock;
            qte += row.qtestock;
            index++;
            // if(famille_id===0){
            if (len > i + 1) {
                if (i === 0) {
                    data.push([{ content: row.famille, colSpan: 8, styles: { fontStyle: 'bold' } }]);
                } else if (tablo[i].famille !== tablo[i - 1].famille) {
                    data.push([{ content: row.famille, colSpan: 9, styles: { fontStyle: 'bold' } }]);
                }
            }
            data.push([
                { content: index },
                { content: row.qrbarre || 'néant' },
                {
                    content: row.descriptionartfr !== 'néant' ? row.descriptionartfr : row.descriptionart
                },
                { content: row.emplacement || 'néant' },
                {
                    content:
                        common.search(common.userdroit, 'SAD') === undefined
                            ? common.search(common.userdroit, 'VPA') === undefined
                                ? '######'
                                : new Intl.NumberFormat('de-DE').format(row.prixachat)
                            : new Intl.NumberFormat('de-DE').format(row.prixachat)
                },
                { content: new Intl.NumberFormat('de-DE').format(row.prixvente) },
                { content: row.qteseuil || '' },
                { content: row.qtestock }
            ]);
            // }else{
            //   data.push(
            //     [index,row.qrbarre, row.descriptionartfr !== 'néant' ? row.descriptionartfr : row.descriptionart.substring(0,60),row.emplacement,new Intl.NumberFormat("de-DE").format(row.prixachat),new Intl.NumberFormat("de-DE").format(row.prixvente),row.qteseuil, row.qtestock]
            //   )
            // }
            i++;
        });
        datarecap.push([
            new Intl.NumberFormat('de-DE').format(qte),
            new Intl.NumberFormat('de-DE').format(pachat),
            new Intl.NumberFormat('de-DE').format(pvente)
        ]);
        const doc = new jsPDF();
        var width = doc.internal.pageSize.getWidth();

        doc.setFontSize(16);
        common.ipapi == 'https://192.168.8.109:81' || common.ipapi == 'https://api.tracky-entrepot.com'
            ? doc.addImage(common.logo, 'jpg', 10, 6, 30, 30)
            : doc.addImage(common.logo1, 'jpg', 10, 6, 30, 30);
        doc.text(gridRef.current.data.entrepot || 'TRACKYENTREPOT', 90, 14);
        doc.setFontSize(12);
        doc.setFillColor(225, 206, 154);
        doc.rect(40, 15, width - 55, 10, 'F');
        doc.setTextColor(0, 0, 0);
        doc.text('LISTE DES ARTICLES', 50, 22);
        doc.setTextColor(0, 0, 0);
        doc.autoTable({
            styles: {
                fillColor: [255, 255, 255],
                textColor: [0, 0, 0],
                cellPadding: 1,
                lineColor: [0, 0, 0],
                halign: 'center',
                fontSize: 8
            },
            columnStyles: {
                0: { fillColor: [255, 255, 255], textColor: [0, 0, 0], fontSize: 12 },
                1: { fillColor: [255, 255, 255], textColor: [0, 0, 0], fontSize: 12 },
                2: { fillColor: [255, 255, 255], textColor: [0, 0, 0], fontSize: 12 }
            },
            theme: 'grid',
            startY: 30,
            fontSize: 9,
            head: [['QUANTITE STOCK', 'VALEUR STOCK', 'VALEUR VENTE STOCK']],
            body: datarecap
        });
        doc.autoTable({
            styles: {
                fillColor: [255, 255, 255],
                textColor: [0, 0, 0],
                cellPadding: 0.75,
                halign: 'center',
                lineColor: [0, 0, 0],
                fontSize: 9
            },
            columnStyles: {
                0: {
                    halign: 'center',
                    fillColor: [255, 255, 255],
                    textColor: [0, 0, 0],
                    fontSize: 8
                },
                1: {
                    halign: 'left',
                    fillColor: [255, 255, 255],
                    textColor: [0, 0, 0],
                    fontSize: 8
                },
                2: {
                    halign: 'left',
                    fillColor: [255, 255, 255],
                    textColor: [0, 0, 0],
                    fontSize: 8
                },
                3: {
                    halign: 'left',
                    fillColor: [255, 255, 255],
                    textColor: [0, 0, 0],
                    fontSize: 8
                },
                4: {
                    halign: 'center',
                    fillColor: [255, 255, 255],
                    textColor: [0, 0, 0],
                    fontSize: 8
                },
                5: {
                    halign: 'center',
                    fillColor: [255, 255, 255],
                    textColor: [0, 0, 0],
                    fontSize: 8
                },
                6: {
                    halign: 'center',
                    fillColor: [255, 255, 255],
                    textColor: [0, 0, 0],
                    fontSize: 8
                },
                7: {
                    halign: 'center',
                    fillColor: [255, 255, 255],
                    textColor: [0, 0, 0],
                    fontSize: 8
                }
            },
            theme: 'grid',
            startY: doc.lastAutoTable.finalY + 4,
            fontSize: 9,
            head: [['#', 'Code', 'Désignation', 'Localisation', 'Prix achat', 'Prix vente', 'Qté Min', 'Qté']],
            body: data
        });
        const pageCount = doc.internal.getNumberOfPages();

        for (var i = 1; i <= pageCount; i++) {
            doc.setPage(i);
            doc.setFontSize(10);
            doc.text('Page ' + String(i) + ' sur ' + String(pageCount), 210 - 18, 292, null, null, 'right');
        }
        doc.save('article.pdf');
    }

    //--------------- EXPORT VERS EXCEL ------------------

    const exportToExcel = async () => {
        const raw_data = gridRef.current.data.sort(GetSortOrder('famille'));
        /* flatten objects */
        const rows = raw_data.map((row, index) => ({
            ordre: index,
            qrbarreshort: row.qrbarre || 'Néant',
            descriptionart: row.descriptionartfr !== 'néant' ? row.descriptionartfr : row.descriptionart,
            marque: row.marque || 'Néant',
            famille: row.famille || 'Néant',
            emplacement: row.emplacement,
            prixachat:
                common.search(common.userdroit, 'SAD') === undefined
                    ? common.search(common.userdroit, 'VPA') === undefined
                        ? '######'
                        : parseInt(row.prixachat)
                    : parseInt(row.prixachat),
            prix: parseInt(row.prixvente),
            qteseuil: row.qteseuil,
            qtestock: row.qtestock
        }));

        /* generate worksheet and workbook */
        const worksheet = XLSX.utils.json_to_sheet(rows);
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, 'Mon stock');
        /* fix headers */
        XLSX.utils.sheet_add_aoa(
            worksheet,
            [['Ordre', 'Code', 'Articles', 'Marques', 'Familles', 'Localisation', 'Prix achat', 'Prix vente', 'Qté min', 'Quantité']],
            { origin: 'A1' }
        );
        /* calculate column width */
        const max_width = rows.reduce((w, r) => Math.max(w, r.qrbarreshort.length), 10);
        worksheet['!cols'] = [{ wch: max_width }];
        /* create an XLSX file and try to save to Presidents.xlsx */
        XLSX.writeFile(workbook, 'stock.xlsx');
    };

    async function saveAsExcel() {
        exportToExcel();
    }

    return (
        <div>
            <Navbar expand="lg" bg="light" variant="light">
                <Container>
                    <Navbar.Brand href="#/admin/articles">{title}</Navbar.Brand>
                    <Nav className="me-auto">
                        <OverlayTrigger placement="top" overlay={<Tooltip id="tooltip-disabled">Ajouter un nouvel article</Tooltip>}>
                            {setAdds !== undefined ? (
                                <Nav.Link onClick={() => setAdds()} title="Ajouter un nouvel article">
                                    <PlusCircleDotted color="orange" size={25} /> Ajouter article
                                </Nav.Link>
                            ) : (
                                <></>
                            )}
                        </OverlayTrigger>
                        <OverlayTrigger placement="top" overlay={<Tooltip id="tooltip-disabled">Exporter vers Excel</Tooltip>}>
                            <Nav.Link title="Exporter vers excel" onClick={() => saveAsExcel()}>
                                <FileExcel color="#088d5a" size={25} /> Excel
                            </Nav.Link>
                        </OverlayTrigger>
                        <OverlayTrigger placement="top" overlay={<Tooltip id="tooltip-disabled">Exporter vers pdf</Tooltip>}>
                            <Nav.Link onClick={() => printListe()} title="Exporter vers pdf">
                                <Printer color="#0080ff" size={25} /> Imprimer
                            </Nav.Link>
                        </OverlayTrigger>
                        <OverlayTrigger placement="top" overlay={<Tooltip id="tooltip-disabled">Importer des articles</Tooltip>}>
                            <Nav.Link onClick={() => setShow(true)} title="Importer">
                                <Download color="cadetblue" size={25} /> Importer
                            </Nav.Link>
                        </OverlayTrigger>
                    </Nav>
                    <Nav>
                        {min != 0 ? (
                            <OverlayTrigger
                                placement="top"
                                overlay={
                                    <Tooltip id="tooltip-disabled">{min} Article (s) sont égals ou en dessous de la quantité seuil</Tooltip>
                                }
                            >
                                <Nav.Link onClick={() => getMin('<= QUANTITE SEUIL (MINIMALE)')}>
                                    <BoxArrowDownLeft color="red" size={25} /> {min}
                                </Nav.Link>
                            </OverlayTrigger>
                        ) : (
                            <></>
                        )}

                        {zero != 0 ? (
                            <OverlayTrigger
                                placement="top"
                                overlay={<Tooltip id="tooltip-disabled"> {zero} Article (s) sont finis en stock</Tooltip>}
                            >
                                <Nav.Link onClick={() => getEmpty('ARTICLE QUANTITE (0)')}>
                                    <CartDash color="red" size={25} /> {zero}
                                </Nav.Link>
                            </OverlayTrigger>
                        ) : (
                            <></>
                        )}
                    </Nav>
                    <Nav>
                        <Dropdown drop="left">
                            <Dropdown.Toggle variant="success" id="dropdown-basic">
                                Plus d'options
                            </Dropdown.Toggle>
                            <Dropdown.Menu align="end">
                                <Dropdown.Item onClick={() => getMin('<= QUANTITE SEUIL (MINIMALE)')}>
                                    <Stars color="red" size={25} /> Article au niveau seuil
                                </Dropdown.Item>
                                <Dropdown.Item onClick={() => getEmpty('ARTICLE QUANTITE (0)')}>
                                    <FolderX color="#000" size={25} /> Article fini en stock{' '}
                                </Dropdown.Item>
                                <Dropdown.Item href="#/admin/articles-hs">
                                    <FileEarmarkExcel color="#000" size={25} /> Articles endommagés
                                </Dropdown.Item>
                                <Dropdown.Item href="#/admin/articles-lost">
                                    <FileEarmarkMedical color="#000" size={25} /> Articles perdus
                                </Dropdown.Item>
                                <Dropdown.Divider />
                                <Dropdown.Item onClick={() => getArticle(0)}>
                                    <FileEarmarkLock color="#000" size={25} /> Articles désactivés
                                </Dropdown.Item>
                                <Dropdown.Divider />
                                <Dropdown.Item href="#/admin/articles-valeur-stock">
                                    <CashCoin color="#000" size={25} /> Valeur du stock
                                </Dropdown.Item>
                                <Dropdown.Divider />
                                <Dropdown.Item onClick={() => setShowFilterModals(true)}>
                                    <FileEaselFill color="#000" size={25} /> Stock list analysis
                                </Dropdown.Item>
                            </Dropdown.Menu>
                        </Dropdown>
                    </Nav>
                </Container>
            </Navbar>
            <ReactDataGrid
                idProperty="id"
                handle={setGridRef}
                style={gridStyle}
                loading={isLoading}
                reorderColumns={false}
                pagination
                pageSizes={[200, 400, 600, 800, rows.length]}
                defaultLimit={400}
                showHoverRows={true}
                editable={true}
                showColumnMenuTool={false}
                defaultFilterValue={filterValue}
                columns={columns}
                dataSource={rows}
            />
        </div>
    );
}

export default MyAwesomeTable;
