import React, { useEffect, useState, useRef } from 'react';
import { X } from 'react-bootstrap-icons';

export function ProductList({ products, onRemoveProduct }) {
    return (
        <table className="table table-striped-hover table-sm">
            <thead>
                <tr>
                    <th scope="col">Ordre</th>
                    <th scope="col">CODE</th>
                    <th scope="col">DESIGNATION</th>
                    <th scope="col">QUANTITE</th>
                    <th scope="col">MARQUES</th>
                    <th scope="col">MODELS</th>
                    <th scope="col">#</th>
                </tr>
            </thead>
            <tbody>
                {products.map((product, index) => {
                    return (
                        <tr key={index} hover="true">
                            <td>{index + 1}</td>
                            <td>{product.qrbarre}</td>
                            <td>{product.descriptionart}</td>
                            <td>{product.quantite}</td>
                            <td>{product.model}</td>
                            <td>{product.marque}</td>
                            <td>
                                <X color="red" size={20} onClick={() => onRemoveProduct(index, product.id)}></X>
                            </td>
                        </tr>
                    );
                })}
            </tbody>
        </table>
    );
}

export function Summary({ subTotal, sitva }) {
    //return  mttc;
    var total = subTotal;
    const ht = total / (1 + 0.18);
    const tva = total - ht;
    total = sitva == 1 ? total - tva : total;
    return (
        <p id="lightr">
            Total HT: <b>{formatCurrency(ht)} </b>|| Total TVA {sitva == 1 ? 'exonéré' : ''}: <b>{formatCurrency(tva)} </b>|| Total TTC:{' '}
            <b>{formatCurrency(total)}</b>
        </p>
    );
}
function formatCurrency(value) {
    return new Intl.NumberFormat().format(Math.round(value));
}
