import { useHistory, useParams, Link } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import Axios from 'axios';
import dateformat from 'dateformat';
import { Spinner, Modal, Toast, InputGroup, Form, Dropdown, Breadcrumb } from 'react-bootstrap';
import { Button, Card, CardBody, Row, Col, CardTitle, CardHeader } from 'reactstrap';
import {
    Trash,
    PencilFill,
    Tools,
    Sliders,
    EyeFill,
    Backspace,
    ListCheck,
    FileEarmarkExcel,
    PencilSquare,
    Eye,
    Printer
} from 'react-bootstrap-icons';
import { saveAs } from 'file-saver';
// import XlsxPopulate from "xlsx-populate";
import { jsPDF } from 'jspdf';
import 'jspdf-autotable';
import commondata from '../../commonData';
import { MyAwesomeTable } from '../../tableDataGar';
import { dateDiff } from './functions';

function DetailClients() {
    const history = useHistory();
    const [datas, setData] = useState([]);
    const [datal, setDatal] = useState([]);
    const [shows, setFactView] = useState(false);
    const [datafact, setDataFacture] = useState([]);
    const [load, setLoad] = useState(true);
    const [msg, setMsg] = useState('');
    const [msgGravity, setMsgravity] = useState('success');
    const [ids, setId] = useState(0);
    const [client, setClient] = useState('');
    const [datesort, setDatesort] = useState('');
    const [nofact, setFact] = useState(0);
    const [showView, setShowView] = useState(false);
    const [isSave, setIsSave] = useState(false);
    const [tfacture, setTfacture] = useState(0);
    const [tReglement, setTreglement] = useState(0);
    const [chaffs, setChaff] = useState(0);
    const [facturee, setFacturee] = useState(0);
    let { id } = useParams();

    useEffect(() => {
        if (localStorage.getItem('user-info')) {
            (async function anyNameFunction() {
                await getDetail();
            })();
        } else {
            history.push('/login');
        }
    }, [history]);

    async function getDetail() {
        Axios.get(commondata.ipapi + '/api/client/' + id + '/contents')
            .then((response) => {
                var tab = [];
                // const initialValue = 0;
                // const sumWithInitial = array1.reduce((accumulator, currentValue) => accumulator + currentValue.prixvente, initialValue);
                // setTfacture(sumWithInitial);
                response.data[0].map((row) => {
                    setClient(row.client ? row.client.raisonsocial : 'néant');
                    tab.push({
                        id: row.id,
                        nofact: row.nofact || 'neant',
                        noprof: row.noprof || 'neant',
                        client: row.client ? row.client.raisonsocial : 'néant',
                        remise: row.client.remise !== null ? row.client.remise.pourcentage : 0,
                        client_id: row.client_id || null,
                        sitva: row.client ? row.client.sitva : null,
                        reglement: row.reglement,
                        ligne: row.lignefacture,
                        immatr_eng: row.engin || 'néant',
                        created_at: dateformat(row.created_at, 'dd-mm-yyyy'),
                        datefact: dateformat(row.datefact, 'dd-mm-yyyy'),
                        dateprof: dateformat(row.dateprof, 'dd-mm-yyyy'),
                        user: row.user.name
                    });
                });
                setDataFacture(tab);

                var tabrecep = [];
                response.data[1].map((row) => {
                    tabrecep.push({
                        id: row.id,
                        proforma: row.lignesort,
                        datesort: dateformat(row.datesort, 'dd-mm-yyyy'),
                        created_at: dateformat(row.created_at, 'dd-mm-yyyy'),
                        nobon: row.numbon,
                        user: row.user.name,
                        engin: row.engin || 'néant',
                        demande_par: row.demande_par,
                        client: row.client ? row.client.raisonsocial : 'néant'
                    });
                });
                setData(tabrecep);
                setLoad(false);
            })
            .catch((errors) => {
                setLoad(false);
            });
    }

    const prixttc = ({ data }) => {
        var prix = 0;
        var remis = 0;
        if (data.ligne.length > 0) {
            data.ligne.forEach((element) => {
                data.remise !== 0
                    ? (remis = element.pivot.prix * element.pivot.quantite * (data.remise / 100))
                    : (remis = element.pivot.prix * element.pivot.quantite * (element.pivot.remise / 100));
                prix += element.pivot.prix * element.pivot.quantite - remis;
            });
        }
        return new Intl.NumberFormat().format(Math.round(prix + prix * 0.18));
    };

    const reglement = ({ data }) => {
        var regl = 0;
        if (data.reglement.length > 0) {
            data.reglement.forEach((element) => {
                regl += element.montant;
            });
        }
        return new Intl.NumberFormat().format(regl);
    };
    const reste = ({ data }) => {
        var regl = 0;
        if (data.reglement.length > 0) {
            data.reglement.forEach((element) => {
                regl += element.montant;
            });
        }
        return new Intl.NumberFormat().format(data.prix_ttc - regl) || 0;
    };
    const Operations = ({ data }) => {
        return <EyeFill color="royalblue" size={23} onClick={(e) => viewSortie(data.id)} />;
    };

    const columnsFact = [
        {
            id: 1,
            label: 'Clients',
            field: 'client'
        },
        {
            id: 2,
            label: 'N° facture',
            field: 'nofact',
            width: '100px'
        },
        {
            id: 3,
            label: 'Date fact.',
            field: 'datefact',
            width: '150px'
        },
        {
            id: 4,
            label: 'N° proforma',
            field: 'noprof',
            width: '100px'
        },

        {
            id: 5,
            label: 'Date prof.',
            field: 'dateprof',
            width: '150px'
        },

        {
            id: 6,
            label: 'Prix TTC',
            field: 'prix_ttc',
            cellRenderer: prixttc,
            width: '130px'
        },
        {
            id: 7,
            label: 'Mt payé',
            cellRenderer: reglement,
            width: '130px'
        },
        {
            id: 8,
            label: 'Reste à payer',
            //cellRenderer: reste,
            width: '130px'
        },
        {
            id: 9,
            field: 'user',
            label: 'Créer par'
        },
        {
            id: 10,
            field: 'created_at',
            label: 'Créer le'
        },
        {
            id: 11,
            label: 'Actions',
            cellRenderer: Operations,
            width: '40px',
            pinned: true,
            minResizeWidth: 20
        },
        {
            id: 12,
            field: 'remise',
            label: 'Créer le'
        }
    ];

    function viewSortie(idl) {
        setLoad(true);
        setFactView(true);
        Axios.get(commondata.ipapi + '/api/receptionfact/edit/' + idl).then((response) => {
            setDatal(response.data.ligne);
            setFact(response.data.nofact == 0 ? response.data.noprof : response.data.nofact);
            setClient(response.data.client.raisonsocial);
            setDatesort(
                response.data.datefact == null
                    ? dateformat(response.data.dateprof, 'yyyy-mm-dd')
                    : dateformat(response.data.datefact, 'yyyy-mm-dd')
            );

            setLoad(false);
        });
    }
    function setIds(id) {
        setId(id);
    }
    function setView(bool, id) {
        setId(id);
        setShowView(bool);
    }
    const handleClose = () => {
        setFactView(false);
    };

    function onDelete(id) {
        if (window.confirm('Voulez-vous supprimer ce client ?')) {
            setLoad(true);
            Axios.delete(commondata.ipapi + '/api/client/destroy/' + id).then((response) => {
                if (response.data.status == 1) {
                    Axios.get(commondata.ipapi + '/api/client/index')
                        .then((response) => {
                            setData(response.data.data);
                            setLoad(false);
                        })
                        .catch((errors) => {
                            setLoad(false);
                        });
                } else {
                    setMsg(response.data.msg);
                    setMsgravity('danger');
                    setIsSave(true);
                    setLoad(false);
                }
            });
        }
    }

    const Operation = ({ tableManager, value, field, data, column, colIndex, rowIndex }) => {
        return (
            <Dropdown>
                <Dropdown.Toggle variant="success" key={data.id} drop="up" id="dropdown-basic"></Dropdown.Toggle>
                <Dropdown.Menu>
                    {data.facture != null ? (
                        data.facture.nofact == 0 ? (
                            <Dropdown.Item onClick={(e) => viewSortie(data.id, 2)}>
                                <FileEarmarkExcel color="#000" size={20} /> Facturer ligne
                            </Dropdown.Item>
                        ) : (
                            <></>
                        )
                    ) : (
                        <Dropdown.Item onClick={(e) => viewSortie(data.id, 2)}>
                            <FileEarmarkExcel color="#000" size={20} /> Facturer ligne
                        </Dropdown.Item>
                    )}
                    <Dropdown.Item onClick={(e) => viewSortie(data.id, 1)}>
                        <Eye color="#000" size={23} /> Voir détails
                    </Dropdown.Item>
                    <Dropdown.Item onClick={() => viewSortie(data.id)}>
                        <Printer color="#000" size={20} /> Imprimer BL
                    </Dropdown.Item>
                    {data.facture != null ? (
                        data.facture.nofact == null ? (
                            <Dropdown.Item onClick={() => setIds(data.id)}>
                                <PencilSquare size={20} color="#000" /> Modifier ligne
                            </Dropdown.Item>
                        ) : (
                            <></>
                        )
                    ) : (
                        <Dropdown.Item onClick={() => setIds(data.id)}>
                            <PencilSquare size={20} color="#000" /> Modifier ligne
                        </Dropdown.Item>
                    )}
                    {data.facture != null ? (
                        data.facture.nofact == null ? (
                            <Dropdown.Item href="#/admin/sorties" onClick={() => onDelete(data.id)}>
                                <Trash color="red" size={20} /> Supprimer ligne
                            </Dropdown.Item>
                        ) : (
                            <></>
                        )
                    ) : (
                        <Dropdown.Item href="#/admin/sorties" onClick={() => onDelete(data.id)}>
                            <Trash color="red" size={20} /> Supprimer ligne
                        </Dropdown.Item>
                    )}
                </Dropdown.Menu>
            </Dropdown>
        );
    };

    const columnsrecep = [
        {
            id: 1,
            label: 'N° bon',
            field: 'nobon',
            width: '110px',
            pinned: true
        },
        {
            id: 2,
            label: 'Clients',
            field: 'client'
        },
        {
            id: 3,
            field: 'datesort'
        },
        {
            id: 4,
            field: 'demande_par',
            label: 'Demandeur (auth)'
        },
        {
            id: 5,
            field: 'engin',
            label: 'Engin',
            width: '130px'
        },
        {
            id: 6,
            field: 'user',
            label: 'Créer par',
            width: '110px'
        },
        {
            id: 7,
            field: 'created_at',
            label: 'Créer le'
        },
        {
            id: 8,
            label: 'Actions',
            cellRenderer: Operation,
            pinned: true,
            width: '40px',
            minResizeWidth: 20
        }
    ];

    // ===================EXPORT VERS EXCEL DE LA LISTE CLIENTS ===============================

    function getSheetData(data, header) {
        var fields = Object.keys(data[0]);
        var sheetData = data.map(function (row) {
            return fields.map(function (fieldName) {
                return row[fieldName] ? row[fieldName] : '';
            });
        });
        sheetData.unshift(header);
        return sheetData;
    }
    async function saveAsExcel() {
        // if(datas.length<=0){
        //   setMsg("Aucune donnée à imprimer")
        //   setIsSave(true)
        //   setMsgravity("danger")
        //   setLoad(false)
        //   return
        // }
        // const data = [];
        // datas.forEach((row) => {
        //     data.push(
        //       { code: row.codecli, client: row.raisonsocial, telcli: row.telcli,sisociete: row.sisociete, bp: row.bp, sitva: row.sitva,remise: row.remise ? row.remise.pourcentage : 0, typereglement: row.typereglement }
        //     )
        // })
        // let header = ["Code", "Noms", "Contacts", "Type", "Boite postal", "Si exoneré","Remise %","Type règlement"];
        // XlsxPopulate.fromBlankAsync().then(async (workbook) => {
        //   const sheet1 = workbook.sheet(0);
        //   const sheetData = getSheetData(data, header);
        //   const totalColumns = sheetData[0].length;
        //   sheet1.cell("A1").value(sheetData);
        //   const range = sheet1.usedRange();
        //   const endColumn = String.fromCharCode(64 + totalColumns);
        //   sheet1.row(1).style("bold", true);
        //   sheet1.range("A1:" + endColumn + "1").style("fill", "BFBFBF");
        //   range.style("border", true);
        //   return workbook.outputAsync().then((res) => {
        //     saveAs(res, "Listeclient.xlsx");
        //   });
        // });
    }

    // ====================== EXPORT PDF =============================

    async function printListe() {
        if (datas.length <= 0) {
            setMsg('Aucune donnée à imprimer');
            setIsSave(true);
            setMsgravity('danger');
            setLoad(false);
            return;
        }
        const doc = new jsPDF({
            orientation: 'l',
            unit: 'mm',
            format: 'a4',
            putOnlyUsedFonts: true
        });
        var width = doc.internal.pageSize.getWidth();
        const data = [];
        let mont = 0;
        let qte = 0;
        doc.addImage(commondata.logo, 'jpg', 14, 10, 30, 30);
        doc.setFontSize(16);
        doc.text('CLUB AUTO ENTREPOT', 90, 14);
        doc.setFontSize(12);
        doc.setFillColor(0, 0, 0);
        doc.rect(65, 15, width - 80, 10, 'F');
        doc.setTextColor(255, 255, 255);
        doc.text('LISTE DES CLIENTS', 90, 22);
        doc.setTextColor(0, 0, 0);

        datas.forEach((row) => {
            data.push([
                row.codecli,
                row.raisonsocial,
                row.telcli,
                row.sisociete,
                row.bp,
                row.sitva,
                row.remise ? row.remise.pourcentage : 0,
                row.typereglement
            ]);
        });
        doc.autoTable({
            styles: {
                fillColor: [0, 0, 0],
                textColor: [255, 255, 255],
                halign: 'center',
                fontSize: 10
            },
            columnStyles: {
                0: {
                    halign: 'center',
                    fillColor: [255, 255, 255],
                    textColor: [0, 0, 0],
                    fontSize: 8
                },
                1: {
                    halign: 'center',
                    fillColor: [255, 255, 255],
                    textColor: [0, 0, 0],
                    fontSize: 8
                },
                2: {
                    halign: 'center',
                    fillColor: [255, 255, 255],
                    textColor: [0, 0, 0],
                    fontSize: 8
                },
                3: {
                    halign: 'center',
                    fillColor: [255, 255, 255],
                    textColor: [0, 0, 0],
                    fontSize: 8
                },
                4: {
                    halign: 'center',
                    fillColor: [255, 255, 255],
                    textColor: [0, 0, 0],
                    fontSize: 8
                },
                5: {
                    halign: 'center',
                    fillColor: [255, 255, 255],
                    textColor: [0, 0, 0],
                    fontSize: 8
                },
                6: {
                    halign: 'center',
                    fillColor: [255, 255, 255],
                    textColor: [0, 0, 0],
                    fontSize: 8
                },
                7: {
                    halign: 'center',
                    fillColor: [255, 255, 255],
                    textColor: [0, 0, 0],
                    fontSize: 8
                }
            },
            theme: 'grid',
            startY: 35,
            fontSize: 6,
            head: [['Code', 'Noms', 'Contacts', 'Type', 'Boite postal', 'Si exoneré', 'Remise %', 'Type règlement']],
            body: data
        });
        const pageCount = doc.internal.getNumberOfPages();
        for (var i = 1; i <= pageCount; i++) {
            doc.setPage(i);
            doc.setFontSize(10);
            doc.text('Page ' + String(i) + ' sur ' + String(pageCount), 210 - 55, 200, null, null, 'right');
        }
        doc.save('Listeclient.pdf');
    }
    return (
        <>
            <Modal show={load} size="sm" aria-labelledby="contained-modal-title-vcenter" centered>
                <Modal.Body>
                    Veuillez patienter.......
                    <Spinner animation="grow" variant="warning" />
                </Modal.Body>
            </Modal>
            <Toast id="toast" onClose={() => setIsSave(false)} show={isSave} delay={3000} bg={msgGravity} autohide>
                <Toast.Header>
                    <img src="holder.js/20x20?text=%20" className="rounded me-2" alt="" />
                    <strong className="me-auto">Info</strong>
                </Toast.Header>
                <Toast.Body>{msg}</Toast.Body>
            </Toast>
            <div className="content contents">
                <h4>{client}</h4>

                <Row>
                    <Col md="12">
                        <Card sx={{ overflow: 'hidden' }} color="dark" text="light">
                            <CardBody>
                                <Row>
                                    {' '}
                                    <Col lg="3" md="6" sm="6">
                                        <Card className="card-stats" title="Cliquer pour plus de détail">
                                            <CardBody>
                                                <Row>
                                                    <Col md="4" xs="5">
                                                        <div className="icon-big text-center icon-warning">
                                                            <i className="nc-icon nc-bus-front-12 text-warning" />
                                                        </div>
                                                    </Col>
                                                    <Col md="8" xs="7">
                                                        <div className="numbers">
                                                            <p className="card-category">Total sortie pièces</p>
                                                            <CardTitle tag="p">{datas.length}</CardTitle>
                                                            <p />
                                                        </div>
                                                    </Col>
                                                </Row>
                                            </CardBody>
                                        </Card>
                                    </Col>
                                    <Col lg="3" md="6" sm="6">
                                        <Card className="card-stats" title="Cliquer pour plus de détail">
                                            <CardBody>
                                                <Row>
                                                    <Col md="4" xs="5">
                                                        <div className="icon-big text-center icon-warning">
                                                            <i className="nc-icon nc-paper text-success" />
                                                        </div>
                                                    </Col>
                                                    <Col md="8" xs="7">
                                                        <div className="numbers">
                                                            <p className="card-category">Total devis/facture</p>
                                                            <CardTitle tag="p">{datafact.length + '/' + facturee}</CardTitle>
                                                            <p />
                                                        </div>
                                                    </Col>
                                                </Row>
                                            </CardBody>
                                        </Card>
                                    </Col>
                                    <Col lg="3" md="6" sm="6">
                                        <Card className="card-stats" title="Cliquer pour plus de détail">
                                            <CardBody>
                                                <Row>
                                                    <Col md="4" xs="5">
                                                        <div className="icon-big text-center icon-warning">
                                                            <i className="nc-icon nc-money-coins text-danger" />
                                                        </div>
                                                    </Col>
                                                    <Col md="8" xs="7">
                                                        <div className="numbers">
                                                            <p className="card-category">Total factuée</p>
                                                            <CardTitle tag="p">{chaffs}</CardTitle>
                                                            <p />
                                                        </div>
                                                    </Col>
                                                </Row>
                                            </CardBody>
                                        </Card>
                                    </Col>
                                    <Col lg="3" md="6" sm="6">
                                        <Card className="card-stats" title="Cliquer pour plus de détail">
                                            <CardBody>
                                                <Row>
                                                    <Col md="4" xs="5">
                                                        <div className="icon-big text-center icon-warning">
                                                            <i className="nc-icon nc-delivery-fast text-primary" />
                                                        </div>
                                                    </Col>
                                                    <Col md="8" xs="7">
                                                        <div className="numbers">
                                                            <p className="card-category">Total reglement</p>
                                                            <CardTitle tag="p">{}</CardTitle>
                                                            <p />
                                                        </div>
                                                    </Col>
                                                </Row>
                                            </CardBody>
                                        </Card>
                                    </Col>
                                </Row>
                            </CardBody>
                        </Card>
                    </Col>

                    <Col md={6}>
                        <div id="tablecontent">
                            <Tools color="blue" size={45} /> HISTORIQUE DES SORTIES DE PIECES
                            <MyAwesomeTable columns={columnsrecep} rows={datas} isLoading={load} />
                        </div>
                    </Col>
                    <Col md={6}>
                        <div id="tablecontent">
                            <ListCheck color="orange" size={45} /> LISTE DES FACTURES FACTURES
                            <MyAwesomeTable columns={columnsFact} rows={datafact} isLoading={load} />
                        </div>
                    </Col>
                </Row>
            </div>

            <Modal show={shows} onHide={handleClose} size="lg">
                <Form noValidate>
                    <Modal.Header closeButton>
                        <Modal.Title>Info facture client</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Card>
                            <CardBody>
                                <Form.Group md="12" controlId="validationCustomUsername">
                                    <InputGroup hasValidation>
                                        <InputGroup.Text id="inputGroupPrepend">N° facture:</InputGroup.Text>
                                        <Form.Control
                                            type="text"
                                            placeholder="N° facture"
                                            aria-describedby="inputGroupPrepend"
                                            disabled
                                            size="sm"
                                            value={nofact}
                                        />
                                    </InputGroup>
                                </Form.Group>
                                <Form.Group md="12" controlId="validationCustomUsername">
                                    <InputGroup hasValidation>
                                        <InputGroup.Text id="inputGroupPrepend">Client :</InputGroup.Text>
                                        <Form.Control type="text" aria-describedby="inputGroupPrepend" disabled size="sm" value={client} />
                                    </InputGroup>
                                </Form.Group>
                                <Form.Group md="12" controlId="validationCustomUsername">
                                    <InputGroup hasValidation>
                                        <InputGroup.Text id="inputGroupPrepend">Date facture:</InputGroup.Text>
                                        <Form.Control
                                            type="date"
                                            placeholder="Date sortie"
                                            aria-describedby="inputGroupPrepend"
                                            disabled
                                            size="sm"
                                            value={datesort}
                                        />
                                    </InputGroup>
                                </Form.Group>
                            </CardBody>
                        </Card>

                        <table className="table table-striped-hover table-sm">
                            <thead>
                                <tr>
                                    <th scope="col">Descr. art.</th>
                                    <th scope="col">Qté</th>
                                    <th scope="col">P. unitaire</th>
                                    <th scope="col">Si - tva</th>
                                    <th scope="col">P. HT</th>
                                    <th scope="col">P. TTC</th>
                                </tr>
                            </thead>
                            <tbody>
                                {datal.map((d, index) => {
                                    return (
                                        <tr key={index} hover="true">
                                            <td>{d.libprestation}</td>
                                            <td>{d.qte}</td>
                                            <td>
                                                {new Intl.NumberFormat('fr-FR', {
                                                    style: 'currency',
                                                    currency: 'CFA'
                                                }).format(d.prixprestation)}
                                            </td>
                                            <td>{d.tva}</td>
                                            <td>
                                                {new Intl.NumberFormat('fr-FR', {
                                                    style: 'currency',
                                                    currency: 'CFA'
                                                }).format(d.qte * d.prixprestation)}
                                            </td>
                                            <td>
                                                {new Intl.NumberFormat('fr-FR', {
                                                    style: 'currency',
                                                    currency: 'CFA'
                                                }).format(d.qte * d.prixprestation * 0.18 + d.qte * d.prixprestation)}
                                            </td>
                                        </tr>
                                    );
                                })}
                            </tbody>
                        </table>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button type="reset" variant="ligth" onClick={handleClose}>
                            <Backspace color="red" size={20} />
                        </Button>
                    </Modal.Footer>
                </Form>
            </Modal>
        </>
    );
}

export default DetailClients;
