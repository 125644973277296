import React,{useEffect,useState,useRef} from 'react';
import {Form,InputGroup,Modal, Spinner,ButtonGroup,ToggleButton} from "react-bootstrap";
import { Button, Row, Col, Label} from "reactstrap";
import Axios from 'axios';
import {useHistory} from 'react-router-dom'
import {  Backspace, Folder, Images, Save2Fill, Star, Trash} from 'react-bootstrap-icons';
import 'react-bootstrap-typeahead/css/Typeahead.css';
import common from '../../commonData';
import SelectFolder from './folderselect';
import { Typeahead } from 'react-bootstrap-typeahead';

var count = 0;

function AddFolder({setShowAddFolder,show,setIsSave,isSave,setDatas,setMsg,setMsgravity}) {
    const [validated, setValidated] = useState(false);
    const [active, setActive] = useState('1');
    const [idParent,setsIdFolder] = useState('')
    const [id,setIdFolder] = useState(null)
    const [folder,setFolder]=useState([])
    const [imgsTf, setImageTf] =useState("")
    const [imgs, setImage] =useState('')
    const [load,setLoad]=useState(false)
    const [file,setFile]=useState("")
    const [remarque,setRemarque]=useState("")
    const [libellefam,setLibellefam]=useState("")
    const [folderSelect,setFolderSelect]=useState("")
    const [showselectFolder,setShowSelectFolder]=useState(false)
    const [marque_id,setMarqueId]=useState('')
    const [marque,setMarque]=useState([])
    const history = useHistory();
    const typeaheadmarref = useRef(null)
    useEffect( ()=>{
        if (!localStorage.getItem("user-info")) {
          history.push("/login")
        }
      (async function anyNameFunction() {
        setLoad(true)
        const getfam = Axios.get(common.ipapi+'/api/famille/sid')
        const getmarq = Axios.get(common.ipapi+'/api/marque/index')
        await Axios.all([getfam,getmarq]).then(Axios.spread((...response) => {
            setFolder(response[0].data)
            setMarque(response[1].data)
            setLoad(false)
          })).catch(errors => {
          setLoad(false)
        })
      })();
      
    },[history,count]) 

    function getFamille(){
      setLoad(true)
      Axios.get(common.ipapi+'/api/famille/indexstep')
          .then(response=>{
            setDatas(response.data)
            setLoad(false)
          }).catch(errors => {
            setLoad(false)
          })
    }
    
    function  onDeleteImg () {
      setImage("")
    }
    const handleClose = () => {
      //alert(count)
      if(count =! 0){getFamille()}
      count=0
      setShowAddFolder(false)
      setIsSave(false)
    }
    function setMarqueIds(d){
      setMarqueId(d.length>0 ? d[0].id : 0)
    }
    function saveFolder(e){
      setLoad(true)
        var formData = new FormData();
        formData.append('remarque',remarque);
        formData.append('libellefam',libellefam);
        formData.append('user_id',common.userid);
        formData.append('parent_id',idParent);
        formData.append('marque_id',marque_id);
        formData.append('etat',active);
        formData.append('photo',imgsTf);
        Axios({
          url:common.ipapi+'/api/famille/create',
          method:'POST',
          headers:{
            'Content-Type':'form-data'
          },data:formData})
        .then(res=>{
            setsIdFolder('')
            count ++
            setMsg(res.data.msg)
            setMsgravity("info")
            setIsSave(true)
            setLoad(false)
        }).catch(error=>{
          setMsg(error)
          setMsgravity("danger")
          setIsSave(true)
          setLoad(false)
        })
    }
    const handleSubmit = (event) => {
      
      const form = event.currentTarget;
      if (form.checkValidity() === false) {
        event.preventDefault();
        event.stopPropagation();
        setValidated(true);
      }else{
        saveFolder()
      }
    };

    function onChangeImage(e) {
      let files = e.target.files || e.dataTransfer.files;
      if (!files.length){
          return;
      }  
      setFile(e.target.value)
      setImage(URL.createObjectURL(e.target.files[0]))
      createImage(files[0]);
    }
    function createImage(file) {
      let reader = new FileReader();
      reader.onload = (e) => {
        setImageTf(e.target.result)
      };
      reader.readAsDataURL(file);
    }
  
    return (
      <>
      <Modal show={load} size="sm" aria-labelledby="contained-modal-title-vcenter" centered>
        <Modal.Body>Veuillez patienter.......<Spinner animation="grow" variant="warning"/></Modal.Body>
      </Modal>
      <Modal show={show}  size="md">
          <Form noValidate validated={validated} onSubmit={handleSubmit}>
              <Modal.Header>
                CREER UNE NOUVELLE FAMILLE <Backspace onClick={handleClose} color="red" size={30}/>
              </Modal.Header>
              <Modal.Body>
              
                  <Form.Group as={Col} md="12" controlId="validationCustomUsername">
                      <InputGroup hasValidation>
                          <InputGroup.Text id="inputGroupPrepend">Libellé famille: <Star color="red" size={10}/></InputGroup.Text>
                                    <Form.Control
                                      type="text"
                                      placeholder="Libellé famille"
                                      aria-describedby="inputGroupPrepend"
                                      required
                                      size="sm"
                                      value={libellefam}
                                      onChange={(e)=>setLibellefam(e.target.value.toUpperCase())}
                                    />
                                    <Form.Control.Feedback type="invalid">
                                      Veuillez renseigner le Libellé famille
                                    </Form.Control.Feedback>
                                  </InputGroup>
                  </Form.Group>
                  <Form.Group as={Col} md="12" controlId="client">
                                <InputGroup hasValidation>
                                <InputGroup.Text id="inputGroupPrepend">Marques:<Star color="red" size={10}/></InputGroup.Text>
                                <Typeahead
                                id="my-typeahead-id" 
                                labelKey={option => `${option.libellemarq}`}
                                filterBy={['libellemarq']}
                                value={marque_id}
                                ref={typeaheadmarref}
                                inputProps={{required : true }}
                                //selected = {marque.slice(marque.findIndex((element) => element.id == marque_id), marque.findIndex((element) => element.id == marque_id)+1)}
                                options={marque}
                                onChange={(cli)=>setMarqueIds(cli)}
                                />
                                <Form.Control.Feedback type="invalid">
                                    Veuillez selectionnez la marque.
                                </Form.Control.Feedback>
                                </InputGroup>
                  </Form.Group>
                  <Form.Group as={Col} md="12" controlId="validationCustomUsername">
                      <InputGroup hasValidation>
                                  <InputGroup.Text id="inputGroupPrepend">Remarque:</InputGroup.Text>
                                  <Form.Control
                                      as="textarea"
                                      placeholder="Ajouter une remarque, descriptions, details....."
                                      aria-describedby="inputGroupPrepend"
                                      rows={2}
                                      value={remarque}
                                      onChange={(e)=>setRemarque(e.target.value)}
                                  />
                      </InputGroup>
                  </Form.Group>
                  <Form.Group as={Col} md="12" controlId="validationCustomUsername" floating>
                    
                      <InputGroup hasValidation>
                      <InputGroup.Text><Folder color="orange" size={17}/></InputGroup.Text>
                                    <Form.Control
                                      type="text"
                                      placeholder="Cliquer ici pour sélectionner un dossier"
                                      aria-describedby="inputGroupPrepend"
                                      autocomplete="off"
                                      size="sm"
                                      value={folderSelect}
                                      onClick={()=>setShowSelectFolder(true)}
                                    />
                                    <Form.Control.Feedback type="invalid">
                                      Veuillez renseigner le Libellé famille
                                    </Form.Control.Feedback>
                                  </InputGroup>
                  </Form.Group>
                  <Form.Group as={Col} md="12" controlId="revision">
                              <InputGroup hasValidation>
                              Stock: 
                              <ButtonGroup>
                                  {[
                                      { name: 'active', value: '1' },
                                      { name: 'désactive', value: '0' },
                                  ].map((radio, idx) => (
                                  <ToggleButton
                                      key={idx}
                                      id={`radiorevision-${idx}`}
                                      type="radio"
                                      size='sm'
                                      variant={idx % 2 ? 'outline-warning' : 'outline-warning'}
                                      value={radio.value}
                                      checked={active === radio.value}
                                      onChange={(e) => setActive(e.currentTarget.value)}
                                  >
                                      {radio.name}
                                  </ToggleButton>
                                  ))}
                              </ButtonGroup>
                              <Form.Control.Feedback type="invalid">
                                  Veuillez selectionnez la revision.
                              </Form.Control.Feedback>
                              </InputGroup>
                        </Form.Group>
                  <Row>
                    {
                      imgs ? <Col md="12">
                      <Trash onClick={()=>onDeleteImg()} color="red" size={15} />
                      <img alt="index" className="imgartUploa rounded" src={imgs}></img>
                      </Col>
                      :
                      <></>
                    }
                        
                        <Col md="12">
                          <Label class="custom-file-upload" title='Ajouter des images.'>
                            <input type="file" accept=".jpg, .png, .jpeg" onChange={(e)=>onChangeImage(e)}/>
                            <Images color="orange" size={50} /> 
                          </Label>
                        </Col>
                      
                  </Row><br></br>
                  { showselectFolder ? <SelectFolder setShowSelectFolder={setShowSelectFolder} show={showselectFolder} idParent={idParent} setIdFolder={setIdFolder} setsIdFolder={setsIdFolder} setFolderSelect={setFolderSelect}></SelectFolder> : <></> }
                  <Row>
                      <Modal.Footer>
                        <div className="ml-auto">
                          <ButtonGroup size="sm">
                            <Button color="danger" onClick={handleClose} title="Fermer pour annuler l'opération"><Backspace color="#000" size={20}/></Button>
                            <Button type="submit" variant="ligth"><Save2Fill color="#000" size={20}/> Enregister</Button>
                          </ButtonGroup>
                        </div>
                      </Modal.Footer>
                  </Row>
            </Modal.Body>
          </Form>
      </Modal>
      </>
    );
  }

export default AddFolder;